import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import {
  Modal,
  ModalAlign,
} from '@teikametrics/tm-design-system/components/Modal';
import { CheckShieldIcon } from '@teikametrics/tm-design-system/img/icons';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import I18nKey from '../../lib/types/I18nKey';

export interface FirstTimeSuccessfulPaymentModalProps {
  readonly onClose: () => void;
}

export const FirstTimeSuccessfulPaymentModal: React.FC<FirstTimeSuccessfulPaymentModalProps> = ({
  onClose,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;

  const firstName = userContext.userInfo.userDetails?.firstName;
  const companyName = account.companyName;

  const SUCCESS_MODAL_HEADER = intl.formatMessage({
    id: I18nKey.ADD_PAYMENT_MODAL_SUCCESS_HEADER,
  });

  const SUCCESS_MODAL_BODY = intl.formatMessage(
    {
      id: I18nKey.ADD_PAYMENT_MODAL_SUCCESS_BODY,
    },
    { firstName, companyName }
  );

  return (
    <Modal
      icon={
        <Icon
          svg={CheckShieldIcon}
          size={IconSize.Large}
          className="text-purple-500 w-388"
        />
      }
      showModal
      align={ModalAlign.Center}
      header={SUCCESS_MODAL_HEADER}
      headerClass="text-xl"
      body={SUCCESS_MODAL_BODY}
      mainAction={{
        label: I18nKey.ADD_PAYMENT_MODAL_SUCCESS_BUTTON_TEXT,
        onClick: onClose,
      }}
    />
  );
};
