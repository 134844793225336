import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flywheel2LogoIcon as FlywheelLogo } from '@teikametrics/tm-design-system/img/branding';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import {
  SurveyQuestionStep,
  SurveyQuestionHeaders,
  SurveyAnswers,
  SurveyQuestion,
  sellProductQuestions,
  chooseProductQuestions,
  accomplishFLywheelQuestions,
} from '../SurveyQuestions';
import I18nKey from '../../../lib/types/I18nKey';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  ButtonState,
} from '@teikametrics/tm-design-system/components/Button';
import { ArrowLeftIcon } from '@teikametrics/tm-design-system/img/icons';
import { TextLink } from '@teikametrics/tm-design-system/components/TextLink';
import classnames from 'classnames';
import { TextInput } from '@teikametrics/tm-design-system/components/TextInput';
import {
  SurveyOption,
  SurveyOptionType,
} from '../../../components/survey/option';

const Gradient: React.FC = () => (
  <div className="h-4 rounded-t bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500" />
);

interface QuestionnaireCardProps extends RouteComponentProps {
  step: SurveyQuestionHeaders;
  title: string;
  onHandleStep: (step: SurveyQuestionHeaders) => void;
  questionsStep: SurveyQuestionStep;
  surveyAnswers: SurveyAnswers;
  saveSurveyAnswers: (
    singleSelect: boolean,
    answer: string,
    checked?: boolean
  ) => void;
  dataTestId?: string;
}

export const MultiSelectQuestionnaireCard: React.FC<QuestionnaireCardProps> = ({
  step,
  title,
  onHandleStep,
  questionsStep,
  surveyAnswers,
  saveSurveyAnswers,
  dataTestId,
}) => {
  const [textInput, setTextInput] = useState<string>('');
  const [showTextInput, setShowTextInput] = useState(false);

  const intl = useIntl();

  const answerState = surveyAnswers[step] || [];

  const [
    BACK,
    INPUT_PLACEHOLDER,
    ADD_OTHER_CHANNELS,
    ADD_OTHER_OBJECTIVE,
    ADD_OTHER_GOAL,
  ] = [
    I18nKey.BACK,
    I18nKey.ONBOARDING_SURVEY_QUESTION_INPUT_PLACEHOLDER_EXAMPLE,
    I18nKey.ONBOARDING_SURVEY_QUESTION_ADD_OTHER_CHANNELS,
    I18nKey.ONBOARDING_SURVEY_QUESTION_ADD_OTHER_OBJECTIVE,
    I18nKey.ONBOARDING_SURVEY_QUESTION_ADD_OTHER_GOAL,
  ].map((id) => intl.formatMessage({ id }));

  const onTextInput = (value: string) => {
    setTextInput(value);
  };
  const gotoNextStep = () => {
    if (textInput) {
      saveSurveyAnswers(false, textInput, false);
    }

    onHandleStep(questionsStep.nextStep!!);
  };

  const goBackStep = () => {
    onHandleStep(questionsStep.previousStep!!);
  };

  const multiSurveyQuestionnaire: {
    [key: string]: { label: string; questions: SurveyQuestion[] };
  } = {
    [SurveyQuestionHeaders.SellProducts]: {
      label: ADD_OTHER_CHANNELS,
      questions: sellProductQuestions,
    },
    [SurveyQuestionHeaders.ChooseProducts]: {
      label: ADD_OTHER_OBJECTIVE,
      questions: chooseProductQuestions,
    },
    [SurveyQuestionHeaders.AccomplishFlywheel]: {
      label: ADD_OTHER_GOAL,
      questions: accomplishFLywheelQuestions,
    },
  };

  // Handles getting saved answers text input
  const getStoredText = () => {
    if (Object.keys(multiSurveyQuestionnaire).includes(step)) {
      const storedText = answerState.filter((item) => {
        const answers = multiSurveyQuestionnaire[step].questions.map(
          (question) => question.value
        );
        if (!answers.includes(item)) {
          return item;
        }
        return '';
      });

      setTextInput(storedText.toString());
      if (storedText.toString()) {
        saveSurveyAnswers(false, storedText.toString(), true);
      }

      if (!!storedText.length) setShowTextInput(true);
    }
  };

  useEffect(() => {
    getStoredText();
  }, []);

  return (
    <div
      className="w-500 min-w-min bg-white border border-solid border-grey-200"
      style={{ minHeight: 370 }}
    >
      <Gradient />
      <div className="p-24 text-center">
        <div>
          <FlywheelLogo />
          <br />
        </div>
        <div className="text-purple-600 text-sm font-medium mt-24 tracking-widest">
          <FormattedMessage id={questionsStep.header} />
        </div>
        <div className="leading-normal text-grey-900 text-lg mt-12 mb-16">
          {title}
        </div>

        {questionsStep.questions.map((item, index) => {
          const isChecked = answerState.includes(item.value);
          const lastRow = questionsStep.questions.length - 1;
          return (
            <div
              key={item.label}
              className={classnames({
                'mb-24': index === lastRow && !showTextInput,
                'mb-12': index !== lastRow || showTextInput,
              })}
            >
              <SurveyOption
                type={SurveyOptionType.Checkbox}
                checked={isChecked}
                onClick={() => saveSurveyAnswers(false, item.value, isChecked)}
                icon={item.icon}
                text={
                  <div className="text-grey-900 text-base">
                    <FormattedMessage id={item.label} />
                  </div>
                }
              />
            </div>
          );
        })}

        {Object.keys(multiSurveyQuestionnaire).includes(step) && (
          <div className="mx-52">
            {showTextInput ? (
              <TextInput
                placeholder={INPUT_PLACEHOLDER}
                value={textInput}
                isDirty={true}
                className="h-42"
                onChange={onTextInput}
              />
            ) : (
              <TextLink
                textLabel={multiSurveyQuestionnaire[step].label}
                onClick={() => setShowTextInput(true)}
                dataTestId={`${dataTestId}_multiSelectQuestionnaireCardAddOtherChannel`}
              />
            )}
          </div>
        )}

        <hr className="text-grey-200 mt-24" />
        <div
          className={classnames('mt-24 mx-52', {
            'flex flex-row justify-between': questionsStep.previousStep,
          })}
        >
          {questionsStep.previousStep && (
            <div className="cursor-pointer" role="button" onClick={goBackStep}>
              <Icon
                svg={ArrowLeftIcon}
                className="text-purple-500 mr-4"
                size={IconSize.Small}
              />
              <TextLink
                textLabel={BACK}
                dataTestId={`${dataTestId}_multiSelectQuestionnaireCardGoBack`}
              />
            </div>
          )}
          <Button
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            state={
              !!answerState.length || textInput
                ? ButtonState.Active
                : ButtonState.Disabled
            }
            onClick={gotoNextStep}
            label={intl.formatMessage({
              id:
                step === SurveyQuestionHeaders.AccomplishFlywheel
                  ? I18nKey.FINISH
                  : I18nKey.ONBOARDING_SURVEY_TERM_NEXT,
            })}
          />
        </div>
      </div>
    </div>
  );
};
