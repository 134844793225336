import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { RouteComponentProps, Router as ReachRouter } from '@reach/router';
import * as Sentry from '@sentry/react';
import { Spinner } from '@teikametrics/tm-design-system/components/Spinner';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { UserProvider } from './containers/userProvider/userProvider';
import LoggedIn from './LoggedIn';
import Logout from './Logout';

const App: React.FC<RouteComponentProps> = () => {
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  if (isAuthenticated) {
    return (
      <UserProvider>
        <ReachRouter>
          <AuthCallback path="/login/callback" />
          <LoggedIn default={true} />
          <Logout path="logout" />
        </ReachRouter>
      </UserProvider>
    );
  } else {
    return <Spinner />;
  }
};

const AuthCallback: React.FC<RouteComponentProps> = () => <Spinner />;

export default Sentry.withProfiler(
  withAuthenticationRequired(App, {
    returnTo: window.location.href,
    onRedirecting: () => <Spinner />,
  })
);
