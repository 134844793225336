import { DateTime } from 'luxon';
import { ColumnManagerOption } from '@teikametrics/tm-design-system/components/ColumnManager';
import { IconSize } from '@teikametrics/tm-design-system/components/Icon';
import { MultiStateIconProps } from '@teikametrics/tm-design-system/components/MultiStateIcon';
import {
  AmazonLogomarkDisabledIcon,
  AmazonLogomarkEnabledIcon,
  WalmartLogomarkDisabledIcon,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system/img/sales-channel';
import { FilterFieldMapper } from 'lib/types/Filter';
import { CurrencyCode } from '../../../../lib/utilities/currency';
import { MerchantCountriesResponse } from '../../../../lib/clients/types';
import {
  AccountSalesChannelPaginatedResult,
  FlywheelSalesChannel,
  MerchantCountry,
  SalesChannelData,
} from '../../../../lib/types/Fam';
import { MoneyWithAmountInString } from '../../../../lib/types/Money';
import {
  MONETARY_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../lib/types/CommonSharedTypes';
import { SkuDetailsTabValues } from './skuCatalogDetails/types';
import { SkuDetailsBottomSectionProps } from './skuDetailsSidebar/skuDetailsBottomSection';
import { SkuDetailsTopSectionProps } from './skuDetailsSidebar/skuDetailsTopSection';

export const CURRENCY_CODE = 'USD';
export const SKU_CATALOG_EXT_ITEM_ID_FILTER_FIELD = 'extItemId';
export const SKU_CATALOG_TABLE_SEARCH_BY_KEY = 'searchText';
export const SKU_AD_PERFORMANCE_TABLE_SEARCH_BY_KEY = 'searchText';
export const SKU_API_REQUEST_DATE_FORMAT = 'yyyy-MM-dd';

export const PRODUCT_GROUP_ID_QUERY_PARAM_KEY = 'product_group_id';

export enum DataFetcherIdentifier {
  TotalCurrent = 'TotalCurrent',
  TotalPrevious = 'TotalPrevious',
}

export interface DateRange {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly minDate?: DateTime;
  readonly maxDate?: DateTime;
}

export const DEFAULT_CURRENCY = 'USD';

export enum SkuCatalogApiColumnValue {
  AdSales = 'adSales',
  AdSpend = 'adSpend',
  ACOS = 'acos',
  ConversionRate = 'conversionRate',
  CampaignCount = 'campaignCount',
  AdGroupCount = 'adGroupCount',
  Cogs = 'cogs',
  EstimatedFees = 'estimatedFees',
  InventoryValue = 'inventoryValue',
  CurrentInventoryQuantity = 'inventoryQuantity',
  SKU = 'sku',
  Tacos = 'tacos',
  AverageOrderPrice = 'averageOrderPrice',
  TotalSales = 'totalSales',
  EstimatedGrossProfit = 'estimatedGrossProfit',
  UnitsSold = 'unitsSold',
  EstimatedGrossMargin = 'estimatedGrossMargin',
  EstimatedPreAdGrossMargin = 'estimatedPreAdGrossMargin',
  Name = 'name',
  ImageUrl = 'imageUrl',
  ExternalItemId = 'extItemId',
  ExternalItemType = 'extItemType',
  SalesChannelId = 'salesChannelId',
  SalesChannelName = 'salesChannelName',
}

export enum ColumnGroupIdentifier {
  Details = 'Details',
  Product = 'Product',
  Profitability = 'Profitability',
  PlatformFees = 'PlatformFees',
  Advertisement = 'Advertisement',
  Inventory = 'Inventory',
}

export const API_COLUMN_VALUE_TO_COLUMN_GROUP_MAPPER: Record<
  SkuCatalogApiColumnValue,
  ColumnGroupIdentifier
> = {
  [SkuCatalogApiColumnValue.SKU]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.Name]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ImageUrl]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ExternalItemId]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ExternalItemType]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.SalesChannelId]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.SalesChannelName]: ColumnGroupIdentifier.Details,

  [SkuCatalogApiColumnValue.Tacos]: ColumnGroupIdentifier.Product,
  [SkuCatalogApiColumnValue.AverageOrderPrice]: ColumnGroupIdentifier.Product,

  [SkuCatalogApiColumnValue.TotalSales]: ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.EstimatedGrossProfit]:
    ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.UnitsSold]: ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.EstimatedGrossMargin]:
    ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.EstimatedPreAdGrossMargin]:
    ColumnGroupIdentifier.Profitability,

  [SkuCatalogApiColumnValue.Cogs]: ColumnGroupIdentifier.PlatformFees,
  [SkuCatalogApiColumnValue.EstimatedFees]: ColumnGroupIdentifier.PlatformFees,

  [SkuCatalogApiColumnValue.AdSales]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.AdSpend]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.ACOS]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.ConversionRate]:
    ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.CampaignCount]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.AdGroupCount]: ColumnGroupIdentifier.Advertisement,

  [SkuCatalogApiColumnValue.InventoryValue]: ColumnGroupIdentifier.Inventory,
  [SkuCatalogApiColumnValue.CurrentInventoryQuantity]:
    ColumnGroupIdentifier.Inventory,
};

export interface ColumnGrouping {
  readonly [ColumnGroupIdentifier.Details]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Advertisement]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Inventory]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.PlatformFees]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Product]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Profitability]: SkuCatalogApiColumnValue[];
}

export interface ColumnGroupingSkuOverview {
  readonly [ColumnGroupIdentifier.Details]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Advertisement]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Inventory]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.PlatformFees]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Product]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Profitability]: SkuOverviewApiColumnValue[];
}

export enum SkuOverviewApiColumnValue {
  TotalSales = 'totalSales',
  EstimatedGrossProfit = 'estimatedGrossProfit',
  UnitsSold = 'unitsSold',
  EstimatedGrossMargin = 'estimatedGrossMargin',
  EstimatedPreAdGrossMargin = 'estimatedPreAdGrossMargin',
  AdSales = 'adSales',
  AdSpend = 'adSpend',
  ACOS = 'acos',
  ConversionRate = 'conversionRate',
  CampaignCount = 'campaignCount',
  AdGroupCount = 'adGroupCount',
  COGS = 'cogs',
  TACOS = 'tacos',
  Channel = 'salesChannelName',
  Merchant = 'merchantCountryName',
  AverageOrderPrice = 'averageOrderPrice',
  EstimatedFees = 'estimatedFees',
  InventoryValue = 'inventoryValue',
  CurrentInventoryQuantity = 'inventoryQuantity',
}

export const SKU_OVERVIEW_API_COLUMN_VALUE_TO_COLUMN_GROUP_MAPPER: Record<
  SkuOverviewApiColumnValue,
  ColumnGroupIdentifier
> = {
  [SkuOverviewApiColumnValue.TACOS]: ColumnGroupIdentifier.Product,
  [SkuOverviewApiColumnValue.AverageOrderPrice]: ColumnGroupIdentifier.Product,

  [SkuOverviewApiColumnValue.TotalSales]: ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.EstimatedGrossProfit]:
    ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.UnitsSold]: ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.EstimatedGrossMargin]:
    ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.EstimatedPreAdGrossMargin]:
    ColumnGroupIdentifier.Profitability,

  [SkuOverviewApiColumnValue.COGS]: ColumnGroupIdentifier.PlatformFees,
  [SkuOverviewApiColumnValue.EstimatedFees]: ColumnGroupIdentifier.PlatformFees,

  [SkuOverviewApiColumnValue.AdSales]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.AdSpend]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.ACOS]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.ConversionRate]:
    ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.CampaignCount]:
    ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.AdGroupCount]: ColumnGroupIdentifier.Advertisement,

  [SkuOverviewApiColumnValue.InventoryValue]: ColumnGroupIdentifier.Inventory,
  [SkuOverviewApiColumnValue.CurrentInventoryQuantity]:
    ColumnGroupIdentifier.Inventory,

  [SkuOverviewApiColumnValue.Channel]: ColumnGroupIdentifier.Details,
  [SkuOverviewApiColumnValue.Merchant]: ColumnGroupIdentifier.Details,
};

export type SkuOverviewTableExtraProps = {
  readonly showPreviousData: boolean;
};

export enum MetricType {
  Numeric,
  Money,
  Percent,
}

export const METRIC_TYPE_TO_DECIMAL_PLACE_LIMIT: Record<MetricType, number> = {
  [MetricType.Numeric]: PERCENTAGE_FRACTION_DIGITS,
  [MetricType.Money]: MONETARY_FRACTION_DIGITS,
  [MetricType.Percent]: PERCENTAGE_FRACTION_DIGITS,
};

export type Metric = number | MoneyWithAmountInString;

export const SALES_CHANNEL_TO_MULTI_STATE_ICON_MAP: Record<
  FlywheelSalesChannel,
  MultiStateIconProps
> = {
  [FlywheelSalesChannel.Amazon]: {
    currentState: 'enabled',
    stateIcons: {
      enabled: AmazonLogomarkEnabledIcon,
      disabled: AmazonLogomarkDisabledIcon,
    },
    size: IconSize.Medium,
  },
  [FlywheelSalesChannel.Walmart]: {
    currentState: 'enabled',
    stateIcons: {
      enabled: WalmartLogomarkEnabledIcon,
      disabled: WalmartLogomarkDisabledIcon,
    },
    size: IconSize.Medium,
  },
};

export interface SkuDetailsTabLevelProps {
  readonly name: string;
  readonly value: SkuDetailsTabValues;
}

export enum SkuCatalogRoutes {
  Catalog = 'sku/*',
}

export enum SkuCatalogDetailsRoutes {
  SkuOverview = ':skuId',
  SkuInventory = ':skuId/inventory',
  SkuAdPerformance = ':skuId/ad-performance',
}

export interface SkuCatalogDetailsRouteParams {
  skuId: string;
}

export const SKU_DETAILS_ROUTES_LIST = [
  SkuCatalogDetailsRoutes.SkuOverview,
  SkuCatalogDetailsRoutes.SkuInventory,
  SkuCatalogDetailsRoutes.SkuAdPerformance,
];

export interface ColumnManagerOptionWithIsSelected extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: SkuCatalogApiColumnValue;
}

export interface SalesChannelDataWithChannelName extends SalesChannelData {
  readonly salesChannelName: string;
}

export interface UserAccountSalesChannelsInfo {
  readonly merchantCountriesResponse: MerchantCountriesResponse;
  readonly salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName;
}

export interface AccountSalesChannelPaginatedResultWithChannelName
  extends AccountSalesChannelPaginatedResult {
  readonly isError?: boolean;
  readonly items: SalesChannelDataWithChannelName[];
}

export interface AccountSalesChannelPaginatedResultWithError
  extends AccountSalesChannelPaginatedResult {
  readonly isError?: boolean;
  readonly salesChannel?: string;
}

export type SkuAdPerformanceTableExtraProps = {
  readonly showPreviousData: boolean;
  readonly merchantInfo: MerchantCountry[];
};

export enum SkuAdPerformanceApiColumnValue {
  SalesChannelName = 'salesChannelName',
  MerchantCountryName = 'merchantCountryName',
  CampaignName = 'campaignName',
  CampaignType = 'campaignType',
  AdGroupName = 'adGroupName',
  AdStatus = 'adStatus',
  Impressions = 'impressions',
  Clicks = 'clicks',
  ClickThroughRate = 'clickThroughRate',
  CostPerClick = 'avgCostPerClick',
  AdSales = 'adSalesTotal',
  AdSpend = 'adSpend',
  ACOS = 'acosTotal',
  AdConversions = 'adConversions',
  ConversionRate = 'conversionRate',
}

export interface SkuOverviewColumnManagerOptionWithIsSelected
  extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: SkuOverviewApiColumnValue;
}

export interface SkuAdPerformanceColumnManagerOptionsWithIsSelected
  extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: SkuAdPerformanceApiColumnValue;
}

export enum SkuPageIdentifier {
  Catalog = 'catalog',
  Overview = 'overview',
  Inventory = 'inventory',
  AdPerformance = 'ad-performance',
}

export interface LoadSkuCatalogDataRequestParams {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly currency: CurrencyCode;
  readonly merchantsId: string[];
  readonly selectedColumns: SkuCatalogApiColumnValue[];
  readonly showPreviousData: boolean;
}

export type SkuDetailSidebarSummaryProps = SkuDetailsBottomSectionProps &
  SkuDetailsTopSectionProps;

export interface SkuDetailsSidebarData extends SkuDetailSidebarSummaryProps {
  readonly productGroupId: string;
}

export const searchTextfilterFieldMapper: FilterFieldMapper[] = [
  {
    alias: 'searchText',
    field: 'searchText',
  },
];

export const DEFAULT_SKU_ELAPSED_TIME_LIMIT_IN_MINUTES = '60';

export const skuCatalogColumns: SkuCatalogApiColumnValue[] = [
  SkuCatalogApiColumnValue.AdSales,
  SkuCatalogApiColumnValue.AdSpend,
  SkuCatalogApiColumnValue.ACOS,
  SkuCatalogApiColumnValue.ConversionRate,
  SkuCatalogApiColumnValue.CampaignCount,
  SkuCatalogApiColumnValue.AdGroupCount,
  SkuCatalogApiColumnValue.Cogs,
  SkuCatalogApiColumnValue.EstimatedFees,
  SkuCatalogApiColumnValue.InventoryValue,
  SkuCatalogApiColumnValue.CurrentInventoryQuantity,
  SkuCatalogApiColumnValue.SKU,
  SkuCatalogApiColumnValue.Tacos,
  SkuCatalogApiColumnValue.AverageOrderPrice,
  SkuCatalogApiColumnValue.TotalSales,
  SkuCatalogApiColumnValue.EstimatedGrossProfit,
  SkuCatalogApiColumnValue.UnitsSold,
  SkuCatalogApiColumnValue.EstimatedGrossMargin,
  SkuCatalogApiColumnValue.EstimatedPreAdGrossMargin,
  SkuCatalogApiColumnValue.Name,
  SkuCatalogApiColumnValue.ImageUrl,
  SkuCatalogApiColumnValue.ExternalItemId,
  SkuCatalogApiColumnValue.ExternalItemType,
  SkuCatalogApiColumnValue.SalesChannelId,
  SkuCatalogApiColumnValue.SalesChannelName,
];

export const skuOverviewColumns: SkuOverviewApiColumnValue[] = [
  SkuOverviewApiColumnValue.TotalSales,
  SkuOverviewApiColumnValue.EstimatedGrossProfit,
  SkuOverviewApiColumnValue.UnitsSold,
  SkuOverviewApiColumnValue.EstimatedGrossMargin,
  SkuOverviewApiColumnValue.EstimatedPreAdGrossMargin,
  SkuOverviewApiColumnValue.AdSales,
  SkuOverviewApiColumnValue.AdSpend,
  SkuOverviewApiColumnValue.ACOS,
  SkuOverviewApiColumnValue.ConversionRate,
  SkuOverviewApiColumnValue.CampaignCount,
  SkuOverviewApiColumnValue.AdGroupCount,
  SkuOverviewApiColumnValue.COGS,
  SkuOverviewApiColumnValue.TACOS,
  SkuOverviewApiColumnValue.Channel,
  SkuOverviewApiColumnValue.Merchant,
  SkuOverviewApiColumnValue.AverageOrderPrice,
  SkuOverviewApiColumnValue.EstimatedFees,
  SkuOverviewApiColumnValue.InventoryValue,
  SkuOverviewApiColumnValue.CurrentInventoryQuantity,
];
export const skuAdPerformanceColumns: SkuAdPerformanceApiColumnValue[] = [
  SkuAdPerformanceApiColumnValue.SalesChannelName,
  SkuAdPerformanceApiColumnValue.MerchantCountryName,
  SkuAdPerformanceApiColumnValue.CampaignName,
  SkuAdPerformanceApiColumnValue.CampaignType,
  SkuAdPerformanceApiColumnValue.AdGroupName,
  SkuAdPerformanceApiColumnValue.AdStatus,
  SkuAdPerformanceApiColumnValue.Impressions,
  SkuAdPerformanceApiColumnValue.Clicks,
  SkuAdPerformanceApiColumnValue.ClickThroughRate,
  SkuAdPerformanceApiColumnValue.CostPerClick,
  SkuAdPerformanceApiColumnValue.AdSales,
  SkuAdPerformanceApiColumnValue.AdSpend,
  SkuAdPerformanceApiColumnValue.ACOS,
  SkuAdPerformanceApiColumnValue.AdConversions,
  SkuAdPerformanceApiColumnValue.ConversionRate,
];

export interface SkuCatalogRedirectLocationState {
  readonly key?: string;
  readonly openManageCogsModal?: boolean;
  readonly merchantCountryIds?: string[];
}
