import {
  Account,
  ProductVertical,
  Role,
  SubscriptionPermission,
  UserDetails,
} from '../../lib/types/Fam';
import { DateTime } from 'luxon';
import { UserContextState } from './userProvider';
import I18nKey from '../../lib/types/I18nKey';
import FWCookie, { PERSISTED_KEYS } from '../../lib/utilities/fwCookie';
import { PRODUCT_SUBSCRIPTIONS } from '../../lib/types/AOSharedTypes';
import {
  BillingBalanceResponse,
  PaymentPlanType,
} from '../../lib/types/Billing';
import { isNotUndefined } from '../../lib/utilities/typeGuards';
import { formatDateRange } from '../../lib/utilities/billingDateRange';

const TEIKAMETRICS_DOMAIN_IDENTIFIER = '@teikametrics.com';

export const getCurrentAccountFromContext = (userContext: UserContextState) => {
  return getCurrentAccountPermissions(userContext.userInfo.userDetails)
    ?.account;
};

export const getCurrentAccountPermissions = (userDetails?: UserDetails) => {
  if (userDetails?.accountPermissions.length === 1) {
    return userDetails?.accountPermissions[0];
  }
  // TODO: change to comparing with userContext.userInfo.currentAccountId
  // https://teikametrics.atlassian.net/browse/PS-438
  const cookie = FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
  return (
    userDetails?.accountPermissions.find(
      (item) => item.account.id === cookie
    ) || userDetails?.accountPermissions[0]
  );
};

export const isTeikametricsUser = (userDetails: UserDetails) =>
  userDetails.email.endsWith(TEIKAMETRICS_DOMAIN_IDENTIFIER);

export const getCurrentAccountSubscriptions = (
  productVerticalName: string,
  userDetails: UserDetails
): ProductVertical | undefined => {
  return getCurrentAccountPermissions(
    userDetails
  )?.subscriptionPermissions.find(
    (subscription) =>
      subscription.subscription.productVertical.name === productVerticalName
  )?.subscription.productVertical;
};

export const getCurrentAccountSubscribedMerchantCountries = (
  userDetails: UserDetails,
  productVertical: PRODUCT_SUBSCRIPTIONS
): string[] => {
  const currentAccountPermissions = getCurrentAccountPermissions(userDetails);
  if (userDetails && currentAccountPermissions) {
    const currentAiSubscriptions = currentAccountPermissions.subscriptionPermissions.find(
      (permission: SubscriptionPermission) =>
        permission.subscription.productVertical.name === productVertical
    );

    if (isNotUndefined(currentAiSubscriptions)) {
      return currentAiSubscriptions.merchantCountries;
    }
  }
  return [];
};

export const isProductSubscribed = (
  productVerticalName: ProductVertical['name'],
  userDetails: UserDetails
) =>
  getCurrentAccountSubscriptions(productVerticalName, userDetails)?.name ===
  productVerticalName;

export const isAOSubscribed = (
  userDetails?: UserDetails
): boolean | undefined => {
  const accountPermissions = getCurrentAccountPermissions(userDetails);
  const subscriptionPermissions = accountPermissions?.subscriptionPermissions;
  return subscriptionPermissions?.some(
    (sub) => sub.subscription.productVertical.name === PRODUCT_SUBSCRIPTIONS.AO
  );
};

export const getCurrentUserFullName = (userDetails?: UserDetails): string => {
  const firstName = userDetails?.firstName;
  const lastName = userDetails?.lastName;
  return `${firstName} ${lastName}`;
};

export const hasStartedTrial = (account?: Account): boolean => {
  return !!account?.freeTrialStartedAt;
};

export const isInTrial = (account?: Account) => {
  const trialDaysLeft = getFreeTrialDaysRemaining(account);
  return !!trialDaysLeft && trialDaysLeft > 0;
};

export const getOutstandingBalanceDateRange = (
  balance: BillingBalanceResponse | undefined
) => {
  if (!balance) {
    return null;
  }
  return formatDateRange(
    DateTime.fromISO(balance.startDate),
    DateTime.fromISO(balance.endDate),
    'MMM d'
  );
};

export const getFreeTrialEndDate = (account: Account) => {
  if (!account.freeTrialStartedAt) {
    return null;
  }

  const trialStart = DateTime.fromISO(account.freeTrialStartedAt);

  const extendedTrial = DateTime.local(2022, 2, 15);

  const extendedDaysLeft = Math.ceil(
    trialStart.until(extendedTrial).length('days')
  );

  return extendedDaysLeft > 29 ? extendedTrial : trialStart.plus({ days: 29 });
};

export const getGracePeriodDate = (userContext?: UserContextState) => {
  if (!userContext?.billingInfo?.delinquentSince) {
    return null;
  }
  const gracePeriod = DateTime.fromISO(
    userContext?.billingInfo?.delinquentSince!!
  )
    .plus({ days: 7 })
    .toFormat('MMM d');

  return gracePeriod;
};

export const getFreeTrialDaysRemaining = (account?: Account) => {
  if (!account) {
    return 0;
  }
  if (!account.freeTrialStartedAt) {
    return undefined;
  }
  const trialExpiration = getFreeTrialEndDate(account);
  if (!trialExpiration) {
    return 0;
  }

  const daysLeft = Math.ceil(
    DateTime.local().until(trialExpiration).length('days')
  );

  if (isNaN(daysLeft)) {
    return 0;
  }
  return daysLeft;
};

export const mapRoleToI18nKey = (userRole: Role): I18nKey => {
  return {
    [Role.ACCOUNT_OWNER]: I18nKey.ACCOUNT_OWNER,
    [Role.ADMIN]: I18nKey.ADMIN,
    [Role.ANALYST]: I18nKey.ANALYST,
  }[userRole];
};

export const isAIPlanEnabled = (userContext: UserContextState) =>
  userContext.userInfo.userDetails
    ? isProductSubscribed(
        PRODUCT_SUBSCRIPTIONS.AI,
        userContext.userInfo.userDetails
      )
    : false;

export const isManagedAccount = (userContext?: UserContextState) => {
  if (!userContext) {
    return false;
  }
  const account = getCurrentAccountFromContext(userContext);
  return (
    account?.isManaged ||
    userContext?.billingInfo?.paymentPlanType === PaymentPlanType.Managed
  );
};

export const isAiSubscribed = (userContext?: UserContextState) => {
  return !!getCurrentAccountSubscriptions(
    PRODUCT_SUBSCRIPTIONS.AI,
    userContext?.userInfo.userDetails!
  )?.id;
};

export const showTrial = (userContext?: UserContextState) => {
  const account = getCurrentAccountFromContext(userContext!);

  return (
    !isManagedAccount(userContext) &&
    isInTrial(account) &&
    isAiSubscribed(userContext)
  );
};

export const showTrialEndedPaymentNeeded = (
  userContext?: UserContextState,
  freeTrialEnforcerEnabled?: boolean
) => {
  if (!freeTrialEnforcerEnabled) {
    return false;
  }

  const currentAccount = getCurrentAccountFromContext(userContext!);
  return (
    !isManagedAccount(userContext) &&
    hasStartedTrial(currentAccount) &&
    getFreeTrialDaysRemaining(currentAccount) === 0 &&
    !isAIPlanEnabled(userContext!)
  );
};

export const hasPaymentInfo = (userContext?: UserContextState): boolean => {
  return userContext?.billingInfo?.hasPayment ?? false;
};

/**
 * @param userContext
 * @returns boolean
 *
 * Logic for showing the paymentReminderCard for an account is
 * 1. should be a self-service account
 * 2. shouldn't have payment info
 * 3. Time to end the free trial is within 10days
 */
export const shouldShowAddPaymentReminderCard = (
  userContext?: UserContextState
): boolean => {
  if (!userContext) {
    return false;
  }
  const hasGivenPayment = hasPaymentInfo(userContext);
  const isManaged = isManagedAccount(userContext);
  const currentAccount = getCurrentAccountFromContext(userContext);
  const remainingFreeTrialDays = getFreeTrialDaysRemaining(currentAccount) || 0;
  const willFreeTrialEndInTenDays =
    remainingFreeTrialDays > 0 && remainingFreeTrialDays <= 10;
  return !hasGivenPayment && !isManaged && willFreeTrialEndInTenDays;
};
