import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system/components/Button';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import {
  PaletteColor,
  Pill,
} from '@teikametrics/tm-design-system/components/Pill';
import { UserType } from '@teikametrics/tm-design-system/components/UserIcon';
import { Flywheel2LogoIcon as FlywheelLogo } from '@teikametrics/tm-design-system/img/branding';
import { ArrowRightIcon as ArrowRight } from '@teikametrics/tm-design-system/img/icons';
import { UserCard } from '@teikametrics/tm-design-system/molecules/UserCard';
import cn from 'classnames';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MerchantCountriesContext } from './containers/merchantCountriesProvider/merchantCountriesProvider';
import {
  getCurrentAccountFromContext,
  mapRoleToI18nKey,
} from './containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from './containers/userProvider/userProvider';
import { Role } from './lib/types/Fam';
import I18nKey from './lib/types/I18nKey';
import FWCookie, { PERSISTED_KEYS } from './lib/utilities/fwCookie';
import * as Segment from './lib/utilities/segment';
import { AsyncRequestNotStarted } from './lib/utilities/asyncRequest';

const Gradient: React.FC = () => (
  <div className="h-4 rounded-t bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500" />
);

type AccountSwitcherProps = RouteComponentProps & {
  redirectUrl: string;
  dataTestId?: string;
};

export const AccountSwitcher: React.FC<AccountSwitcherProps> = (props) => {
  const userContext = useContext<UserContextState>(UserContext);
  const { clearMerchantCountries } = useContext(MerchantCountriesContext);
  const userDetails = userContext.userInfo.userDetails!;
  const accounts = userDetails.accountPermissions;
  const navigate = useNavigate();
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const onChangeClicked = () => {
    navigate('/logout');
  };

  const onAccountClicked = (id: string) => {
    Segment.reset();
    userContext.updateUserInfo({
      ...userContext.userInfo,
      currentAccountId: id,
    });

    // If new account selected is different than the current one, clear merchant countries
    if (id !== accountId) {
      clearMerchantCountries();
    }

    userContext.updateBillingInfo(AsyncRequestNotStarted());
    FWCookie.saveCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID, id);
    navigate(props.redirectUrl);
  };

  return (
    <div className="h-screen bg-grey-50 flex justify-center items-center">
      <div className="w-1/4 min-w-min bg-white border border-solid border-grey-200 rounded">
        <Gradient />
        <div className="p-24 text-center">
          <div>
            <FlywheelLogo />
            <br />
          </div>
          <div className="leading-none text-grey-700 text-xl mt-24">
            <FormattedMessage id={I18nKey.ACCOUNT_SWITCHER_PAGE_HEADER} />
          </div>
          <div className="border border-solid border-grey-200 rounded mt-16 overflow-y-auto max-h-340">
            {accounts?.map((item, index) => (
              <AccountRow
                key={`account-${index}`}
                accountName={item.account.companyName}
                id={item.account.id}
                currentAccount={
                  FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID) ===
                  item.account.id
                }
                role={item.role}
                onClick={(id) => onAccountClicked(id)}
                dataTestId={props.dataTestId}
              />
            ))}
          </div>
          <div className="flex justify-between items-center mt-32 text-left">
            <UserCard
              email={userDetails.email}
              firstName={userDetails.firstName}
              lastName={userDetails.lastName}
              userType={UserType.Current}
              dataTestId={'accountSwitcher'}
            />
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.BlackAndWhiteBorder}
              onClick={onChangeClicked}
              label={
                <FormattedMessage id={I18nKey.ACCOUNT_SWITCHER_PAGE_LOG_OUT} />
              }
              dataTestId={'us_logout'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface AccountRowProps {
  accountName: string;
  id: string;
  role: Role;
  currentAccount?: boolean;
  onClick: (id: string) => void;
  dataTestId?: string;
}

const AccountRow: React.FC<AccountRowProps> = ({
  id,
  accountName,
  role,
  currentAccount,
  onClick,
  dataTestId,
}) => {
  return (
    <div
      onClick={() => onClick(id)}
      className={cn(
        'group flex justify-between items-center cursor-pointer',
        'h-72 px-16 bg-white',
        'leading-none text-left',
        'border-b border-solid border-grey-200',
        'hover:bg-purple-50'
      )}
    >
      <div>
        <div className="text-lg font-semibold text-grey-900 group-hover:text-purple-700 truncate max-w-sm mr-4">
          {accountName}
        </div>
        <div className="mt-4 text-base text-grey-500 group-hover:text-grey-700">
          {<FormattedMessage id={mapRoleToI18nKey(role)} />}
        </div>
      </div>
      {currentAccount ? (
        <Pill
          color={PaletteColor.green}
          text={<FormattedMessage id={I18nKey.ACCOUNT_SWITCHER_PAGE_PILL} />}
          dataTestId={`${dataTestId}_accountSwitcher`}
        />
      ) : (
        <Icon
          size={IconSize.Small}
          svg={ArrowRight}
          className="text-grey-400 group-hover:text-purple-500"
        />
      )}
    </div>
  );
};
