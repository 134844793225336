import React from 'react';
import { SingleSelectQuestionnaireCard } from './SingleSelectQuestionnareCard';
import {
  surveyQuestionsSteps,
  SurveyQuestionStep,
  QuestionnaireProps,
} from '../SurveyQuestions';
import { useIntl } from 'react-intl';

interface AnnualSalesQuestionnaireProps extends QuestionnaireProps {
  companyName: string;
}

export const AnnualSalesQuestionnaire: React.FC<AnnualSalesQuestionnaireProps> = ({
  step,
  nextStep,
  companyName,
  surveyAnswers,
  saveSurveyAnswers,
}) => {
  const intl = useIntl();
  const questions: SurveyQuestionStep = surveyQuestionsSteps[step];
  const formattedTitle = intl.formatMessage(
    {
      id: questions.title,
    },
    { company: companyName }
  );

  return (
    <>
      <SingleSelectQuestionnaireCard
        step={step}
        title={formattedTitle}
        onHandleStep={nextStep}
        questionsStep={questions}
        surveyAnswers={surveyAnswers}
        saveSurveyAnswers={saveSurveyAnswers}
        dataTestId={'annualSalesQuestionnaire'}
      />
    </>
  );
};
