import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import {
  DataInspectionColumnGroupIdentifier,
  DATA_INSPECTOR_COLUMN_NAME,
} from '../dataInspectorConfig';
import produce from 'immer';
import {
  DataInspectionAdLevelConfigProps,
  DataInspectorColumnManagerConfigProps,
} from '../types';
import { WritableDraft } from 'immer/dist/internal';

function selectAndUpdateFwSettingsColumnsConfig(
  perAdLevelConfig: WritableDraft<DataInspectionAdLevelConfigProps>,
  shouldDisableFwSettingsColumns: boolean
) {
  let newColumnsConfig = { ...perAdLevelConfig.columns };
  const fwSettingsKeyName =
    DataInspectionColumnGroupIdentifier.FlywheelSettings;

  if (newColumnsConfig[fwSettingsKeyName]) {
    for (const columnName in newColumnsConfig[fwSettingsKeyName]) {
      newColumnsConfig[fwSettingsKeyName][
        columnName
      ].isDisabled = shouldDisableFwSettingsColumns;
      newColumnsConfig[fwSettingsKeyName][columnName].isSelected = true;
    }
  }
  return {
    ...perAdLevelConfig,
    columns: newColumnsConfig,
  };
}

// Traverse through config and disable Fw settings columns wherever present
export const disableFwSettingsColumnsIfBasicPlan = (
  isAIPlanEnabled: boolean,
  config: DataInspectorColumnManagerConfigProps
) => {
  if (isAIPlanEnabled) {
    return config;
  }

  return produce(config, (draft) => {
    for (const key in draft) {
      const salesChannel = key as FlywheelSalesChannel;
      draft[salesChannel] = draft[salesChannel].map((perAdTypeConfig) => {
        return {
          ...perAdTypeConfig,
          config: perAdTypeConfig.config.map((perAdLevelConfig) =>
            selectAndUpdateFwSettingsColumnsConfig(perAdLevelConfig, true)
          ),
        };
      });
    }
  });
};

// filter Target Summary columns based on the Feature Flag
export const filterTargetSummaryColumns = (
  featureEnabled: boolean | undefined,
  config: DataInspectorColumnManagerConfigProps
) => {
  if (featureEnabled) {
    return config;
  }
  const updatedConfig: any = {};
  for (const key in config) {
    const salesChannel = key as FlywheelSalesChannel;
    let perAdTypeConfigArray: any = [];
    config[salesChannel].forEach((perAdTypeConfig) => {
      if (perAdTypeConfig.adType === AdType.SponsoredProducts) {
        const result = perAdTypeConfig.config.map(({ adLevel, columns }) => {
          if (adLevel === AdLevel.KeywordTargets) {
            const detailColumns =
              columns[DataInspectionColumnGroupIdentifier.Details];
            const copyOfDetailsColumns = { ...detailColumns };
            delete copyOfDetailsColumns[DATA_INSPECTOR_COLUMN_NAME.TargetType];
            delete copyOfDetailsColumns[DATA_INSPECTOR_COLUMN_NAME.DateAdded];
            return {
              adLevel,
              columns: {
                ...columns,
                [DataInspectionColumnGroupIdentifier.Details]: copyOfDetailsColumns,
              },
            };
          }
          return { adLevel, columns };
        });
        perAdTypeConfigArray.push({
          adType: AdType.SponsoredProducts,
          config: result,
        });
      } else {
        perAdTypeConfigArray.push(perAdTypeConfig);
      }
    });
    updatedConfig[salesChannel] = perAdTypeConfigArray;
  }
  return updatedConfig;
};

// Traverse through config and enable Fw settings columns wherever present
export const enableFwSettingsColumns = (
  config: DataInspectorColumnManagerConfigProps
) =>
  produce(config, (draft) => {
    for (const key in draft) {
      const salesChannel = key as FlywheelSalesChannel;
      draft[salesChannel] = draft[salesChannel].map((perAdTypeConfig) => {
        return {
          ...perAdTypeConfig,
          config: perAdTypeConfig.config.map((perAdLevelConfig) =>
            selectAndUpdateFwSettingsColumnsConfig(perAdLevelConfig, false)
          ),
        };
      });
    }
  });
