import I18nKey from '../../../lib/types/I18nKey';

export const OPPORTUNITY_BREAKDOWN = [
  {
    header: I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_TITLE,
    list: [
      I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_PAUSED,
      I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_NEW,
      I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_AUTOMATE,
    ],
  },
  {
    header:
      I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_TITLE,
    list: [
      I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_DAILY,
      I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_BIDDING,
    ],
  },
];
