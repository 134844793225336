import React from 'react';
import classNames from 'classnames';

export const BarsLoader = () => {
  const barClassName =
    'w-4 h-28 bg-white opacity-0 rounded-sm animate-spinner-bounce-4-bars';

  return (
    <div
      className={classNames(
        'w-64 h-64 flex-none flex items-center',
        'justify-center rounded-full bg-purple-100',
        'bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500'
      )}
    >
      <div className="relative flex items-center justify-center">
        <div
          className={`absolute block right-10 ${barClassName} animation-delay-0`}
        />
        <div
          className={`absolute block right-2 ${barClassName} animation-delay-450`}
        />
        <div
          className={`absolute block left-2 ${barClassName} animation-delay-900`}
        />
        <div
          className={`absolute block left-10 ${barClassName} animation-delay-1350`}
        />
      </div>
    </div>
  );
};
