import React from 'react';
import { SalesChannelPreview } from './SalesChannelPreview';
import { FormattedMessage } from 'react-intl';
import I18nKey from '../../../lib/types/I18nKey';
import { AIOnboardingResponse } from '../../../lib/types/AIPoweredSharedTypes';
import { groupElementsBySalesChannelId } from '../../../lib/utilities/aiPoweredUtilities';

interface PreviewBreakdownProps {
  readonly aiOnboardingData: AIOnboardingResponse;
}

export const PreviewBreakdown: React.FC<PreviewBreakdownProps> = ({
  aiOnboardingData,
}) => {
  const dataGroupedBySalesChannelId = groupElementsBySalesChannelId(
    aiOnboardingData
  );

  return (
    <div className="max-w-1024 w-full text-base text-grey-900">
      <div className="flex justify-end font-semibold">
        <div className="flex items-center w-2/3 pl-48 pr-12">
          <span className="w-1/4 text-center">
            <FormattedMessage
              id={
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_ADVERTISED_PRODUCTS_SUB_TITLE
              }
            />
          </span>
          <span className="w-1/4 text-right">
            <FormattedMessage
              id={
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CAMPAIGNS_SUB_TITLE
              }
            />
          </span>
          <span className="w-1/4 text-right">
            <FormattedMessage
              id={
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_TARGETS_SUB_TITLE
              }
            />
          </span>
          <span className="w-1/4 text-right">
            <FormattedMessage
              id={
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_DAILY_AD_BUDGET_SUB_TITLE
              }
            />
          </span>
        </div>
      </div>
      {Object.keys(dataGroupedBySalesChannelId).map((key, index) => (
        <SalesChannelPreview
          key={`preview_${index}`}
          onboardingData={dataGroupedBySalesChannelId[key]}
        />
      ))}
    </div>
  );
};
