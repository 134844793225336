import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from '../../../lib/types/I18nKey';
import { BarsLoader } from './BarsLoader';
import { Header } from './Header';
import { UpgradeStatus } from '../Upgrade';

interface ProcessingProps {
  readonly count?: number;
}

export const Processing: React.FC<ProcessingProps> = ({ count = 0 }) => {
  const intl = useIntl();
  return (
    <div className="flex flex-col">
      <div className="mt-40">
        <Header status={UpgradeStatus.Submit} />
      </div>
      <div className="flex-1" />
      <div className="flex flex-col items-center justify-center">
        <BarsLoader />
        <div className="mt-16 font-semibold text-center">
          <FormattedMessage
            id={
              I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PROCESSING_SUMMARY_DESCRIPTION
            }
            values={{
              products: (
                <span className="text-purple-500">
                  {intl.formatNumber(count)}
                </span>
              ),
            }}
          />
        </div>
      </div>
      <div className="flex-1" />
    </div>
  );
};
