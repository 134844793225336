import { SvgIcon } from '@teikametrics/tm-design-system/components/Icon';
import {
  AmazonLogomarkEnabledIcon as AmazonIcon,
  EbayLogomarkEnabledIcon as EbayIcon,
  GoogleLogomarkEnabledIcon as GoogleShoppingIcon,
  TargetLogomarkEnabledIcon as TargetIcon,
  WalmartLogomarkEnabledIcon as WalmartIcon,
} from '@teikametrics/tm-design-system/img/sales-channel';
import { AD_LEVEL_CONFIG } from '../../modules/advertising_optimization/containers/adsManager/dataInspectorConfig';
import {
  AdLevel,
  AdType,
  AdvertisingTypeInfo,
  FlywheelSalesChannel,
  ICON_BY_MERCHANT_NAME,
  SALES_CHANNEL_DETAILS_AD_LEVEL_CONFIG,
} from '../types/AOSharedTypes';
import { AllSalesChannel, MerchantCountry, SalesChannel } from '../types/Fam';

export enum HttpCode {
  SUCCESS = 200,
  ACCEPTED = 202,
}

export const getSalesChannelsWithMerchantCountriesInfo = (
  merchantCountryInfoItems: MerchantCountry[],
  flywheelSalesChannels: AllSalesChannel[]
): SalesChannel[] => {
  const salesChannelIds = flywheelSalesChannels.map((channel) => channel.id);
  const subscribedSalesChannelIds = salesChannelIds.filter(
    (channelId: string) =>
      merchantCountryInfoItems.some(
        (merchantCountryInfo: MerchantCountry) =>
          merchantCountryInfo.salesChannelId === channelId
      )
  );
  const subscribedChannelsInfo: SalesChannel[] = subscribedSalesChannelIds.map(
    (channelId: string) => ({
      salesChannelId: channelId,
      name: flywheelSalesChannels.find(
        (channelInfo) => channelInfo.id === channelId
      )!.name,
      merchantCountries: merchantCountryInfoItems.filter(
        (merchantCountryInfo: MerchantCountry) =>
          merchantCountryInfo.salesChannelId === channelId
      ),
    })
  );
  return subscribedChannelsInfo;
};

export const getAdTypeInfoItems = (
  adTypes: AdType[] | undefined = [],
  adLevel: AdLevel,
  salesChannel: FlywheelSalesChannel,
  adsManagerDetailsPageFlag: boolean
): AdvertisingTypeInfo[] =>
  adTypes.map((adType: AdType) => ({
    adType: adType,
    adLevels: getAdLevels(
      adLevel,
      adType,
      salesChannel,
      adsManagerDetailsPageFlag
    ),
  }));

export const getAdLevels = (
  adLevel: AdLevel,
  adType: AdType,
  salesChannel: FlywheelSalesChannel,
  adsManagerDetailsPageFlag: boolean
): AdLevel[] =>
  adsManagerDetailsPageFlag
    ? getDetailsPageDropDownOptions(adType, adLevel, salesChannel)
    : (AD_LEVEL_CONFIG[salesChannel] &&
        AD_LEVEL_CONFIG[salesChannel][adType]) ||
      [];

export const getDetailsPageDropDownOptions = (
  adType: AdType,
  adLevel: AdLevel,
  salesChannel: FlywheelSalesChannel
): AdLevel[] =>
  SALES_CHANNEL_DETAILS_AD_LEVEL_CONFIG[salesChannel]?.[adType]?.[adLevel] ||
  [];

// Sales Channel Helpers
export const getChannelIcon = (channelName: string): SvgIcon | undefined => {
  const platformIcons: Map<string, SvgIcon> = new Map();
  platformIcons.set(FlywheelSalesChannel.Amazon, AmazonIcon);
  platformIcons.set(FlywheelSalesChannel.Walmart, WalmartIcon);
  platformIcons.set('eBay', EbayIcon);
  platformIcons.set('google_shopping', GoogleShoppingIcon);
  platformIcons.set('target', TargetIcon);
  return platformIcons.get(channelName.toLowerCase());
};

export const getMerchantlIcon = (merchantName: string): SvgIcon | undefined => {
  return ICON_BY_MERCHANT_NAME[merchantName];
};
