import React from 'react';
import './animatedLoader.scss';

interface AnimatedLoaderProps {
  name: string;
  companyName: string;
}
export const AnimatedLoader: React.FC<AnimatedLoaderProps> = ({
  name,
  companyName,
}) => {
  return (
    <>
      <style>
        {`@keyframes roloCopy {
                0% {
                    content: 'Creating your ${companyName} account';
                }
                50% {
                    content: 'Finishing some details';
                }
                100% {
                    content: 'Your ${companyName} account is ready, ${name}';
                }
            }
        `}
      </style>
      <div className="flex flex-col items-center px-32">
        <div className="flex flex-col overflow-hidden w-64 h-64 mt-72 rounded-full bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500">
          <div
            id="animation_Start"
            className="flex justify-center align-middle items-center min-h-64"
          >
            <div
              id="hello_One"
              className="bg-white rounded-full mr-4"
              style={{ width: 4, height: 32 }}
            ></div>
            <div
              id="hello_Two"
              className="bg-white rounded-full mr-4"
              style={{ width: 4, height: 32 }}
            ></div>
            <div
              id="hello_Three"
              className="bg-white rounded-full mr-4"
              style={{ width: 4, height: 32 }}
            ></div>
            <div
              id="hello_Four"
              className="bg-white rounded-full mr-4"
              style={{ width: 4, height: 32 }}
            ></div>
            <div
              id="hello_Five"
              className="bg-white rounded-full"
              style={{ width: 4, height: 32 }}
            ></div>
          </div>
          <div className="flex justify-center align-middle items-center h-64">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9621 0L16.9924 0.727292C10.5329 5.57191 7.67957 6.86887 3.41625 6.86887H1.80005V8.48508C1.80005 18.8021 4.6892 26.1855 9.54635 31.0426C11.2716 32.7679 13.126 34.0454 14.9882 34.942C16.1291 35.4913 17.0414 35.7954 17.6115 35.9221L17.9621 36L18.3127 35.9221C18.8828 35.7954 19.7951 35.4913 20.936 34.942C22.7982 34.0454 24.6526 32.7679 26.3779 31.0426C31.235 26.1855 34.1242 18.8021 34.1242 8.48508V6.86887H32.508C28.3664 6.86887 25.5555 5.64496 19.4768 1.13391L17.9621 0ZM17.9621 4.03243L18.1532 4.17354C23.418 8.02459 26.6139 9.60807 30.4049 9.9995L30.8675 10.0399L30.8388 10.7949C30.4526 19.0432 27.9734 24.8758 24.0922 28.757C22.6356 30.2136 21.0807 31.2847 19.5338 32.0296L19.2408 32.1671C18.8617 32.3403 18.5297 32.4734 18.2548 32.5717L17.9621 32.6684L17.8647 32.6389C17.4961 32.5172 16.9935 32.3199 16.3905 32.0296C14.8435 31.2847 13.2887 30.2136 11.832 28.757L11.4831 28.3988C7.8025 24.5201 5.45993 18.7932 5.08539 10.7949L5.05509 10.0399L5.11069 10.0369C8.94764 9.72927 12.0943 8.26798 17.2147 4.57753L17.9621 4.03243ZM23.9536 13.8071C23.3224 13.1759 22.2991 13.1759 21.6679 13.8071L16.3459 19.1278L14.2563 17.0395L14.1041 16.905C13.47 16.4121 12.5533 16.4569 11.9707 17.0395C11.3395 17.6707 11.3395 18.694 11.9707 19.3251L16.3459 23.7004L23.9536 16.0927L24.088 15.9405C24.581 15.3065 24.5362 14.3897 23.9536 13.8071Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        id="rolodex_Headline"
        className="text-xl text-grey-900 text-center leading-none mt-24"
      ></div>
      <div
        id="rolodex_Copy"
        className="text-base text-grey-600 text-center leading-normal mt-8 mb-24"
      ></div>
    </>
  );
};
