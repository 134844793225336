import { IdToken } from '@auth0/auth0-react';
import { createContext, useEffect, useState } from 'react';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { AllSalesChannel } from '../../lib/types/Fam';

interface SalesChannelContextState {
  readonly salesChannels: AllSalesChannel[];
  readonly areSalesChannelsLoaded: boolean;
}

const initialState: SalesChannelContextState = {
  salesChannels: [],
  areSalesChannelsLoaded: false,
};

export const SalesChannelContext = createContext<SalesChannelContextState>(
  initialState
);

const { Provider } = SalesChannelContext;

export const SalesChannelProvider: React.FC<
  Readonly<{
    idToken: IdToken;
  }>
> = ({ children, idToken }) => {
  const [salesChannels, setSalesChannels] = useState<AllSalesChannel[]>([]);
  const [areSalesChannelsLoaded, setSalesChannelsLoaded] = useState<boolean>(
    false
  );

  const famApiClient = createFAMApiClient(idToken);

  useEffect(() => {
    famApiClient
      .getAllSalesChannels()
      .then((salesChannelsResponse) => {
        setSalesChannels(salesChannelsResponse);
        setSalesChannelsLoaded(true);
      })
      .catch(() => {
        setSalesChannelsLoaded(true);
      });
  }, []);

  return (
    <Provider
      value={{
        salesChannels,
        areSalesChannelsLoaded,
      }}
    >
      {children}
    </Provider>
  );
};
