import React from 'react';
import {
  Icon,
  IconSize,
  SvgIcon,
} from '@teikametrics/tm-design-system/components/Icon';
import {
  Checkbox,
  CheckboxCheckedState,
} from '@teikametrics/tm-design-system/components/Checkbox';
import RadioButton from '@teikametrics/tm-design-system/components/RadioButton';
import classNames from 'classnames';
import noop from 'lodash/noop';

export enum SurveyOptionType {
  Radio = 'Radio',
  Checkbox = 'Checkbox',
}

export interface SurveyOptionProps {
  readonly icon?: SvgIcon;
  readonly text: string | JSX.Element;
  readonly topContent?: JSX.Element;
  readonly type: SurveyOptionType;
  readonly checked?: boolean;
  readonly onClick?: () => void;
  readonly dataTestId?: string;
  backgroundColor?: string;
  padding?: string;
}

export const SurveyOption: React.FC<SurveyOptionProps> = ({
  icon,
  text,
  type,
  checked = false,
  onClick,
  dataTestId,
  topContent,
  backgroundColor,
  padding,
}) => {
  return (
    <div
      className={classNames(
        `${
          padding ? padding : 'py-16 px-12'
        } flex flex-col border border-solid border-grey-200 ${backgroundColor}
       rounded-md cursor-pointer mx-52
      hover:border-grey-300 hover:shadow-sm hover:bg-purple-50`,
        { 'shadow-sm': checked }
      )}
      onClick={onClick}
    >
      {topContent}
      <div className="flex items-center">
        {type === SurveyOptionType.Checkbox && (
          <Checkbox
            checked={
              checked
                ? CheckboxCheckedState.Checked
                : CheckboxCheckedState.Unchecked
            }
            dataTestId={`${dataTestId}_surveyOption`}
          />
        )}
        {type === SurveyOptionType.Radio && (
          <RadioButton
            selected={checked}
            disabled={false}
            onChange={noop}
            dataTestId={`${dataTestId}_suveryOption`}
          />
        )}
        {icon && (
          <Icon className="ml-8 mr-4" svg={icon} size={IconSize.Medium} />
        )}
        <span
          className={classNames('text-base text-grey-900 leading-none', {
            'ml-8': !icon,
            'font-medium': checked,
          })}
        >
          {text}
        </span>
      </div>
    </div>
  );
};
