import React from 'react';
import {
  Typography,
  TypographySize,
} from '@teikametrics/tm-design-system/components/Typography';
import { FormattedMessage } from 'react-intl';
import I18nKey from '../../../lib/types/I18nKey';
import AdStructure from '../img/AdStructure.svg';
import { OpportunityUL } from './OpportunityUL';
import { Separator } from './Separator';
import { OPPORTUNITY_BREAKDOWN } from '../constants/opportunity.constant';

export const OpportunityBreakdown = () => {
  return (
    <div className="flex flex-row items-center gap-52">
      <div className="flex flex-col gap-28 items-center">
        <img
          src={AdStructure}
          className="shadow-lg rounded-lg"
          alt="AdStructure"
          width={484}
          height={256}
        />
        <Typography
          size={TypographySize.base}
          className="max-w-340 text-center"
        >
          <FormattedMessage
            id={I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AD_STRUCTURE}
          />
        </Typography>
      </div>
      <div className="flex flex-col items-start gap-32">
        {OPPORTUNITY_BREAKDOWN.map((item, index) => {
          return (
            <div key={index} className="flex flex-row gap-16">
              <Separator />
              <OpportunityUL title={item.header} list={item.list} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
