import { Moment } from 'moment';

export const getTrailingDays = (m1: Moment, m2: Moment) => {
  return 1 + Math.abs(m1.diff(m2, 'days'));
};
export const convertToUTCDate = (m: Moment) => {
  const date = m.toDate();
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return date;
};
