import { Money } from './Money';
export interface ZuoraSignature {
  readonly token: string;
  readonly tenantId: string;
  readonly signature: string;
  readonly key: string;
  readonly success: boolean;
}

export interface AccountStatusResponse {
  id: string;
  hasPayment: boolean;
  isDelinquent: boolean;
  isFreemium: boolean;
  nextBillingDate: string;
  createdAt: string;
  delinquentSince: string | null;
  paymentPlanType?: PaymentPlanType;
}

export enum PaymentPlanType {
  SelfService = 'SELF_SERVICE',
  Managed = 'MANAGED',
}

export interface BillingInfoResponse {
  zuoraAccountId: string;
  zuoraAccountNumber: string;
  zuoraBillingInfo: PaymentInfo;
}

export interface BillingBalanceResponse {
  startDate: string;
  endDate: string;
  balance: Money;
}

export interface PaymentInfo {
  cardNumber: string;
  expDate: string;
  zuoraCardType?: string;
  cardType: CreditCardType;
}

export enum CreditCardType {
  Amex = 'amex',
  Barclays = 'barclays',
  Citi = 'citi',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Visa = 'visa',
  Generic = 'generic',
}

export interface BillingEstimateResponseType {
  adSpend: BillingAdSpend;
  endDate: Date;
  gms?: Money;
  isFreemium: boolean;
  startDate: Date;
  terms: BillingTerms;
  totalBill: Money;
}
export interface ItemizedEstimate {
  readonly amount: Money;
  readonly quantity: Money | number;
  readonly rate: string;
}

export interface BillingAdSpend {
  readonly amazonAdSpend: ItemizedEstimate;
  readonly walmartAdSpend: ItemizedEstimate;
}

export interface BillingTerms {
  readonly amazonTerms?: ItemizedEstimate;
  readonly walmartTerms?: ItemizedEstimate;
}
