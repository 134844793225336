export const DEFAULT_RETRY = 3;
export const DEFAULT_RETRY_INTERVAL = 1000;

export enum ClientError {
  HTTP_400_BAD_REQUEST = 400,
  HTTP_401_UNAUTHORIZED = 401,
  HTTP_402_PAYMENT_REQUIRED = 402,
  HTTP_403_FORBIDDEN = 403,
  HTTP_404_NOT_FOUND = 404,
  HTTP_405_REQUEST_METHOD_NOT_ALLOWED = 405,
  HTTP_408_REQUEST_TIMEOUT = 408,
  HTTP_414_URL_LENGTH_EXCEEDS = 414,
  HTTP_429_TOO_MANY_REQUESTS = 429,
}
