import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flywheel2LogoIcon as FlywheelLogo } from '@teikametrics/tm-design-system/img/branding';
import { AnimatedLoader } from './AnimatedLoader/AnimatedLoader';

const Gradient: React.FC = () => (
  <div className="h-4 rounded-t bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500" />
);

interface CompletedSurveydProps extends RouteComponentProps {
  name: string;
  companyName: string;
  onComplete: () => void;
}

export const CompletedSurvey: React.FC<CompletedSurveydProps> = ({
  name,
  companyName,
  onComplete,
}) => {
  useEffect(() => {
    onComplete();
  }, []);

  return (
    <div
      className="w-500 min-w-min bg-white border border-solid border-grey-200"
      style={{ minHeight: 380 }}
    >
      <Gradient />
      <div className="p-24 text-center">
        <FlywheelLogo />

        {name && companyName && (
          <AnimatedLoader name={name} companyName={companyName} />
        )}
      </div>
    </div>
  );
};
