import React, { useContext } from 'react';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import Axios, { AxiosError, AxiosInstance } from 'axios';
import {
  NotificationContextState,
  NotificationContext,
} from '../notificationProvider';
import { useIntl } from 'react-intl';
import { handleErrorResponse } from '../../lib/utilities/errorHandler';
import { useNavigate } from '@reach/router';
import {
  DEFAULT_RETRY,
  DEFAULT_RETRY_INTERVAL,
  ClientError,
} from '../../lib/types/HttpErrorCodes';

export const dummyAxiosInstance = (undefined as unknown) as AxiosInstance;

export const AxiosContext = React.createContext<AxiosInstance>(
  dummyAxiosInstance
);

export function AxiosProvider({ children }: { children: React.ReactNode }) {
  const toasts = useContext<NotificationContextState>(NotificationContext);
  const intl = useIntl();
  const navigate = useNavigate();

  const axios = React.useMemo(() => {
    const axiosInstance = Axios.create();

    axiosRetry(axiosInstance, {
      retries: DEFAULT_RETRY,
      retryDelay: (retryCount: number) => retryCount * DEFAULT_RETRY_INTERVAL,
      retryCondition: (error: AxiosError) => {
        if (
          isNetworkOrIdempotentRequestError(error) ||
          error?.response?.status === ClientError.HTTP_429_TOO_MANY_REQUESTS
        ) {
          return true;
        } else {
          handleErrorResponse(error, toasts, intl, navigate);
          return false;
        }
      },
    });

    return axiosInstance;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}

export const useAxios = () => useContext(AxiosContext);
