import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import { OptimizelyContextState } from '../../containers/optimizelyProvider/optimizelyProvider';
import { TableCell, TableChange } from '../../containers/tableV2/ducks/types';
import { isManagedAccount } from '../../containers/userProvider/selectors';
import { UserContextState } from '../../containers/userProvider/userProvider';
import { TableCellChangeAIPoweredSettings } from '../../modules/ai-powered/containers/product-settings/types';
import { AIPoweredApiClient } from '../clients/AIPoweredApiClient';
import {
  AIOnboardingData,
  AIOnboardingRequest,
  AIOnboardingResponse,
  AIOnboardingRunRequest,
  AIOnboardingStatus,
  AIProductSettingsResponse,
  AIUpdateSkusData,
  AIUpdateSkuTargetsRequest,
  OnboardingRequest,
  OnboardingRunData,
} from '../types/AIPoweredSharedTypes';
import { MerchantCountry } from '../types/Fam';
import { OptimizelyFlags } from '../types/OptimizelyFlags';
import { SkuProductGroupId } from '../types/SKUSharedTypes';

export const mergeOnboardingResponseWithCountryCode = (
  aiOnboardingResponse: AIOnboardingResponse,
  merchantCountries: MerchantCountry[]
) => {
  aiOnboardingResponse.elements.forEach((onboardingElement) => {
    const currentCountry = merchantCountries.find(
      (country) =>
        country.merchantCountryId === onboardingElement.merchantCountryId &&
        country.salesChannelId === onboardingElement.salesChannelId
    );
    onboardingElement.countryCode = currentCountry?.country;
    onboardingElement.merchantCountryName = currentCountry?.merchantName;
  });
  return aiOnboardingResponse;
};

export const buildAiOnboardingRequest = (
  merchantCountries: MerchantCountry[]
): AIOnboardingRequest => {
  const countries = merchantCountries.map((country) => ({
    merchantCountryId: country.merchantCountryId || '',
    salesChannelId: country.salesChannelId || '',
  }));
  return { merchantCountries: countries };
};

export const buildAiOnboardingRequestFromOnboardingData = (
  onboardingData: AIOnboardingData[]
): AIOnboardingRequest => {
  const countries = onboardingData.map((data) => ({
    merchantCountryId: data.merchantCountryId,
    salesChannelId: data.salesChannelId,
  }));
  return { merchantCountries: countries };
};

export const buildOnboardingRequest = (
  aiOnboardingResponse?: AIOnboardingResponse
): OnboardingRequest => {
  const countries = aiOnboardingResponse?.elements.map((aiOnboardingData) => ({
    salesChannelId: aiOnboardingData.salesChannelId,
    merchantCountryId: aiOnboardingData.merchantCountryId,
    runId: aiOnboardingData.onboardingData.runId,
  }));
  return { merchantCountries: countries };
};

export const buildOnboardingRequestFromOnboardingData = (
  onboardingData: AIOnboardingData[]
) => {
  const countries = onboardingData.map((data) => ({
    salesChannelId: data.salesChannelId,
    merchantCountryId: data.merchantCountryId,
    runId: data.onboardingData.runId,
  }));
  return { merchantCountries: countries };
};

export const buildOnboardingRunRequest = (
  onboardingRunData: OnboardingRunData[]
): AIOnboardingRunRequest => {
  const merchantCountries = onboardingRunData.map((runData) => ({
    merchantCountryId: runData.merchantCountryId,
    salesChannelId: runData.salesChannelId,
    runId: runData.run.id,
  }));
  return { merchantCountries: merchantCountries || [] };
};

export const buildCampaignUpdateRequest = (
  settingsTableData: AIProductSettingsResponse[],
  tableChange: TableChange,
  merchantCountryId: string
): AIUpdateSkuTargetsRequest => {
  const updatedCells = tableChange.cell as TableCellChangeAIPoweredSettings;
  const aiUpdateSkusData = Object.entries(updatedCells).map(
    ([id, { dailyBudget, acosTarget }]): AIUpdateSkusData => {
      return buildAIUpdateSkusData(
        settingsTableData,
        id,
        dailyBudget,
        acosTarget
      );
    }
  );

  return { merchantCountryId, updates: aiUpdateSkusData };
};

export const buildAIUpdateSkusData = (
  settingsTableData: AIProductSettingsResponse[],
  id: string,
  dailyBudget?: TableCell,
  acosTarget?: TableCell
) => {
  const settingsData = settingsTableData.find(
    (settingsData) => settingsData.skuDetailsFields.sku === id
  );
  const productId = settingsData?.skuDetailsFields.productId ?? '';
  const sku = settingsData?.skuDetailsFields.sku ?? '';
  let data: AIUpdateSkusData = { productId, sku };

  if (dailyBudget?.value && dailyBudget?.isValid && dailyBudget?.isDirty) {
    const campaignIds = settingsData?.advertisementFields.campaignIds ?? [];
    const currentDailyBudget = parseFloat(
      settingsData?.aiPoweredSettings.dailyBudget?.amount || '0'
    );
    const newDailyBudget = parseFloat(dailyBudget?.value);
    data = {
      ...data,
      campaignIds,
      ...(newDailyBudget && { currentDailyBudget }),
      ...(newDailyBudget && { newDailyBudget }),
    };
  }
  if (acosTarget?.value && acosTarget?.isValid && acosTarget?.isDirty) {
    const adGroupIds = settingsData?.advertisementFields.adGroupIds ?? [];
    const currentAcosTarget = settingsData?.aiPoweredSettings.acosTarget || 0;
    const newAcosTarget = parseFloat(acosTarget?.value) / 100;
    data = {
      ...data,
      adGroupIds,
      ...(newAcosTarget && { currentAcosTarget }),
      ...(newAcosTarget && { newAcosTarget }),
    };
  }
  return data;
};

export const mergeProducSettingsWithProductGroupIds = (
  productSettingsResponse: AIProductSettingsResponse[],
  productGroupIds: SkuProductGroupId[]
) => {
  const productSettingsCopy = cloneDeep(productSettingsResponse);
  return productSettingsCopy.map((productSettingsResponse) => {
    const skuProductGroupId = productGroupIds.find(
      (productGroupId) =>
        productSettingsResponse.skuDetailsFields.sku === productGroupId.sku
    );
    productSettingsResponse.skuDetailsFields.productGroupId =
      skuProductGroupId?.productGroupId;
    return productSettingsResponse;
  });
};

export const INVALID_STATUSES = [
  AIOnboardingStatus.DataUnavailable,
  AIOnboardingStatus.Error,
  AIOnboardingStatus.InsufficientData,
];

export const sanitizeAIOnboardingResponse = (
  aiOnboardingResponse: AIOnboardingResponse,
  invalidStatuses: AIOnboardingStatus[]
) => {
  aiOnboardingResponse.elements = aiOnboardingResponse.elements.filter(
    (element) => !invalidStatuses.includes(element.status)
  );
  return aiOnboardingResponse;
};

export const groupElementsBySalesChannelId = (
  aiOnboardingResponse: AIOnboardingResponse
) => {
  return groupBy(aiOnboardingResponse.elements, 'salesChannelId');
};

export const isAIPoweredUpgradeEligible = (
  userContext: UserContextState,
  optimizelyContext: OptimizelyContextState,
  response: AIOnboardingResponse
): boolean => {
  const aiUpgradeFlowFlag =
    optimizelyContext.featureFlags[OptimizelyFlags.AIUpgradeFlow] ||
    !isManagedAccount(userContext);
  const isDataAvaiable = response?.elements.some(
    (element) => element.status === AIOnboardingStatus.DataAvailable
  );
  const isRunningOrErrorOrComplete = response?.elements.some(
    (element) =>
      element.status === AIOnboardingStatus.Error ||
      element.status === AIOnboardingStatus.Running ||
      element.status === AIOnboardingStatus.RunComplete
  );
  return aiUpgradeFlowFlag && isDataAvaiable && !isRunningOrErrorOrComplete;
};

export const isEveryMCIDStatusInsufficientOrUnavailableData = (
  response: AIOnboardingResponse
): boolean => {
  return response?.elements.every(
    (element) =>
      element.status === AIOnboardingStatus.InsufficientData ||
      element.status === AIOnboardingStatus.DataUnavailable
  );
};

export const isAtLeastOneMCIDRunComplete = (
  response: AIOnboardingResponse
): boolean => {
  return response?.elements?.some(
    (element) => element.status === AIOnboardingStatus.RunComplete
  );
};

export const getAIOnboardingDataByStatus = (
  request: AIOnboardingRequest,
  accountId: string,
  aiPoweredApiClient: AIPoweredApiClient
) => {
  return aiPoweredApiClient.getAIOnboardingData(accountId, request);
};

export const stopPolling = (response: AIOnboardingResponse) => {
  // Polling should stop for at least one run_complete or error
  return (
    response.code === AIOnboardingStatus.Error ||
    response.elements.some(
      (element) => element.status === AIOnboardingStatus.RunComplete
    )
  );
};
