import { ZuoraSignature } from '../types/Billing';
import * as Sentry from '@sentry/react';

declare global {
  interface Window {
    Z: any;
  }
}

const prepopulateFields = {};

export interface ZuoraResponseError {
  readonly errorCode: string;
  readonly errorMessage: string;
  readonly success: boolean;
  readonly responseFrom: string;
}

export const renderZuoraIframe = (
  zuoraParams: ZuoraSignature,
  onIframeLoad: () => void,
  setSuccess: (success: boolean) => void,
  setRefId: (refId: string) => void,
  setError?: (error: ZuoraResponseError) => void
) => {
  window.Z.setEventHandler('onloadCallback', function () {
    onIframeLoad();
  });

  window.Z.render(
    {
      tenantId: zuoraParams.tenantId,
      id: process.env.REACT_APP_ZUORA_PAGE_ID,
      token: zuoraParams.token,
      signature: zuoraParams.signature,
      style: 'inline',
      key: zuoraParams.key,
      submitEnabled: 'true',
      url: process.env.REACT_APP_ZUORA_HOST_PAGE_URL,
    },
    prepopulateFields,
    (response: any) => {
      if (response.success) {
        // Handle the response after the Payment Page is successfully submitted.
        setSuccess(true);
        setRefId(response.refId);
      } else {
        // Handle the error response
        const zuoraError = response as ZuoraResponseError;

        Sentry.captureMessage(
          `Zuora error: ${zuoraError.errorCode}; errorMessage: ${zuoraError.errorMessage}; responseFrom: ${zuoraError.responseFrom}`,
          Sentry.Severity.Error
        );

        if (setError) {
          setError(zuoraError);
        }

        setSuccess(false);
      }
    }
  );
};

// Zuora requires a div with id 'zuora_payment' on the page when its completed
// initializing the iframe. If the div isn't present the UI will not update
// and appears blank. Zuora doesn't play nice with React's virtual ODM.
// We can remove the iframe the oldschool way so that the div is always present
export const removeZuoraIframe = () => {
  const container = document.getElementById('zuora_payment')!;
  if (container) {
    container.innerHTML = '';
  }
};
