import { navigate, RouteComponentProps } from '@reach/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActionBar } from './components/ActionBar';
import { Opportunity } from './components/Opportunity';
import { Preview } from './components/Preview';
import { Processing } from './components/Processing';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import { createAIPoweredApiClient } from '../../lib/clients/AIPoweredApiClient';
import {
  AIOnboardingResponse,
  AIOnboardingStatus,
} from '../../lib/types/AIPoweredSharedTypes';
import {
  buildOnboardingRequest,
  buildAiOnboardingRequest,
  mergeOnboardingResponseWithCountryCode,
  sanitizeAIOnboardingResponse,
  INVALID_STATUSES,
  buildAiOnboardingRequestFromOnboardingData,
  buildOnboardingRequestFromOnboardingData,
} from '../../lib/utilities/aiPoweredUtilities';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { NavPaths } from '../../NavPaths';
import { useUpgradeContext } from '../../containers/upgradeProvider';
import { PRODUCT_SUBSCRIPTIONS } from '../../lib/types/AOSharedTypes';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import Loader from '../../components/loader';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useAxios } from '../../containers/axiosProvider';

export enum UpgradeStatus {
  'Opportunity' = 'Opportunity',
  'Preview' = 'Preview',
  'Submit' = 'Submit',
}

export const Upgrade: React.FC<RouteComponentProps> = () => {
  const [status, setStatus] = useState(UpgradeStatus.Opportunity);
  const [isLoading, setIsLoading] = useState(true);
  const userContext = React.useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const axios = useAxios();

  const aiPoweredApiClient = createAIPoweredApiClient(
    userContext.userInfo.idToken!,
    axios
  );
  const [
    aiOnboardingResponse,
    setAIOnboardingResponse,
  ] = useState<AIOnboardingResponse>();

  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const merchantCountries = merchantCountriesContext.merchantCountries!;
  const {
    setOnboardingRunData,
    onboardingDataToUpgrade,
    setIsAIPoweredEligible,
  } = useUpgradeContext();
  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);

  const upgradePlanToAI = async () => {
    const ai = await famApiClient
      .getProductVerticals()
      .then((verticals) =>
        verticals.find((v) => v.name === PRODUCT_SUBSCRIPTIONS.AI)
      );
    await famApiClient.subscriptionsAdd(account.id!, [ai!.id]);
    userContext.refreshUserContext();
  };

  const getAIOnboardingData = async () => {
    const aiOnboardingRequest = isEmpty(onboardingDataToUpgrade)
      ? buildAiOnboardingRequest(merchantCountries)
      : buildAiOnboardingRequestFromOnboardingData(onboardingDataToUpgrade);
    return aiPoweredApiClient.getAIOnboardingData(
      account.id,
      aiOnboardingRequest
    );
  };

  const onNextClickHandler = useCallback(() => {
    if (status === UpgradeStatus.Opportunity) {
      setStatus(UpgradeStatus.Preview);
    } else if (status === UpgradeStatus.Preview) {
      setStatus(UpgradeStatus.Submit);
      handleUpgradeAll();
    }
  }, [status]);

  const handleUpgradeAll = () => {
    const onboardingRequest = isEmpty(onboardingDataToUpgrade)
      ? buildOnboardingRequest(aiOnboardingResponse)
      : buildOnboardingRequestFromOnboardingData(onboardingDataToUpgrade);
    if (!isEmpty(onboardingRequest.merchantCountries)) {
      aiPoweredApiClient
        .createOnboarding(account.id, onboardingRequest)
        .then(async (onboardingResponse) => {
          setIsAIPoweredEligible(false);
          setOnboardingRunData(onboardingResponse.elements);
          await upgradePlanToAI();
          navigate(NavPaths.AIPoweredProductSettings);
        });
    }
  };

  const onBackClickHandler = useCallback(() => {
    if (status === UpgradeStatus.Opportunity) {
      navigate(-1);
    } else if (status === UpgradeStatus.Preview) {
      setStatus(UpgradeStatus.Opportunity);
    }
  }, [status]);

  useEffect(() => {
    if (!merchantCountries) return;
    setIsLoading(true);
    getAIOnboardingData().then((aiOnboardingResponse) => {
      const validAIOnboardingResponse = sanitizeAIOnboardingResponse(
        aiOnboardingResponse,
        [
          ...INVALID_STATUSES,
          AIOnboardingStatus.RunComplete,
          AIOnboardingStatus.Running,
        ]
      );
      mergeOnboardingResponseWithCountryCode(
        validAIOnboardingResponse,
        merchantCountries
      );
      setAIOnboardingResponse(validAIOnboardingResponse);
      setIsLoading(false);
    });
  }, [merchantCountries, onboardingDataToUpgrade]);

  return (
    <div
      className={classNames('flex flex-col h-screen', {
        'justify-center': isLoading,
      })}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <ActionBar status={status} onBackClick={onBackClickHandler} />
          {status === UpgradeStatus.Opportunity && (
            <Opportunity
              onNextClick={onNextClickHandler}
              totalAdvertisedProducts={
                aiOnboardingResponse?.totalAdvertisedProducts || 0
              }
            />
          )}
          {status === UpgradeStatus.Preview && aiOnboardingResponse && (
            <Preview
              aiOnboardingData={aiOnboardingResponse}
              count={aiOnboardingResponse?.totalAdvertisedProducts || 0}
              onNextClick={onNextClickHandler}
            />
          )}
          {status === UpgradeStatus.Submit && (
            <div className="flex-grow flex justify-center">
              <Processing
                count={aiOnboardingResponse?.totalAdvertisedProducts || 0}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
