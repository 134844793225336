import {
  NavLink,
  NavModule,
} from '@teikametrics/tm-design-system/components/nav/types';
import {
  BoxCubeLinearIcon as ProductsInactiveIcon,
  BoxCubeSolidIcon as ProductsActiveIcon,
  BullhornLinearIcon as AdOptimizationInactiveIcon,
  BullhornSolidIcon as AdOptimizationActiveIcon,
  BullseyeLinearIcon as MarketIntelligenceInactiveIcon,
  BullseyeSolidIcon as MarketIntelligenceActiveIcon,
  CogLinearIcon as AccountInactiveIcon,
  CogSolidIcon as AccountActiveIcon,
  CompassLinearIcon as CompassInactiveIcon,
  CompassSolidIcon as CompassActiveIcon,
  HomeLinearIcon as HomeInactiveIcon,
  HomeSolidIcon as HomeActiveIcon,
  LightningIcon as AIPoweredInactiveIcon,
  LightningSolidIcon as AIPoweredActiveIcon,
} from '@teikametrics/tm-design-system/img/icons';
import { FeatureFlagState } from './containers/optimizelyProvider/optimizelyProvider';
import { AIOnboardingResponse } from './lib/types/AIPoweredSharedTypes';
import { Role } from './lib/types/Fam';
import I18nKey from './lib/types/I18nKey';
import { OptimizelyFlags } from './lib/types/OptimizelyFlags';
import { NavPaths } from './NavPaths';
import { isEveryMCIDStatusInsufficientOrUnavailableData } from './lib/utilities/aiPoweredUtilities';

const compassModule: NavModule = {
  icon: {
    active: CompassActiveIcon,
    inactive: CompassInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_COMPASS,
  navLinks: [
    {
      heapId: 'nav__link-compass',
      i18nKey: I18nKey.NAV_BAR_LABEL_COMPASS,
      path: NavPaths.Compass,
      dataTestId: 'com_CompassMainMenu',
      matchTo: NavPaths.Compass,
    },
  ],
};

const onboardingModule = (showCompassIcon?: boolean) => ({
  icon: {
    active: showCompassIcon ? CompassActiveIcon : HomeActiveIcon,
    inactive: showCompassIcon ? CompassInactiveIcon : HomeInactiveIcon,
  },
  moduleName: showCompassIcon
    ? I18nKey.NAV_BAR_LABEL_COMPASS
    : I18nKey.NAV_BAR_LABEL_HOME,
  navLinks: [
    {
      heapId: 'nav__link-onboarding',
      i18nKey: showCompassIcon
        ? I18nKey.NAV_BAR_LABEL_COMPASS
        : I18nKey.NAV_BAR_LABEL_HOME,
      path: NavPaths.GetStarted,
      dataTestId: showCompassIcon ? 'com_CompassMainMenu' : 'ob_home',
    },
  ],
});

const productsModule: NavModule = {
  icon: {
    active: ProductsActiveIcon,
    inactive: ProductsInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_PRODUCTS,
  navLinks: [
    {
      heapId: 'nav__link-sku-catalog',
      i18nKey: I18nKey.NAV_BAR_LABEL_SKU_CATALOG,
      path: NavPaths.IOSku,
      dataTestId: 'pc_skuCatalog',
    },
  ],
};

const AISettings = {
  heapId: 'nav__link-ai-settings',
  i18nKey: I18nKey.NAV_BAR_LABEL_AI_SETTINGS,
  path: NavPaths.AIPoweredProductSettings,
  dataTestId: 'ai_settings',
};

const AISettingsV2 = {
  heapId: 'nav__link-ai-settings-v2',
  i18nKey: I18nKey.NAV_BAR_LABEL_PRODUCT_SETTINGS,
  path: NavPaths.AIPoweredProductSettingsV2,
  dataTestId: 'ai_settingsV2',
};

const aiSettingsModule: NavModule = {
  icon: {
    active: AIPoweredActiveIcon,
    inactive: AIPoweredInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_AI_POWERED,
  navLinks: [],
  upgradeEligibility: true,
};

const advertisingOptimizationModule: NavModule = {
  icon: {
    active: AdOptimizationActiveIcon,
    inactive: AdOptimizationInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_ADVERTISING_OPTIMIZATION,
  navLinks: [
    {
      heapId: 'nav__link-ao-ads-manager',
      i18nKey: I18nKey.ADVERTISING_OPTIMIZATION_TITLE,
      path: NavPaths.AOAdsManager,
      matchTo: NavPaths.AOAdsManager,
      dataTestId: 'ao_adsManager',
    },
  ],
};

const marketIntelligenceModule: NavModule = {
  icon: {
    active: MarketIntelligenceActiveIcon,
    inactive: MarketIntelligenceInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_MARKET_INTELLIGENCE,
  navLinks: [
    {
      heapId: 'nav__link-mi-dashboard',
      i18nKey:
        I18nKey.MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_DASHBOARD_LINK,
      path: NavPaths.MarketIntelligenceDashboard,
      dataTestId: 'mi_dashboard',
    },
    {
      heapId: 'nav__link-mi-settings',
      i18nKey: I18nKey.MARKET_INTELLIGENCE_PAGE_HEADER_SETTINGS,
      path: NavPaths.MarketIntelligenceTrackedTerms,
      dataTestId: 'mi_termsTracker',
    },
  ],
};

const buildAccountNavLinks = (
  userRole: Role,
  featureFlags?: FeatureFlagState
): NavLink[] => {
  let accountLinks: NavLink[] = [
    {
      heapId: 'nav__link-account-billing',
      i18nKey: I18nKey.BILLING_TITLE,
      path: NavPaths.Billing,
      matchTo: NavPaths.Billing,
    },
    {
      heapId: 'nav__link-account-sales-channels',
      i18nKey: I18nKey.SALES_CHANNELS_TITLE,
      path: NavPaths.SalesChannels,
      matchTo: NavPaths.SalesChannels,
      dataTestId: 'us_salesChannels',
    },
    {
      heapId: 'nav__link-account-users',
      i18nKey: I18nKey.USERS_TITLE,
      path: NavPaths.Users,
      matchTo: NavPaths.Users,
      dataTestId: 'us_users',
    },
  ];

  if (userRole === Role.ACCOUNT_OWNER) {
    accountLinks = [
      ...accountLinks,
      {
        heapId: 'nav__link-account-company',
        i18nKey: I18nKey.COMPANY_PAGE_TITLE,
        path: NavPaths.Company,
        dataTestId: 'us_company',
      },
    ];
  }

  return accountLinks;
};

const accountModule: NavModule = {
  icon: {
    active: AccountActiveIcon,
    inactive: AccountInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_ACCOUNT,
  navLinks: [],
};

export const getNavModulesForAccount = (
  userRole: Role,
  featureFlags: FeatureFlagState,
  showOnboardingNav: boolean,
  upgradeEligibility: boolean,
  upgradeButtonClick: () => void,
  upgradeResponse: AIOnboardingResponse,
  isManaged: boolean
): NavModule[] => {
  const navModules: NavModule[] = [];

  if (showOnboardingNav) {
    navModules.push(onboardingModule(featureFlags[OptimizelyFlags.Compass]));
  }

  if (featureFlags[OptimizelyFlags.Compass] && !showOnboardingNav) {
    navModules.push(compassModule);
  }

  if (featureFlags[OptimizelyFlags.SkuCatalog]) {
    if (
      featureFlags[OptimizelyFlags.FlowV2] &&
      productsModule.navLinks.indexOf(AISettingsV2) === -1
    ) {
      productsModule.navLinks.push(AISettingsV2);
    }
    navModules.push(productsModule);
  }

  if (
    (featureFlags[OptimizelyFlags.AIUpgradeFlow] || !isManaged) &&
    !isEveryMCIDStatusInsufficientOrUnavailableData(upgradeResponse)
  ) {
    aiSettingsModule.upgradeEligibility = upgradeEligibility;
    if (upgradeEligibility) {
      aiSettingsModule.navLinks = [];
      aiSettingsModule.upgradeButtonClick = upgradeButtonClick;
      navModules.push(aiSettingsModule);
    } else {
      aiSettingsModule.navLinks = [AISettings];
      navModules.push(aiSettingsModule);
    }
  }

  navModules.push(
    advertisingOptimizationModule,
    marketIntelligenceModule,
    accountModule
  );

  accountModule.navLinks = buildAccountNavLinks(userRole, featureFlags);

  return navModules;
};
