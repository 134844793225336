import noop from 'lodash/noop';
import React, { createContext, useState } from 'react';

export interface SaveChangesContextDataProps {
  readonly editDataFlag: boolean;
  readonly saveChangesModalIsOpen: boolean;
  readonly navPath: string;
  readonly aoAdLevel?: string;
}

export interface SaveChangesFlagContextState {
  readonly saveChangesData: SaveChangesContextDataProps;
  readonly updateSaveChangesData: (d: SaveChangesContextDataProps) => void;
}

export interface SaveChangesFlagProviderProps {
  readonly children: JSX.Element;
}

const initialState: SaveChangesFlagContextState = {
  saveChangesData: {
    editDataFlag: false,
    saveChangesModalIsOpen: false,
    navPath: '',
  },
  updateSaveChangesData: noop,
};

const SaveChangesFlagContext = createContext<SaveChangesFlagContextState>(
  initialState
);
const { Provider } = SaveChangesFlagContext;

const SaveChangesFlagProvider: React.FC<SaveChangesFlagProviderProps> = ({
  children,
}) => {
  const [
    saveChangesData,
    updateSaveChangesData,
  ] = useState<SaveChangesContextDataProps>({
    ...initialState.saveChangesData,
  });

  return (
    <Provider value={{ saveChangesData, updateSaveChangesData }}>
      {children}
    </Provider>
  );
};

export { SaveChangesFlagContext, SaveChangesFlagProvider };
