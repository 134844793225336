import React from 'react';
import { SplitCardPreview } from './SplitCardPreview';
import { getChannelIcon } from '../../../lib/clients/util';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import { AIOnboardingData } from '../../../lib/types/AIPoweredSharedTypes';

interface SalesChannelPreviewProps {
  readonly onboardingData: AIOnboardingData[];
}

export const SalesChannelPreview: React.FC<SalesChannelPreviewProps> = ({
  onboardingData,
}) => {
  const salesChannelName = onboardingData[0].salesChannelName;
  const channelIcon =
    salesChannelName && getChannelIcon(salesChannelName.toLowerCase());
  return (
    <div className="mb-16">
      <div className="pl-12 py-12">
        {channelIcon && <Icon size={IconSize.Large} svg={channelIcon} />}
        <span className="ml-12 font-semibold capitalize">
          {salesChannelName}
        </span>
      </div>
      {onboardingData.map((data, key) => (
        <SplitCardPreview
          key={`channelPreview_${key}`}
          onboardingData={data.onboardingData}
          countryCode={data.countryCode}
          merchantCountryName={data.merchantCountryName}
        />
      ))}
    </div>
  );
};
