import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system/components/Button';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import {
  ChevronLeftIcon,
  LightningIcon,
} from '@teikametrics/tm-design-system/img/icons';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from '../../../lib/types/I18nKey';
import { UpgradeStatus } from '../Upgrade';

export interface ActionBarProps {
  readonly status: UpgradeStatus;
  readonly onBackClick: () => void;
}

export const ActionBar: React.FC<ActionBarProps> = ({
  status,
  onBackClick,
}) => {
  const intl = useIntl();
  return (
    <div className="flex justify-between bg-white border border-solid border-grey-200 p-16 h-64">
      <div className="flex items-center flex-1">
        {status !== UpgradeStatus.Submit && (
          <Button
            label={intl.formatMessage({
              id: I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_GO_BACK,
            })}
            variant={ButtonVariant.BlackAndWhite}
            size={ButtonSize.Large}
            svgIcon={ChevronLeftIcon}
            onClick={onBackClick}
            dataTestId="ob_ActionbarGoback"
          />
        )}
      </div>

      <div className="flex items-center">
        <Icon
          size={IconSize.Large}
          svg={LightningIcon}
          className="mr-8 text-purple-500"
        />
        <span className="align-middle text-base leading-none font-medium text-grey-900">
          <FormattedMessage
            id={I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_HEADER}
          />
        </span>
      </div>
      <div className="flex items-center flex-1 justify-end"></div>
    </div>
  );
};
