import React from 'react';

interface CalloutProps {
  readonly title: string;
  readonly subtitle?: string;
  readonly children?: React.ReactNode;
  readonly dataTestId?: string;
}

export const Callout: React.FC<CalloutProps> = (props) => {
  const { title, subtitle, children, dataTestId } = props;

  return (
    <div
      className="text-base text-grey-900 rounded-full inline-flex justify-center bg-white border border-solid border-grey-200 px-24 py-16"
      data-test-id={`${dataTestId}_callout`}
    >
      <span className="font-medium">{title}</span>
      {subtitle && <span className="ml-4">{subtitle}</span>}
      {children && <span className="ml-16">{children}</span>}
    </div>
  );
};
