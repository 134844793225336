import React from 'react';
import { Modal } from '@teikametrics/tm-design-system/components/Modal';
import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
} from '@teikametrics/tm-design-system/components/Button';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import {
  AiFeatureRow,
  ShadowyAlertBox,
} from '../../modules/account/components/disableAutomationModal';
import { LightningIcon } from '@teikametrics/tm-design-system/img/icons';

export interface SuccessfulPaymentModalProps {
  onClose: () => void;
}

export const SuccessfulPaymentModal: React.FC<SuccessfulPaymentModalProps> = ({
  onClose,
}) => {
  const intl = useIntl();

  const [TITLE, BODY, LIST_HEADER, BUTTON, FIRST_FEATURE] = [
    I18nKey.UPGRADE_HAPPENED_TITLE,
    I18nKey.TRIAL_ENDED_SUCCESSFUL_PAYMENT_BODY,
    I18nKey.TRIAL_ENDED_SUCCESSFUL_PAYMENT_LIST_HEADER,
    I18nKey.ADD_PAYMENT_MODAL_SUCCESS_BUTTON_TEXT,
    I18nKey.BILLING_PLAN_FEATURES_BIDDER,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <Modal showModal={true} header={TITLE} headerClass="text-xl" body={BODY}>
      <ShadowyAlertBox icon={LightningIcon} shouldCenterIcon={false}>
        <p className="font-semibold pb-12">{LIST_HEADER}</p>
        <div className="flex flex-col space-y-8 pb-12">
          <AiFeatureRow text={FIRST_FEATURE} isUpgrade={true} />
        </div>
      </ShadowyAlertBox>
      <div className="pt-24 text-center">
        <Button
          state={ButtonState.Enabled}
          label={BUTTON}
          onClick={onClose}
          size={ButtonSize.Large}
          variant={ButtonVariant.Primary}
        />
      </div>
    </Modal>
  );
};
