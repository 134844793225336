import { useContext, useState } from 'react';
import {
  getCurrentAccountFromContext,
  hasPaymentInfo,
  showTrialEndedPaymentNeeded,
} from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { FAMApiClient } from '../../lib/clients/FAMApiClient';
import { PRODUCT_SUBSCRIPTIONS } from '../../lib/types/AOSharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import { PaymentEntryModal } from './paymentEntryModal';
import { TrialEndedModal } from './trialEndedModal';
import Confetti from 'react-confetti';
import { SuccessfulPaymentModal } from './successfulPaymentModal';
import noop from 'lodash/noop';
import { FirstTimeSuccessfulPaymentModal } from './firstTimeSuccessfulPaymentModal';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../containers/optimizelyProvider/optimizelyProvider';
import { OptimizelyFlags } from '../../lib/types/OptimizelyFlags';

export enum TrialEndedModalScreens {
  InitialPrompt = 'InitialPrompt',
  EnterPaymentDetails = 'EnterPaymentDetails',
  SuccessfulPayment = 'SuccessfulPayment',
}

interface TrialEndedModalFlowProps {
  readonly famClient: FAMApiClient;
  readonly closeModal: () => void;
  readonly shouldStartWithEnterPaymentDetailsScreen?: boolean;
}

export const TrialEndedModalFlow: React.FC<TrialEndedModalFlowProps> = ({
  famClient,
  closeModal,
  shouldStartWithEnterPaymentDetailsScreen = false,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccountFromContext(userContext);
  const optimizelyContext = useContext<OptimizelyContextState>(
    OptimizelyContext
  );
  const shouldEnforceFreeTrial =
    optimizelyContext.featureFlags[OptimizelyFlags.FreeTrialEnforcer];

  const [modalScreen, setModalScreen] = useState<TrialEndedModalScreens>(
    getStartScreen(shouldStartWithEnterPaymentDetailsScreen)
  );

  const stayBasicPlanAfterTrial = () => {
    closeModal();
  };

  const onUpgradePlanToAI = () => {
    if (hasPaymentInfo(userContext)) {
      paymentSaved();
    } else {
      setModalScreen(TrialEndedModalScreens.EnterPaymentDetails);
    }
  };

  const paymentSaved = async () => {
    const ai = await famClient
      .getProductVerticals()
      .then((verticals) =>
        verticals.find((v) => v.name === PRODUCT_SUBSCRIPTIONS.AI)
      );
    await famClient.subscriptionsAdd(currentAccount?.id!, [ai!.id]).catch(noop);
    setModalScreen(TrialEndedModalScreens.SuccessfulPayment);
  };

  const onBack = () => {
    setModalScreen(TrialEndedModalScreens.InitialPrompt);
  };

  const closeTrialModal = async () => {
    closeModal();
    userContext.refreshUserContext();
  };

  if (
    showTrialEndedPaymentNeeded(userContext, shouldEnforceFreeTrial) ||
    shouldStartWithEnterPaymentDetailsScreen
  ) {
    switch (modalScreen) {
      case TrialEndedModalScreens.EnterPaymentDetails:
        return (
          <PaymentEntryModal
            backText={I18nKey.BACK}
            headerText={
              shouldStartWithEnterPaymentDetailsScreen
                ? I18nKey.TRIAL_ENDED_ADD_PAYMENT_REMINDER_TITLE
                : I18nKey.TRIAL_ENDED_ADD_PAYMENT_TITLE
            }
            bodyText={
              shouldStartWithEnterPaymentDetailsScreen
                ? I18nKey.TRIAL_ENDED_ADD_PAYMENT_REMINDER_BODY
                : I18nKey.TRIAL_ENDED_ADD_PAYMENT_BODY
            }
            pillText={I18nKey.TRIAL_ENDED_ADD_PAYMENT_PILL}
            noteText={I18nKey.TRIAL_ENDED_ADD_PAYMENT_NOTE}
            onBack={onBack}
            onSavePayment={paymentSaved}
            showGoBackOption={!shouldStartWithEnterPaymentDetailsScreen}
          />
        );
      case TrialEndedModalScreens.SuccessfulPayment:
        return (
          <>
            {shouldStartWithEnterPaymentDetailsScreen ? (
              <FirstTimeSuccessfulPaymentModal onClose={closeTrialModal} />
            ) : (
              <SuccessfulPaymentModal onClose={closeTrialModal} />
            )}
            <div className="fixed inset-0">
              <Confetti
                numberOfPieces={400}
                colors={['#35c477', '#0ac2ff', '#4b25ea']}
                gravity={0.1}
                drawShape={(ctx) => {
                  ctx.beginPath();
                  ctx.fillRect(0, 0, 8, 16);
                  ctx.closePath();
                }}
              />
            </div>
          </>
        );
      case TrialEndedModalScreens.InitialPrompt:
      default:
        return (
          <TrialEndedModal
            onStayBasic={stayBasicPlanAfterTrial}
            onUpgrade={onUpgradePlanToAI}
          />
        );
    }
  } else {
    return null;
  }
};

const getStartScreen = (shouldStartWithEnterPaymentDetailsScreen: boolean) =>
  shouldStartWithEnterPaymentDetailsScreen
    ? TrialEndedModalScreens.EnterPaymentDetails
    : TrialEndedModalScreens.InitialPrompt;
