import { IdToken } from '@auth0/auth0-spa-js';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { DEFAULT_AXIOS_CONFIG } from '.';

import {
  AIOnboardingRequest,
  AIOnboardingResponse,
  AIOnboardingRunRequest,
  AIOnboardingRunResponse,
  AIProductSettingsResponse,
  AIProductSettingsRequest,
  AIProductSettingSummaryRequest,
  AIProductSettingSummaryResponse,
  OnboardingRequest,
  OnboardingResponse,
  AIUpdateSkuTargetsRequest,
  AIUpdateSkuTargetsResponse,
} from '../types/AIPoweredSharedTypes';
import {
  filterEmptyValues,
  getPaginationParamsFromRequest,
} from '../factories/apiDataFetcherFactory';
import {
  FilteredRequest,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResult,
} from './types';
import { filtersToQueryString } from '../utilities/filter';
import { generateSortQueryString } from '../utilities/buildUrlUtilities';
import { mergeProducSettingsWithProductGroupIds } from '../utilities/aiPoweredUtilities';
import { SKUApiClient } from './SKUApiClient';

const CORTEX_BASE_URL = process.env.REACT_APP_CORTEX_HOST;
const AO_BASE_URL = process.env.REACT_APP_AO_HOST;

export interface AIPoweredApiClient {
  readonly getAIOnboardingData: (
    accountId: string,
    request: AIOnboardingRequest
  ) => Promise<AIOnboardingResponse>;

  readonly createOnboarding: (
    accountId: string,
    request: OnboardingRequest
  ) => Promise<OnboardingResponse>;

  readonly getOnboardingRuns: (
    accountId: string,
    request: AIOnboardingRunRequest
  ) => Promise<AIOnboardingRunResponse>;

  readonly getAIProductSettings: (
    accountId: string,
    request: AIProductSettingsRequest,
    skuApiClient: SKUApiClient
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<AIProductSettingsResponse>>;

  readonly getAIProductSettingsSummary: (
    accountId: string,
    request: AIProductSettingSummaryRequest,
    queryParams?: Record<string, string>
  ) => (
    filteredequest: FilteredRequest
  ) => Promise<AIProductSettingSummaryResponse>;

  readonly updateSkusTarget: (
    accountId: string,
    request: AIUpdateSkuTargetsRequest
  ) => Promise<AIUpdateSkuTargetsResponse>;
}

export const PATHS = Object.freeze({
  AI_ONBOARDING_DATA: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/accounts/${
      dummyEndpoint ? 'dummy/' : ''
    }${accountId}/ai-onboarding-data`,
  AI_CREATE_ONBOARDING: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/accounts/${dummyEndpoint ? 'dummy/' : ''}${accountId}/onboard`,
  AI_ONBOARDING_RUNS: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/accounts/${dummyEndpoint ? 'dummy/' : ''}${accountId}/run-status`,
  AI_PRODUCT_SETTINGS: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${dummyEndPoint ? 'dummy/' : ''}${accountId}/ai-settings/data`,
  AI_PRODUCT_SETTINGS_SUMMARY: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/ai-settings/aggregate`,
  AI_UPDATE_SKU_TARGETS: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/products/update-sku-targets`,
});

export const createAIPoweredApiClient = (
  token: IdToken | null,
  axios: AxiosInstance
): AIPoweredApiClient => {
  const config: AxiosRequestConfig = {
    ...DEFAULT_AXIOS_CONFIG,
    baseURL: CORTEX_BASE_URL,
  };
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token?.__raw}`,
  };

  const getAIOnboardingData = async (
    accountId: string,
    request: AIOnboardingRequest
  ): Promise<AIOnboardingResponse> => {
    const response = await axios.post<AIOnboardingResponse>(
      PATHS.AI_ONBOARDING_DATA(accountId),
      request,
      { ...config }
    );
    return response.data;
  };

  const createOnboarding = async (
    accountId: string,
    request: OnboardingRequest
  ): Promise<OnboardingResponse> => {
    const response = await axios.post<OnboardingResponse>(
      PATHS.AI_CREATE_ONBOARDING(accountId),
      request,
      { ...config }
    );
    return response.data;
  };

  const getOnboardingRuns = async (
    accountId: string,
    request: AIOnboardingRunRequest
  ): Promise<AIOnboardingRunResponse> => {
    const response = await axios.post<AIOnboardingRunResponse>(
      PATHS.AI_ONBOARDING_RUNS(accountId),
      request,
      { ...config }
    );
    return response.data;
  };

  const getAIProductSettings = (
    accountId: string,
    request: AIProductSettingsRequest,
    skuApiClient: SKUApiClient
  ) => async (
    paginatedRequest: PaginatedRequest
  ): Promise<PaginatedResult<AIProductSettingsResponse>> => {
    const allParams = getPaginationParamsFromRequest(paginatedRequest);
    const params = filterEmptyValues(allParams);

    const response = await axios.post<
      PaginatedResponse<AIProductSettingsResponse>
    >(PATHS.AI_PRODUCT_SETTINGS(accountId), request, {
      ...config,
      baseURL: AO_BASE_URL,
      params,
    });
    const skus = response.data.elements.map(
      (element) => element.skuDetailsFields.sku
    );
    if (skus.length > 0) {
      const productGroupIds = await skuApiClient.getProductGroupIds(
        accountId,
        skus
      );
      response.data.elements = mergeProducSettingsWithProductGroupIds(
        response.data.elements,
        productGroupIds.elements
      );
    }
    return {
      items: response.data.elements,
      totalItems: response.data.filteredElements,
    };
  };

  const getAIProductSettingsSummary = (
    accountId: string,
    request: AIProductSettingSummaryRequest,
    queryParams?: Record<string, string>
  ) => async (
    filteredRequest: FilteredRequest
  ): Promise<AIProductSettingSummaryResponse> => {
    const allParams = {
      ...filteredRequest.extraParams,
      filter: filtersToQueryString(filteredRequest.filters),
      sort: generateSortQueryString(filteredRequest.sorts),
    };

    const params = filterEmptyValues(allParams);

    const response = await axios.post<AIProductSettingSummaryResponse>(
      PATHS.AI_PRODUCT_SETTINGS_SUMMARY(accountId),
      {
        ...request,
      },
      { ...config, baseURL: AO_BASE_URL, params: { ...queryParams, ...params } }
    );
    return response.data;
  };

  const updateSkusTarget = async (
    accountId: string,
    request: AIUpdateSkuTargetsRequest
  ): Promise<AIUpdateSkuTargetsResponse> => {
    const response = await axios.put<AIUpdateSkuTargetsResponse>(
      PATHS.AI_UPDATE_SKU_TARGETS(accountId),
      {
        ...request,
      },
      { ...config, baseURL: AO_BASE_URL }
    );
    return response.data;
  };

  return {
    getAIProductSettings,
    createOnboarding,
    getAIProductSettingsSummary,
    getAIOnboardingData,
    getOnboardingRuns,
    updateSkusTarget,
  };
};
