import React from 'react';
import { useIntl } from 'react-intl';
import { Callout } from './Callout';
import I18nKey from '../../../lib/types/I18nKey';
import {
  TextLink,
  TextLinkSize,
} from '@teikametrics/tm-design-system/components/TextLink';
import { OpportunityBreakdown } from './OpportunityBreakdown';
import { UpgradeStatus } from '../Upgrade';
import { Header } from './Header';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system/components/Button';

interface OpportunityProps {
  readonly totalAdvertisedProducts: number;
  readonly onNextClick: () => void;
}

export const Opportunity: React.FC<OpportunityProps> = ({
  totalAdvertisedProducts = 0,
  onNextClick,
}) => {
  const intl = useIntl();
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mt-40">
        <Header
          status={UpgradeStatus.Opportunity}
          count={totalAdvertisedProducts}
        />
      </div>
      <div className="mt-56">
        <OpportunityBreakdown />
      </div>
      <div className="flex flex-row gap-8 items-center mt-56">
        {/*
        // we need to enable this when we have a url
        <Button
          label={intl.formatMessage({
            id: I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_LEARN_MORE,
          })}
          variant={ButtonVariant.BlackAndWhiteBorder}
          size={ButtonSize.Large}
        //  onClick={onNextClick}
          dataTestId="ob_LearnMore"
        /> */}
        <Button
          label={intl.formatMessage({
            id: I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_NEXT_PREVIEW,
          })}
          variant={ButtonVariant.Primary}
          size={ButtonSize.Large}
          onClick={onNextClick}
          dataTestId="ob_NextPreview"
        />
      </div>
      <div className="flex flex-row justify-center items-center mt-56">
        <Callout
          title={intl.formatMessage({
            id:
              I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_OPPORTUNITY_CALL_OUT_TITLE,
          })}
        >
          <TextLink
            href="https://www.teikametrics.com/pricing/"
            openNewTab
            textLabel={intl.formatMessage({
              id:
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_OPPORTUNITY_CALL_OUT_LINK,
            })}
            size={TextLinkSize.Small}
            dataTestId="ob_opportunity"
          />
        </Callout>
      </div>
    </div>
  );
};
