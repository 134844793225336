import { IdToken } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import base64url from 'base64url';
import noop from 'lodash/noop';
import { UserInviteRequestType } from '../../modules/account/containers/users/ducks/types';
import {
  createPaginatedApiDataFetcher,
  filterEmptyValues,
  getPaginationParamsFromRequest,
} from '../factories/apiDataFetcherFactory';
import {
  Account,
  AccountSalesChannelPaginatedResult,
  AllSalesChannel,
  AmazonAdsConnectResult,
  MerchantClaimResponseType,
  MerchantCorrelationResponseType,
  ProductDataSyncRequestType,
  ProductSubscriptionEventResponse,
  ProductVertical,
  SalesChannelData,
  SalesChannelResponse,
  SalesChannelUpdateData,
  UserDetails,
  UserFetchedResponseType,
  UserResponseType,
  WalmartProductConnectResult,
} from '../types/Fam';
import { DEFAULT_AXIOS_CONFIG } from './index';
import {
  MerchantCountriesResponse,
  PaginatedDataFetcher,
  PaginatedRequest,
} from './types';

export interface FAMApiClient {
  createAccount: () => Promise<Account>;
  getAccountDetails: (accountId: string) => Promise<Account>;
  subscriptionsAdd: (
    accountId: string,
    productVerticals: string[]
  ) => Promise<void>;
  getUserDetails: () => Promise<UserDetails | undefined>;
  triggerVerificationEmail: () => Promise<void>;
  triggerPasswordResetEmail: () => Promise<boolean>;
  emailVerified: () => Promise<void>;
  getProductVerticals: () => Promise<ProductVertical[]>;
  readonly getSalesChannelTableData: (
    accountId: string,
    salesChannelId: string
  ) => (
    request: PaginatedRequest
  ) => Promise<AccountSalesChannelPaginatedResult>;
  readonly getAllSalesChannels: () => Promise<AllSalesChannel[]>;
  readonly updateSalesChannel: (
    data: SalesChannelUpdateData,
    accountId: string,
    merchant_country_id: string
  ) => Promise<AxiosResponse<SalesChannelData>>;
  readonly enableAoForSalesChannels: (
    accountId: string,
    merchant_country_ids: string[]
  ) => Promise<SalesChannelData[]>;
  sendInitialWalmartSyncEmail: (accountId: string) => Promise<void>;
  inviteUser: (
    accountId: string,
    userData: UserInviteRequestType
  ) => Promise<UserResponseType>;
  acceptInvite: (accountId: string) => Promise<void>;
  fetchUserFromAccount: (
    accountId: string,
    email: string
  ) => Promise<UserFetchedResponseType>;
  removeUserFromAccount: (accountId: string, email: string) => Promise<void>;
  connectProductSyncForm: (
    accountId: string,
    formData: ProductDataSyncRequestType
  ) => Promise<WalmartProductConnectResult>;
  updateUsername: (firstName: string, lastName: string) => Promise<boolean>;
  getUsers: (accountId: string) => PaginatedDataFetcher<UserResponseType>;
  resendInvite: (accountId: string, email: string) => Promise<void>;
  removeProductSubscription: (
    accountId: string,
    subscriptionId: string
  ) => Promise<ProductVertical[]>;
  getProductSubscriptionEvents: (
    accountId: string,
    productVerticalId: string
  ) => Promise<ProductSubscriptionEventResponse>;
  getMerchantCountries: (
    accountId: string,
    channelIds: string[]
  ) => Promise<MerchantCountriesResponse>;
  updateCompanyName: (
    accountId: string,
    companyName: string
  ) => Promise<Account | undefined>;
  addAmazonAdsConnect: (
    accountId: string,
    authCode: string
  ) => Promise<AmazonAdsConnectResult>;
  amazonSellingPartnerConnect: (
    accountId: string,
    regionId: string,
    sellingPartnerId: string,
    merchantType: string,
    spApiOauthCode: string
  ) => Promise<AmazonAdsConnectResult>;
  merchantConnect: (
    accountId: string,
    correlationId: string
  ) => Promise<MerchantCorrelationResponseType>;
  claimMerchantCountries: (
    accountId: string,
    ids: string[]
  ) => Promise<MerchantClaimResponseType>;
}

const REACT_APP_FAM_HOST = process.env.REACT_APP_FAM_HOST;

export const PATHS = Object.freeze({
  ACCOUNTS: 'accounts',
  ACCOUNT_DETAILS: (accountId: string) => `accounts/${accountId}`,
  ACCOUNT_USERS: (accountId: string) => `accounts/${accountId}/users`,
  ACCOUNT_USER: (accountId: string, email: string) =>
    `accounts/${accountId}/users/${email}`,
  SUBSCRIPTIONS_APPEND: (accountId: string) =>
    `accounts/${accountId}/subscriptions/append`,
  USERS: (email: string) => `users/${email}`,
  USERS_EMAIL_VERIFY: (email: string) => `users/${email}/verify`,
  USERS_PASSWORD_RESET: (email: string) => `users/${email}/password_reset`,
  PATCH_USER: (email: string) => `users/${email}`,
  USERS_EMAIL_VERIFIED: (email: string) => `users/${email}/verified`,
  PRODUCT_VERTICALS: 'product_verticals',
  GET_ALL_SALES_CHANNELS: () => `/sales-channels`,
  SALES_CHANNELS: (accountId: string) => `accounts/${accountId}/sales-channels`,
  MERCHANT_COUNTRY: (accountId: string, merchant_country_id: string) =>
    `/accounts/${accountId}/merchant-countries/${merchant_country_id}`,
  MERCHANT_COUNTRIES: (accountId: string) =>
    `/accounts/${accountId}/merchant-countries`,
  CLAIM_MERCHANT_COUNTRIES: (accountId: string) =>
    `/accounts/${accountId}/merchant-countries/claim`,
  CONNECT_PRODUCT_SYNC_FORM: (accountId: string) =>
    `accounts/${accountId}/walmart-products-connect`,
  SEND_INITIAL_WALMART_SYNC_EMAIL: (accountId: string) =>
    `accounts/${accountId}/walmart-ads-connect`,
  PRODUCT_SUBSCRIPTION: (accountId: string, subscription_id: string) =>
    `/accounts/${accountId}/subscriptions/${subscription_id}`,
  PRODUCT_SUBSCRIPTION_EVENTS: (accountId: string, productVerticalId: string) =>
    `/accounts/${accountId}/subscriptions/${productVerticalId}/history`,
  AMAZON_ADS_CONNECT: (accountId: string) =>
    `accounts/${accountId}/amazon-ads-connect`,
  AMAZON_SELLING_PARTNER_CONNECT: (accountId: string) =>
    `accounts/${accountId}/amazon-selling-partner-connect`,
  MERCHANT_CONNECT: (accountId: string, correlationId: string) =>
    `accounts/${accountId}/sales-channels/connection/${correlationId}`,
});

export const createFAMApiClient = (token: IdToken): FAMApiClient => {
  const config: AxiosRequestConfig = {
    ...DEFAULT_AXIOS_CONFIG,
    baseURL: REACT_APP_FAM_HOST,
  };
  if (process.env.REACT_APP_INCLUDE_API_GATEWAY_AUTH !== 'true') {
    // This will be removed when the code is minimized in staging and prod builds
    config.headers = {
      ...config.headers,
      'Teika-Verified-User': base64url.encode(
        JSON.stringify({
          version: '1.0',
          body: {
            email: token.email,
            ext_id: token.sub,
          },
        })
      ),
    };
  } else {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token.__raw}`,
    };
  }

  const createAccount = () => {
    const signUpData = token['https://flywheel.teikametrics.com/metadata'];

    return axios
      .post<Account>(
        PATHS.ACCOUNTS,
        {
          userEmail: token.email,
          userAuth0Id: token.sub,
          firstName: signUpData?.first_name || token.given_name,
          lastName: signUpData?.last_name || token.family_name,
          companyName: signUpData?.business_name?.trim() || '',
        },
        config
      )
      .then((response) => response.data);
  };

  const getAccountDetails = (accountId: string) =>
    axios
      .get(PATHS.ACCOUNT_DETAILS(accountId), config)
      .then((response) => response.data)
      .catch(() => undefined);

  const subscriptionsAdd = (accountId: string, productVerticals: string[]) =>
    axios
      .post<void>(
        PATHS.SUBSCRIPTIONS_APPEND(accountId),
        productVerticals.map((productVertical) => ({
          productVerticalId: productVertical,
        })),
        config
      )
      .then(noop);

  const getUserDetails = (): Promise<UserDetails | undefined> =>
    axios
      .get<UserDetails>(PATHS.USERS(token.email!), config)
      .then((response) => response.data)
      .catch(() => undefined);

  const getProductVerticals = () =>
    axios
      .get<ProductVertical[]>(PATHS.PRODUCT_VERTICALS, config)
      .then((response) => response.data);

  const triggerVerificationEmail = () =>
    axios
      .post<void>(PATHS.USERS_EMAIL_VERIFY(token.email!), undefined, config)
      .then(noop);

  const triggerPasswordResetEmail = () =>
    axios
      .post<void>(PATHS.USERS_PASSWORD_RESET(token.email!), undefined, config)
      .then((response) => true)
      .catch(() => false);

  const updateUsername = (firstName: string, lastName: string) =>
    axios
      .patch(
        PATHS.PATCH_USER(token.email!),
        {
          firstName,
          lastName,
        },
        config
      )
      .then((response) => true)
      .catch(() => false);

  const emailVerified = () =>
    axios
      .put(PATHS.USERS_EMAIL_VERIFIED(token.email!), undefined, config)
      .then(noop);

  const sendInitialWalmartSyncEmail = async (accountId: string) => {
    await axios.post(
      PATHS.SEND_INITIAL_WALMART_SYNC_EMAIL(accountId),
      undefined,
      config
    );
  };

  const inviteUser = (accountId: string, userData: UserInviteRequestType) =>
    axios
      .post(
        PATHS.ACCOUNT_USERS(accountId),
        {
          ...userData,
        },
        config
      )
      .then((response) => response.data);

  const addAmazonAdsConnect = (accountId: string, authCode: string) =>
    axios
      .post(
        PATHS.AMAZON_ADS_CONNECT(accountId),
        {
          authCode,
        },
        config
      )
      .then((response) => response.data);

  const amazonSellingPartnerConnect = (
    accountId: string,
    regionId: string,
    sellingPartnerId: string,
    merchantType: string,
    spApiOauthCode: string
  ) =>
    axios
      .post(
        PATHS.AMAZON_SELLING_PARTNER_CONNECT(accountId),
        {
          regionId,
          sellingPartnerId,
          merchantType,
          spApiOauthCode,
        },
        config
      )
      .then((response) => response.data)
      .catch((err) => err);

  const merchantConnect = (
    accountId: string,
    correlationId: string
  ): Promise<MerchantCorrelationResponseType> =>
    axios
      .get(PATHS.MERCHANT_CONNECT(accountId, correlationId), config)
      .then((response) => response.data);

  const claimMerchantCountries = (
    accountId: string,
    ids: string[]
  ): Promise<MerchantClaimResponseType> =>
    axios
      .post(PATHS.CLAIM_MERCHANT_COUNTRIES(accountId), { ids }, config)
      .then((response) => response.data);

  const acceptInvite = (accountId: string) =>
    axios
      .patch(
        PATHS.ACCOUNT_USER(accountId, token.email!),
        { state: 'active' },
        config
      )
      .then(noop)
      .catch((err) => err);

  const connectProductSyncForm = (
    accountId: string,
    formData: ProductDataSyncRequestType
  ) =>
    axios
      .post(
        PATHS.CONNECT_PRODUCT_SYNC_FORM(accountId),
        {
          ...formData,
        },
        config
      )
      .then((res) => res.data);

  const getSalesChannelTableData = (
    accountId: string,
    salesChannelId: string
  ) => async (
    request: PaginatedRequest
  ): Promise<AccountSalesChannelPaginatedResult> => {
    const allParams = getPaginationParamsFromRequest(request);
    const params = filterEmptyValues(allParams);

    const { filteredElements, elements } = await axios
      .get<SalesChannelResponse>(
        PATHS.SALES_CHANNELS(accountId) +
          `/${salesChannelId}/merchant-countries`,
        {
          ...config,
          params,
        }
      )
      .then((res) => {
        return res.data;
      });

    return {
      totalItems: filteredElements,
      items: elements,
    };
  };

  const getAllSalesChannels = async (): Promise<AllSalesChannel[]> =>
    axios
      .get(PATHS.GET_ALL_SALES_CHANNELS(), config)
      .then((response) => response.data);

  const updateSalesChannel = async (
    data: SalesChannelUpdateData,
    accountId: string,
    merchant_country_id: string
  ): Promise<AxiosResponse<SalesChannelData>> =>
    axios.patch<SalesChannelData>(
      PATHS.MERCHANT_COUNTRY(accountId, merchant_country_id),
      data,
      config
    );

  const enableAoForSalesChannels = (
    accountId: string,
    merchant_country_ids: string[]
  ): Promise<SalesChannelData[]> => {
    const updateSalesChannelRequests: Promise<
      AxiosResponse<SalesChannelData>
    >[] = merchant_country_ids.map<Promise<AxiosResponse<SalesChannelData>>>(
      (merchantCountryId) =>
        updateSalesChannel(
          { aoEnabled: true },
          accountId,
          merchantCountryId
        ).catch((err) => err)
    );

    return Promise.all(updateSalesChannelRequests).then((res) =>
      res.map((r) => r.data)
    );
  };

  const fetchUserFromAccount = (accountId: string, email: string) =>
    axios
      .get(PATHS.ACCOUNT_USER(accountId, email), config)
      .then((response) => response.data);

  const removeUserFromAccount = (accountId: string, email: string) =>
    axios
      .delete(PATHS.ACCOUNT_USER(accountId, email), config)
      .then((response) => response.data);

  const getUsers = (accountId: string) =>
    createPaginatedApiDataFetcher<UserResponseType>({
      endpoint: PATHS.ACCOUNT_USERS(accountId),
      headers: config.headers,
      baseURL: REACT_APP_FAM_HOST,
    });

  const resendInvite = (accountId: string, email: string) =>
    axios
      .patch(PATHS.ACCOUNT_USER(accountId, email), { state: 'pending' }, config)
      .then(noop);

  const removeProductSubscription = (
    accountId: string,
    subscriptionId: string
  ) =>
    axios
      .delete(PATHS.PRODUCT_SUBSCRIPTION(accountId, subscriptionId), config)
      .then((response) => response.data);

  const getProductSubscriptionEvents = async (
    accountId: string,
    productVerticalId: string
  ) => {
    const response = await axios.get(
      PATHS.PRODUCT_SUBSCRIPTION_EVENTS(accountId, productVerticalId),
      config
    );

    return response.data;
  };

  const getMerchantCountries = async (
    accountId: string,
    channelIds: string[]
  ): Promise<MerchantCountriesResponse> =>
    axios
      .post<MerchantCountriesResponse>(
        PATHS.MERCHANT_COUNTRIES(accountId),
        {
          ids: channelIds,
        },
        config
      )
      .then(
        (response: AxiosResponse<MerchantCountriesResponse>) => response.data
      )
      .catch(() => ({ merchantCountryInfoItems: [] }));

  const updateCompanyName = (accountId: string, companyName: string) =>
    axios
      .patch(
        PATHS.ACCOUNT_DETAILS(accountId),
        {
          companyName: companyName,
        },
        config
      )
      .then((response) => response.data)
      .catch(() => undefined);

  return {
    createAccount,
    getAccountDetails,
    subscriptionsAdd,
    getUserDetails,
    triggerVerificationEmail,
    triggerPasswordResetEmail,
    emailVerified,
    getProductVerticals,
    getSalesChannelTableData,
    getAllSalesChannels,
    updateSalesChannel,
    enableAoForSalesChannels,
    sendInitialWalmartSyncEmail,
    inviteUser,
    acceptInvite,
    fetchUserFromAccount,
    removeUserFromAccount,
    connectProductSyncForm,
    updateUsername,
    getUsers,
    resendInvite,
    removeProductSubscription,
    getProductSubscriptionEvents,
    getMerchantCountries,
    updateCompanyName,
    addAmazonAdsConnect,
    amazonSellingPartnerConnect,
    merchantConnect,
    claimMerchantCountries,
  };
};
