import React from 'react';
import { Modal } from '@teikametrics/tm-design-system/components/Modal';
import { ButtonState } from '@teikametrics/tm-design-system/components/Button';
import I18nKey from '../../lib/types/I18nKey';
import { useIntl } from 'react-intl';
import { AiFeatureRow } from '../../modules/account/components/disableAutomationModal';
import { LightningSlashIcon } from '@teikametrics/tm-design-system/img/icons';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';

export interface TrialEndedModalProps {
  onUpgrade: () => void;
  onStayBasic: () => void;
}

export const TrialEndedModal: React.FC<TrialEndedModalProps> = ({
  onUpgrade,
  onStayBasic,
}) => {
  const intl = useIntl();

  const [
    TITLE,
    BODY,
    LIST_HEADER,
    SECONDARY_BUTTON,
    PRIMARY_BUTTON,
    FIRST_FEATURE,
  ] = [
    I18nKey.TRIAL_ENDED_MODAL_TITLE,
    I18nKey.TRIAL_ENDED_MODAL_BODY,
    I18nKey.TRIAL_ENDED_MODAL_LIST_HEADER,
    I18nKey.TRIAL_ENDED_MODAL_SECONDARY_BUTTON,
    I18nKey.TRIAL_ENDED_MODAL_PRIMARY_BUTTON,
    I18nKey.BILLING_PLAN_FEATURES_BIDDER,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <Modal
      showModal={true}
      header={TITLE}
      headerClass="text-xl"
      body={BODY}
      icon={
        <Icon
          svg={LightningSlashIcon}
          size={IconSize.Large}
          className="text-purple-500"
        />
      }
      mainAction={{
        state: ButtonState.Enabled,
        label: PRIMARY_BUTTON,
        onClick: onUpgrade,
      }}
      secondaryAction={{
        state: ButtonState.Enabled,
        label: SECONDARY_BUTTON,
        onClick: onStayBasic,
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className="pt-20 flex flex-col space-y-8">
        <p className="text-base font-medium text-grey-900">{LIST_HEADER}</p>
        <AiFeatureRow text={FIRST_FEATURE} />
      </div>
    </Modal>
  );
};
