import { Filter } from '../types/Filter';
import { Sort } from '../types/Sort';
import { filtersToQueryString } from './filter';
import { StringMap } from '../types';

export const sortToQueryString = (sort: Sort): string =>
  `${sort.column},${sort.direction}`;

export const generateSortQueryString = (sorts?: Sort[]): string =>
  sorts ? sorts.map(sortToQueryString).join(';') : '';

export interface RequestFilterSort {
  readonly sorts: Sort[];
  readonly filters: Filter[];
}

export interface ParamsFilterSort {
  readonly sort: string;
  readonly filter: string;
}

export const getFilterAndSort = ({
  filters,
  sorts,
}: RequestFilterSort): ParamsFilterSort => {
  const params = {
    filter: filtersToQueryString(filters),
    sort: generateSortQueryString(sorts),
  };
  return filterEmptyValues(params);
};

export const buildUrlWithQueryParams = (
  path: string,
  params: Record<string, string> = {}
) => {
  const queryString = new URLSearchParams(params).toString();
  return queryString ? path.concat('?', queryString) : path;
};

export function filterEmptyValues<
  T extends StringMap<string> | Record<string, string>
>(stringMap: T): T {
  return Object.keys(stringMap)
    .filter((key) => stringMap[key] !== '')
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: stringMap[key],
      }),
      {} as T
    );
}
