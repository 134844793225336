import { IdToken, useAuth0 } from '@auth0/auth0-react';
import {
  RouteComponentProps,
  Router as ReachRouter,
  useLocation,
  useNavigate,
} from '@reach/router';
import { Spinner } from '@teikametrics/tm-design-system/components/Spinner';
import { AxiosProvider } from './containers/axiosProvider';
import { NotificationProvider } from './containers/notificationProvider';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { AccountSwitcher } from './AccountSwitcher';
import { AppModules } from './AppModules';
import { MerchantCountriesProvider } from './containers/merchantCountriesProvider/merchantCountriesProvider';
import { OptimizelyProvider } from './containers/optimizelyProvider/optimizelyProvider';
import { SalesChannelProvider } from './containers/SalesChannelProvider';
import { SaveChangesFlagProvider } from './containers/saveChangesFlagProvider';
import { SubscriptionProvider } from './containers/SubscriptionProvider';
import { UpgradeProvider } from './containers/upgradeProvider';
import {
  getCurrentAccountPermissions,
  getFreeTrialEndDate,
  isAIPlanEnabled,
  isInTrial,
  isManagedAccount,
} from './containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
  UserInfo,
} from './containers/userProvider/userProvider';
import { createBillingApiClient } from './lib/clients/BillingApiClient';
import { createFAMApiClient, FAMApiClient } from './lib/clients/FAMApiClient';
import { StringMap } from './lib/types';
import { AccountStatusResponse } from './lib/types/Billing';
import { Account, UserDetails } from './lib/types/Fam';
import * as FullStory from './lib/utilities/fullStory';
import FWCookie, { PERSISTED_KEYS } from './lib/utilities/fwCookie';
import * as Intercom from './lib/utilities/intercom';
import * as Segment from './lib/utilities/segment';
import {
  getAuthenticationDate,
  getRevenueTotals,
  getZuoraAccountInfo,
} from './lib/utilities/tracking';
import { DEMO_REQUEST } from './modules/survey/components/SingleSelectQuestionnareCard';
import { OnboardingSurvey } from './modules/survey/OnboardingSurvey';
import {
  SurveyAnswers,
  SurveyQuestionHeaders,
} from './modules/survey/SurveyQuestions';
import { Upgrade } from './modules/upgrade/Upgrade';
import { NavPaths } from './NavPaths';
import { OauthPopup } from './OauthPopup';
import {
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequest,
  asyncRequestIsComplete,
  AsyncRequestNotStarted,
} from './lib/utilities/asyncRequest';

const SHOW_SURVEY = process.env.REACT_APP_SHOW_SURVEY === 'true';

const LoggedIn: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext<UserContextState>(UserContext);
  const { getIdTokenClaims } = useAuth0();
  const params = new URLSearchParams(location.search);
  const accountPermissions = getCurrentAccountPermissions(
    userContext.userInfo.userDetails
  );

  const [step, setStep] = useState<SurveyQuestionHeaders>();
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswers>(
    {} as SurveyAnswers
  );
  const [showSurvey, setShowSurvey] = useState(false);

  const getUserIdToken = async () => {
    const idToken = await getIdTokenClaims();
    userContext.updateUserInfo({
      idToken: idToken,
    });

    return idToken;
  };

  const shouldRedirectToAccountSwitcher = (userDetails: UserDetails) => {
    return (
      userDetails.accountPermissions.length > 1 &&
      !FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID)
    );
  };

  const getUserDetails = async (): Promise<
    [IdToken, UserDetails | undefined]
  > => {
    const idToken = await getUserIdToken();
    const famClient = createFAMApiClient(idToken);

    // 1. Accept Any Invitations
    await acceptInvitationIfPresent(famClient, params);

    // 2. Get the users information
    let userDetails = await famClient.getUserDetails();
    return [idToken, userDetails];
  };

  const handleUserSignIn = async (): Promise<string | undefined> => {
    // 1. Get the users information
    const [idToken, userDetails] = await getUserDetails();

    // 2. Survey must be completed in order to create account.
    // Create the users account if they are new or are no longer in an account.
    if (!userDetails || !userDetails.accountPermissions.length) {
      if (SHOW_SURVEY) {
        setShowSurvey(true);
        return '/survey';
      } else {
        const newUserDetails = await createAccountForNewUser(
          createFAMApiClient(idToken!!)
        );
        return handleUserWithAccount(idToken, newUserDetails);
      }
    } else {
      return handleUserWithAccount(idToken, userDetails);
    }
  };

  const createAccountForNewUser = async (famClient: FAMApiClient) => {
    return (await createNewAccount(famClient))[1]!;
  };

  const handleUserWithAccount = async (
    token: IdToken,
    userDetails: UserDetails
  ): Promise<string | undefined> => {
    const famClient = createFAMApiClient(token!!);

    // 2. Ensure Email is verified.
    userDetails = await verifyEmail(famClient, token, userDetails!!);

    // 3. Set the user context
    userContext.updateUserInfo({
      idToken: token,
      userDetails,
    });

    // 4. If on account switcher page short circuit here.
    if (location.pathname === '/switch_account') {
      return;
    }

    // 5. Show Account Switcher if needed
    if (shouldRedirectToAccountSwitcher(userDetails!)) {
      userContext.updateBillingInfo(AsyncRequestNotStarted());
      return '/switch_account';
    }

    // 6. Update user context with currentAccountId from cookie, or first account if one is not found
    const cookie = FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
    const currentAccountId = (
      userDetails.accountPermissions.find(
        (item) => item.account.id === cookie
      ) || userDetails.accountPermissions[0]
    )?.account.id;

    userContext.updateUserInfo({
      idToken: token,
      userDetails,
      currentAccountId,
    });

    // 7. Show current page
  };

  const onUserInfoUpdate = async () => {
    // Once we have a user with an account, we identify you as user
    if (userContext.userInfo.idToken && userContext.userInfo.currentAccountId) {
      const billingInfo: AsyncRequest<
        AccountStatusResponse,
        void
      > = await createBillingApiClient(userContext.userInfo.idToken)
        .getAccountStatus(userContext.userInfo.currentAccountId)
        .then((response) => AsyncRequestCompleted(response))
        .catch(() => AsyncRequestFailed(undefined));

      userContext.updateBillingInfo(billingInfo);
      const billingData = asyncRequestIsComplete(billingInfo)
        ? billingInfo.result
        : undefined;

      operationBigBrother(userContext.userInfo, billingData);
    }
  };

  useEffect(() => {
    segmentTracking();
    onUserInfoUpdate();
  }, [userContext.userInfo]);

  const segmentTracking = async () => {
    if (userContext.userInfo.idToken && userContext.userInfo.currentAccountId) {
      const { idToken: token, currentAccountId: id } = userContext.userInfo;

      const auth = await getAuthenticationDate(token, id);
      const revenueMetrics = await getRevenueTotals(token, id);
      const zuoraAccountInfo = await getZuoraAccountInfo(token, id);

      Segment.identify(
        userContext.userInfo.userDetails?.id,
        userContext.userInfo.userDetails?.email!,
        { ...auth, ...revenueMetrics, ...zuoraAccountInfo }
      );
    }
  };

  const operationBigBrother = async (
    userInfo: UserInfo,
    billingAccountInfo?: AccountStatusResponse
  ) => {
    const currentAccount = getCurrentAccountPermissions(userInfo.userDetails)
      ?.account;
    if (
      userInfo.idToken &&
      userInfo.userDetails &&
      userInfo.currentAccountId &&
      currentAccount
    ) {
      Intercom.userLoggedIn(userInfo.idToken, userInfo.userDetails);
      FullStory.userLoggedIn(userInfo.userDetails);

      const ftStartDate = currentAccount.freeTrialStartedAt;
      const ftEndDate = getFreeTrialEndDate(currentAccount);
      const inTrial = isInTrial(currentAccount);

      Segment.identify(userInfo.userDetails.id, userInfo.userDetails.email, {
        rop_account_id: currentAccount.id,
        fam_id: currentAccount.id,
        salesforceaccountid: currentAccount.id,
        agreed_to_terms_of_service_and_privacy: currentAccount.onboardedAt,
        account_created_at: currentAccount.onboardedAt,
        company: { name: currentAccount.companyName },
        contact_name:
          userInfo.userDetails.firstName + ' ' + userInfo.userDetails.lastName,
        first_name: userInfo.userDetails.firstName,
        last_name: userInfo.userDetails.lastName,
        account_name: currentAccount.companyName,
        fw2_email_verified_date: currentAccount.onboardedAt,
        fw2_registration_date: currentAccount.onboardedAt,
        fw2_automation_status: isAIPlanEnabled(userContext),
        fw2_trial_status: inTrial,
        fw2_trial_start_date: ftStartDate,
        fw2_trial_end_date: ftEndDate,
        fw2_billing_status: billingAccountInfo?.hasPayment
          ? 'paying'
          : 'not_paying',
        first_credit_card_added_date: billingAccountInfo?.createdAt,
      });
    }
  };

  useEffect(() => {
    handleUserSignIn().then((redirectTo) => {
      if (redirectTo) {
        return navigate(redirectTo);
      }
    });
  }, []);

  const onSurveyCompleted = async () => {
    const token = await getUserIdToken();
    // Sending survey data to Segment
    // Hubspot takes multi value answers as <value1>;<value2>
    const segmentAnswers = Object.keys(surveyAnswers).reduce(
      (obj, key) => {
        if (key === SurveyQuestionHeaders.SellProducts) {
          // The only supported values to send are Amazon, Walmart, eBay, and Other.
          // This filters out any other vales and replaces them with other.
          (obj as any)[key] = surveyAnswers[key as SurveyQuestionHeaders]
            .map((value) =>
              !['Amazon', 'Walmart', 'eBay'].includes(value) ? 'Other' : value
            )
            .join(';');

          return obj;
        }

        if (key === SurveyQuestionHeaders.AnnualSales) {
          // We check for the special flag that the user requested a demo and
          // will send that as a separate field to hubspot.
          obj['connect_to_sales'] = surveyAnswers[
            key as SurveyQuestionHeaders
          ].includes(DEMO_REQUEST)
            ? 'yes'
            : 'no';
          obj[key] = surveyAnswers[key as SurveyQuestionHeaders]
            .filter((v) => v !== DEMO_REQUEST)
            .join(';');
          return obj;
        }

        obj[key] = surveyAnswers[key as SurveyQuestionHeaders].join(';');
        return obj;
      },
      { email: token.email } as StringMap<string>
    );
    // The user doesn't have a FAM id yet but we will associate their answers with their email.
    Segment.identify(undefined, token.email!, {
      ...segmentAnswers,
      fw_survey_completion_date: DateTime.local().toISO(),
    });
    Segment.track('Onboarding Survey Completed');

    const userDetails = await createAccountForNewUser(
      createFAMApiClient(token)
    );
    await handleUserWithAccount(token, userDetails); // ignore the redirect because this is a net new user that will only have a single account.
    setTimeout(() => {
      setShowSurvey(false); // Setting new
      navigate('/');
    }, 11000);
  };

  const isManaged = isManagedAccount(userContext);

  if (showSurvey && !isManaged) {
    return (
      <ReachRouter>
        <OnboardingSurvey
          path="survey"
          step={step}
          setStep={setStep}
          surveyAnswers={surveyAnswers}
          setSurveyAnswers={setSurveyAnswers}
          onComplete={onSurveyCompleted}
        />
      </ReachRouter>
    );
  }

  if (!userContext.userInfo.userDetails) {
    return <Spinner />;
  }

  return (
    <OptimizelyProvider userInfo={userContext.userInfo.userDetails!!}>
      <SaveChangesFlagProvider>
        <SalesChannelProvider idToken={userContext.userInfo.idToken!!}>
          <MerchantCountriesProvider>
            <NotificationProvider>
              <AxiosProvider>
                <UpgradeProvider>
                  <SubscriptionProvider
                    idToken={userContext.userInfo.idToken!!}
                  >
                    <ReachRouter>
                      <AccountSwitcher
                        path="/switch_account"
                        redirectUrl="/"
                        dataTestId={'switchAccount'}
                      />
                      <AppModules
                        default={true}
                        token={userContext.userInfo.idToken!!}
                        permissions={accountPermissions}
                      />
                      <OauthPopup path="amz-sp-api/callback" />
                      <Upgrade path={NavPaths.Upgrade} />
                    </ReachRouter>
                  </SubscriptionProvider>
                </UpgradeProvider>
              </AxiosProvider>
            </NotificationProvider>
          </MerchantCountriesProvider>
        </SalesChannelProvider>
      </SaveChangesFlagProvider>
    </OptimizelyProvider>
  );
};

/**
 * If the 'accept_account_invite' query param is present this will
 * clear the current account cookie so that the account switcher if forced once
 * the user accepts the invite.
 */
const acceptInvitationIfPresent = async (
  famClient: FAMApiClient,
  params: URLSearchParams
): Promise<void> => {
  const invitedAccountId = params.get('accept_account_invite');
  if (invitedAccountId) {
    FWCookie.deleteCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
    return famClient.acceptInvite(invitedAccountId);
  }

  return Promise.resolve();
};

const verifyEmail = async (
  famClient: FAMApiClient,
  idToken: IdToken,
  userDetails: UserDetails
) => {
  // Check if the users email was verified. If so update our system to show the user as verified.
  if (userDetails && !userDetails?.emailVerified && idToken.email_verified) {
    famClient.emailVerified().catch(noop);
    userDetails.emailVerified = true;
  }
  return userDetails;
};

const createNewAccount = async (
  famClient: FAMApiClient
): Promise<[Account, UserDetails | undefined]> => {
  // Create new account for user.
  const account = await famClient.createAccount();

  // Load the users new data.
  const refreshedDetails = await famClient.getUserDetails();
  return [account, refreshedDetails];
};

export default LoggedIn;
