import { FormattedMessage, IntlShape } from 'react-intl';
import find from 'lodash/find';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import lowerCase from 'lodash/lowerCase';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { CheckboxState } from '@teikametrics/tm-design-system/components/Checkbox';
import { SelectOptionProps } from '@teikametrics/tm-design-system/components/Select';
import {
  Alignment,
  Placement,
} from '@teikametrics/tm-design-system/components/Tooltip';
import { TableChange, TableSelectSettings } from 'containers/table/ducks/types';
import { OptimizelyContextState } from '../../../../../containers/optimizelyProvider/optimizelyProvider';
import { getChannelIcon } from '../../../../../lib/clients/util';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AdType,
  BudgetType,
  CampaignAdFormat,
  CampaignCostType,
  CampaignDetails,
  CampaignStatus,
  CampaignTactic,
  CampaignTargetingType,
  DataAvailabilityInfo,
  DataInspectionList,
  DateRange,
  ExpressionType,
  FilterFieldMapper,
  FlywheelSalesChannel,
  LabelValue,
  MerchantCountryCode,
  Portfolio,
  SUPPORTED_MERCHANT_COUNTRIES_PER_CHANNEL,
  TargetStatus as TargetStatusValues,
} from '../../../../../lib/types/AOSharedTypes';
import {
  MerchantCountry,
  MerchantType,
  SalesChannel,
} from '../../../../../lib/types/Fam';
import { Filter, FilterOps } from '../../../../../lib/types/Filter';
import I18nKey from '../../../../../lib/types/I18nKey';
import { OptimizelyFlags } from '../../../../../lib/types/OptimizelyFlags';
import { getCountryFlagIcon } from '../../../../../lib/utilities/countryFlags';
import {
  BulkEditModalElement,
  BulkEditModalGroupedElement,
} from '../bulkEditModal';
import {
  DATA_INSPECTION_COLUMN_MANAGER_CONFIG,
  DATA_INSPECTOR_COLUMN_NAME,
  DataInspectorColumnIdentifier,
} from '../dataInspectorConfig';
import {
  ADS_MANAGER_AD_ITEMS_TABLE_ID,
  ADS_MANAGER_ADGROUP_TABLE_ID,
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  ADS_MANAGER_PROFILES_TABLE_ID,
  ADS_MANAGER_TARGETS_TABLE_ID,
} from '../ducks/types';
import {
  AD_ITEM_SEARCH_KEY_FIELD,
  AD_LEVEL_EXTERNAL_ID_MAPPER,
  AD_LEVEL_INTERNAL_ID_MAPPER,
  ADGROUP_MIN_MACS_VALUE,
  ADGROUP_TABLE_SEARCH_KEY,
  AdgroupDataApiColumnGroupIdentifier,
  AdLevelI8nKeyMapper,
  ADS_MANAGER_AD_LEVEL_PAGES,
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER,
  ADS_MANAGER_PAGE_TO_AD_TYPE_MAPPER,
  ADS_MANAGER_PAGE_TO_SALESCHANNEL_MAPPER,
  ADS_MANAGER_PAGE_URI_TO_AD_LEVEL_MAPPER,
  AdTypeI8nKeyMapper,
  CAMPAIGN_DATE_FORMAT,
  CAMPAIGN_TABLE_SEARCH_KEY,
  CampaignDataApiColumnGroupIdentifier,
  Checkbox,
  DataInspection,
  DataInspectionAdLevelConfigProps,
  DataInspectorColumnManagerConfigProps,
  EXPRESSION_TYPE_TO_FILTER_I18NKEY_MAPPER,
  NumericValueConfig,
  NumericValueConfigProps,
  PRODUCTAD_TABLE_SEARCH_KEY,
  ProductAdsApiColumnGroupIdentifier,
  PROFILE_TABLE_SEARCH_KEY,
  ProfileDataApiColumnGroupIdentifier,
  TabCheckbox,
  TARGET_ADLEVEL_LIST,
  TARGETS_TABLE_SEARCH_KEY,
  TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
  TargetsDataApiColumnGroupIdentifier,
} from '../types';
import {
  getTabCheckboxColumnSelectionInfoInLocalStorage,
  storeTabCheckboxColumnSelectionInfoInLocalStorage,
} from './storageUtils';
import { ColumnManagerOption } from '@teikametrics/tm-design-system/components/ColumnManager';
import reduce from 'lodash/reduce';
import { filterTargetSummaryColumns } from './columnManagerUtils';
import {
  DATE_RANGE_CONFIG_FOR_DATA_TRACKING,
  PredefinedDateRange,
} from './dateRangeConfig';

export const getSalesChannelsOptions = (
  salesChannels: SalesChannel[]
): SelectOptionProps<string>[] =>
  salesChannels.map((channel: SalesChannel) => ({
    label: startCase(channel.name),
    value: channel.salesChannelId,
    icon: getChannelIcon(channel.name),
  }));

export const sortSalesChannelsByName = (salesChannels: SalesChannel[]) =>
  sortBy(salesChannels, [(channel) => channel.name]);

export const sortPortfoliosByName = (portfolios: Portfolio[]) =>
  sortBy(portfolios, [(portfolio) => portfolio.name]);

export const isMerchantCountryEnabled = (
  salesChannel: FlywheelSalesChannel,
  country: MerchantCountryCode
): boolean =>
  SUPPORTED_MERCHANT_COUNTRIES_PER_CHANNEL[salesChannel].includes(country);

// Merchant Country Helpers
export const getMerchantCountriesOptions = (
  merchants: Array<MerchantCountry>,
  salesChannelName: FlywheelSalesChannel
): Array<SelectOptionProps<string | number>> => {
  return sortBy(merchants, [(merchant) => merchant.merchantName]).map(
    (merchant: MerchantCountry) => {
      const shouldMcBeEnabled = isMerchantCountryEnabled(
        salesChannelName,
        merchant.country as MerchantCountryCode
      );

      return {
        label: merchant.merchantName,
        value: merchant.merchantCountryId,
        icon: getCountryFlagIcon(merchant.country),
        salesChannelId: merchant.salesChannelId,
        disabled: !shouldMcBeEnabled,
        tooltip: !shouldMcBeEnabled
          ? {
              content: (
                <FormattedMessage
                  id={I18nKey.ADS_MANAGER_TOOLTIP_MESSAGE_MC_DISABLED}
                />
              ),
              position: {
                placement: Placement.Right,
                alignment: Alignment.Center,
              },
            }
          : undefined,
      };
    }
  );
};

export const getMerchantCountries = (
  salesChannels: Array<SalesChannel>
): Array<MerchantCountry> => {
  const merchantCountries: Array<MerchantCountry> = [];
  salesChannels.forEach((channel: SalesChannel) => {
    const currentChannelsMerchantCountries: Array<MerchantCountry> = channel.merchantCountries.map(
      (merchantCountry) => ({
        ...merchantCountry,
        salesChannelId: channel.salesChannelId,
      })
    );
    merchantCountries.push(...currentChannelsMerchantCountries);
  });
  return merchantCountries;
};

export const getMerchantCountriesForSalesChannel = (
  salesChannel: SalesChannel
): MerchantCountry[] => {
  const currentChannelsMerchantCountries: MerchantCountry[] = salesChannel.merchantCountries.map(
    (merchantCountry) => ({
      ...merchantCountry,
      salesChannelId: salesChannel.salesChannelId,
    })
  );
  return currentChannelsMerchantCountries;
};

// Date Picker Helpers
export const presentDay: DateTime = DateTime.local();
export const previousDayDate: DateTime = presentDay.minus({ days: 1 });
export const sevenDayBackDate: DateTime = presentDay.minus({ days: 7 });

export const getDaysBetweenDates = (
  startDate: DateTime,
  endDate: DateTime
): number => {
  const { days } = endDate.diff(startDate, ['days']);
  return isUndefined(days) ? 0 : Math.ceil(days) + 1;
};

export const areGivenDatesEqual = (date1: DateTime, date2: DateTime) =>
  date1.startOf('day').equals(date2.startOf('day'));

export const getPermittedDateRange = (
  data: Array<DataAvailabilityInfo>,
  selectedMerchantsIds: Array<string>
): DateRange => {
  const selectedMerchantDataAvailabilityInfo: Array<DataAvailabilityInfo> = data.filter(
    (entry: DataAvailabilityInfo) =>
      selectedMerchantsIds.includes(entry.merchantCountryId)
  );
  const earliestPossibleDate: Date = selectedMerchantDataAvailabilityInfo
    .map((info: DataAvailabilityInfo) => info.earliestAvailableDate)
    .sort((dateA: Date, dateB: Date) => dateA.getTime() - dateB.getTime())[0];

  const latestPossibleDate: Date = selectedMerchantDataAvailabilityInfo
    .map((info: DataAvailabilityInfo) => info.latestAvailableDate)
    .sort((dateA: Date, dateB: Date) => dateA.getTime() - dateB.getTime())[
    selectedMerchantDataAvailabilityInfo.length - 1
  ];

  const isLatestPossibleDateEqualToToday =
    latestPossibleDate &&
    areGivenDatesEqual(presentDay, DateTime.fromJSDate(latestPossibleDate));

  let permittedDateRange: DateRange = {
    initialStartDate: DateTime.fromJSDate(sevenDayBackDate.toJSDate()),
    initialEndDate: DateTime.fromJSDate(latestPossibleDate),
    minDate: DateTime.fromJSDate(earliestPossibleDate),
    maxDate: isLatestPossibleDateEqualToToday ? presentDay : previousDayDate,
  };

  if (!earliestPossibleDate) {
    permittedDateRange = {
      ...permittedDateRange,
      initialStartDate: sevenDayBackDate,
      minDate: sevenDayBackDate,
    };
  }
  if (!latestPossibleDate) {
    permittedDateRange = {
      ...permittedDateRange,
      initialEndDate: previousDayDate,
      maxDate: previousDayDate,
    };
  }

  // if start date is more than end date, set start date to previous date minus 7 days and end date to previous date
  if (
    latestPossibleDate &&
    permittedDateRange.initialStartDate >
      DateTime.fromJSDate(latestPossibleDate)
  ) {
    permittedDateRange = {
      ...permittedDateRange,
      initialStartDate: sevenDayBackDate,
      initialEndDate: previousDayDate,
    };
  }

  // If there is no data for previous days
  // Check if earliest possible date is more than latest possible date minus 6 days and set start date to latest possible date minus 6 days and end date latest possible date
  // otherwise set start date to earliest date and end date to latest date

  const sixDaysBackFromLatestPossibleDate =
    latestPossibleDate &&
    DateTime.fromJSDate(latestPossibleDate).minus({ days: 6 });

  const isLatestSyncDateLessThanPreviousDay =
    latestPossibleDate &&
    earliestPossibleDate &&
    DateTime.fromJSDate(latestPossibleDate).startOf('day') <
      previousDayDate.startOf('day');
  if (isLatestSyncDateLessThanPreviousDay) {
    if (
      sixDaysBackFromLatestPossibleDate >
      DateTime.fromJSDate(earliestPossibleDate)
    ) {
      permittedDateRange = {
        ...permittedDateRange,
        initialStartDate: sixDaysBackFromLatestPossibleDate,
        initialEndDate: DateTime.fromJSDate(latestPossibleDate),
      };
    } else {
      permittedDateRange = {
        ...permittedDateRange,
        initialStartDate: DateTime.fromJSDate(earliestPossibleDate),
        initialEndDate: DateTime.fromJSDate(latestPossibleDate),
      };
    }
  }

  return permittedDateRange;
};

// Data Inspection Helpers
const getIntlStringForAdType = (intl: IntlShape, adType: AdType) => {
  const id = AdTypeI8nKeyMapper[adType];
  return intl.formatMessage({ id });
};

const getIntlStringForAdLevel = (intl: IntlShape, adLevel: AdLevel) => {
  const id = AdLevelI8nKeyMapper[adLevel];
  return intl.formatMessage({ id });
};

export const getAdLevelWithFilteredTargets = (
  availableTargetTypes: AdLevel[],
  adLevelList: AdLevel[]
): AdLevel[] => {
  return !isEmpty(availableTargetTypes)
    ? adLevelList.filter((adLevel) => {
        if (TARGET_ADLEVEL_LIST.includes(adLevel)) {
          return availableTargetTypes.includes(adLevel);
        }
        return true;
      })
    : adLevelList;
};

export const getDropDownMetaDataForDataInspection = (
  intl: IntlShape,
  dataInspectionList: DataInspectionList[],
  countryCode: MerchantCountryCode,
  specificTargetTypes: AdLevel[],
  targetingType?: CampaignTargetingType,
  shouldShowSBA?: boolean
): DataInspection[] => {
  const allAdvertisingTypeInfo = dataInspectionList
    .filter((info) => info.merchantCountryCode === countryCode)
    .map((info) => info.advertisingTypeInfo)
    .flat();

  const filteredAdTypes = uniq(
    allAdvertisingTypeInfo
      .filter((adTypeInfo) =>
        adTypeInfo.adType === AdType.SearchBrandAmplifier ? shouldShowSBA : true
      )
      .map((info) => info.adType)
  ).sort();

  return filteredAdTypes.map((type) => {
    const adLevels = uniq(
      allAdvertisingTypeInfo
        .filter((info) => info.adType === type)
        .map((info) =>
          getAdLevelWithFilteredTargets(specificTargetTypes, info.adLevels)
        )
        .flat()
    ).map((adLevel) => {
      return {
        label: getIntlStringForAdLevel(intl, adLevel),
        value: adLevel,
      };
    });

    // Auto campaigns can only have Product Targets. Hence filtering out Keyword targets and targets from the dropdown list.
    const adLevelsBasedOnCampaignTargetingType = isAutoCampaign(targetingType)
      ? adLevels.filter(
          (adLevel) =>
            adLevel.value !== AdLevel.KeywordTargets &&
            adLevel.value !== AdLevel.Targets
        )
      : [...adLevels];

    return {
      parent: {
        label: getIntlStringForAdType(intl, type),
        value: type,
      },
      child: adLevelsBasedOnCampaignTargetingType,
    };
  });
};

// For SBA advertising type returning SponseredBrands as BE and Sangam understands it in the same way
export const getRequestAdvertisingType = (currentAdType: AdType) => {
  return currentAdType === AdType.SearchBrandAmplifier
    ? AdType.SponsoredBrands
    : currentAdType;
};

// Ad-Level Campaigns Helpers
export enum CampaignsApiColumnIdentifier {
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'CampaignStatus',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  BudgetType = 'BudgetType',
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  BudgetRollover = 'BudgetRollover',
  SearchCarouselInclusion = 'SearchCarouselInclusion',
  BuyBoxBidMultiplier = 'BuyBoxBidMultiplier',
  SearchIngridBidMultiplier = 'SearchIngridBidMultiplier',
  HomePageBidMultiplier = 'HomePageBidMultiplier',
  StockUpBidMultiplier = 'StockUpBidMultiplier',
  DesktopBidMultiplier = 'DesktopBidMultiplier',
  AppBidMultiplier = 'AppBidMultiplier',
  MobileBidMultiplier = 'MobileBidMultiplier',
  AutomationStatus = 'AutomationStatus',
  MACSTarget = 'MACSTarget',
  MinBid = 'MinBid',
  MaxBid = 'MaxBid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  AdSales = 'AdSales',
  AdUnitsSold = 'AdUnitsSold',
  ConversionRate = 'ConversionRate',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  ClickThroughRate = 'ClickThroughRate',
  CostPerClick = 'CostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  BrandClickRevenue = 'BrandClickRevenue',
  //amazon specific columns
  CampaignAdFormat = 'CampaignAdFormat',
  AmazonBidOptimization = 'AmazonBidOptimization',
  CampaignServingStatus = 'CampaignServingStatus',
  BelowTopOfTheSearchBidMultiplier = 'BelowTopOfTheSearchBidMultiplier',
  Portfolio = 'Portfolio',
  AmazonBiddingStrategy = 'AmazonBiddingStrategy',
  ProductPageBidMultiplier = 'ProductPageBidMultiplier',
  TopOfSearchBidMultiplier = 'TopOfSearchBidMultiplier',
  AdConversions = 'AdConversions',
  CampaignTactic = 'CampaignTactic',
  NewToBrandOrders = 'NewToBrandOrders',
  PercentageOfOrdersNewToBrand = 'PercentageOfOrdersNewToBrand',
  NewToBrandOrdersRate = 'NewToBrandOrdersRate',
  NewToBrandSales = 'NewToBrandSales',
  PercentageOfSalesNewToBrand = 'PercentageOfSalesNewToBrand',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  PercentageOfAdUnitsSoldNewToBrand = 'PercentageOfAdUnitsSoldNewToBrand',
  MerchantName = 'MerchantName',
  ItemBuyBoxPlacement = 'ItemBuyBoxPlacement',
  ItemCarouselPlacement = 'ItemCarouselPlacement',
  CampaignCostType = 'CampaignCostType',
}

export const CAMPAIGNS_API_COLUMN_NAME: Record<
  CampaignsApiColumnIdentifier,
  string
> = Object.freeze({
  [CampaignsApiColumnIdentifier.CampaignName]: 'campaignName',
  [CampaignsApiColumnIdentifier.CampaignType]: 'campaignType',
  [CampaignsApiColumnIdentifier.CampaignTargetingType]: 'targetingType',
  [CampaignsApiColumnIdentifier.CampaignStatus]: 'status',
  [CampaignsApiColumnIdentifier.StartDate]: 'startDate',
  [CampaignsApiColumnIdentifier.EndDate]: 'endDate',
  [CampaignsApiColumnIdentifier.BudgetType]: 'budgetType',
  [CampaignsApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [CampaignsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [CampaignsApiColumnIdentifier.BudgetRollover]: 'budgetRollover',
  [CampaignsApiColumnIdentifier.SearchCarouselInclusion]: 'carouselStatus',
  [CampaignsApiColumnIdentifier.ItemBuyBoxPlacement]: 'itemBuyBox',
  [CampaignsApiColumnIdentifier.ItemCarouselPlacement]: 'itemCarousel',
  [CampaignsApiColumnIdentifier.BuyBoxBidMultiplier]: 'buyBoxBidMultiplier',
  [CampaignsApiColumnIdentifier.SearchIngridBidMultiplier]:
    'searchIngridBidMultiplier',
  [CampaignsApiColumnIdentifier.HomePageBidMultiplier]: 'homePageBidMultiplier',
  [CampaignsApiColumnIdentifier.StockUpBidMultiplier]: 'stockUpBidMultiplier',
  [CampaignsApiColumnIdentifier.DesktopBidMultiplier]: 'desktopBidMultiplier',
  [CampaignsApiColumnIdentifier.AppBidMultiplier]: 'appBidMultiplier',
  [CampaignsApiColumnIdentifier.MobileBidMultiplier]: 'mobileBidMultiplier',
  [CampaignsApiColumnIdentifier.AutomationStatus]: 'automationStatus',
  [CampaignsApiColumnIdentifier.MACSTarget]: 'macsTarget',
  [CampaignsApiColumnIdentifier.MinBid]: 'minBid',
  [CampaignsApiColumnIdentifier.MaxBid]: 'maxBid',
  [CampaignsApiColumnIdentifier.Impressions]: 'impressions',
  [CampaignsApiColumnIdentifier.Clicks]: 'clicks',
  [CampaignsApiColumnIdentifier.AdSpend]: 'adSpend',
  [CampaignsApiColumnIdentifier.AdSales]: 'adSalesTotal',
  [CampaignsApiColumnIdentifier.AdUnitsSold]: 'unitsSold',
  [CampaignsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [CampaignsApiColumnIdentifier.ACOS]: 'acosTotal',
  [CampaignsApiColumnIdentifier.ROAS]: 'roasTotal',
  [CampaignsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [CampaignsApiColumnIdentifier.CostPerClick]: 'avgCostPerClick',
  [CampaignsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [CampaignsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [CampaignsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [CampaignsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [CampaignsApiColumnIdentifier.BrandClickRevenue]: 'brandClickRevenue',
  //amazon specific
  [CampaignsApiColumnIdentifier.CampaignAdFormat]: 'campaignAdFormat',
  [CampaignsApiColumnIdentifier.AmazonBidOptimization]: 'bidOptimization',
  [CampaignsApiColumnIdentifier.CampaignServingStatus]: 'campaignServingStatus',
  [CampaignsApiColumnIdentifier.BelowTopOfTheSearchBidMultiplier]:
    'belowTopOfSearchBidMultiplier',
  [CampaignsApiColumnIdentifier.Portfolio]: 'portfolio',
  [CampaignsApiColumnIdentifier.AmazonBiddingStrategy]: 'biddingStrategy',
  [CampaignsApiColumnIdentifier.ProductPageBidMultiplier]:
    'productPageBidMultiplier',
  [CampaignsApiColumnIdentifier.TopOfSearchBidMultiplier]:
    'topOfSearchBidMultiplier',
  [CampaignsApiColumnIdentifier.AdConversions]: 'adConversions',
  [CampaignsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [CampaignsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [CampaignsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [CampaignsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [CampaignsApiColumnIdentifier.PercentageOfOrdersNewToBrand]:
    'newToBrandOrdersPercentage',
  [CampaignsApiColumnIdentifier.NewToBrandOrdersRate]: 'newToBrandOrderRate',
  [CampaignsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [CampaignsApiColumnIdentifier.PercentageOfSalesNewToBrand]:
    'newToBrandSalesPercentage',
  [CampaignsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [CampaignsApiColumnIdentifier.PercentageOfAdUnitsSoldNewToBrand]:
    'newToBrandAdUnitsSoldPercentage',
  [CampaignsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [CampaignsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
});

export const CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  CampaignDataApiColumnGroupIdentifier
> = Object.freeze({
  [CAMPAIGNS_API_COLUMN_NAME.CampaignName]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.Portfolio]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignTactic]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MerchantName]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignCostType]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.StartDate]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.EndDate]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignType]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignStatus]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BudgetType]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.DailyBudget]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.TotalBudget]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BudgetRollover]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignServingStatus]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AutomationStatus]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MACSTarget]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MinBid]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MaxBid]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.Impressions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.Clicks]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdSpend]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdUnitsSold]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdConversions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ConversionRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ACOS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ROAS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ClickThroughRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.CostPerClick]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.DirectAdSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.DirectACOS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.DirectROAS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.RelatedClickRevenue]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.BrandClickRevenue]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdConversions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrders]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrdersRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.PercentageOfSalesNewToBrand]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_CAMPAIGNS_API_COLUMN_DATA_MAPPING: Record<
  string,
  string
> = Object.freeze({
  [CAMPAIGNS_API_COLUMN_NAME.CampaignStatus]: 'channelSettings.status',
  [CAMPAIGNS_API_COLUMN_NAME.DailyBudget]: 'channelSettings.dailyBudget.amount',
  [CAMPAIGNS_API_COLUMN_NAME.TotalBudget]: 'channelSettings.totalBudget.amount',
  [CAMPAIGNS_API_COLUMN_NAME.BudgetRollover]: 'channelSettings.budgetRollover',
  [CAMPAIGNS_API_COLUMN_NAME.StartDate]: 'channelSettings.startDate',
  [CAMPAIGNS_API_COLUMN_NAME.EndDate]: 'channelSettings.endDate',
  [CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion]:
    'channelSettings.carouselStatus',
  [CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement]: 'channelSettings.itemBuyBox',
  [CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement]:
    'channelSettings.itemCarousel',
  [CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier]:
    'channelSettings.placementBidMultiplier.searchIngridBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier]:
    'channelSettings.placementBidMultiplier.buyBoxBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier]:
    'channelSettings.placementBidMultiplier.homePageBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier]:
    'channelSettings.placementBidMultiplier.stockUpBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier]:
    'channelSettings.platformBidMultiplier.desktopBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier]:
    'channelSettings.platformBidMultiplier.appBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier]:
    'channelSettings.platformBidMultiplier.mobileBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy]:
    'channelSettings.biddingStrategy',
  [CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier]:
    'channelSettings.placementBidMultiplier.productPageBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier]:
    'channelSettings.placementBidMultiplier.topOfSearchBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.Portfolio]:
    'campaignDetails.campaignPortfolioName',
  [CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization]:
    'channelSettings.bidOptimization',
  [CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier]:
    'channelSettings.placementBidMultiplier.belowTopOfSearchBidMultiplier',
  [CAMPAIGNS_API_COLUMN_NAME.AutomationStatus]:
    'flywheelSettings.automationStatus',
  [CAMPAIGNS_API_COLUMN_NAME.MACSTarget]: 'flywheelSettings.macsTarget',
  [CAMPAIGNS_API_COLUMN_NAME.MinBid]: 'flywheelSettings.minBid.amount',
  [CAMPAIGNS_API_COLUMN_NAME.MaxBid]: 'flywheelSettings.maxBid.amount',
});

//Ad-Level AdGroup Helpers
export enum AdgroupsApiColumnIdentifier {
  AdGroupName = 'AdGroupName',
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'CampaignStatus',
  AdGroupStatus = 'AdGroupStatus',
  BidAutomationStatus = 'BidAutomationStatus',
  MACSTarget = 'MACSTarget',
  MinBid = 'MinBid',
  MaxBid = 'MaxBid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  AdSales = 'AdSales',
  UnitsSold = 'UnitsSold',
  ConversionRate = 'ConversionRate',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  ClickThroughRate = 'ClickThroughRate',
  AverageCostPerClick = 'AverageCostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  BrandClickRevenue = 'BrandClickRevenue',
  //amazon specific columns
  CampaignTactic = 'CampaignTactic',
  CampaignDailyBudget = 'CampaignDailyBudget',
  TotalBudget = 'TotalBudget',
  AdgroupDefaultBid = 'AdgroupDefaultBid',
  AdConversions = 'AdConversions',
  NewToBrandSales = 'NewToBrandSales',
  NewToBrandOrders = 'NewToBrandOrders',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  MerchantName = 'MerchantName',
  StartDate = 'StartDate',
  Portfolio = 'Portfolio',
  CampaignCostType = 'CampaignCostType',
  ReviewStatus = 'AdGroupReviewStatus',
}

export const ADGROUPS_API_COLUMN_NAME: Record<
  AdgroupsApiColumnIdentifier,
  string
> = Object.freeze({
  [AdgroupsApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [AdgroupsApiColumnIdentifier.CampaignName]: 'campaignName',
  [AdgroupsApiColumnIdentifier.CampaignType]: 'campaignType',
  [AdgroupsApiColumnIdentifier.CampaignTargetingType]: 'campaignTargetingType',
  [AdgroupsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [AdgroupsApiColumnIdentifier.AdGroupStatus]: 'status',
  [AdgroupsApiColumnIdentifier.BidAutomationStatus]: 'automationStatus',
  [AdgroupsApiColumnIdentifier.MACSTarget]: 'macsTarget',
  [AdgroupsApiColumnIdentifier.MinBid]: 'minBid',
  [AdgroupsApiColumnIdentifier.MaxBid]: 'maxBid',
  [AdgroupsApiColumnIdentifier.Impressions]: 'impressions',
  [AdgroupsApiColumnIdentifier.Clicks]: 'clicks',
  [AdgroupsApiColumnIdentifier.AdSpend]: 'adSpend',
  [AdgroupsApiColumnIdentifier.AdSales]: 'adSalesTotal',
  [AdgroupsApiColumnIdentifier.UnitsSold]: 'unitsSold',
  [AdgroupsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [AdgroupsApiColumnIdentifier.ACOS]: 'acosTotal',
  [AdgroupsApiColumnIdentifier.ROAS]: 'roasTotal',
  [AdgroupsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [AdgroupsApiColumnIdentifier.AverageCostPerClick]: 'avgCostPerClick',
  [AdgroupsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [AdgroupsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [AdgroupsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [AdgroupsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [AdgroupsApiColumnIdentifier.BrandClickRevenue]: 'brandClickRevenue',
  //amazon specific columns
  [AdgroupsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [AdgroupsApiColumnIdentifier.CampaignDailyBudget]: 'campaignDailyBudget',
  [AdgroupsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [AdgroupsApiColumnIdentifier.AdgroupDefaultBid]: 'adGroupDefaultBid',
  [AdgroupsApiColumnIdentifier.AdConversions]: 'adConversions',
  [AdgroupsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [AdgroupsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [AdgroupsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [AdgroupsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [AdgroupsApiColumnIdentifier.StartDate]: 'startDate',
  [AdgroupsApiColumnIdentifier.Portfolio]: 'portfolio',
  [AdgroupsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
  [AdgroupsApiColumnIdentifier.ReviewStatus]: 'adGroupReviewStatus',
});

export const ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  AdgroupDataApiColumnGroupIdentifier
> = Object.freeze({
  [ADGROUPS_API_COLUMN_NAME.AdGroupName]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignName]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignType]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignTargetingType]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignStatus]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignTactic]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.TotalBudget]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.MerchantName]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.Portfolio]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignCostType]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.AdGroupStatus]:
    AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus]:
    AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid]:
    AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.BidAutomationStatus]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MACSTarget]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MinBid]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MaxBid]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.Impressions]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.Clicks]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdSpend]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.UnitsSold]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ConversionRate]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ACOS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ROAS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ClickThroughRate]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AverageCostPerClick]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.DirectAdSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.DirectACOS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.DirectROAS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.RelatedClickRevenue]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.BrandClickRevenue]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdConversions]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.NewToBrandSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.NewToBrandOrders]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING: Record<
  string,
  string
> = Object.freeze({
  [ADGROUPS_API_COLUMN_NAME.AdGroupName]: 'adGroupDetails.adGroupName',
  [ADGROUPS_API_COLUMN_NAME.AdGroupStatus]: 'channelSettings.status',
  [ADGROUPS_API_COLUMN_NAME.BidAutomationStatus]:
    'flywheelSettings.automationStatus',
  [ADGROUPS_API_COLUMN_NAME.MACSTarget]: 'flywheelSettings.macsTarget',
  [ADGROUPS_API_COLUMN_NAME.MinBid]: 'flywheelSettings.minBid.amount',
  [ADGROUPS_API_COLUMN_NAME.MaxBid]: 'flywheelSettings.maxBid.amount',
  [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid]:
    'channelSettings.defaultBid.amount',
});

//Ad-Level AdItems Helpers
export enum ProductAdsApiColumnIdentifier {
  ProductName = 'ProductName',
  ProductImage = 'ProductImage',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'AdItemsCampaignStatus',
  ProductAdStatus = 'Status',
  Bid = 'Bid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  TotalAdSales = 'TotalAdSales',
  DirectAdSales = 'DirectAdSales',
  AdUnitsSold = 'AdUnitsSold',
  TotalConversions = 'TotalConversions',
  ConversionRate = 'ConversionRate',
  TotalACOS = 'TotalACOS',
  TotalROAS = 'TotalROAS',
  ClickThroughRate = 'ClickThroughRate',
  AvgCostPerClick = 'AvgCostPerClick',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  BrandClickRevenue = 'BrandClickRevenue',
  CampaignTactic = 'CampaignTactic',
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  AdConversions = 'AdConversions',
  NewToBrandSales = 'NewToBrandSales',
  NewToBrandOrders = 'NewToBrandOrders',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  Sku = 'Sku',
  ExtItemId = 'ExtItemId',
  MerchantName = 'MerchantName',
  StartDate = 'StartDate',
  Portfolio = 'Portfolio',
  CampaignCostType = 'CampaignCostType',
  ProductReviewStatus = 'ProductReviewStatus',
  AdGroupReviewStatus = 'AdGroupReviewStatus',
}

export const PRODUCT_ADS_API_COLUMN_NAME: Record<
  ProductAdsApiColumnIdentifier,
  string
> = {
  [ProductAdsApiColumnIdentifier.ProductName]: 'productName',
  [ProductAdsApiColumnIdentifier.ProductImage]: 'productImage',
  [ProductAdsApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [ProductAdsApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
  [ProductAdsApiColumnIdentifier.CampaignName]: 'campaignName',
  [ProductAdsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [ProductAdsApiColumnIdentifier.CampaignTargetingType]:
    'campaignTargetingType',
  [ProductAdsApiColumnIdentifier.CampaignType]: 'campaignType',
  [ProductAdsApiColumnIdentifier.ProductAdStatus]: 'status',
  [ProductAdsApiColumnIdentifier.Bid]: 'bid',
  [ProductAdsApiColumnIdentifier.Impressions]: 'impressions',
  [ProductAdsApiColumnIdentifier.Clicks]: 'clicks',
  [ProductAdsApiColumnIdentifier.AdSpend]: 'adSpend',
  [ProductAdsApiColumnIdentifier.TotalAdSales]: 'adSalesTotal',
  [ProductAdsApiColumnIdentifier.AdUnitsSold]: 'unitsSold',
  [ProductAdsApiColumnIdentifier.TotalConversions]: 'totalConversions',
  [ProductAdsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [ProductAdsApiColumnIdentifier.TotalACOS]: 'acosTotal',
  [ProductAdsApiColumnIdentifier.TotalROAS]: 'roasTotal',
  [ProductAdsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [ProductAdsApiColumnIdentifier.AvgCostPerClick]: 'avgCostPerClick',
  [ProductAdsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [ProductAdsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [ProductAdsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [ProductAdsApiColumnIdentifier.BrandClickRevenue]: 'brandClickRevenue',
  [ProductAdsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [ProductAdsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [ProductAdsApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [ProductAdsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [ProductAdsApiColumnIdentifier.AdConversions]: 'adConversions',
  [ProductAdsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [ProductAdsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [ProductAdsApiColumnIdentifier.NewToBrandAdUnitsSold]:
    'newToBrandAdUnitsSold',
  [ProductAdsApiColumnIdentifier.Sku]: 'sku',
  [ProductAdsApiColumnIdentifier.ExtItemId]: 'extItemId',
  [ProductAdsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [ProductAdsApiColumnIdentifier.StartDate]: 'startDate',
  [ProductAdsApiColumnIdentifier.Portfolio]: 'portfolio',
  [ProductAdsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
  [ProductAdsApiColumnIdentifier.ProductReviewStatus]: 'adItemReviewStatus',
  [ProductAdsApiColumnIdentifier.AdGroupReviewStatus]: 'adGroupReviewStatus',
};

export const AD_ITEMS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  ProductAdsApiColumnGroupIdentifier
> = Object.freeze({
  [PRODUCT_ADS_API_COLUMN_NAME.ProductName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ProductImage]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Sku]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ExtItemId]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.MerchantName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdGroupName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignType]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignTactic]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DailyBudget]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalBudget]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Portfolio]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignCostType]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ProductReviewStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdGroupReviewStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Status]:
    ProductAdsApiColumnGroupIdentifier.ChannelSettingsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Bid]:
    ProductAdsApiColumnGroupIdentifier.ChannelSettingsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Impressions]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Clicks]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdSpend]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalAdSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdUnitsSold]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ConversionRate]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalACOS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalROAS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ClickThroughRate]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AvgCostPerClick]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DirectAdSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DirectACOS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DirectROAS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.RelatedClickRevenue]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.BrandClickRevenue]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdConversions]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.NewToBrandSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.NewToBrandOrders]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_AD_ITEMS_API_COLUMN_DATA_MAPPING: Record<
  string,
  string
> = Object.freeze({
  [PRODUCT_ADS_API_COLUMN_NAME.Status]: 'channelSettings.status',
  [PRODUCT_ADS_API_COLUMN_NAME.Bid]: 'channelSettings.bid.amount',
});

// Ad-Level Profiles Helper
export enum ProfilesApiColumnIdentifier {
  // Details
  Brand = 'Brand',
  MerchantName = 'MerchantName',
  ProfileReviewStatus = 'ProfileReviewStatus',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',
  AdGroupReviewStatus = 'AdGroupReviewStatus',
  CampaignName = 'CampaignName',
  CampaignTargetingType = 'CampaignTargetingType',
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  CampaignStatus = 'CampaignStatus',
  // Channel Setting
  HeadLine = 'HeadLine',
  ClickURL = 'ClickURL',
  Logo = 'Logo',
  // Performance
  AdSales = 'AdSales',
  AdSpend = 'AdSpend',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  ConversionRate = 'ConversionRate',
  AdUnitsSold = 'AdUnitsSold',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  CostPerClick = 'CostPerClick',
  ClickThroughRate = 'ClickThroughRate',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  BrandClickRevenue = 'BrandClickRevenue',
  CampaignId = 'CampaignId',
  AdGroupId = 'AdGroupId',
}

export const PROFILES_API_COLUMN_NAME: Record<
  ProfilesApiColumnIdentifier,
  string
> = Object.freeze({
  [ProfilesApiColumnIdentifier.Brand]: 'brand',
  [ProfilesApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [ProfilesApiColumnIdentifier.CampaignName]: 'campaignName',
  [ProfilesApiColumnIdentifier.CampaignId]: 'campaignId',
  [ProfilesApiColumnIdentifier.ProfileReviewStatus]: 'profileReviewStatus',
  [ProfilesApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [ProfilesApiColumnIdentifier.AdGroupId]: 'adGroupId',
  [ProfilesApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
  [ProfilesApiColumnIdentifier.AdGroupReviewStatus]: 'adGroupReviewStatus',
  [ProfilesApiColumnIdentifier.CampaignTargetingType]: 'campaignTargetingType',
  [ProfilesApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [ProfilesApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [ProfilesApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [ProfilesApiColumnIdentifier.HeadLine]: 'headline',
  [ProfilesApiColumnIdentifier.ClickURL]: 'clickUrl',
  [ProfilesApiColumnIdentifier.Logo]: 'logo',
  [ProfilesApiColumnIdentifier.AdSales]: 'adSalesTotal',
  [ProfilesApiColumnIdentifier.AdSpend]: 'adSpend',
  [ProfilesApiColumnIdentifier.ACOS]: 'acosTotal',
  [ProfilesApiColumnIdentifier.ROAS]: 'roasTotal',
  [ProfilesApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [ProfilesApiColumnIdentifier.AdUnitsSold]: 'unitsSold',
  [ProfilesApiColumnIdentifier.Impressions]: 'impressions',
  [ProfilesApiColumnIdentifier.Clicks]: 'clicks',
  [ProfilesApiColumnIdentifier.CostPerClick]: 'avgCostPerClick',
  [ProfilesApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [ProfilesApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [ProfilesApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [ProfilesApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [ProfilesApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [ProfilesApiColumnIdentifier.BrandClickRevenue]: 'brandClickRevenue',
});

export const PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  ProfileDataApiColumnGroupIdentifier
> = Object.freeze({
  [PROFILES_API_COLUMN_NAME.Brand]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.MerchantName]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignName]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignId]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.ProfileReviewStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupName]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupId]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupReviewStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignTargetingType]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.DailyBudget]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.TotalBudget]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.HeadLine]:
    ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [PROFILES_API_COLUMN_NAME.ClickURL]:
    ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [PROFILES_API_COLUMN_NAME.Logo]:
    ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [PROFILES_API_COLUMN_NAME.AdSales]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.AdSpend]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ACOS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ROAS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ConversionRate]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.AdUnitsSold]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.Impressions]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.Clicks]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.CostPerClick]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ClickThroughRate]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.DirectAdSales]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.DirectACOS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.DirectROAS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.RelatedClickRevenue]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.BrandClickRevenue]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
});

//Ad-Level Targets Helpers
export enum TargetsApiColumnIdentifier {
  TargetText = 'TargetText',
  MatchType = 'MatchType',
  TargetType = 'TargetType',
  FlywheelAiEnabled = 'FlywheelAiEnabled',
  DateAdded = 'DateAdded',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'CampaignStatus',
  TargetStatus = 'TargetStatus',
  Bid = 'Bid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  CostPerClick = 'CostPerClick',
  TotalAdSales = 'TotalAdSales',
  UnitsSold = 'UnitsSold',
  TotalConversionRate = 'TotalConversionRate',
  TotalACOS = 'TotalACOS',
  TotalROAS = 'TotalROAS',
  ClickThroughRate = 'ClickThroughRate',
  AverageCostPerClick = 'AverageCostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  BrandClickRevenue = 'BrandClickRevenue',
  TargetingExpression = 'TargetingExpression',
  ExpressionType = 'ExpressionType',
  CampaignTactic = 'CampaignTactic',
  ConversionRate = 'ConversionRate',
  AdUnitsSold = 'AdUnitsSold',
  KeywordReviewStatus = 'KeywordReviewStatus', //#TODO: Re-check this on IFD-261
  AdGroupReviewStatus = 'AdGroupReviewStatus', //#TODO: Re-check this on IFD-261
  // Amazon specific columns
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  Keyword = 'Keyword',
  CampaignAdFormat = 'CampaignAdFormat',
  AdConversions = 'AdConversions',
  NewToBrandOrders = 'NewToBrandOrders',
  PercentageOfOrdersNewToBrand = 'PercentageOfOrdersNewToBrand',
  NewToBrandOrdersRate = 'NewToBrandOrdersRate',
  NewToBrandSales = 'NewToBrandSales',
  PercentageOfSalesNewToBrand = 'PercentageOfSalesNewToBrand',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  PercentageOfAdUnitsSoldNewToBrand = 'PercentageOfAdUnitsSoldNewToBrand',
  MerchantName = 'MerchantName',
  ViewsTargetText = 'ViewsTargetText',
  StartDate = 'StartDate',
  Portfolio = 'Portfolio',
  CampaignCostType = 'CampaignCostType',
}

export const TARGETS_API_COLUMN_NAME: Record<
  TargetsApiColumnIdentifier,
  string
> = Object.freeze({
  [TargetsApiColumnIdentifier.ViewsTargetText]: 'viewsTargetText',
  [TargetsApiColumnIdentifier.TargetText]: 'targetText',
  [TargetsApiColumnIdentifier.MatchType]: 'matchType',
  [TargetsApiColumnIdentifier.DateAdded]: 'createdAt',
  [TargetsApiColumnIdentifier.TargetType]: 'targetType',
  [TargetsApiColumnIdentifier.FlywheelAiEnabled]: 'flywheelAiEnabled',
  [TargetsApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [TargetsApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
  [TargetsApiColumnIdentifier.CampaignName]: 'campaignName',
  [TargetsApiColumnIdentifier.CampaignType]: 'campaignType',
  [TargetsApiColumnIdentifier.CampaignTargetingType]: 'campaignTargetingType',
  [TargetsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [TargetsApiColumnIdentifier.TargetStatus]: 'targetStatus',
  [TargetsApiColumnIdentifier.Bid]: 'bid',
  [TargetsApiColumnIdentifier.Impressions]: 'impressions',
  [TargetsApiColumnIdentifier.Clicks]: 'clicks',
  [TargetsApiColumnIdentifier.AdSpend]: 'adSpend',
  [TargetsApiColumnIdentifier.ACOS]: 'ACOS',
  [TargetsApiColumnIdentifier.ROAS]: 'ROAS',
  [TargetsApiColumnIdentifier.CostPerClick]: 'avgCostPerClick',
  [TargetsApiColumnIdentifier.TotalAdSales]: 'adSalesTotal',
  [TargetsApiColumnIdentifier.UnitsSold]: 'unitsSold',
  [TargetsApiColumnIdentifier.TotalConversionRate]: 'conversionRate',
  [TargetsApiColumnIdentifier.AdUnitsSold]: 'adUnitsSold',
  [TargetsApiColumnIdentifier.TotalACOS]: 'acosTotal',
  [TargetsApiColumnIdentifier.TotalROAS]: 'roasTotal',
  [TargetsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [TargetsApiColumnIdentifier.AverageCostPerClick]: 'avgCostPerClick',
  [TargetsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [TargetsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [TargetsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [TargetsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [TargetsApiColumnIdentifier.BrandClickRevenue]: 'brandClickRevenue',
  [TargetsApiColumnIdentifier.ExpressionType]: 'targetingExpressionType',
  [TargetsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [TargetsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [TargetsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [TargetsApiColumnIdentifier.TargetingExpression]: 'targetingExpression',
  [TargetsApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [TargetsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [TargetsApiColumnIdentifier.Keyword]: 'nativeLanguageKeyword',
  [TargetsApiColumnIdentifier.CampaignAdFormat]: 'campaignAdFormat',
  [TargetsApiColumnIdentifier.AdConversions]: 'adConversions',
  [TargetsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [TargetsApiColumnIdentifier.PercentageOfOrdersNewToBrand]:
    'newToBrandOrdersPercentage',
  [TargetsApiColumnIdentifier.NewToBrandOrdersRate]: 'newToBrandOrderRate',
  [TargetsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [TargetsApiColumnIdentifier.PercentageOfSalesNewToBrand]:
    'newToBrandSalesPercentage',
  [TargetsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [TargetsApiColumnIdentifier.PercentageOfAdUnitsSoldNewToBrand]:
    'newToBrandAdUnitsSoldPercentage',
  [TargetsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [TargetsApiColumnIdentifier.StartDate]: 'startDate',
  [TargetsApiColumnIdentifier.Portfolio]: 'portfolio',
  [TargetsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
  [TargetsApiColumnIdentifier.KeywordReviewStatus]: 'keywordReviewStatus',
  [TargetsApiColumnIdentifier.AdGroupReviewStatus]: 'adGroupReviewStatus',
});

export const MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS: Record<
  FlywheelSalesChannel,
  Record<AdLevel, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [],
    [AdLevel.ProductAds]: [],
    [AdLevel.Targets]: [
      TARGETS_API_COLUMN_NAME.TargetText,
      TARGETS_API_COLUMN_NAME.ExpressionType,
      TARGETS_API_COLUMN_NAME.CampaignAdFormat,
      TARGETS_API_COLUMN_NAME.DailyBudget,
      TARGETS_API_COLUMN_NAME.ViewsTargetText,
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],

    [AdLevel.KeywordTargets]: [
      TARGETS_API_COLUMN_NAME.CampaignAdFormat,
      TARGETS_API_COLUMN_NAME.DailyBudget,
      TARGETS_API_COLUMN_NAME.AdGroupStatus,
      TARGETS_API_COLUMN_NAME.CampaignStatus,
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.ProductTargets]: [
      TARGETS_API_COLUMN_NAME.TargetText,
      TARGETS_API_COLUMN_NAME.ExpressionType,
      TARGETS_API_COLUMN_NAME.CampaignAdFormat,
      TARGETS_API_COLUMN_NAME.DailyBudget,
      TARGETS_API_COLUMN_NAME.ViewsTargetText,
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.ProductAds]: [],
    [AdLevel.Profiles]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [],
    [AdLevel.ProductAds]: [],
    [AdLevel.KeywordTargets]: [
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.ProductTargets]: [
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.ProductAds]: [],
    [AdLevel.Targets]: [
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.Profiles]: [],
  },
};

export const MANDATORY_CHANNEL_SETTINGS_TARGETS_COLUMNS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [TARGETS_API_COLUMN_NAME.TargetStatus],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [TARGETS_API_COLUMN_NAME.TargetStatus],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [],
  },
};

export const AD_LEVEL_API_COLUMNS_TO_EXCLUDE: Record<
  FlywheelSalesChannel,
  Record<AdLevel, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [],
    [AdLevel.ProductAds]: [],
    [AdLevel.Targets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.KeywordTargets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.ProductTargets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.ProductAds]: [],
    [AdLevel.Profiles]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [ADGROUPS_API_COLUMN_NAME.MerchantName],
    [AdLevel.ProductAds]: [],
    [AdLevel.KeywordTargets]: [],
    [AdLevel.ProductTargets]: [],
    [AdLevel.ProductAds]: [],
    [AdLevel.Targets]: [TARGETS_API_COLUMN_NAME.MerchantName],
    [AdLevel.Profiles]: [],
  },
};

export const TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  TargetsDataApiColumnGroupIdentifier
> = Object.freeze({
  [TARGETS_API_COLUMN_NAME.TargetText]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.MerchantName]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.MatchType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.DateAdded]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TargetType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdGroupName]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdGroupStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignName]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignTargetingType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TargetingExpression]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.ExpressionType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignTactic]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.DailyBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TotalBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.Portfolio]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignCostType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.KeywordReviewStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdGroupReviewStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TargetStatus]:
    TargetsDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [TARGETS_API_COLUMN_NAME.Bid]:
    TargetsDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [TARGETS_API_COLUMN_NAME.Impressions]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ConversionRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AdUnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.Clicks]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AdSpend]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalAdSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.UnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalConversionRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalACOS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalROAS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ClickThroughRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AverageCostPerClick]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DirectAdSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DirectACOS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DirectROAS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.RelatedClickRevenue]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.BrandClickRevenue]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DailyBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TotalBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.Keyword]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignAdFormat]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdConversions]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandOrders]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandOrdersRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.PercentageOfSalesNewToBrand]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_TARGETS_API_COLUMN_DATA_MAPPING: Record<
  string,
  string
> = Object.freeze({
  [TARGETS_API_COLUMN_NAME.TargetStatus]: 'channelSettings.status',
  [TARGETS_API_COLUMN_NAME.Bid]: 'channelSettings.bid.amount',
});

//Editable Columns
export enum EditableColumnsCampaign {
  buyBoxBidMultiplier = 'buyBoxBidMultiplier',
  campaignName = 'campaignName',
  carouselStatus = 'carouselStatus',
  itemBuyBox = 'itemBuyBox',
  itemCarousel = 'itemCarousel',
  campaignDailyBudget = 'campaignDailyBudget',
  desktopBidMultiplier = 'desktopBidMultiplier',
  endDate = 'endDate',
  bidOptimization = 'bidOptimization',
  belowTopOfSearchBidMultiplier = 'belowTopOfSearchBidMultiplier',
  homePageBidMultiplier = 'homePageBidMultiplier',
  appBidMultiplier = 'appBidMultiplier',
  mobileBidMultiplier = 'mobileBidMultiplier',
  searchIngridBidMultiplier = 'searchIngridBidMultiplier',
  status = 'status',
  stockUpBidMultiplier = 'stockUpBidMultiplier',
  totalBudget = 'totalBudget',
  budgetRollover = 'budgetRollover',
  automationStatus = 'automationStatus',
  macsTarget = 'macsTarget',
  minBid = 'minBid',
  maxBid = 'maxBid',
  portfolio = 'portfolio',
  biddingStrategy = 'biddingStrategy',
  topOfSearchBidMultiplier = 'topOfSearchBidMultiplier',
  productPageBidMultiplier = 'productPageBidMultiplier',
}

export enum EditableColumnsAdGroup {
  automationStatus = 'automationStatus',
  macsTarget = 'macsTarget',
  minBid = 'minBid',
  maxBid = 'maxBid',
  name = 'adGroupName',
  status = 'status',
  adGroupDefaultBid = 'adGroupDefaultBid',
}

export enum EditableColumnsAdItem {
  bid = 'bid',
  status = 'status',
}

export enum EditableColumnsTarget {
  bid = 'bid',
  status = 'targetStatus',
}

export const TABLE_UNIQ_KEY: Record<string, string> = Object.freeze({
  [ADS_MANAGER_CAMPAIGNS_TABLE_ID]: 'campaignId',
  [ADS_MANAGER_ADGROUP_TABLE_ID]: 'adGroupId',
  [ADS_MANAGER_AD_ITEMS_TABLE_ID]: 'adItemId',
  [ADS_MANAGER_PROFILES_TABLE_ID]: 'profileId',
  [ADS_MANAGER_TARGETS_TABLE_ID]: 'targetId',
});

// Sales channels mapping to default ad level columns
export const FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER: Record<
  FlywheelSalesChannel,
  Record<AdLevel, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdLevel.Campaigns]: [CAMPAIGNS_API_COLUMN_NAME.CampaignName],
    [AdLevel.AdGroups]: [ADGROUPS_API_COLUMN_NAME.AdGroupName],
    [AdLevel.KeywordTargets]: [TARGETS_API_COLUMN_NAME.Keyword],
    [AdLevel.Targets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.ProductAds]: [
      PRODUCT_ADS_API_COLUMN_NAME.ProductName,
      PRODUCT_ADS_API_COLUMN_NAME.ProductImage,
    ],
    [AdLevel.ProductTargets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.Profiles]: [], // TODO: as part of IFD-229
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdLevel.Campaigns]: [CAMPAIGNS_API_COLUMN_NAME.CampaignName],
    [AdLevel.AdGroups]: [ADGROUPS_API_COLUMN_NAME.AdGroupName],
    [AdLevel.ProductAds]: [
      PRODUCT_ADS_API_COLUMN_NAME.ProductName,
      PRODUCT_ADS_API_COLUMN_NAME.ProductImage,
    ],
    [AdLevel.KeywordTargets]: [TARGETS_API_COLUMN_NAME.TargetText],
    [AdLevel.ProductTargets]: [],
    [AdLevel.Targets]: [],
    [AdLevel.Profiles]: [PROFILES_API_COLUMN_NAME.Brand],
  },
};

export const MANDATORY_CAMPAIGN_COLUMNS_CHANNEL_SETTINGS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
      CAMPAIGNS_API_COLUMN_NAME.BudgetType,
    ],
    [AdType.SponsoredBrandsVideo]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
      CAMPAIGNS_API_COLUMN_NAME.BudgetType,
    ],
    [AdType.SponsoredDisplay]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
    ],
    [AdType.SponsoredProducts]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
    ],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [
      CAMPAIGNS_API_COLUMN_NAME.BudgetType,
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
    ],
  },
};

export const MANDATORY_CAMPAIGN_COLUMNS_CAMPAIGN_DETAILS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat],
    [AdType.SponsoredBrandsVideo]: [CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat],
    [AdType.SponsoredDisplay]: [CAMPAIGNS_API_COLUMN_NAME.CampaignTactic],
    [AdType.SponsoredProducts]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [
      CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
    ],
  },
};

export const MANDATORY_AD_ITEMS_COLUMNS_AD_ITEM_DETAILS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [
      PRODUCT_ADS_API_COLUMN_NAME.Sku,
      PRODUCT_ADS_API_COLUMN_NAME.ExtItemId,
      PRODUCT_ADS_API_COLUMN_NAME.StartDate,
    ],
    [AdType.SponsoredProducts]: [
      PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
      PRODUCT_ADS_API_COLUMN_NAME.Sku,
      PRODUCT_ADS_API_COLUMN_NAME.ExtItemId,
      PRODUCT_ADS_API_COLUMN_NAME.StartDate,
    ],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [
      PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
      PRODUCT_ADS_API_COLUMN_NAME.StartDate,
    ],
  },
};

// Ad Level to Table Id mapper
export const getTableIdByAdLevel = (adLevel: string): string => {
  if (adLevel) {
    switch (adLevel) {
      case AdLevel.Campaigns:
        return ADS_MANAGER_CAMPAIGNS_TABLE_ID;
      case AdLevel.AdGroups:
        return ADS_MANAGER_ADGROUP_TABLE_ID;
      case AdLevel.ProductAds:
        return ADS_MANAGER_AD_ITEMS_TABLE_ID;
      case AdLevel.KeywordTargets:
      case AdLevel.ProductTargets:
      case AdLevel.Targets:
        return ADS_MANAGER_TARGETS_TABLE_ID;
      case AdLevel.Profiles:
        return ADS_MANAGER_PROFILES_TABLE_ID;
    }
  }
  return ADS_MANAGER_CAMPAIGNS_TABLE_ID;
};

export enum ProductAdStatus {
  Enabled = 'Enabled',
  Paused = 'Paused',
  Archived = 'Archived',
  Disabled = 'Disabled',
}

export enum TargetStatus {
  Enabled = 'Enabled',
  Paused = 'Paused',
  Archived = 'Archived',
}

export interface DropdownMetadata {
  readonly label: string;
  readonly value: string;
}

export enum ProductAdStatusValue {
  Enabled = 'enabled',
  Paused = 'paused',
  Archived = 'archived',
  Disabled = 'disabled',
}

export const ProductAdStatusOptions: Record<
  ProductAdStatus,
  DropdownMetadata
> = {
  [ProductAdStatus.Enabled]: {
    label: ProductAdStatus.Enabled,
    value: ProductAdStatusValue.Enabled,
  },
  [ProductAdStatus.Paused]: {
    label: ProductAdStatus.Paused,
    value: ProductAdStatusValue.Paused,
  },
  [ProductAdStatus.Archived]: {
    label: ProductAdStatus.Archived,
    value: ProductAdStatusValue.Archived,
  },
  [ProductAdStatus.Disabled]: {
    label: ProductAdStatus.Disabled,
    value: ProductAdStatusValue.Disabled,
  },
};

export const TargetStatusOptions: Record<TargetStatus, DropdownMetadata> = {
  [TargetStatus.Enabled]: {
    label: TargetStatus.Enabled,
    value: 'enabled',
  },
  [TargetStatus.Paused]: {
    label: TargetStatus.Paused,
    value: 'paused',
  },
  [TargetStatus.Archived]: {
    label: TargetStatus.Archived,
    value: 'archived',
  },
};

// Breadcrumb helpers
/**
 *
 * @param currentUrl
 * @returns previous URL by splicing the last token off
 *
 */
export const getPreviousUrl = (currentUrl: string): string => {
  const urlTokens = currentUrl.split('/');
  urlTokens.splice(urlTokens.length - 1, 1);
  return urlTokens.join('/');
};

export interface AdLevelFromPathInfo {
  readonly identifiedAdLevel: AdLevel;
  readonly adsManagerDetailsPageFlag: boolean;
  readonly adsManagerDetailsPageName: string;
  readonly rootAdLevelId?: string;
  readonly currentAdLevelId?: string;
}

/**
 * This function identifies the details page root for the current
 * adType | adLevel from the url path
 * @param urlPathNameList
 * @param levelDifference
 * @returns
 */
const getAdLevelDetailsRoot = (
  urlPathNameList: string[],
  levelDifference: number
): ADS_MANAGER_AD_LEVEL_PAGES_URI => {
  if (
    urlPathNameList.includes(ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups) ||
    levelDifference === 2
  ) {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups;
  }
  return ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
};

/**
 * This function identifies the ad Level type in campaign details flow
 * Flow : Campaigns -> Ad Groups -> Product ads
 */
//TODO: Need to make this generic getCampaignDetailsAdLevel -> getDetailsAdLevel after we have suffiecient/required flow
export const getCampaignDetailsAdLevel = (
  urlPathNameList: string[],
  indexOfCampaign: number
) => {
  if (urlPathNameList.length - indexOfCampaign - 1 === 1) {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  } else if (urlPathNameList.length - indexOfCampaign - 1 === 2) {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups;
  } else {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  }
};

export const getAdLevelInfo = (
  urlPathNameList: string[]
): AdLevelFromPathInfo => {
  const levelsUri = Object.values(ADS_MANAGER_AD_LEVEL_PAGES_URI);
  let passedAdLevel: string = ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  let adlevelPosition = -1;
  let adsManagerDetailsPageFlag = false;
  let adsManagerDetailsPageName = ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  let urlAdLevel: string = ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  urlPathNameList = urlPathNameList.filter((ele) => !isEmpty(ele));

  /**
   * Below for is to identify if the url ends with either
   *  a.'campaigns',
   *  b.'ad-groups',
   *  c.'ad-items',
   *  d.'targets' or identifier(eg:campaignId,adgroupId),
   * This will set the adsManagerDetailsPageFlag to true if identifier is passed
   * i.e if we are drillingdown through campaigns -> adgroups -> ad-items -> targets.
   */
  for (const uriPart of levelsUri) {
    const currentIndex = urlPathNameList.indexOf(uriPart);
    if (currentIndex !== -1) {
      passedAdLevel = uriPart;
      urlAdLevel = uriPart;
      if (uriPart === ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns) {
        passedAdLevel = getCampaignDetailsAdLevel(
          urlPathNameList,
          currentIndex
        );
      }
      adlevelPosition = currentIndex;
      if (adlevelPosition !== urlPathNameList.length - 1) {
        adsManagerDetailsPageFlag = true;
        adsManagerDetailsPageName = getAdLevelDetailsRoot(
          urlPathNameList,
          urlPathNameList.length - 1 - adlevelPosition
        );
      }
      break;
    }
  }
  //root AdLevelId from URL
  const indexOfCampaignId =
    urlPathNameList.indexOf(urlAdLevel as ADS_MANAGER_AD_LEVEL_PAGES_URI) + 1;
  const rootAdLevelId = adsManagerDetailsPageFlag
    ? urlPathNameList[indexOfCampaignId]
    : undefined;
  const currentAdLevelId = adsManagerDetailsPageFlag
    ? urlPathNameList[urlPathNameList.length - 1]
    : undefined;
  return {
    identifiedAdLevel:
      ADS_MANAGER_PAGE_URI_TO_AD_LEVEL_MAPPER[
        passedAdLevel as ADS_MANAGER_AD_LEVEL_PAGES_URI
      ],
    adsManagerDetailsPageFlag,
    rootAdLevelId,
    adsManagerDetailsPageName,
    currentAdLevelId,
  };
};

// Generic
export const DATA_INSPECTOR_COLUMN_TO_API_COLUMN_MAPPER: Record<
  string,
  string
> = Object.freeze({
  [DATA_INSPECTOR_COLUMN_NAME.CampaignType]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignType,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignTactic,
  [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: CAMPAIGNS_API_COLUMN_NAME.Portfolio,
  [DATA_INSPECTOR_COLUMN_NAME.StartDate]: CAMPAIGNS_API_COLUMN_NAME.StartDate,
  [DATA_INSPECTOR_COLUMN_NAME.EndDate]: CAMPAIGNS_API_COLUMN_NAME.EndDate,
  [DATA_INSPECTOR_COLUMN_NAME.BudgetType]: CAMPAIGNS_API_COLUMN_NAME.BudgetType,
  [DATA_INSPECTOR_COLUMN_NAME.AmazonBiddingStrategy]:
    CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
  [DATA_INSPECTOR_COLUMN_NAME.ProductPageBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.TopOfSearchBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
    CAMPAIGNS_API_COLUMN_NAME.AdConversions,
  [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]:
    CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
  [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]:
    CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
  [DATA_INSPECTOR_COLUMN_NAME.BudgetRollover]:
    CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
  [DATA_INSPECTOR_COLUMN_NAME.SearchCarouselInclusion]:
    CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion,
  [DATA_INSPECTOR_COLUMN_NAME.ItemBuyBoxPlacement]:
    CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement,
  [DATA_INSPECTOR_COLUMN_NAME.ItemCarouselPlacement]:
    CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement,
  [DATA_INSPECTOR_COLUMN_NAME.BuyBoxBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.SearchIngridBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.HomePageBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.StockUpBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.DesktopBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.AppBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.MobileBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.MACSTarget]: CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
  [DATA_INSPECTOR_COLUMN_NAME.MinBid]: CAMPAIGNS_API_COLUMN_NAME.MinBid,
  [DATA_INSPECTOR_COLUMN_NAME.MaxBid]: CAMPAIGNS_API_COLUMN_NAME.MaxBid,
  [DATA_INSPECTOR_COLUMN_NAME.Impressions]:
    CAMPAIGNS_API_COLUMN_NAME.Impressions,
  [DATA_INSPECTOR_COLUMN_NAME.Clicks]: CAMPAIGNS_API_COLUMN_NAME.Clicks,
  [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: CAMPAIGNS_API_COLUMN_NAME.AdSpend,
  [DATA_INSPECTOR_COLUMN_NAME.AdSales]: CAMPAIGNS_API_COLUMN_NAME.AdSales,
  [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
    CAMPAIGNS_API_COLUMN_NAME.AdUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
    CAMPAIGNS_API_COLUMN_NAME.ConversionRate,
  [DATA_INSPECTOR_COLUMN_NAME.ACOS]: CAMPAIGNS_API_COLUMN_NAME.ACOS,
  [DATA_INSPECTOR_COLUMN_NAME.ROAS]: CAMPAIGNS_API_COLUMN_NAME.ROAS,
  [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]:
    CAMPAIGNS_API_COLUMN_NAME.ClickThroughRate,
  [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]:
    CAMPAIGNS_API_COLUMN_NAME.CostPerClick,
  [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]:
    CAMPAIGNS_API_COLUMN_NAME.DirectAdSales,
  [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: CAMPAIGNS_API_COLUMN_NAME.DirectACOS,
  [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: CAMPAIGNS_API_COLUMN_NAME.DirectROAS,
  [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]:
    CAMPAIGNS_API_COLUMN_NAME.RelatedClickRevenue,
  [DATA_INSPECTOR_COLUMN_NAME.BrandClickRevenue]:
    CAMPAIGNS_API_COLUMN_NAME.BrandClickRevenue,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
    CAMPAIGNS_API_COLUMN_NAME.NewToBrandSales,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignServingStatus]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignServingStatus,
  [DATA_INSPECTOR_COLUMN_NAME.AmazonBidOptimization]:
    CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
  [DATA_INSPECTOR_COLUMN_NAME.BelowTopOfSearchBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
    CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrders,
  [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]:
    CAMPAIGNS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrderRate]:
    CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrdersRate,
  [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]:
    CAMPAIGNS_API_COLUMN_NAME.PercentageOfSalesNewToBrand,
  [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
    CAMPAIGNS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
    ADGROUPS_API_COLUMN_NAME.CampaignName,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
    ADGROUPS_API_COLUMN_NAME.AdGroupName,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
    ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
  [DATA_INSPECTOR_COLUMN_NAME.BidAutomationStatus]:
    ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
  [DATA_INSPECTOR_COLUMN_NAME.MACSTarget]: ADGROUPS_API_COLUMN_NAME.MACSTarget,
  [DATA_INSPECTOR_COLUMN_NAME.MaxBid]: ADGROUPS_API_COLUMN_NAME.MaxBid,
  [DATA_INSPECTOR_COLUMN_NAME.MinBid]: ADGROUPS_API_COLUMN_NAME.MinBid,
  [DATA_INSPECTOR_COLUMN_NAME.TotalConversions]:
    PRODUCT_ADS_API_COLUMN_NAME.TotalConversions,
  [DATA_INSPECTOR_COLUMN_NAME.ProductName]:
    PRODUCT_ADS_API_COLUMN_NAME.ProductName,
  [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]:
    PRODUCT_ADS_API_COLUMN_NAME.Status,
  [DATA_INSPECTOR_COLUMN_NAME.Bid]: PRODUCT_ADS_API_COLUMN_NAME.Bid,
  [DATA_INSPECTOR_COLUMN_NAME.ProductReviewStatus]:
    PRODUCT_ADS_API_COLUMN_NAME.ProductReviewStatus,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]:
    PRODUCT_ADS_API_COLUMN_NAME.AdGroupReviewStatus,
  [DATA_INSPECTOR_COLUMN_NAME.TargetText]: TARGETS_API_COLUMN_NAME.TargetText,
  [DATA_INSPECTOR_COLUMN_NAME.MatchType]: TARGETS_API_COLUMN_NAME.MatchType,
  [DATA_INSPECTOR_COLUMN_NAME.DateAdded]: TARGETS_API_COLUMN_NAME.DateAdded,
  [DATA_INSPECTOR_COLUMN_NAME.TargetType]: TARGETS_API_COLUMN_NAME.TargetType,
  [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]:
    TARGETS_API_COLUMN_NAME.TargetStatus,
  [DATA_INSPECTOR_COLUMN_NAME.TargetingExpression]:
    TARGETS_API_COLUMN_NAME.TargetingExpression,
  [DATA_INSPECTOR_COLUMN_NAME.AdgroupDefaultBid]:
    ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
    ADGROUPS_API_COLUMN_NAME.NewToBrandSales,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdConversions]:
    ADGROUPS_API_COLUMN_NAME.NewToBrandOrders,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]:
    ADGROUPS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.ExpressionType]:
    TARGETS_API_COLUMN_NAME.ExpressionType,
  [DATA_INSPECTOR_COLUMN_NAME.TargetsDailyBudget]:
    TARGETS_API_COLUMN_NAME.DailyBudget,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]:
    TARGETS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.MerchantName]:
    CAMPAIGNS_API_COLUMN_NAME.MerchantName,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignCostType]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignCostType,
  [DATA_INSPECTOR_COLUMN_NAME.KeywordReviewStatus]:
    TARGETS_API_COLUMN_NAME.KeywordReviewStatus,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]:
    TARGETS_API_COLUMN_NAME.AdGroupReviewStatus,
});

export const mapDataInspectorColumnToApiColumn = (
  tabCheckboxMetadata: TabCheckbox,
  adLevel?: AdLevel
): string[] => {
  const { flattenDataInspectorColumns, apiColumnNames } = getApiColumnNames(
    tabCheckboxMetadata,
    adLevel
  );

  return Object.keys(flattenDataInspectorColumns)
    .filter((columnName) => flattenDataInspectorColumns[columnName].isSelected)
    .map((columnName) => apiColumnNames[columnName]);
};

export const getAllApiColumns = (
  tabCheckboxMetadata: TabCheckbox,
  adLevel?: AdLevel
) => {
  const { flattenDataInspectorColumns, apiColumnNames } = getApiColumnNames(
    tabCheckboxMetadata,
    adLevel
  );

  return Object.keys(flattenDataInspectorColumns).map(
    (columnName) => apiColumnNames[columnName]
  );
};

export const getColumnManagerOptions = (
  currentTabCheckboxMetadata: TabCheckbox
) => {
  if (!currentTabCheckboxMetadata) {
    return [];
  }

  const options: ColumnManagerOption[] = [];
  Object.keys(currentTabCheckboxMetadata).forEach((group) => {
    Object.keys(currentTabCheckboxMetadata[group]).forEach((column) => {
      options.push({
        groupName: group,
        label: column,
        value: column,
        disabled:
          currentTabCheckboxMetadata?.[group]?.[column]?.isDisabled ||
          undefined,
      });
    });
  });
  return options;
};

const getApiColumnNames = (
  tabCheckboxMetadata: TabCheckbox,
  adLevel?: AdLevel
) => {
  const flattenDataInspectorColumns: Checkbox = {};

  Object.keys(tabCheckboxMetadata).forEach((key) => {
    merge(flattenDataInspectorColumns, tabCheckboxMetadata[key]);
  });

  const apiColumnNames = getApiColumnNamesForAdLevel(adLevel);

  return {
    flattenDataInspectorColumns,
    apiColumnNames,
  };
};

const getApiColumnNamesForAdLevel = (adLevel?: AdLevel) => {
  const apiColumnNames = { ...DATA_INSPECTOR_COLUMN_TO_API_COLUMN_MAPPER };

  switch (adLevel) {
    case AdLevel.AdGroups:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          ADGROUPS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          ADGROUPS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          ADGROUPS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]:
          ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          ADGROUPS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          ADGROUPS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdConversions]:
          ADGROUPS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
          ADGROUPS_API_COLUMN_NAME.NewToBrandSales,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]:
          ADGROUPS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
          ADGROUPS_API_COLUMN_NAME.CampaignTactic,
      };
    case AdLevel.Targets:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          TARGETS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          TARGETS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          TARGETS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          TARGETS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          TARGETS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
          TARGETS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
          TARGETS_API_COLUMN_NAME.CampaignTactic,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          TARGETS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          TARGETS_API_COLUMN_NAME.CampaignStatus,
      };
    case AdLevel.KeywordTargets:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          TARGETS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          TARGETS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          TARGETS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          TARGETS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          TARGETS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
          TARGETS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
          TARGETS_API_COLUMN_NAME.CampaignTactic,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          TARGETS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          TARGETS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]:
          TARGETS_API_COLUMN_NAME.CampaignAdFormat,
        [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
          TARGETS_API_COLUMN_NAME.UnitsSold,
      };
    case AdLevel.ProductTargets:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          TARGETS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          TARGETS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]:
          TARGETS_API_COLUMN_NAME.CampaignAdFormat,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          TARGETS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          TARGETS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          TARGETS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      };
    case AdLevel.Profiles:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.ProfileReviewStatus]:
          PROFILES_API_COLUMN_NAME.ProfileReviewStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          PROFILES_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.ClickURL]:
          PROFILES_API_COLUMN_NAME.ClickURL,
        [DATA_INSPECTOR_COLUMN_NAME.Logo]: PROFILES_API_COLUMN_NAME.Logo,
        [DATA_INSPECTOR_COLUMN_NAME.Headline]:
          PROFILES_API_COLUMN_NAME.HeadLine,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          PROFILES_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          PROFILES_API_COLUMN_NAME.CampaignStatus,
      };
    case AdLevel.ProductAds:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          PRODUCT_ADS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]:
          PRODUCT_ADS_API_COLUMN_NAME.DailyBudget,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
          PRODUCT_ADS_API_COLUMN_NAME.NewToBrandSales,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          PRODUCT_ADS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
          PRODUCT_ADS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
          PRODUCT_ADS_API_COLUMN_NAME.AdUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
          PRODUCT_ADS_API_COLUMN_NAME.NewToBrandSales,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.Status,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignType]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignType,
        [DATA_INSPECTOR_COLUMN_NAME.AdItemsCampaignStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          PRODUCT_ADS_API_COLUMN_NAME.AdGroupName,
      };

    default:
      return apiColumnNames;
  }
};

export const isManualCampaign = (
  campaignTargetingType?: CampaignTargetingType
) => campaignTargetingType === CampaignTargetingType.Manual;

export const isAutoCampaign = (campaignTargetingType?: CampaignTargetingType) =>
  campaignTargetingType === CampaignTargetingType.Auto;

export const isBudgetTypeDaily = (budgetType?: string) =>
  budgetType === BudgetType.Daily;

export const isBudgetTypeLifetime = (budgetType?: string) =>
  budgetType === BudgetType.Lifetime;

export const isCampaignAdFormatProductCollectionOrSpotlight = (
  campaignAdFormat?: CampaignAdFormat
) =>
  campaignAdFormat === CampaignAdFormat.ProductCollection ||
  campaignAdFormat === CampaignAdFormat.StoreSpotlight;

export const isCampaignAdFormatEqualToProductCollection = (
  campaignAdFormat?: CampaignAdFormat
) => campaignAdFormat === CampaignAdFormat.ProductCollection;

export const isCampaignAdFormatEqualToStoreSpotlight = (
  campaignAdFormat?: CampaignAdFormat
) => campaignAdFormat === CampaignAdFormat.StoreSpotlight;

export const isCampaignAdFormatEqualToVideo = (
  campaignAdFormat?: CampaignAdFormat
) => campaignAdFormat === CampaignAdFormat.Video;

export const isCampaignTacticsNotEqualToLegacyOrViews = (
  campaignTactic?: CampaignTactic
) =>
  campaignTactic !== CampaignTactic.Legacy &&
  campaignTactic !== CampaignTactic.Views;

export const DEBOUNCE_AFTER_IN_MILLISECONDS = 800;

export const truncateValuesAfterNDecimalPlaces = (
  value: string,
  decimalPlacesLimit: number
) =>
  value
    .split('.')
    .map((num, index) =>
      index === 0 ? num : num.split('').slice(0, decimalPlacesLimit).join('')
    )
    .join('.');

export const replaceAllBooleanValuesFromString = (
  unsanitizedString: string
): string =>
  unsanitizedString
    .replace(/:true/g, '')
    .replace(/:false/g, '')
    .replace(/,"isDisabled"/g, '');

export const isValidDataInspectionConfig = (
  existingConfig: DataInspectorColumnManagerConfigProps,
  targetTypeFeatureFlag?: boolean
): boolean => {
  const updatedExistingConfig = filterTargetSummaryColumns(
    targetTypeFeatureFlag,
    existingConfig
  );
  const updatedLocalStorageConfig = filterTargetSummaryColumns(
    targetTypeFeatureFlag,
    DATA_INSPECTION_COLUMN_MANAGER_CONFIG
  );

  const sanitizedExistingConfig = replaceAllBooleanValuesFromString(
    JSON.stringify(updatedExistingConfig)
  );
  const sanitzedLocalStorageConfig = replaceAllBooleanValuesFromString(
    JSON.stringify(updatedLocalStorageConfig)
  );

  return sanitizedExistingConfig === sanitzedLocalStorageConfig;
};

export const getAllAdLevelTabCheckboxesMetaData = (
  userId: string,
  accountId: string,
  targetTypeFeatureFlag?: boolean
) => {
  const existingColumnData = getTabCheckboxColumnSelectionInfoInLocalStorage(
    userId,
    accountId
  );
  if (
    existingColumnData &&
    isValidDataInspectionConfig(existingColumnData, targetTypeFeatureFlag)
  ) {
    return existingColumnData;
  } else {
    storeTabCheckboxColumnSelectionInfoInLocalStorage(
      userId,
      accountId,
      DATA_INSPECTION_COLUMN_MANAGER_CONFIG
    );
    return DATA_INSPECTION_COLUMN_MANAGER_CONFIG;
  }
};

export const checkIfAdLevelPathsAreOfSameLevel = (
  prevLocation: Location,
  currentLocation: Location
) => {
  const prevLocationPathTokens = prevLocation.pathname
    .split('/')
    .filter((subPath) => subPath);

  const currentLocationPathTokens = currentLocation.pathname
    .split('/')
    .filter((subPath) => subPath);

  return isEqual(
    prevLocationPathTokens.length,
    currentLocationPathTokens.length
  );
};

export const checkIfUrlPathsAreOfSameAdLevel = (
  prevLocation: string,
  currentLocation: string
) => {
  const prevLocationPathTokens = prevLocation
    .split('/')
    .filter((subPath) => subPath);

  const currentLocationPathTokens = currentLocation
    .split('/')
    .filter((subPath) => subPath);

  const adLevelUriArray = Object.values(
    ADS_MANAGER_AD_LEVEL_PAGES_URI
  ).map((adLevel) => adLevel.toString());

  const prevAdLevel = prevLocationPathTokens.find((token) =>
    adLevelUriArray.includes(token)
  );

  const currentAdLevel = currentLocationPathTokens.find((token) =>
    adLevelUriArray.includes(token)
  );

  return prevAdLevel === currentAdLevel;
};

// AdGroup Flywheel settings validations
export const isValidMinBid = (
  currentMinBid: string,
  maxValue: string,
  minValue: string,
  currentMaxBid: string,
  extraValidation?: () => boolean,
  existingValue?: string
): boolean => {
  const isValidBid =
    existingValue === '' && currentMinBid === existingValue
      ? true
      : !isNaN(Number(currentMinBid)) &&
        Number(currentMinBid) <= Number(maxValue) &&
        Number(currentMinBid) >= Number(minValue) &&
        Number(currentMinBid) <= Number(currentMaxBid);

  if (extraValidation) {
    return isValidBid && extraValidation();
  }

  return isValidBid;
};

export const isValidMaxBid = (
  currentMaxBid: string,
  maxValue: string,
  minValue: string,
  currentMinBid: string,
  extraValidation?: () => boolean,
  existingValue?: string
): boolean => {
  const isValidBid =
    existingValue === '' && currentMaxBid === existingValue
      ? true
      : !isNaN(Number(currentMaxBid)) &&
        Number(currentMaxBid) <= Number(maxValue) &&
        Number(currentMaxBid) >= Number(minValue) &&
        Number(currentMaxBid) >= Number(currentMinBid);

  if (extraValidation) {
    return isValidBid && extraValidation();
  }

  return isValidBid;
};

export const isValidMacsTarget = (
  currentMacs: string | undefined,
  extraValidation?: () => boolean,
  existingValue?: string
): boolean => {
  const isValidTarget =
    existingValue === '' && currentMacs === existingValue
      ? true
      : !isNaN(Number(currentMacs)) &&
        Number(currentMacs) > ADGROUP_MIN_MACS_VALUE;
  if (extraValidation) {
    return isValidTarget && extraValidation();
  }
  return isValidTarget;
};

export const ALL_AD_LEVELS_FILTER_FILED_MAPPER: FilterFieldMapper[] = [
  {
    alias: CAMPAIGN_TABLE_SEARCH_KEY,
    field: CAMPAIGNS_API_COLUMN_NAME.CampaignName,
  },
  {
    alias: ADGROUP_TABLE_SEARCH_KEY,
    field: ADGROUPS_API_COLUMN_NAME.AdGroupName,
  },
  {
    alias: PRODUCTAD_TABLE_SEARCH_KEY,
    field: AD_ITEM_SEARCH_KEY_FIELD,
  },
  {
    alias: PROFILE_TABLE_SEARCH_KEY,
    field: PROFILES_API_COLUMN_NAME.Brand,
  },
  {
    alias: TARGETS_TABLE_SEARCH_KEY,
    field: TARGETS_API_COLUMN_NAME.TargetText,
  },
  {
    alias: TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
    field: TARGETS_API_COLUMN_NAME.TargetingExpression,
  },
];

export const getUniqueFilters = (filters: Filter[]) => {
  const stringifiedFilters = filters.map((e) => JSON.stringify(e));
  const stringifiedFilterSet = new Set(stringifiedFilters);
  const stringifiedFilterArray = Array.from(stringifiedFilterSet);
  return stringifiedFilterArray.map((e) => JSON.parse(e));
};
//Edit Campaign Name and AdGroup Name Utils

// eslint-disable-next-line no-control-regex
const VALID_ASCII_REGEX = /^[\x00-\x7F]*$/;

// eslint-disable-next-line no-control-regex
const VALID_UNICODE_REGEX = /[^\x00-\x7F]/gu;

const ONLY_SPECIAL_CHARACTERS_REGEX = /^[ -/:-@[-`{-~®]+$/;

// eslint-disable-next-line no-control-regex
const AMAZON_VALID_ASCII_REGEX = /^[\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEFÁÉÍÑÓÚÜáéíñóúüÄÖÜßäöüÀÂÆÇÈÉÊËÎÏÔÙÛÜŒŸàâæçèêëîïôùûüÿœ®Æ\x00-\x7F]+$/;

// eslint-disable-next-line no-control-regex
const AMAZON_VALID_UNICODE_REGEX = /[^\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEFÁÉÍÑÓÚÜáéíñóúüÄÖÜßäöüÀÂÆÇÈÉÊËÎÏÔÙÛÜŒŸàâæçèêëîïôùûüÿœ®Æ\x00-\x7F]/gu;

export const isValidAscii = (
  value: string,
  salesChannel: FlywheelSalesChannel
): boolean => {
  const regex = new RegExp(VALID_ASCII_REGEX);
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return regex.test(value);
    case FlywheelSalesChannel.Amazon:
      return (
        regex.test(value) || new RegExp(AMAZON_VALID_ASCII_REGEX).test(value)
      );
  }
};

export const hasOnlySpecialCharacters = (value: string) =>
  ONLY_SPECIAL_CHARACTERS_REGEX.test(value);

export const isInvalidAdGroupName = (
  salesChannel: FlywheelSalesChannel,
  value?: string
): boolean =>
  !value ||
  !isValidAscii(value, salesChannel) ||
  hasOnlySpecialCharacters(value);

const MAX_ADGROUP_NAME_LENGTH = 255;

export const isValidAdGroupNameCharacterLength = (value: string) =>
  value.length <= MAX_ADGROUP_NAME_LENGTH;

export const MAX_CAMPAIGN_NAME_LENGTH = 255;

export const isInvalidCampaignName = (
  salesChannel: FlywheelSalesChannel,
  value?: string
) =>
  !value ||
  !isValidAscii(value, salesChannel) ||
  hasOnlySpecialCharacters(value);

export const isMerchantTypeSeller = (merchantType?: MerchantType) =>
  merchantType === MerchantType.Seller;

export const isMerchantTypeVendor = (merchantType?: MerchantType) =>
  merchantType === MerchantType.Vendor;

export const getMaxLengthForCampaignName = (
  salesChannel?: FlywheelSalesChannel,
  merchantType?: MerchantType
): number => {
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    const MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_SELLER = 128;
    const MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_VENDOR = 128;
    if (isMerchantTypeSeller(merchantType)) {
      return MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_SELLER;
    } else {
      return MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_VENDOR;
    }
  }
  return MAX_CAMPAIGN_NAME_LENGTH;
};

export const isValidCampaignNameCharacterLength = (
  value: string,
  maxLength: number = MAX_CAMPAIGN_NAME_LENGTH
) => value.length <= maxLength;

export const fetchNonAsciiCharacters = (
  value: string,
  salesChannel: FlywheelSalesChannel
) => {
  const regex =
    salesChannel === FlywheelSalesChannel.Walmart
      ? VALID_UNICODE_REGEX
      : AMAZON_VALID_UNICODE_REGEX;
  return value.match(regex);
};

export const isFirstDateSmallerThanSecondDate = (
  firstDate: DateTime,
  secondDate: DateTime
) => firstDate.diff(secondDate, 'days').days < 0;

export const isDateSmallerThanToday = (date: DateTime) =>
  date.diff(DateTime.local().startOf('day'), 'days').days < 0;

export const getAdLevelColumnManagerCheckboxData = (
  tabCheckboxMetadata: DataInspectorColumnManagerConfigProps,
  selectedSalesChannel: FlywheelSalesChannel,
  adType?: AdType,
  adLevel?: AdLevel
): TabCheckbox => {
  const adTypeFilteredTabCheckboxData = tabCheckboxMetadata[
    selectedSalesChannel
  ].filter((adTypeConfig) => adTypeConfig.adType === adType);

  const adLevelFilteredTabCheckboxData = first(
    adTypeFilteredTabCheckboxData
  )?.config.filter((adLevelConfig) => adLevelConfig.adLevel === adLevel);

  return first(adLevelFilteredTabCheckboxData)?.columns || {};
};

export const isAdTypeAvailableInMetadata = (
  dropdownMetadata: DataInspection[],
  adType: AdType
): boolean => {
  const currentAdTypeSelection = dropdownMetadata.find(
    (data: DataInspection) => data.parent.value === adType
  );
  return currentAdTypeSelection ? true : false;
};

export const isAdLevelAvailableInMetadata = (
  dropdownMetadata: DataInspection[],
  adType: AdType,
  adLevel: AdLevel
): boolean => {
  const adLevelDropdownMetaData = dropdownMetadata.find(
    (data) => data.parent.value === adType
  );
  const adLevels = adLevelDropdownMetaData?.child;
  let currentAdLevelSelected;
  if (adLevels) {
    currentAdLevelSelected = adLevels.find(
      (data: LabelValue<AdLevel>) => data.value === adLevel
    );
  }
  return currentAdLevelSelected ? true : false;
};

export const getAdGroupStatusOptionsBySelectedChannel = (
  selectedSalesChannelName: string,
  intl: IntlShape
) => {
  const [ENABLED, DISABLED, ARCHIVED, PAUSED] = [
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DISABLED,
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED,
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
  ].map((id) => intl.formatMessage({ id }));

  const commonOptions = [
    {
      label: ENABLED,
      value: AdGroupStatus.Enabled,
    },
  ];

  switch (selectedSalesChannelName) {
    case FlywheelSalesChannel.Amazon:
      return [
        ...commonOptions,
        {
          label: PAUSED,
          value: AdGroupStatus.Paused,
        },
        {
          label: ARCHIVED,
          value: AdGroupStatus.Archived,
        },
      ];
    case FlywheelSalesChannel.Walmart:
      return [
        ...commonOptions,
        {
          label: DISABLED,
          value: AdGroupStatus.Disabled,
        },
      ];
    default:
      return [];
  }
};

export const findSalesChannelByChannelId = (
  salesChannels: SalesChannel[],
  channelId: string | null | undefined
): SalesChannel | undefined =>
  salesChannels.find((channel) => channel.salesChannelId === channelId);

export const getFlywheelChannelFromSalesChannelId = (
  salesChannelId: string,
  salesChannels: SalesChannel[]
): FlywheelSalesChannel =>
  findSalesChannelByChannelId(salesChannels, salesChannelId)
    ?.name as FlywheelSalesChannel;

export const CAMPAIGN_TACTIC_I18NKEY_MAPPER: Record<CampaignTactic, I18nKey> = {
  [CampaignTactic.Legacy]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_LEGACY,
  [CampaignTactic.Product]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_PRODUCT,
  [CampaignTactic.Audiences]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_AUDIENCES,
  [CampaignTactic.Views]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_VIEWS,
};

export const isCampaignStatusArchived = (campaignStatus?: CampaignStatus) =>
  campaignStatus === CampaignStatus.Archived;

export const isAdGroupStatusArchived = (adGroupStatus?: AdGroupStatus) =>
  adGroupStatus === AdGroupStatus.Archived;

export const isCampaignStatusCompleted = (
  campaignStatus?: CampaignStatus,
  updatedCampaignStatus?: CampaignStatus
) =>
  campaignStatus
    ? campaignStatus === CampaignStatus.Completed &&
      isNil(updatedCampaignStatus)
    : false;

export const isTargetStatusArchived = (status?: TargetStatusValues) =>
  status === TargetStatusValues.Archived;

export const isProductAdStatusArchived = (status?: ProductAdStatusValue) =>
  status === ProductAdStatusValue.Archived;

export const NUMERIC_VALUE_CONFIG: NumericValueConfigProps[] = [
  {
    min: 10,
    default: 10,
    max: Infinity,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
    countryCodes: [MerchantCountryCode.US],
    merchantType: MerchantType.Seller,
  },
  {
    min: 50,
    default: 50,
    max: Infinity,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
    countryCodes: [MerchantCountryCode.US],
    merchantType: MerchantType.Vendor,
  },
  {
    min: 50,
    default: 50,
    max: Infinity,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [MerchantCountryCode.US],
    merchantType: MerchantType.Seller,
  },
  {
    min: 100,
    default: 100,
    max: Infinity,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [MerchantCountryCode.US],
    merchantType: MerchantType.Vendor,
  },
  {
    min: 0.2,
    max: 20,
    default: 0.2,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.US],
    campaignTargetingType: CampaignTargetingType.Auto,
  },
  {
    min: 0.3,
    max: 20,
    default: 0.3,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.US],
    campaignTargetingType: CampaignTargetingType.Manual,
  },
  {
    min: 0.3,
    max: 20,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.US],
    campaignTargetingType: CampaignTargetingType.Manual,
  },
  {
    min: 0.2,
    max: 20,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.US],
    campaignTargetingType: CampaignTargetingType.Auto,
  },
  {
    min: 0,
    max: Infinity,
    default: 0,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MACSTarget],
    countryCodes: [],
  },
  {
    min: 0,
    max: Infinity,
    default: 0.1,
    adTypes: [AdType.SearchBrandAmplifier],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MACSTarget],
    countryCodes: [],
  },
  {
    min: 1,
    max: 20,
    default: 1,
    adTypes: [AdType.SearchBrandAmplifier],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MinBid],
    countryCodes: [],
  },
  {
    min: 1,
    max: 20,
    default: 5,
    adTypes: [AdType.SearchBrandAmplifier],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [],
  },
  {
    min: 1,
    max: 999,
    default: 1,
    adTypes: [AdType.SponsoredProducts, AdType.SponsoredDisplay],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MACSTarget],
    countryCodes: [],
  },
  {
    min: 1,
    default: 20,
    adTypes: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    max: 1000000,
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
    countryCodes: [
      MerchantCountryCode.US,
      MerchantCountryCode.CA,
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  // SP minbid
  {
    min: 0.02,
    max: 1000,
    default: 0.02,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [
      MerchantCountryCode.US,
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.1,
    max: 20000,
    default: 0.1,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.1,
    max: 1410,
    default: 0.1,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 2,
    max: 10000,
    default: 2,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.24,
    max: 184,
    default: 0.24,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.07,
    max: 3700,
    default: 0.07,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 0.02,
    max: 1100,
    default: 0.02,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    min: 0.18,
    max: 9300,
    default: 0.18,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    countryCodes: [MerchantCountryCode.SE],
  },

  // SP Maxbid
  {
    min: 0.02,
    max: 1000,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [
      MerchantCountryCode.US,
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.1,
    max: 20000,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.1,
    max: 1410,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 2,
    max: 100000,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.24,
    max: 184,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.07,
    max: 3700,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 0.02,
    max: 1100,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    min: 0.18,
    max: 9300,
    default: 5,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.SE],
  },
  {
    min: 1.4,
    default: 20,
    adTypes: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    max: 1500000,
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 4,
    default: 20,
    adTypes: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    max: 3700000,
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
    countryCodes: [MerchantCountryCode.BR, MerchantCountryCode.AE],
  },
  {
    min: 1,
    adTypes: [AdType.SponsoredProducts, AdType.SponsoredBrands],
    max: 21000000,
    default: 20,
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 100,
    default: 20,
    adTypes: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    max: 21000000,
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
    countryCodes: [MerchantCountryCode.JP],
  },
  // multipliers
  {
    min: 0,
    max: 900,
    default: undefined,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [
      CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
      CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
    ],
    countryCodes: [],
  },
  // below top of the search bid multiplier
  {
    min: -99,
    max: 99,
    default: 0,
    adTypes: [AdType.SponsoredBrands, AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier],
    countryCodes: [],
  },
  // total budget
  {
    min: 100,
    default: 100,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [MerchantCountryCode.US],
  },
  {
    min: 100,
    max: 20000000,
    default: 100,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [
      MerchantCountryCode.US,
      MerchantCountryCode.CA,
      MerchantCountryCode.MX,
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 370,
    max: 73100000,
    default: 370,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 10000,
    max: 21000000,
    default: 10000,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 141,
    max: 28000000,
    default: 141,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 367,
    max: 74000000,
    default: 367,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
    countryCodes: [MerchantCountryCode.AE],
  },
  // SB min bid
  {
    min: 0.1,
    max: 49,
    default: 0.1,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [
      MerchantCountryCode.US,
      MerchantCountryCode.CA,
      MerchantCountryCode.MX,
    ],
  },
  {
    min: 0.1,
    max: 31,
    default: 0.1,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.GB],
  },
  {
    min: 0.1,
    max: 39,
    default: 0.1,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.1,
    max: 70,
    default: 0.1,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.14,
    max: 100,
    default: 0.14,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    min: 9,
    max: 9400000,
    default: 9,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.SE],
  },
  {
    min: 0.53,
    max: 200,
    default: 0.53,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 10,
    max: 7760,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.4,
    max: 184,
    default: 0.4,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.AE],
  },
  // min bid for SBV
  {
    min: 0.25,
    max: 49,
    default: 0.25,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.US],
  },
  {
    min: 0.15,
    max: 49,
    default: 0.15,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.CA],
  },
  {
    min: 0.15,
    max: 20000,
    default: 0.15,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.15,
    max: 31,
    default: 0.15,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.GB],
  },
  {
    min: 0.15,
    max: 39,
    default: 0.15,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.15,
    max: 70,
    default: 0.15,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.2,
    max: 100,
    default: 0.2,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MinBid],
    countryCodes: [
      MerchantCountryCode.BR,
      MerchantCountryCode.JP,
      MerchantCountryCode.AE,
    ],
  },
  // max bid
  {
    min: 0.1,
    max: 49,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [
      MerchantCountryCode.US,
      MerchantCountryCode.CA,
      MerchantCountryCode.MX,
    ],
  },
  {
    min: 0.53,
    max: 200,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 0.1,
    max: 31,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.GB],
  },
  {
    min: 0.1,
    max: 39,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 10,
    max: 7760,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.1,
    max: 70,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.4,
    max: 184,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.14,
    max: 100,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    min: 9,
    max: 9400000,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.SE],
  },

  // max bid SBV
  {
    min: 0.25,
    max: 49,
    default: 10,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.US],
  },
  {
    min: 0.15,
    max: 49,
    default: 10,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.CA],
  },
  {
    min: 0.15,
    max: 20000,
    default: 10,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.15,
    max: 39,
    default: 10,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.DE],
  },
  {
    min: 0.15,
    max: 31,
    default: 10,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.GB],
  },
  {
    min: 0.15,
    max: 70,
    default: 10,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.2,
    max: 100,
    default: 10,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    min: 0.2,
    max: 100,
    default: 0.2,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MaxBid],
    countryCodes: [
      MerchantCountryCode.BR,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
      MerchantCountryCode.JP,
      MerchantCountryCode.AE,
    ],
  },
  //macs target
  {
    min: 1,
    max: 999,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [CAMPAIGNS_API_COLUMN_NAME.MACSTarget],
    countryCodes: [],
    default: 1,
  },
  // targets bid
  {
    min: 0.3,
    max: 20.0,
    default: 0.3,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Walmart],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.US],
  },
  {
    min: 0.02,
    max: 1000.0,
    default: 0.02,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.1,
    max: 20000,
    default: 0.1,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.1,
    max: 1410.0,
    default: 0.1,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 2.0,
    max: 100000.0,
    default: 2.0,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.24,
    max: 184.0,
    default: 0.24,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.07,
    max: 3700.0,
    default: 0.07,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 0.02,
    max: 1100.0,
    default: 0.02,
    adTypes: [AdType.SponsoredProducts],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    min: 0.1,
    default: 0.1,
    max: 49,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [
      MerchantCountryCode.CA,
      MerchantCountryCode.MX,
      MerchantCountryCode.US,
    ],
  },
  {
    min: 9,
    default: 0,
    max: 9400000,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.SE],
  },
  {
    min: 0.25,
    default: 0.25,
    max: 49,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.US],
  },
  {
    min: 0.15,
    max: 49,
    default: 0.15,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.CA, MerchantCountryCode.DE],
  },
  {
    min: 0.15,
    max: 20000,
    default: 0.15,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.15,
    default: 0.15,
    max: 39,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.15,
    default: 0.15,
    max: 31,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.GB],
  },
  {
    min: 0.15,
    default: 0.15,
    max: 70,
    adTypes: [AdType.SponsoredBrandsVideo],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.1,
    default: 0.1,
    max: 39,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.1,
    default: 0.1,
    max: 31,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.GB],
  },
  {
    min: 0.1,
    max: 70,
    default: 0.1,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 10,
    max: 7760,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.4,
    max: 184,
    default: 0.4,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.53,
    max: 200,
    default: 0.53,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 0.14,
    max: 100,
    default: 0.14,
    adTypes: [AdType.SponsoredBrands],
    salesChannels: [FlywheelSalesChannel.Amazon],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    countryCodes: [MerchantCountryCode.SG],
  },
  // SD targets
  {
    min: 0.02,
    default: 0.02,
    max: 31,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.GB],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.02,
    max: 39,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.02,
    max: 49,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.CA, MerchantCountryCode.US],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AU],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.02,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 2.0,
    default: 2.0,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.24,
    default: 0.24,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.1,
    default: 0.1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.07,
    default: 0.07,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 1,
    default: 1,
    max: 1000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
    ],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AU],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 100,
    default: 100,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 4,
    default: 4,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.VCPM,
  },
  // SD targets seller
  {
    min: 0.02,
    default: 0.02,
    max: 1000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.1,
    default: 0.1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AU],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.02,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 100,
    default: 100,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.24,
    default: 0.24,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.1,
    default: 0.1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.07,
    default: 0.07,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 1,
    default: 1,
    max: 1000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AU],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 100,
    default: 100,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 4,
    default: 4,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.VCPM,
  },

  // SD min bid and default bid for vendor
  {
    min: 0.02,
    default: 0.02,
    max: 31,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.GB],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.02,
    max: 39,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.02,
    max: 49,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.CA, MerchantCountryCode.US],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.1,
    default: 0.1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    campaignCostType: CampaignCostType.CPC,
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.02,
    default: 0.02,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 2,
    default: 2,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.24,
    default: 0.24,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.1,
    default: 0.1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.07,
    default: 0.07,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.CPC,
  },
  // SD adgroup default bid for vendor and vCPM
  {
    min: 1,
    default: 1,
    max: 1000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
    ],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AU],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 100,
    default: 100,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 4,
    default: 4,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.VCPM,
  },
  // SD vendor max bid
  {
    min: 0.02,
    default: 5,
    max: 31,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.GB],
  },
  {
    min: 0.02,
    default: 5,
    max: 39,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [
      MerchantCountryCode.DE,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
  },
  {
    min: 0.02,
    default: 5,
    max: 49,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.CA, MerchantCountryCode.US],
  },
  {
    min: 0.1,
    default: 5,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.02,
    default: 5,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.NL],
  },
  {
    min: 2,
    default: 5,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.24,
    default: 5,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.1,
    default: 5,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.07,
    default: 5,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Vendor,
    countryCodes: [MerchantCountryCode.BR],
  },
  // SD min bid and default bid for sellers
  {
    min: 0.02,
    default: 0.02,
    max: 1000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.1,
    default: 0.1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AU],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.02,
    default: 0.02,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 2,
    default: 2,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.24,
    default: 0.24,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.1,
    default: 0.1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.CPC,
  },
  {
    min: 0.07,
    default: 0.07,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.CPC,
  },
  // SD min bid and default bid for sellers and vCPM
  {
    min: 1,
    default: 1,
    max: 1000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AU],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.NL],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 100,
    default: 100,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.JP],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 4,
    default: 4,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AE],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.MX],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1,
    default: 1,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.BR],
    campaignCostType: CampaignCostType.VCPM,
  },
  // SD seller max bid
  {
    min: 0.02,
    default: 5,
    max: 1000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
    ],
  },
  {
    min: 0.1,
    default: 5,
    max: 1410,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 0.02,
    default: 5,
    max: 100,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.NL],
  },
  {
    min: 2,
    default: 5,
    max: 100000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.24,
    default: 5,
    max: 3670,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.1,
    default: 5,
    max: 20000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.MX],
  },
  {
    min: 0.07,
    default: 5,
    max: 3700,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    merchantType: MerchantType.Seller,
    countryCodes: [MerchantCountryCode.BR],
  },
  // SP-Targets Bid
  {
    min: 0.02,
    default: 0.05,
    max: 1000.0,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [
      MerchantCountryCode.GB,
      MerchantCountryCode.DE,
      MerchantCountryCode.CA,
      MerchantCountryCode.US,
      MerchantCountryCode.FR,
      MerchantCountryCode.ES,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
    ],
  },
  {
    min: 0.1,
    default: 0.1,
    max: 1410.0,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.AU],
  },
  {
    min: 2.0,
    default: 2,
    max: 100000.0,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.JP],
  },
  {
    min: 0.24,
    default: 0.24,
    max: 184,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.AE],
  },
  {
    min: 0.07,
    default: 0.07,
    max: 3700,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.BR],
  },
  {
    min: 0.02,
    default: 0.02,
    max: 1100,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.SG],
  },
  {
    min: 0.18,
    default: 1,
    max: 9300,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [TARGETS_API_COLUMN_NAME.Bid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.SE],
  },
  {
    min: 1.0,
    max: 5000.0,
    default: 1.0,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [
      CAMPAIGNS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.MinBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
  },
  {
    min: 1.0,
    max: 5000.0,
    default: 5.0,
    adTypes: [AdType.SponsoredProducts],
    columnNames: [
      CAMPAIGNS_API_COLUMN_NAME.MaxBid,
      ADGROUPS_API_COLUMN_NAME.MaxBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
  },
  {
    min: 1.0,
    max: 2000.0,
    default: 1.0,
    adTypes: [AdType.SponsoredBrands],
    columnNames: [
      CAMPAIGNS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      TARGETS_API_COLUMN_NAME.Bid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
  },
  {
    min: 1.0,
    max: 2000.0,
    default: 10,
    adTypes: [AdType.SponsoredBrands],
    columnNames: [
      CAMPAIGNS_API_COLUMN_NAME.MaxBid,
      ADGROUPS_API_COLUMN_NAME.MaxBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
  },
  {
    min: 1.5,
    max: 500.0,
    default: 1.5,
    adTypes: [AdType.SponsoredBrandsVideo],
    columnNames: [
      CAMPAIGNS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      TARGETS_API_COLUMN_NAME.Bid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
  },
  {
    min: 1.5,
    max: 500.0,
    default: 10.0,
    adTypes: [AdType.SponsoredBrandsVideo],
    columnNames: [
      CAMPAIGNS_API_COLUMN_NAME.MaxBid,
      ADGROUPS_API_COLUMN_NAME.MaxBid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
  },
  {
    min: 4.0,
    default: 4.0,
    max: 5000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.MaxBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      TARGETS_API_COLUMN_NAME.Bid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
    campaignCostType: CampaignCostType.VCPM,
  },
  {
    min: 1.0,
    default: 1.0,
    max: 500,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      TARGETS_API_COLUMN_NAME.Bid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
    merchantType: MerchantType.Seller,
  },
  {
    min: 1.0,
    default: 10.0,
    max: 500,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
    merchantType: MerchantType.Seller,
  },
  {
    min: 1.0,
    default: 1.0,
    max: 5000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      TARGETS_API_COLUMN_NAME.Bid,
    ],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
    merchantType: MerchantType.Vendor,
  },
  {
    min: 1.0,
    default: 10.0,
    max: 5000,
    adTypes: [AdType.SponsoredDisplay],
    columnNames: [ADGROUPS_API_COLUMN_NAME.MaxBid],
    salesChannels: [FlywheelSalesChannel.Amazon],
    countryCodes: [MerchantCountryCode.IN],
    merchantType: MerchantType.Vendor,
  },
];

export const getCurrentColumnNumericParams = (
  adType: AdType | null,
  salesChannel: FlywheelSalesChannel | null,
  columnName: string,
  countryCode: MerchantCountryCode,
  campaignTargetingType?: CampaignTargetingType,
  merchantType?: MerchantType,
  campaignCostType?: CampaignCostType
): NumericValueConfig => {
  const requiredConfigs: NumericValueConfigProps[] = NUMERIC_VALUE_CONFIG.filter(
    (numericConfig: NumericValueConfigProps) =>
      adType &&
      salesChannel &&
      numericConfig.adTypes.includes(adType) &&
      numericConfig.salesChannels.includes(salesChannel) &&
      numericConfig.columnNames.includes(columnName) &&
      (isEmpty(numericConfig.countryCodes) ||
        numericConfig.countryCodes.includes(countryCode)) &&
      (campaignTargetingType && numericConfig.campaignTargetingType
        ? campaignTargetingType === numericConfig.campaignTargetingType
        : true) &&
      (merchantType && numericConfig.merchantType
        ? numericConfig.merchantType === merchantType
        : true) &&
      (campaignCostType && numericConfig.campaignCostType
        ? numericConfig.campaignCostType === campaignCostType
        : true)
  );

  const requiredConfig = first(requiredConfigs);
  return {
    min: requiredConfig?.min ?? 0,
    max: requiredConfig?.max ?? Infinity,
    default: requiredConfig?.default ?? 0,
  };
};

export const isCurrentValueLessThanRequiredMinValue = (
  minValue?: number,
  value?: string
): boolean => {
  return value && !isNil(minValue) ? Number(value) < minValue : false;
};

export const isValueBetweenMinAndMaxValue = (
  minValue: number,
  maxValue: number,
  value?: string
): boolean =>
  value ? Number(value) >= minValue && Number(value) <= maxValue : false;

export const isCurrentValueGreaterThanRequiredMaxValue = (
  maxValue?: number,
  value?: string
): boolean => {
  return value && !isNil(maxValue) ? Number(value) > maxValue : false;
};

// If isCampaignTacticsNotEqualToLegacyOrViews/isCampaignAdFormatProductCollectionOrSpotlight = true -> show value received from the backend. If value is null/undefined/0, UI should show 0.
// If isCampaignTacticsNotEqualToLegacyOrViews and isCampaignAdFormatProductCollectionOrSpotlight = false -> show EmDash, hence undefined
export const isNumericValueWithCampaignTacticAndCampaignAdFormatValid = (
  value?: string | number,
  campaignTactic?: CampaignTactic,
  campaignAdFormat?: CampaignAdFormat
) =>
  (((campaignTactic &&
    isCampaignTacticsNotEqualToLegacyOrViews(campaignTactic)) ||
    isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat)) &&
    (value?.toString() || '0')) ||
  undefined;

export const getCheckBoxLabelStateForProductAds = (
  salesChannel: FlywheelSalesChannel | undefined,
  adStatus: string
): CheckboxState =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  adStatus === ProductAdStatusOptions.Archived.value
    ? CheckboxState.Disabled
    : CheckboxState.Default;

export const getCheckBoxLabelStateForTargets = (
  salesChannel: FlywheelSalesChannel | undefined,
  targetStatus: string
): CheckboxState =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  targetStatus === TargetStatusOptions.Archived.value
    ? CheckboxState.Disabled
    : CheckboxState.Default;

export const isBulkEditModalDisabledForPlacementBidMultipliersAmazonSP = (
  filteredElementsForBulkEditModal:
    | (BulkEditModalElement | BulkEditModalGroupedElement)[]
    | undefined
): boolean => {
  const areAllValuesEmpty = filteredElementsForBulkEditModal?.every(
    (singleElement: BulkEditModalElement | BulkEditModalGroupedElement) => {
      return (singleElement as BulkEditModalElement).value === '';
    }
  );

  if (areAllValuesEmpty) {
    return true;
  } else {
    const areAllMultipliersValid = filteredElementsForBulkEditModal?.every(
      (singleElement: BulkEditModalElement | BulkEditModalGroupedElement) => {
        if ((singleElement as BulkEditModalElement).value === '') {
          return true;
        } else {
          return (singleElement as BulkEditModalElement).validators.every(
            ({ validator }) =>
              validator((singleElement as BulkEditModalElement).value)
          );
        }
      }
    );
    return !areAllMultipliersValid;
  }
};

export const getDateCountGreaterThanEqualToMaxOfStartDateAndCurrentDate = (
  date: DateTime,
  campaignsTableData: CampaignDetails[],
  selectSettings: TableSelectSettings
) => {
  return campaignsTableData.filter(
    ({ campaignId, channelSettings: { startDate } }) => {
      const isCampaignPresent = selectSettings.rows.indexOf(campaignId) !== -1;
      const isStartDatePresent = !isNil(startDate);
      const isDateValid = date.isValid;

      const compareBetweenDates =
        isCampaignPresent && isStartDatePresent && isDateValid;

      const minDateBetweenStartDateAndCurrentDate = getMaxDateBetweenTwoDates(
        DateTime.fromISO(startDate),
        DateTime.local(),
        CAMPAIGN_DATE_FORMAT
      );

      return (
        compareBetweenDates &&
        minDateBetweenStartDateAndCurrentDate <=
          date.toFormat(CAMPAIGN_DATE_FORMAT)
      );
    }
  ).length;
};

export const getValidDateCount = (
  date: DateTime,
  campaignsTableData: CampaignDetails[],
  selectSettings: TableSelectSettings,
  tableChange?: TableChange
) => {
  return campaignsTableData.filter(
    ({ campaignId, channelSettings: { startDate, status } }) => {
      const isCampaignPresent = selectSettings.rows.indexOf(campaignId) !== -1;
      const isStartDatePresent = !isNil(startDate);
      const isDateValid = date.isValid;
      const compareBetweenDates =
        isCampaignPresent && isStartDatePresent && isDateValid;
      const updatedRow = tableChange?.cell[campaignId];
      const updatedStatus =
        updatedRow && updatedRow[CAMPAIGNS_API_COLUMN_NAME.CampaignStatus];

      const minDateBetweenStartDateAndCurrentDate = getMaxDateBetweenTwoDates(
        DateTime.fromISO(startDate),
        DateTime.local(),
        CAMPAIGN_DATE_FORMAT
      );

      // check if date is greater than or equal to Max. Of start date and current date
      const isDateApplicable =
        compareBetweenDates &&
        minDateBetweenStartDateAndCurrentDate <=
          date.toFormat(CAMPAIGN_DATE_FORMAT);

      // check if campaign status is incomplete for the row
      const isCampaignIncomplete = !isCampaignStatusCompleted(
        status,
        updatedStatus as CampaignStatus
      );

      return isCampaignPresent && isCampaignIncomplete && isDateApplicable;
    }
  ).length;
};

export const getIncompleteCampaignCount = (
  campaignsTableData: CampaignDetails[],
  selectSettings: TableSelectSettings,
  tableChange?: TableChange
) =>
  campaignsTableData.filter(({ campaignId, channelSettings: { status } }) => {
    const isCampaignPresent = selectSettings.rows.indexOf(campaignId) !== -1;
    const updatedRow = tableChange?.cell[campaignId];
    const updatedStatus =
      updatedRow && updatedRow[CAMPAIGNS_API_COLUMN_NAME.CampaignStatus];

    // check if campaign status is incomplete for the row
    const isCampaignIncomplete = !isCampaignStatusCompleted(
      status,
      updatedStatus as CampaignStatus
    );

    return isCampaignPresent && isCampaignIncomplete;
  }).length;

export const getMaxDateBetweenTwoDates = (
  firstDate: DateTime,
  secondDate: DateTime,
  dateFormat: string
) =>
  firstDate > secondDate
    ? firstDate.toFormat(dateFormat)
    : secondDate.toFormat(dateFormat);

export const roundValuesToDecimalPlaces = (
  values: string[],
  decimalPlaces: number
) =>
  values.map((value) =>
    value ? parseFloat(value).toFixed(decimalPlaces) : value
  );

export const isStringValuePresent = (value?: string) =>
  value !== '' && !isNil(value);

export const isTargetingExpressionTypeView = (
  targetingExpressionType?: string
) => targetingExpressionType === 'views';

export const getSearchInputPlaceholder = (
  adType: AdType,
  adLevel: AdLevel,
  intl: IntlShape
) => {
  const [CAMPAIGNS, ADGROUPS, PRODUCT, KEYWORD_TARGETS, TARGETS, DEFAULT] = [
    I18nKey.SEARCH_INPUT_PLACEHOLDER_CAMPAIGNS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_ADGROUPS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_PRODUCT,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_KEYWORD_TARGETS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_TARGETS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER,
  ].map((id) => intl.formatMessage({ id }));

  switch (adType + adLevel) {
    case AdType.SponsoredProducts + AdLevel.Campaigns:
    case AdType.SponsoredBrands + AdLevel.Campaigns:
    case AdType.SponsoredDisplay + AdLevel.Campaigns:
      return CAMPAIGNS;
    case AdType.SponsoredDisplay + AdLevel.AdGroups:
    case AdType.SponsoredProducts + AdLevel.AdGroups:
      return ADGROUPS;
    case AdType.SponsoredProducts + AdLevel.KeywordTargets:
    case AdType.SponsoredBrands + AdLevel.KeywordTargets:
      return KEYWORD_TARGETS;
    case AdType.SponsoredProducts + AdLevel.ProductAds:
    case AdType.SponsoredDisplay + AdLevel.ProductAds:
      return PRODUCT;
    case AdType.SponsoredDisplay + AdLevel.Targets:
    case AdType.SponsoredProducts + AdLevel.ProductTargets:
    case AdType.SponsoredBrands + AdLevel.ProductTargets:
      return TARGETS;
    default:
      return DEFAULT;
  }
};

export const getDropdownValuesFromPathname = (pathname: string) => {
  const salesChannelFromPathname: FlywheelSalesChannel | undefined =
    ADS_MANAGER_PAGE_TO_SALESCHANNEL_MAPPER[
      pathname as ADS_MANAGER_AD_LEVEL_PAGES
    ];

  const adTypeFromPathname: AdType =
    ADS_MANAGER_PAGE_TO_AD_TYPE_MAPPER[pathname as ADS_MANAGER_AD_LEVEL_PAGES];

  const adLevelFromPathname: AdLevel =
    ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER[pathname as ADS_MANAGER_AD_LEVEL_PAGES];

  return {
    salesChannelFromPathname,
    adTypeFromPathname,
    adLevelFromPathname,
  };
};

export const cleanUpExpressionValue = (expressionValue: string) =>
  expressionValue.startsWith('is:')
    ? expressionValue.replace('is:', '')
    : expressionValue;

export const findFuzzyExpressionTypeInFilter = (
  expressionText: string,
  expressionTypeDescription: string
): string | null => {
  const EXPRESSION_TYPE_SPECIAL_CHARACTERS = ['>', '<'];

  let probablyExpressionType = '';
  EXPRESSION_TYPE_SPECIAL_CHARACTERS.every((expressionSpecialCharacter) => {
    if (expressionText.includes(expressionSpecialCharacter)) {
      probablyExpressionType =
        first(expressionText.split(expressionSpecialCharacter)) +
        expressionSpecialCharacter;
      return false;
    }
    return true;
  });

  if (!probablyExpressionType) {
    const allPossibleExpressionTypes = expressionText.split(' ');
    probablyExpressionType = allPossibleExpressionTypes[0];
  }

  if (probablyExpressionType) {
    return expressionTypeDescription.includes(probablyExpressionType)
      ? `${probablyExpressionType}${
          expressionTypeDescription.split(probablyExpressionType)[1]
        }`
      : null;
  }
  return null;
};

const expressionTypes = (
  expressionText: string,
  intl: IntlShape,
  remainingExpressions: string[]
) => {
  let expressionTypeDescription;
  const EXPRESSION_TYPE_LIST: ExpressionType[] = [
    ExpressionType.AsinAgeRangeSameAs,
    ExpressionType.AsinBrandSameAs,
    ExpressionType.AsinCategorySameAs,
    ExpressionType.AsinGenreSameAs,
    ExpressionType.AsinIsPrimeShippingEligible,
    ExpressionType.AsinPriceBetween,
    ExpressionType.AsinPriceGreaterThan,
    ExpressionType.AsinPriceLessThan,
    ExpressionType.AsinReviewRatingBetween,
    ExpressionType.AsinReviewRatingGreaterThan,
    ExpressionType.AsinReviewRatingLessThan,
    ExpressionType.AsinSameAs,
    ExpressionType.Lookback,
    ExpressionType.AudienceSameAs,
    ExpressionType.ExactProduct,
    ExpressionType.SimilarProduct,
    ExpressionType.QueryBroadRelMatches,
    ExpressionType.QueryHighRelMatches,
    ExpressionType.AsinAccessoryRelated,
    ExpressionType.AsinSubstituteRelated,
  ];
  return EXPRESSION_TYPE_LIST.filter((expressionType: ExpressionType) => {
    expressionTypeDescription = intl
      .formatMessage({
        id: EXPRESSION_TYPE_TO_FILTER_I18NKEY_MAPPER[expressionType],
      })
      .toLowerCase();

    const fuzzyExpressionType = findFuzzyExpressionTypeInFilter(
      expressionText,
      expressionTypeDescription
    );

    const isExpressionTypeRequiredInFilter =
      expressionText.toLowerCase().indexOf(expressionTypeDescription) === 0 ||
      expressionTypeDescription.indexOf(expressionText.toLowerCase()) !== -1 ||
      fuzzyExpressionType;

    const subStringToBeReplaced =
      fuzzyExpressionType || expressionTypeDescription;

    if (isExpressionTypeRequiredInFilter) {
      remainingExpressions.push(
        expressionText.substring(subStringToBeReplaced.length).trim()
      );
    }
    return isExpressionTypeRequiredInFilter;
  });
};

const getRemainingExpression = (remainingExpressions: string[]) => {
  return remainingExpressions.length > 1
    ? remainingExpressions[1]
    : remainingExpressions[0];
};

const getSubStringEnd = (requiredExpressionTypes: ExpressionType[]): number => {
  let endSubStr = 0;
  let isAtleastUndefinedOrNotEqual = false;
  while (isAtleastUndefinedOrNotEqual === false) {
    const values = [];
    for (let expressionType of requiredExpressionTypes) {
      values.push(expressionType[endSubStr]);
    }
    const firstVal = first(values);
    for (let i = 1; i < values.length; i++) {
      if (firstVal?.toLowerCase() !== values[i]?.toLowerCase()) {
        isAtleastUndefinedOrNotEqual = true;
      }
    }
    endSubStr++;
  }
  return endSubStr;
};

const getRequiredExpressionType = (
  requiredExpressionTypes: ExpressionType[]
) => {
  const COMPARBLE_EXPRESSION_TYPE_LIST: ExpressionType[] = [
    ExpressionType.AsinPriceBetween,
    ExpressionType.AsinPriceGreaterThan,
    ExpressionType.AsinPriceLessThan,
    ExpressionType.AsinReviewRatingBetween,
    ExpressionType.AsinReviewRatingGreaterThan,
    ExpressionType.AsinReviewRatingLessThan,
  ];
  let requiredExpressionType;

  if (requiredExpressionTypes.length === 0) {
    requiredExpressionType = first(requiredExpressionTypes);
  }

  if (requiredExpressionTypes.length > 1) {
    requiredExpressionType = first(requiredExpressionTypes);
    if (
      COMPARBLE_EXPRESSION_TYPE_LIST.includes(
        requiredExpressionType as ExpressionType
      )
    ) {
      let endSubStr: number = getSubStringEnd(requiredExpressionTypes);
      requiredExpressionType = requiredExpressionTypes[0].substr(
        0,
        endSubStr - 1
      );
    }
  }
  return requiredExpressionType;
};

export const getTargetingExpressionFilters = (
  intl: IntlShape,
  filterFields: string[]
) => (originalFilters: Filter[]): Filter[] => {
  const filtersToBeTransformed = originalFilters.filter((filter) =>
    filterFields.includes(filter.field)
  );

  if (filtersToBeTransformed.length === 0) {
    return originalFilters;
  }
  let newFilters: Filter[] = [];

  filtersToBeTransformed.forEach((filter) => {
    const expressionText = cleanUpExpressionValue(filter.value as string);
    const remainingExpressions: string[] = [expressionText];
    const requiredExpressionTypes = expressionTypes(
      expressionText,
      intl,
      remainingExpressions
    );
    /**
     * This takes care of the scenario where search query may match to more than
     * one expression type and comaparable expressionTypes are taken care of separately.
     * For example `Star rating` will match `asinReviewRatingBetween` and
     * `asinReviewRatingLessThan`.
     * But for example `me shipping eligible` will match asinIsPrimeShippingEligible exactly
     * and doesn't need to go through the comparable logic since its not like starRating
     */

    let requiredExpressionType = getRequiredExpressionType(
      requiredExpressionTypes
    );

    if (requiredExpressionType) {
      newFilters.push({
        field: TARGETS_API_COLUMN_NAME.ExpressionType,
        op: FilterOps.like,
        value: requiredExpressionType,
      });
    }
    const TARGETING_EXPRESSION_VALUE_KEY = 'targetingExpressionValue';

    let remainingExpression = getRemainingExpression(remainingExpressions);
    if (remainingExpression) {
      newFilters.push({
        field: TARGETING_EXPRESSION_VALUE_KEY,
        op: FilterOps.like,
        value: remainingExpression,
      });
    }
  });

  const transformedFilters = originalFilters.filter(
    (filter) => !filterFields.includes(filter.field)
  );

  transformedFilters.push(...newFilters);

  return transformedFilters;
};

export const getTooltipContentForArchivedEntity = (
  adLevel: string,
  columnName: string,
  intl: IntlShape
) => {
  return (
    <p className="w-180 text-center">
      {intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_COLUMNS_ARCHIVED_ENTITY_ERROR_TOOLTIP,
        },
        {
          adLevel: lowerCase(adLevel),
          columnName: columnName,
        }
      )}
    </p>
  );
};

export const getPerformanceMetricValue = (
  selectedEndDate?: DateTime,
  campaignStartDate?: DateTime,
  performanceMetricValue?: number
) => {
  if (
    isNil(campaignStartDate) ||
    isNil(selectedEndDate) ||
    (performanceMetricValue && !isNaN(performanceMetricValue))
  ) {
    return performanceMetricValue;
  }

  const daysDiff = campaignStartDate.diff(selectedEndDate, ['days']).days;

  return daysDiff <= 0 ? 0 : undefined;
};

export const shouldShowManageBidding = (
  optimizelyContext: OptimizelyContextState
) => optimizelyContext.featureFlags[OptimizelyFlags.AOManageBidding];

export const campaignDetailsFieldsForCSVExport: string[] = [
  CAMPAIGNS_API_COLUMN_NAME.MerchantName,
  AD_LEVEL_INTERNAL_ID_MAPPER[AdLevel.Campaigns] || '',
  AD_LEVEL_EXTERNAL_ID_MAPPER[AdLevel.Campaigns][0],
  CAMPAIGNS_API_COLUMN_NAME.CampaignName,
];

export const campaignflywheelSettingsFieldsForCSVExport: string[] = [
  CAMPAIGNS_API_COLUMN_NAME.AutomationStatus,
  CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
  CAMPAIGNS_API_COLUMN_NAME.MinBid,
  CAMPAIGNS_API_COLUMN_NAME.MaxBid,
];

export const adGroupDetailsFieldsForCSVExport: string[] = [
  ADGROUPS_API_COLUMN_NAME.MerchantName,
  AD_LEVEL_EXTERNAL_ID_MAPPER[AdLevel.AdGroups][0],
  AD_LEVEL_EXTERNAL_ID_MAPPER[AdLevel.Campaigns][0],
  AD_LEVEL_INTERNAL_ID_MAPPER[AdLevel.Campaigns] || '',
  ADGROUPS_API_COLUMN_NAME.CampaignName,
  AD_LEVEL_INTERNAL_ID_MAPPER[AdLevel.AdGroups] || '',
  ADGROUPS_API_COLUMN_NAME.AdGroupName,
];

export const adGroupflywheelSettingsFieldsForCSVExport: string[] = [
  ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
  ADGROUPS_API_COLUMN_NAME.MACSTarget,
  ADGROUPS_API_COLUMN_NAME.MinBid,
  ADGROUPS_API_COLUMN_NAME.MaxBid,
];

export const getMerchantCountryNameForCSVDownload = (
  selectedMerchantCountries: Array<number | string> | string,
  selectedMerchantCountryInfo?: MerchantCountry
) =>
  isArray(selectedMerchantCountries) && selectedMerchantCountries.length > 1
    ? undefined
    : selectedMerchantCountryInfo?.merchantName;

export const getMerchantName = (
  merchantDetails: MerchantCountry[],
  merchantId?: string
): string | undefined =>
  merchantId
    ? merchantDetails.find(
        (merchant) => merchant.merchantCountryId === merchantId
      )?.merchantName
    : undefined;

export const isInputValueNumber = (inputValue: string) =>
  !isNaN(Number(inputValue));

export const getEditModePlacementStatusOption = (intl: IntlShape) => [
  {
    value: 'excluded',
    label: intl.formatMessage({
      id:
        I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_EXCLUDED,
    }),
  },
  {
    value: 'included',
    label: intl.formatMessage({
      id:
        I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_INCLUDED,
    }),
  },
];

export const getAutomationDisabledTooltipMessage = (value: string) => (
  <p className="w-200 text-center">
    <FormattedMessage
      id={I18nKey.ADS_MANAGER_TOOLTIP_MESSAGE_AUTOMATION_DISABLED}
      values={{
        value,
      }}
    />
  </p>
);

export const isDatelessThanGivenDate = (date1: DateTime, date2: DateTime) =>
  date1.startOf('day') < date2.startOf('day');

export const areAllSelectedCampaignCostTypeVcpm = (
  tableChange: TableChange,
  tableData: AdGroupDetails[]
) =>
  tableChange.select.rows.every((rowId: string) => {
    const rowData = find(tableData, {
      [TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]]: rowId,
    });
    return isCampaignCostTypeVcpm(rowData?.adGroupDetails.campaignCostType);
  });

export const areAllSelectedCampaignCostTypeCpc = (
  tableChange: TableChange,
  tableData: AdGroupDetails[]
) =>
  tableChange.select.rows.every((rowId: string) => {
    const rowData = find(tableData, {
      [TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]]: rowId,
    });
    return isCampaignCostTypeCPC(rowData?.adGroupDetails.campaignCostType);
  });

export const isCampaignCostTypeVcpm = (
  campaignCostType: CampaignCostType | undefined
) => campaignCostType === CampaignCostType.VCPM;

export const isCampaignCostTypeCPC = (
  campaignCostType: CampaignCostType | undefined
) => campaignCostType === CampaignCostType.CPC;

export const shouldDisplayEditToggle = (
  adType: AdType | undefined,
  adLevel: AdLevel
) => {
  if (
    adType &&
    adType === AdType.SearchBrandAmplifier &&
    adLevel !== AdLevel.AdGroups
  ) {
    return false;
  }

  return true;
};

/*
Target types API is to be called only in the following conditions.

1. channel = Amazon
2. It should be a details page
3. Campaigns & SB / AdGroups & SP / AdGroups & SD
Entity in the above message is actually the details page entity. Eg: Campaign means it's for Campaign details page.
*/

export const shouldFetchSpecificTargetTypes = (
  identifiedAdLevel: AdLevel,
  adTypeDropDownValue: AdType | undefined,
  selectedFlywheelChannel: FlywheelSalesChannel,
  adsManagerDetailsPageFlag: boolean
) =>
  ((identifiedAdLevel === AdLevel.Campaigns &&
    adTypeDropDownValue === AdType.SponsoredBrands) ||
    (identifiedAdLevel === AdLevel.AdGroups &&
      adTypeDropDownValue === AdType.SponsoredProducts) ||
    (identifiedAdLevel === AdLevel.AdGroups &&
      adTypeDropDownValue === AdType.SponsoredDisplay)) &&
  selectedFlywheelChannel === FlywheelSalesChannel.Amazon &&
  adsManagerDetailsPageFlag;

export const isFlyWheelSettingsField = (field: string) => {
  const MAP = {
    [DATA_INSPECTOR_COLUMN_NAME[
      DataInspectorColumnIdentifier.BidAutomationStatus
    ]]: true,
    [DATA_INSPECTOR_COLUMN_NAME[
      DataInspectorColumnIdentifier.MACSTarget
    ]]: true,
    [DATA_INSPECTOR_COLUMN_NAME[DataInspectorColumnIdentifier.MinBid]]: true,
    [DATA_INSPECTOR_COLUMN_NAME[DataInspectorColumnIdentifier.MaxBid]]: true,
  };
  return MAP[field];
};

const getNewColumn = (
  column: string,
  selected: boolean,
  disabled?: boolean
) => {
  if (isFlyWheelSettingsField(column)) {
    return {
      [column]: {
        isSelected: selected || false,
        isDisabled: disabled,
      },
    };
  }

  return {
    [column]: {
      isSelected: selected || false,
    },
  };
};

export const getNewTabCheckboxValues = (
  selectedColumns: string[],
  adLevelConfig: DataInspectionAdLevelConfigProps,
  oldCheckboxConfig: TabCheckbox
) => {
  const selectedColumnsMap: {
    [column: string]: boolean;
  } = reduce(
    selectedColumns,
    (acc, column) => ({ ...acc, [column]: true }),
    {}
  );
  const newColumns: {
    [group: string]: {
      [column: string]: {
        isSelected: boolean;
        isDisabled?: boolean;
      };
    };
  } = {};
  Object.keys(adLevelConfig.columns).forEach((group) => {
    if (!newColumns[group]) {
      newColumns[group] = {};
    }
    Object.keys(adLevelConfig.columns[group]).forEach((column) => {
      newColumns[group][column] = getNewColumn(
        column,
        selectedColumnsMap[column],
        oldCheckboxConfig?.[group]?.[column]?.isDisabled
      )[column];
    });
  });

  return newColumns;
};

export const isBetweenMinAndMaxValue = (
  value: number,
  minValue: number,
  maxValue: number
): boolean => value >= minValue && value <= maxValue;

export const getDatePresentRangeForTracking = (diffDays: number) => {
  const keys: PredefinedDateRange[] = Object.keys(
    DATE_RANGE_CONFIG_FOR_DATA_TRACKING
  ) as PredefinedDateRange[];
  return keys.find((preDefinedRange) => {
    if (
      isBetweenMinAndMaxValue(
        diffDays,
        DATE_RANGE_CONFIG_FOR_DATA_TRACKING[preDefinedRange].min,
        DATE_RANGE_CONFIG_FOR_DATA_TRACKING[preDefinedRange].max
      )
    ) {
      return preDefinedRange;
    }
    return false;
  });
};

export const isValidFlywheelSetting = (
  initialSettings:
    | AdGroupDetails['flywheelSettings']
    | CampaignDetails['flywheelSettings']
    | undefined,
  currentSettings: {
    minBid?: string;
    maxBid?: string;
    macsTarget?: string | number;
  },
  currentFieldValue?: string
) => {
  // if automationStatus is set then the default settings will apply, so returning true.
  // if current field has value, then no need to check
  // if nothing has updated in any of 3 fields then no need to check
  if (
    !isNil(initialSettings?.automationStatus) ||
    currentFieldValue ||
    isEqual(
      {
        minBid: initialSettings?.minBid?.amount,
        maxBid: initialSettings?.maxBid?.amount,
        macsTarget: initialSettings?.macsTarget,
      },
      currentSettings
    )
  ) {
    return true;
  }

  // As per ticket IFD-416
  // will only return false if current field has no value & any of other fields have value
  // Ex: minBid: unset, maxBid: 1 & if acosTarget is unset, then acosTarget has invalid state
  // @ts-ignore
  return !Object.keys(currentSettings).filter((key) => !!currentSettings[key])
    .length;
};
