import { IdToken } from '@auth0/auth0-react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { createContext, useContext, useEffect, useState } from 'react';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';
import { SalesChannelContext } from '../SalesChannelProvider';
import { UserContext } from '../userProvider/userProvider';

interface SubscriptionState {
  readonly salesChannelData: AccountSalesChannelPaginatedResultWithError[];
  readonly isSubscriptionInformationLoaded: boolean;
  readonly updateSubscriptionInformation: () => void;
}

const initialState: SubscriptionState = {
  salesChannelData: [],
  isSubscriptionInformationLoaded: false,
  updateSubscriptionInformation: noop,
};

export const SubscriptionContext = createContext<SubscriptionState>(
  initialState
);

const { Provider } = SubscriptionContext;

export const SubscriptionProvider: React.FC<
  Readonly<{
    idToken: IdToken;
  }>
> = ({ children, idToken }) => {
  const [salesChannelData, setSalesChannelData] = useState<
    AccountSalesChannelPaginatedResultWithError[]
  >([]);
  const [
    isSubscriptionInformationLoaded,
    setSubscriptionInformationLoaded,
  ] = useState(false);

  const salesChannelContext = useContext(SalesChannelContext);
  const { salesChannels, areSalesChannelsLoaded } = salesChannelContext;

  const famAPIClient = createFAMApiClient(idToken);

  const { userInfo } = useContext(UserContext);
  const currentAccountId =
    userInfo?.currentAccountId ??
    userInfo?.userDetails?.accountPermissions[0]?.account.id;

  useEffect(() => {
    setSubscriptionInformation();
  }, [areSalesChannelsLoaded, salesChannels, currentAccountId]);

  const setSubscriptionInformation = () => {
    if (areSalesChannelsLoaded && !isNil(currentAccountId)) {
      const salesChannelDataPromises = salesChannels.map(({ id, name }) =>
        getSalesChannelsForCurrentAccount(currentAccountId, id, name)
      );
      setSubscriptionInformationLoaded(false);

      Promise.all(salesChannelDataPromises)
        .then((response) => {
          const merchantsFromResponse = response.reduce<
            AccountSalesChannelPaginatedResultWithError[]
          >((prevValue, currentValue) => {
            prevValue.push(currentValue);
            return prevValue;
          }, []);

          setSalesChannelData(merchantsFromResponse);
          setSubscriptionInformationLoaded(true);
        })
        .catch(() => {
          setSubscriptionInformationLoaded(true);
          setSalesChannelData([]);
        });
    }
  };

  const updateSubscriptionInformation = () => setSubscriptionInformation();

  const getSalesChannelsForCurrentAccount = async (
    accountId: string,
    salesChannelId: string,
    salesChannelName: string
  ): Promise<AccountSalesChannelPaginatedResultWithError> => {
    try {
      const merchantConnectionDetails = await famAPIClient.getSalesChannelTableData(
        accountId,
        salesChannelId
      )({
        filters: [],
        sorts: [],
        page: 1,
        itemsPerPage: 1000,
      });
      return { ...merchantConnectionDetails, salesChannel: salesChannelName };
    } catch {
      return {
        items: [],
        totalItems: 0,
        isError: true,
      };
    }
  };

  return (
    <Provider
      value={{
        salesChannelData,
        isSubscriptionInformationLoaded,
        updateSubscriptionInformation,
      }}
    >
      {children}
    </Provider>
  );
};
