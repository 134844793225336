import { ColumnManagerOption } from '@teikametrics/tm-design-system/components/ColumnManager';
import { Sort } from 'lib/types/Sort';
import { CurrencyCode } from 'lib/utilities/currency';
import forIn from 'lodash/forIn';
import startsWith from 'lodash/startsWith';
import { DateTime } from 'luxon';
import { Filter } from '../../../../lib/types/Filter';
import { Fw2LocalStorage } from '../../../../lib/utilities/fw2LocalStorage';
import { Fw2SessionStorage } from '../../../../lib/utilities/fw2SessionStorage';
import {
  ColumnManagerOptionWithIsSelected,
  DateRange,
  SkuAdPerformanceApiColumnValue,
  SkuCatalogApiColumnValue,
  SkuOverviewApiColumnValue,
  SKU_API_REQUEST_DATE_FORMAT,
} from '../skuCatalog/types';

export interface UserInfo {
  readonly userId: string;
  readonly accountId: string;
}

export const SKU_STORAGE_KEY = 'sku';

export const removeAllUserSelectionFromLocalStorageSku = (): void => {
  forIn(window.localStorage, (value: string, objKey: string) => {
    if (startsWith(objKey, `${SKU_STORAGE_KEY}`)) {
      Fw2LocalStorage.removeItem(objKey);
    }
  });
};

export const getLocalStorageKeyNameForSku = ({ userId, accountId }: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_`;

const getLocalStorageKeyNameForSkuCatalogMerchants = ({
  userId,
  accountId,
}: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_catalog_merchants`;

const getLocalStorageKeyNameForSkuCatalogCurrency = ({
  userId,
  accountId,
}: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_catalog_currency`;

const getLocalStorageKeyNameForSkuCatalogDateRange = ({
  userId,
  accountId,
}: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_catalog_date_range`;

export const getSkuCatalogMerchantsFromLocalStorage = (
  userInfo: UserInfo
): string[] | null => {
  try {
    const key = getLocalStorageKeyNameForSkuCatalogMerchants(userInfo);
    const selectedMerchants = Fw2LocalStorage.getItem(key);
    return selectedMerchants ? JSON.parse(selectedMerchants) : null;
  } catch (error) {
    return null;
  }
};

export const setSkuCatalogMerchantsInLocalStorage = (
  userInfo: UserInfo,
  merchants: string[]
) =>
  Fw2LocalStorage.setItem(
    getLocalStorageKeyNameForSkuCatalogMerchants(userInfo),
    JSON.stringify(merchants)
  );

export const getSkuCatalogCurrencyFromLocalStorage = (userInfo: UserInfo) =>
  Fw2LocalStorage.getItem(
    getLocalStorageKeyNameForSkuCatalogCurrency(userInfo)
  ) as CurrencyCode;

export const setSkuCatalogCurrencyInLocalStorage = (
  userInfo: UserInfo,
  currency: CurrencyCode
) =>
  Fw2LocalStorage.setItem(
    getLocalStorageKeyNameForSkuCatalogCurrency(userInfo),
    currency
  );

export const getSkuCatalogDateRangeFromLocalStorage = (userInfo: UserInfo) => {
  try {
    const key = getLocalStorageKeyNameForSkuCatalogDateRange(userInfo);

    const formattedDateRangeString = Fw2LocalStorage.getItem(key) || '';
    const formattedDateRange = JSON.parse(formattedDateRangeString);

    const dateRange: DateRange = {
      startDate: DateTime.fromFormat(
        formattedDateRange.startDate,
        SKU_API_REQUEST_DATE_FORMAT
      ),
      endDate: DateTime.fromFormat(
        formattedDateRange.endDate,
        SKU_API_REQUEST_DATE_FORMAT
      ),
    };

    return dateRange;
  } catch (error) {
    return null;
  }
};

export const setSkuCatalogDateRangeInLocalStorage = (
  userInfo: UserInfo,
  dateRange?: DateRange
) => {
  if (dateRange) {
    const formattedDateRange = {
      ...dateRange,
      startDate: dateRange.startDate.toFormat(SKU_API_REQUEST_DATE_FORMAT),
      endDate: dateRange.endDate.toFormat(SKU_API_REQUEST_DATE_FORMAT),
    };

    Fw2LocalStorage.setItem(
      getLocalStorageKeyNameForSkuCatalogDateRange(userInfo),
      JSON.stringify(formattedDateRange)
    );
  }
};

// SKU Catalog - Column manager - config
const getLocalStorageKeyNameForSkuCatalogColumnManagerConfig = ({
  userId,
  accountId,
}: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_catalog_column_manager_config`;

export const getSkuCatalogColumnManagerConfigFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo): ColumnManagerOptionWithIsSelected[] => {
  try {
    const key = getLocalStorageKeyNameForSkuCatalogColumnManagerConfig({
      userId,
      accountId,
    });
    const selectedColumns = Fw2LocalStorage.getItem(key) || JSON.stringify([]);
    return JSON.parse(selectedColumns);
  } catch (error) {
    return [];
  }
};

export const storeSkuCatalogColumnManagerConfigToLocalStorage = ({
  userId,
  accountId,
  values,
}: UserInfo & Readonly<{ values: ColumnManagerOption[] }>) => {
  const key = getLocalStorageKeyNameForSkuCatalogColumnManagerConfig({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(values));
};

// SKU Catalog - Column manager - selected columns
const getLocalStorageKeyNameForSelectedColumnsSkuCatalog = ({
  userId,
  accountId,
}: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_catalog_column_manager_selected_columns`;

export const getSelectedColumnsSkuCatalogFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo): SkuCatalogApiColumnValue[] => {
  try {
    const key = getLocalStorageKeyNameForSelectedColumnsSkuCatalog({
      userId,
      accountId,
    });
    const selectedColumns = Fw2LocalStorage.getItem(key) || JSON.stringify([]);
    return JSON.parse(selectedColumns);
  } catch (error) {
    return [];
  }
};

export const storeSelectedColumnsSkuCatalogToLocalStorage = ({
  userId,
  accountId,
  values,
}: UserInfo & Readonly<{ values: SkuCatalogApiColumnValue[] }>) => {
  const key = getLocalStorageKeyNameForSelectedColumnsSkuCatalog({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(values));
};

export const getSelectedColumnsSkuAdPerformanceLocalStorageKey = ({
  accountId,
  userId,
}: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_ad_performance_selected_columns`;

export const getSelectedColumnsSkuAdPerformanceFromLocalStorage = (
  userInfo: UserInfo
): SkuAdPerformanceApiColumnValue[] | undefined => {
  const key = getSelectedColumnsSkuAdPerformanceLocalStorageKey(userInfo);

  try {
    const selectedColumns = Fw2LocalStorage.getItem(key);
    if (selectedColumns) {
      return JSON.parse(selectedColumns);
    }
  } catch (error) {
    return undefined;
  }
};

export const storeSelectedColumnsAdPerformanceInLocalStorage = (
  userInfo: UserInfo,
  selectedColumns: string[]
) => {
  const key = getSelectedColumnsSkuAdPerformanceLocalStorageKey(userInfo);

  Fw2LocalStorage.setItem(key, JSON.stringify(selectedColumns));
};

export const getSkuCatalogFiltersFromLocalStorage = (
  userId: string,
  accountId: string
): Filter[] => {
  const filtersLocalStorageKey = getSkuCatalogFiltersLocalStorageKey(
    userId,
    accountId
  );
  const filtersFromLocalStorage = Fw2LocalStorage.getItem(
    filtersLocalStorageKey
  );

  try {
    return filtersFromLocalStorage
      ? (JSON.parse(filtersFromLocalStorage) as Filter[])
      : [];
  } catch (e) {
    return [];
  }
};

export const storeSkuCatalogFiltersInLocalStorage = (
  userId: string,
  accountId: string,
  filters: Filter[]
): void => {
  const filtersLocalStorageKey = getSkuCatalogFiltersLocalStorageKey(
    userId,
    accountId
  );
  Fw2LocalStorage.setItem(filtersLocalStorageKey, JSON.stringify(filters));
};

const getSkuCatalogFiltersLocalStorageKey = (
  userId: string,
  accountId: string
) => `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_catalog_filters`;

// SKU Overview - Column manager - selected columns
const getSkuOverviewColumnSelectionStorageKey = ({
  userId,
  accountId,
}: UserInfo) =>
  `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_overview_column_manager_selected_columns`;

export const getSelectedColumnsSkuOverviewFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo): SkuOverviewApiColumnValue[] => {
  try {
    const key = getSkuOverviewColumnSelectionStorageKey({
      userId,
      accountId,
    });
    const selectedColumns = Fw2LocalStorage.getItem(key) || JSON.stringify([]);
    return JSON.parse(selectedColumns);
  } catch (e) {
    return [];
  }
};

export const getSkuAdPerformanceCatalogFiltersFromLocalStorage = (
  userId: string,
  accountId: string
): Filter[] => {
  const filtersLocalStorageKey = getSkuAdPerformanceFiltersLocalStorageKey(
    userId,
    accountId
  );
  const filtersFromLocalStorage = Fw2LocalStorage.getItem(
    filtersLocalStorageKey
  );

  try {
    return filtersFromLocalStorage
      ? (JSON.parse(filtersFromLocalStorage) as Filter[])
      : [];
  } catch (e) {
    return [];
  }
};

export const storeSelectedColumnsSkuOverviewToLocalStorage = ({
  userId,
  accountId,
  values,
}: UserInfo & Readonly<{ values: SkuOverviewApiColumnValue[] }>) => {
  const key = getSkuOverviewColumnSelectionStorageKey({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(values));
};
export const storeSkuAdPerformanceFiltersInLocalStorage = (
  userId: string,
  accountId: string,
  filters: Filter[]
): void => {
  const filtersLocalStorageKey = getSkuAdPerformanceFiltersLocalStorageKey(
    userId,
    accountId
  );
  Fw2LocalStorage.setItem(filtersLocalStorageKey, JSON.stringify(filters));
};

const getSkuAdPerformanceFiltersLocalStorageKey = (
  userId: string,
  accountId: string
) => `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_ad_performance_filters`;

// SKU Sort
const getSkuCatalogSortSessionStorageKey = (
  userId: string,
  accountId: string
) => `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_catalog_sort`;

export const storeSkuCatalogSortToSessionStorage = (
  userId: string,
  accountId: string,
  sort: Sort[]
) => {
  const skuCatalogSortSessionStorageKey = getSkuCatalogSortSessionStorageKey(
    userId,
    accountId
  );
  Fw2SessionStorage.setItem(
    skuCatalogSortSessionStorageKey,
    JSON.stringify(sort)
  );
};

export const getSkuCatalogSortFromSessionStorage = (
  userId: string,
  accountId: string
) => {
  const skuCatalogSortSessionStorageKey = getSkuCatalogSortSessionStorageKey(
    userId,
    accountId
  );
  const sortFromSessionStorage = Fw2SessionStorage.getItem(
    skuCatalogSortSessionStorageKey
  );

  try {
    return sortFromSessionStorage
      ? (JSON.parse(sortFromSessionStorage) as Sort[])
      : [];
  } catch (e) {
    return [];
  }
};

// SKU Overview Filters
export const getSkuOverviewFiltersFromLocalStorage = (
  userId: string,
  accountId: string
): Filter[] => {
  const filtersLocalStorageKey = getSkuOverviewFiltersLocalStorageKey(
    userId,
    accountId
  );
  const filtersFromLocalStorage = Fw2LocalStorage.getItem(
    filtersLocalStorageKey
  );

  try {
    return filtersFromLocalStorage
      ? (JSON.parse(filtersFromLocalStorage) as Filter[])
      : [];
  } catch (e) {
    return [];
  }
};

export const storeSkuOverviewFiltersInLocalStorage = (
  userId: string,
  accountId: string,
  filters: Filter[]
): void => {
  const filtersLocalStorageKey = getSkuOverviewFiltersLocalStorageKey(
    userId,
    accountId
  );
  Fw2LocalStorage.setItem(filtersLocalStorageKey, JSON.stringify(filters));
};

const getSkuOverviewFiltersLocalStorageKey = (
  userId: string,
  accountId: string
) => `${SKU_STORAGE_KEY}_${userId}_${accountId}_sku_overview_filters`;

export const resetLocalStorageForSku = (userId: string, accountId: string) => {
  const localStoragePrefixForSku = getLocalStorageKeyNameForSku({
    userId,
    accountId,
  });

  resetLocalStorageForAPrefix(localStoragePrefixForSku);
};

const resetLocalStorageForAPrefix = (prefix: string) => {
  const keysToBeRemoved: string[] = Object.keys(localStorage).filter(
    (key) => key.substring(0, prefix.length) === prefix
  );

  for (let key of keysToBeRemoved) {
    localStorage.removeItem(key);
  }
};
