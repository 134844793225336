import { useAxios } from '../axiosProvider';
import noop from 'lodash/noop';
import React, { useState, useEffect } from 'react';
import { createContext, useContext } from 'react';
import { createAIPoweredApiClient } from '../../lib/clients/AIPoweredApiClient';
import {
  OnboardingRunData,
  AIOnboardingResponse,
  AIOnboardingStatus,
  AIOnboardingData,
} from '../../lib/types/AIPoweredSharedTypes';
import {
  buildAiOnboardingRequest,
  isAIPoweredUpgradeEligible,
} from '../../lib/utilities/aiPoweredUtilities';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../merchantCountriesProvider/merchantCountriesProvider';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../optimizelyProvider/optimizelyProvider';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContextState, UserContext } from '../userProvider/userProvider';

export type UpgradeContextType = {
  upgradeResponse: AIOnboardingResponse;
  setUpgradeResponse: (upgradeResponse: AIOnboardingResponse) => void;
  onboardingRunData: OnboardingRunData[];
  setOnboardingRunData: (onboardingRunData: OnboardingRunData[]) => void;
  isAIPoweredEligible: boolean;
  setIsAIPoweredEligible: React.Dispatch<React.SetStateAction<boolean>>;
  onboardingDataToUpgrade: AIOnboardingData[];
  setOnboardingDataToUpgrade: (onboardingData: AIOnboardingData[]) => void;
};

export interface UpgradeProviderProps {
  children: JSX.Element;
}

export const UpgradeContext = createContext<UpgradeContextType>({
  upgradeResponse: { elements: [], code: AIOnboardingStatus.DataUnavailable },
  setUpgradeResponse: noop,
  onboardingRunData: [],
  setOnboardingRunData: noop,
  isAIPoweredEligible: false,
  setIsAIPoweredEligible: noop,
  onboardingDataToUpgrade: [],
  setOnboardingDataToUpgrade: noop,
});

UpgradeContext.displayName = 'UpgradeContext';

const { Provider } = UpgradeContext;

export const useUpgradeContext = () => useContext(UpgradeContext);

export const UpgradeProvider: React.FC<UpgradeProviderProps> = ({
  children,
}) => {
  const [upgradeResponse, setUpgradeResponse] = useState<AIOnboardingResponse>({
    elements: [],
    code: AIOnboardingStatus.DataUnavailable,
  });
  const [onboardingRunData, setOnboardingRunData] = useState<
    OnboardingRunData[]
  >([]);
  const [isAIPoweredEligible, setIsAIPoweredEligible] = useState<boolean>(
    false
  );
  const userContext = useContext<UserContextState>(UserContext);
  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const merchantCountries = merchantCountriesContext.merchantCountries!;
  const account = getCurrentAccountFromContext(userContext)!;

  const optimizelyContext = useContext<OptimizelyContextState>(
    OptimizelyContext
  );
  const [onboardingDataToUpgrade, setOnboardingDataToUpgrade] = useState<
    AIOnboardingData[]
  >([]);
  const axios = useAxios();
  useEffect(() => {
    if (!account || !merchantCountries?.length) return;
    const aiPoweredApiClient = createAIPoweredApiClient(
      userContext.userInfo.idToken!,
      axios
    );
    const aiOnboardingRequest = buildAiOnboardingRequest(merchantCountries);

    aiPoweredApiClient
      .getAIOnboardingData(account.id, aiOnboardingRequest)
      .then((response) => {
        setUpgradeResponse(response);
        setIsAIPoweredEligible(
          isAIPoweredUpgradeEligible(userContext, optimizelyContext, response)
        );
      });
  }, [merchantCountries]);

  return (
    <Provider
      value={{
        upgradeResponse,
        setUpgradeResponse,
        onboardingRunData,
        setOnboardingRunData,
        isAIPoweredEligible,
        setIsAIPoweredEligible,
        onboardingDataToUpgrade,
        setOnboardingDataToUpgrade,
      }}
    >
      {children}
    </Provider>
  );
};
