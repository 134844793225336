import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import FWCookie, { PERSISTED_KEYS } from './lib/utilities/fwCookie';
import * as Segment from './lib/utilities/segment';

const Logout: React.FC<RouteComponentProps> = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    FWCookie.deleteCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
    Segment.reset();
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return <></>;
};

export default Logout;
