export const MONETARY_FRACTION_DIGITS = 2;
export const PERCENTAGE_FRACTION_DIGITS = 1;
export const NO_FRACTION_DIGITS = 0;
export enum MerchantCountryCode {
  AE = 'AE',
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IN = 'IN',
  IT = 'IT',
  JP = 'JP',
  KSA = 'KSA',
  MX = 'MX',
  NL = 'NL',
  SG = 'SG',
  UK = 'UK',
  US = 'US',
  GB = 'GB',
}
