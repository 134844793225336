import { IdToken } from '@auth0/auth0-spa-js';
import axios, { AxiosRequestConfig } from 'axios';
import {
  AccountStatusResponse,
  BillingEstimateResponseType,
  BillingInfoResponse,
  ZuoraSignature,
  BillingBalanceResponse,
} from '../types/Billing';
import { DEFAULT_AXIOS_CONFIG } from './index';
import { buildUrlWithQueryParams } from '../utilities/buildUrlUtilities';

const REACT_APP_BILLING_HOST = process.env.REACT_APP_BILLING_HOST;

export const PATHS = Object.freeze({
  ACCOUNT_STATUS: (accountId: string) => `billing/${accountId}`,
  ZUORA_SIGNATURE: 'billing/zuora-signature',
  BILLING_CONFIGURATION: (accountId: string) => `billing/${accountId}/zuora`,
  BILLING_ESTIMATE: (accountId: string) => `billing/${accountId}/estimate`,
  BILLING_BALANCE: (accountId: string) => `billing/${accountId}/balance`,
});

export interface BillingApiClient {
  readonly getZuoraSignature: () => Promise<ZuoraSignature>;
  readonly createZuoraAccount: (
    accountId: string,
    refId: string,
    billingContact: { firstName: string; lastName: string; email: string }
  ) => Promise<any>;
  getBillingEstimate: (
    accountId: string,
    startDate: string,
    endDate: string
  ) => Promise<BillingEstimateResponseType>;
  getBillingInfo: (accountId: string) => Promise<BillingInfoResponse>;
  getAccountStatus: (accountId: string) => Promise<AccountStatusResponse>;
  getBillingBalance: (accountId: string) => Promise<BillingBalanceResponse>;
}

export const createBillingApiClient = (token: IdToken): BillingApiClient => {
  const config: AxiosRequestConfig = {
    ...DEFAULT_AXIOS_CONFIG,
    baseURL: REACT_APP_BILLING_HOST,
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  };

  const getZuoraSignature = () => {
    return axios
      .get<ZuoraSignature>(PATHS.ZUORA_SIGNATURE, config)
      .then((response) => response.data);
  };

  const createZuoraAccount = (
    accountId: string,
    refId: string,
    billingContact: {
      firstName: string;
      lastName: string;
      email: string;
    }
  ) => {
    return axios
      .post<void>(
        PATHS.BILLING_CONFIGURATION(accountId),
        { paymentRef: refId, contact: billingContact },
        config
      )
      .then((response) => response.data);
  };

  const getBillingInfo = (accountId: string) =>
    axios
      .get<BillingInfoResponse>(PATHS.BILLING_CONFIGURATION(accountId), config)
      .then((response) => response.data)
      .catch((err) => err);

  const getAccountStatus = (accountId: string) =>
    axios
      .get<AccountStatusResponse>(PATHS.ACCOUNT_STATUS(accountId), config)
      .then((response) => response.data);

  const getBillingEstimate = (
    accountId: string,
    startDate: string,
    endDate: string
  ): Promise<BillingEstimateResponseType> => {
    const endpoint = buildUrlWithQueryParams(
      PATHS.BILLING_ESTIMATE(accountId),
      {
        startDate,
        endDate,
      }
    );

    return axios.get(endpoint, { ...config }).then((response) => response.data);
  };

  const getBillingBalance = (accountId: string) =>
    axios
      .get<BillingBalanceResponse>(PATHS.BILLING_BALANCE(accountId), config)
      .then((response) => response.data);

  return {
    getZuoraSignature,
    createZuoraAccount,
    getBillingEstimate,
    getBillingInfo,
    getAccountStatus,
    getBillingBalance,
  };
};
