import React from 'react';
import classNames from 'classnames';
import { UserIcon } from '@teikametrics/tm-design-system/components/UserIcon';
import { LogomarkIcon } from '@teikametrics/tm-design-system/img/branding';

export interface SurveySteps {
  total: number;
  current: number;
}
export interface SurveyHeaderProps {
  readonly surveySteps?: SurveySteps;
  readonly userDetails?: {
    firstName: string;
    lastName: string;
    companyName: string;
  };
}

export const SurveyHeader: React.FC<SurveyHeaderProps> = ({
  surveySteps,
  userDetails,
}) => {
  return (
    <div
      className={`relative h-64 px-24 bg-white
        box-content flex items-center justify-between
        border-b border-grey-200 shadow-sm
      `}
    >
      <div className="flex items-center">
        <LogomarkIcon />
      </div>

      {surveySteps && (
        <div className="flex items-center">
          {Array(surveySteps.total)
            .fill(0)
            .map((_, stepIdx) => (
              <div
                key={stepIdx}
                className={classNames('w-surveyStep h-8 rounded mx-8', {
                  'bg-grey-100': stepIdx >= surveySteps.current,
                  'bg-purple-200': stepIdx < surveySteps.current,
                })}
              />
            ))}
        </div>
      )}

      <div>
        {userDetails && (
          <UserIcon
            firstName={userDetails.firstName}
            lastName={userDetails.lastName}
          />
        )}
      </div>
    </div>
  );
};
