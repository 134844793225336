import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import {
  Typography,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system/components/Typography';
import {
  EyeIcon,
  LightbulbIcon,
  LightningIcon,
  ArrowRightIcon,
} from '@teikametrics/tm-design-system/img/icons';
import I18nKey from '../../../lib/types/I18nKey';
import { UpgradeStatus } from '../Upgrade';

interface HeaderProps {
  readonly status: UpgradeStatus;
  readonly count?: number;
}

interface HeaderIconTextProps {
  readonly icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  readonly text: string;
  readonly active: boolean;
  readonly displayNumber?: string;
}

const HeaderIconText: React.FC<HeaderIconTextProps> = ({
  icon,
  active,
  text,
  displayNumber,
}) => {
  const activeClass = active ? 'text-purple-500' : 'text-grey-500';
  return (
    <div className="flex flex-row justify-center items-center">
      <Icon size={IconSize.Medium} svg={icon} className={activeClass} />
      <Typography
        size={TypographySize.sm}
        weight={TypographyWeight.semibold}
        className={`uppercase pl-12 ${activeClass}`}
      >
        {displayNumber}.&nbsp;
        <FormattedMessage id={text} />
      </Typography>
    </div>
  );
};

export const Header: React.FC<HeaderProps> = ({ status, count = 0 }) => {
  const intl = useIntl();
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-center gap-24">
        <HeaderIconText
          icon={LightbulbIcon}
          text={
            I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ADVERTISED_TAG
          }
          displayNumber="1"
          active={status === UpgradeStatus.Opportunity}
        />
        <Icon
          size={IconSize.Medium}
          svg={ArrowRightIcon}
          className="text-grey-500"
        />
        <HeaderIconText
          icon={EyeIcon}
          text={I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_TAG}
          displayNumber="2"
          active={status === UpgradeStatus.Preview}
        />
        <Icon
          size={IconSize.Medium}
          svg={ArrowRightIcon}
          className="text-grey-500"
        />
        <HeaderIconText
          icon={LightningIcon}
          displayNumber="3"
          text={
            I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PRODUCTS
          }
          active={status === UpgradeStatus.Submit}
        />
      </div>
      {status !== UpgradeStatus.Submit && (
        <div className="text-2xl leading-tight font-semibold mt-16">
          <FormattedMessage
            id={
              status === UpgradeStatus.Opportunity
                ? I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ADVERTISED_PRODUCT
                : I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AFTER_AI
            }
            values={{
              products: (
                <span className="text-purple-500">
                  {intl.formatNumber(count)}
                </span>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};
