import React from 'react';
import { useIntl } from 'react-intl';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import { MONETARY_FRACTION_DIGITS } from '../../../lib/types/CommonSharedTypes';
import { getCountryFlagIcon } from '../../../lib/utilities/countryFlags';
import { OnboardingData } from '../../../lib/types/AIPoweredSharedTypes';
import { ReactComponent as ArrowRightGradientIcon } from '../img/ArrowRightGradient.svg';

interface SplitCardPreviewProps {
  readonly onboardingData: OnboardingData;
  readonly countryCode?: string;
  readonly merchantCountryName?: string;
}

export const SplitCardPreview: React.FC<SplitCardPreviewProps> = ({
  onboardingData,
  countryCode,
  merchantCountryName,
}) => {
  const intl = useIntl();
  const countryFlagIcon = getCountryFlagIcon(countryCode || '');
  return (
    <div className="text-base text-grey-900 flex items-center rounded mb-8 border border-solid border-grey-200">
      <div className="w-1/3 flex items-center bg-white border-r-1 border-solid border-grey-200 py-12 pl-12 pr-48 rounded-l">
        {countryFlagIcon != null && (
          <Icon svg={countryFlagIcon} size={IconSize.Large} />
        )}
        <span className="ml-12">{merchantCountryName}</span>
      </div>
      <div className="relative flex justify-center items-center">
        <div className="flex justify-center items-center bg-white border border-grey-200 border-solid h-24 w-48 rounded-xl absolute">
          <Icon svg={ArrowRightGradientIcon} size={IconSize.Large} />
        </div>
      </div>
      <div className="w-2/3 flex items-center bg-purple-50 py-12 pl-48 pr-12 rounded-r">
        <div className="flex justify-center w-1/4">
          <span className="flex w-5/12 font-medium tabular-nums justify-end">
            {onboardingData?.advertisedProducts
              ? intl.formatNumber(onboardingData?.advertisedProducts)
              : '-'}
          </span>
        </div>
        <span className="w-1/4 flex justify-end tabular-nums">
          {onboardingData?.campaigns?.count
            ? intl.formatNumber(onboardingData?.campaigns?.count)
            : '-'}
        </span>
        <span className="w-1/4 flex justify-end tabular-nums">
          {onboardingData?.targets?.keywords?.count
            ? intl.formatNumber(onboardingData?.targets?.keywords?.count)
            : '-'}
        </span>
        <span className="w-1/4 flex justify-end tabular-nums">
          {onboardingData?.dailyBudget?.value
            ? intl.formatNumber(
                parseFloat(onboardingData?.dailyBudget?.value),
                {
                  style: 'currency',
                  currency: onboardingData?.dailyBudget?.currency,
                  minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                  maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                }
              )
            : '-'}
        </span>
      </div>
    </div>
  );
};
