import { COLUMNS_WITH_SINGLE_DECIMAL_FILTER } from './utilities.constants';
import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import moment, { Moment } from 'moment';
import { StringMap } from '../types';
import {
  ContainsFilter,
  DoesNotContainFilter,
  EqualToFilter,
  Filter,
  FilterOps,
  GreaterThanFilter,
  GreaterThanOrEqualFilter,
  InFilter,
  IsFilter,
  IsNotFilter,
  LessThanFilter,
  LessThanOrEqualFilter,
  LikeFilter,
  NotInFilter,
  SingleSelectOption,
} from '../types/Filter';
import { SortOrder } from '../types/Sort';

export const DATE_QUERY_STRING_FORMAT = 'YYYY-MM-DD';
export const AMPERSAND_ESCAPE_SEQUENCE = '|||';

export type compareFunction<RowData> = (a: RowData, b: RowData) => number;
export type CustomSortMap<RowData> = StringMap<compareFunction<RowData>>;

export const likeFilter = (field: string, value: string): LikeFilter => ({
  op: FilterOps.like,
  field,
  value,
});

export const isLikeFilter = (filter: Filter): filter is LikeFilter =>
  filter.op === FilterOps.like;

export const greaterThanOrEqualFilter = (
  field: string,
  value: number | Moment
): GreaterThanOrEqualFilter => ({
  op: FilterOps.gte,
  field,
  value,
});

export const lessThanOrEqualFilter = (
  field: string,
  value: number | Moment
): LessThanOrEqualFilter => ({
  op: FilterOps.lte,
  field,
  value,
});

export const greaterThanFilter = (
  field: string,
  value: number | Moment
): GreaterThanFilter => ({
  op: FilterOps.gt,
  field,
  value,
});

export const lessThanFilter = (
  field: string,
  value: number | Moment
): LessThanFilter => ({
  op: FilterOps.lt,
  field,
  value,
});

export const equalToFilter = (
  field: string,
  value: number | string | Moment
): EqualToFilter => ({
  op: FilterOps.eq,
  field,
  value,
});

export const singleSelectFilter = (
  field: string,
  value: SingleSelectOption
): IsFilter => ({
  op: FilterOps.is,
  field,
  value,
});

export const isNotFilter = (field: string, value: string): IsNotFilter => ({
  op: FilterOps.isNot,
  field,
  value,
});

export const containsFilter = (
  field: string,
  value: string
): ContainsFilter => ({
  op: FilterOps.like,
  field,
  value,
});

export const doesNotContainFilter = (
  field: string,
  value: string
): DoesNotContainFilter => ({
  op: FilterOps.notLike,
  field,
  value,
});

export const inFilter = (field: string, value: string[]): InFilter => ({
  op: FilterOps.in,
  field,
  value,
});

export const notInFilter = (field: string, value: string[]): NotInFilter => ({
  op: FilterOps.notIn,
  field,
  value,
});

export const getValueWithFractions = (
  value: number,
  minDecimalDigits: number = 2
) => {
  const digitsAfterDecimal = value.toString().split('.')[1];
  const digitsAfterDecimalLength = digitsAfterDecimal
    ? digitsAfterDecimal.length
    : 0;
  if (digitsAfterDecimalLength < minDecimalDigits) {
    return Number(value).toFixed(minDecimalDigits);
  } else {
    return value.toString();
  }
};

const filterToFilterQueryValue = (filter: Filter): string => {
  switch (filter.op) {
    case FilterOps.like:
    case FilterOps.notLike:
    case FilterOps.gte:
    case FilterOps.lte:
    case FilterOps.gt:
    case FilterOps.lt:
    case FilterOps.is:
    case FilterOps.eq:
    case FilterOps.isNot:
      if (moment.isMoment(filter.value)) {
        return filter.value.format(DATE_QUERY_STRING_FORMAT);
      } else if (
        isNumber(filter.value) &&
        COLUMNS_WITH_SINGLE_DECIMAL_FILTER.includes(filter.field)
      ) {
        return `${getValueWithFractions(filter.value, 1)}`;
      } else if (isNumber(filter.value)) {
        return `${getValueWithFractions(filter.value)}`;
      } else {
        return `${filter.value}`;
      }
    case FilterOps.in:
    case FilterOps.notIn:
      return filter.value.join(',');
  }
};

const escapeAmpersandsInFilter = (filter: string): string =>
  filter
    .replace(AMPERSAND_ESCAPE_SEQUENCE, '') // Don't filter for a user-written escape
    .replace(/&/g, AMPERSAND_ESCAPE_SEQUENCE);

const filterToQueryString = (filter: Filter): string =>
  `${filter.field}${filter.op}${escapeAmpersandsInFilter(
    filterToFilterQueryValue(filter)
  )}`;

export const filtersToQueryString = (filters: Filter[]): string =>
  filters.map(filterToQueryString).join('&');

export function getSortedRowData<ColumnIds extends string, RowData>({
  columnId,
  direction,
  rowData,
  customSortMap,
}: {
  columnId: ColumnIds;
  direction: SortOrder;
  rowData: RowData[];
  customSortMap?: CustomSortMap<RowData>;
}) {
  const potentialCustomSort = customSortMap
    ? customSortMap[columnId]
    : undefined;
  if (potentialCustomSort) {
    return direction === SortOrder.Asc
      ? rowData.sort(potentialCustomSort)
      : rowData.sort(potentialCustomSort).reverse();
  } else {
    return direction === SortOrder.Asc
      ? sortBy(rowData, columnId)
      : sortBy(rowData, columnId).reverse();
  }
}
