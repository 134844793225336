import React from 'react';
import {
  surveyQuestionsSteps,
  SurveyQuestionStep,
  QuestionnaireProps,
} from '../SurveyQuestions';
import { MultiSelectQuestionnaireCard } from './MultiSelectQuestionnaireCard';
import { useIntl } from 'react-intl';

export const SellProductsQuestionnaire: React.FC<QuestionnaireProps> = ({
  step,
  nextStep,
  surveyAnswers,
  saveSurveyAnswers,
}) => {
  const intl = useIntl();
  const questions: SurveyQuestionStep = surveyQuestionsSteps[step];
  const formattedTitle = intl.formatMessage({ id: questions.title });

  return (
    <>
      <MultiSelectQuestionnaireCard
        step={step}
        title={formattedTitle}
        onHandleStep={nextStep}
        questionsStep={questions}
        surveyAnswers={surveyAnswers}
        saveSurveyAnswers={saveSurveyAnswers}
        dataTestId={'sellProductsQuestionnaire'}
      />
    </>
  );
};
