import React, { useContext, useEffect, useState } from 'react';
import I18nKey from '../../lib/types/I18nKey';
import { useIntl } from 'react-intl';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import { TextLink } from '@teikametrics/tm-design-system/components/TextLink';
import { ArrowLeftIcon } from '@teikametrics/tm-design-system/img/icons';
import { ZuoraSignature } from '../../lib/types/Billing';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import { createBillingApiClient } from '../../lib/clients/BillingApiClient';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import {
  removeZuoraIframe,
  renderZuoraIframe,
  ZuoraResponseError,
} from '../../lib/utilities/zuora';
import { PaginatedRequest } from '../../lib/clients/types';
import { Role } from '../../lib/types/Fam';
import { SortOrder } from '../../lib/types/Sort';
import {
  PaletteColor,
  Pill,
} from '@teikametrics/tm-design-system/components/Pill';
import { Spinner } from '@teikametrics/tm-design-system/components/Spinner';
import ReactModal from 'react-modal';
import isUndefined from 'lodash/isUndefined';

export interface PaymentEntryModalProps {
  readonly backText: I18nKey;
  readonly headerText: I18nKey;
  readonly bodyText: I18nKey;
  readonly pillText?: I18nKey;
  readonly noteText?: I18nKey;
  readonly onBack: () => void;
  readonly onSavePayment: () => void;
  readonly showGoBackOption?: boolean;
}

export const PaymentEntryModal: React.FC<PaymentEntryModalProps> = ({
  backText,
  headerText,
  bodyText,
  pillText,
  noteText,
  onBack,
  onSavePayment,
  showGoBackOption = true,
}) => {
  const [zuoraDivId, setZuoraDivId] = useState('');
  const [refId, setRefId] = useState<string>('');
  const [loadingIframe, setLoadingIframe] = useState<boolean>(false);
  const [zuoraSignature, setZuoraSignature] = useState<ZuoraSignature>();
  const [zuoraSuccess, setZuoraSuccess] = useState<boolean>(false);
  const [zuoraError, setZuoraError] = useState<ZuoraResponseError>();
  const [showErrorText, setShowErrorText] = useState<boolean>(false);

  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const famClient = createFAMApiClient(userContext.userInfo.idToken!);
  const billingClient = createBillingApiClient(userContext.userInfo.idToken!);

  const intl = useIntl();
  const [BACK, TITLE, BODY, PILL, NOTE, ERROR] = [
    backText,
    headerText,
    bodyText,
    pillText,
    noteText,
    I18nKey.ERROR_MESSAGE,
  ].map((id) => intl.formatMessage({ id }));

  const goBack = () => {
    removeZuoraIframe();
    onBack();
  };

  useEffect(() => {
    onAddPayment();
  }, []);

  const onAddPayment = async () => {
    setZuoraDivId('zuora_payment');
    setLoadingIframe(true);
    try {
      const signature = await billingClient.getZuoraSignature();
      setZuoraSignature(signature);
    } catch (err) {
      setLoadingIframe(false);
    }
  };

  const onIframeLoad = () => {
    setLoadingIframe(false);
  };

  useEffect(() => {
    if (zuoraSignature) {
      renderZuoraIframe(
        zuoraSignature,
        onIframeLoad,
        setZuoraSuccess,
        setRefId,
        setZuoraError
      );
    }
  }, [zuoraSignature]);

  useEffect(() => {
    if (!isUndefined(zuoraError) && !zuoraError?.success) {
      handlePaymentError();
    }
    if (zuoraSuccess && refId) {
      createZuoraAccount().then((success) => {
        if (success) {
          onSavePayment();
        } else {
          handlePaymentError();
        }
      });
    }
  }, [zuoraSuccess, refId, zuoraError]);

  const handlePaymentError = () => {
    setLoadingIframe(false);
    setShowErrorText(true);
    setZuoraSuccess(false);
    removeZuoraIframe();
    onAddPayment();
  };

  const createZuoraAccount = async () => {
    setShowErrorText(false);
    setLoadingIframe(true);
    const request: PaginatedRequest = {
      itemsPerPage: 100,
      page: 1,
      sorts: [{ column: 'role', direction: SortOrder.Asc }],
      filters: [],
    };
    const users = await famClient.getUsers(account.id)(request);
    const billingContact = users.items.find(
      (item) => item.role.role === Role.ACCOUNT_OWNER
    );
    return billingClient
      .createZuoraAccount(account.id, refId, {
        firstName: billingContact?.user.firstName!,
        lastName: billingContact?.user.lastName!,
        email: billingContact?.user.email!,
      })
      .then(() => true)
      .catch(() => false);
  };

  return (
    <ReactModal
      isOpen={true}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 outline-none"
      overlayClassName="fixed inset-0 z-50 bg-grey-800 bg-opacity-40"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div
        className="bg-white flex flex-col p-24"
        style={{ width: 420, minHeight: 359, maxHeight: 600 }}
      >
        {showGoBackOption && (
          <div className="cursor-pointer mb-24" role="button" onClick={goBack}>
            <Icon
              svg={ArrowLeftIcon}
              className="text-purple-500 mr-8"
              size={IconSize.Small}
            />
            <TextLink textLabel={BACK} />
          </div>
        )}
        <div className="text-xl font-semibold text-grey-900 leading-none">
          <span className="pr-8">{TITLE}</span>
          {pillText && (
            <Pill
              color={PaletteColor.rainbow}
              text={PILL}
              isHoverable={false}
            />
          )}
        </div>
        <div className="text-grey-600 text-base leading-5 mt-8 mb-12">
          {BODY}
        </div>
        {showErrorText && (
          <div className="font-semibold text-base my-4">{ERROR}</div>
        )}
        {loadingIframe && <Spinner centerAlign={false} />}
        {!zuoraSuccess && (
          <div
            id={zuoraDivId}
            className="fs-exclude max-h-360 overflow-y-scroll w-full scrollbar scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full"
          />
        )}
        <div className="mt-16 h-32 font-medium flex items-center justify-center border-solid border border-grey-200 bg-grey-50 w-full text-sm text-grey-700 leading-none">
          {NOTE}
        </div>
      </div>
    </ReactModal>
  );
};
