import noop from 'lodash/noop';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SalesChannelContext } from '../../containers/SalesChannelProvider';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { MerchantCountry } from '../../lib/types/Fam';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContext, UserContextState } from '../userProvider/userProvider';

export interface MerchantCountriesContextState {
  readonly merchantCountries?: MerchantCountry[];
  readonly clearMerchantCountries: () => void;
}

export interface MerchantCountriesProviderProps {
  children: JSX.Element;
}

const initialState = {
  merchantCountries: [],
  clearMerchantCountries: noop,
};

const MerchantCountriesContext = createContext<MerchantCountriesContextState>(
  initialState
);
MerchantCountriesContext.displayName = 'MerchantCountriesContext';

const { Provider } = MerchantCountriesContext;

const MerchantCountriesProvider: React.FC<MerchantCountriesProviderProps> = ({
  children,
}) => {
  const [merchantCountries, setMerchantCountries] = useState<
    MerchantCountry[]
  >();
  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);
  const salesChannelContext = useContext(SalesChannelContext);

  const { salesChannels } = salesChannelContext;

  const salesChannelsIds = useMemo(() => {
    return salesChannels.map(({ id }) => id);
  }, [salesChannels]);

  const getMerchantCountries = async () => {
    const merchantCountriesResponse = await famApiClient.getMerchantCountries(
      account.id,
      salesChannelsIds
    );
    setMerchantCountries(merchantCountriesResponse.merchantCountryInfoItems);
  };

  const clearMerchantCountries = () => {
    setMerchantCountries([]);
  };

  useEffect(() => {
    if (!userContext.userInfo.idToken) return;
    salesChannelsIds?.length && getMerchantCountries();
  }, [userContext.userInfo.idToken, salesChannelsIds, account.id]);

  return (
    <Provider value={{ merchantCountries, clearMerchantCountries }}>
      {children}
    </Provider>
  );
};

export { MerchantCountriesContext, MerchantCountriesProvider };
