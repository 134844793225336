export enum NavPathsTypes {
  GetStarted = 'GetStarted',
  Survey = 'Survey',
  Users = 'Users',
  UserEdit = 'UserEdit',
  InviteUser = 'InviteUser',
  Company = 'Company',
  Billing = 'Billing',
  UserProfile = 'UserProfile',
  MarketIntelligenceDashboard = 'MarketIntelligenceDashboard',
  MarketIntelligenceTrackedTerms = 'MarketIntelligenceTrackedTerms',
  SalesChannels = 'SalesChannels',
  AoWalmartSponsoredProductsCampaigns = 'AoWalmartSponsoredProductsCampaigns',
  AoWalmartSponsoredProductsAdGroup = 'AoWalmartSponsoredProductsAdGroup',
  AoWalmartSponsoredProductsProductAd = 'AoWalmartSponsoredProductsProductAd',
  AoWalmartSponsoredProductsTarget = 'AoWalmartSponsoredProductsTarget',
  AoAmazonSponsoredProductsCampaigns = 'AoAmazonSponsoredProductsCampaigns',
  AoAmazonSponsoredProductsAdGroup = 'AoAmazonSponsoredProductsAdGroup',
  AoAmazonSponsoredProductsProductAds = 'AoAmazonSponsoredProductsProductAds',
  AoAmazonSponsoredProductsTarget = 'AoAmazonSponsoredProductsTarget',
  AoAmazonSponsoredProductsKeywordTargets = 'AoAmazonSponsoredProductsKeywrodTargets',
  AoAmazonSponsoredBrandsProductsTarget = 'AoAmazonSponsoredBrandsProductsTarget',
  AoAmazonSponsoredBrandsCampaigns = 'AoAmazonSponsoredBrandsCampaigns',
  AoAmazonSponsoredBrandsKeywordTargets = 'AoAmazonSponsoredBrandsKeywordTargets',
  AoAmazonSponsoredDisplayCampaigns = 'AoAmazonSponsoredDisplayCampaigns',
  AoAmazonSponsoredDisplayTargets = 'AoAmazonSponsoredDisplayTargets',
  AoAmazonSponsoredDisplayProductAds = 'AoAmazonSponsoredDisplayProductAds',
  AdvertisingManager = 'AdvertisingManager',
  AOAdsManager = 'AOAdsManager',
  AoAmazonSponsoredProductsProductsTarget = 'AoAmazonSponsoredProductsProductsTarget',
  IOSku = 'IOSku',
  IOSkuDetails = 'IOSkuDetails',
  Upgrade = 'Upgrade',
  AIPowered = 'AIPowered',
  AIPoweredProductSettings = 'AIPoweredProductSettings',
  AIPoweredProductSettingsV2 = 'AIPoweredProductSettingsV2',
  AIProductGroupSettings = 'AIProductGroupSettings',
  ManageAIPoweredProductSettings = 'ManageAIPoweredProductSettings',
  Compass = 'Compass',
  Logout = 'Logout',
  SwitchAccount = 'SwitchAccount',
}

export const NavPaths: Record<NavPathsTypes, string> = {
  [NavPathsTypes.GetStarted]: '/get-started',
  [NavPathsTypes.Survey]: '/survey',
  [NavPathsTypes.Users]: '/account/users',
  [NavPathsTypes.UserEdit]: '/account/users/edit',
  [NavPathsTypes.InviteUser]: '/account/users/invite',
  [NavPathsTypes.Company]: '/account/company',
  [NavPathsTypes.Billing]: '/account/billing',
  [NavPathsTypes.UserProfile]: '/user-profile',
  [NavPathsTypes.SwitchAccount]: '/switch_account',
  [NavPathsTypes.Logout]: '/logout',
  [NavPathsTypes.MarketIntelligenceDashboard]: '/market-intelligence',
  [NavPathsTypes.MarketIntelligenceTrackedTerms]:
    '/market-intelligence/tracked-terms',
  [NavPathsTypes.SalesChannels]: '/account/sales-channels',
  [NavPathsTypes.AdvertisingManager]: 'ads-manager/*',
  [NavPathsTypes.AOAdsManager]: '/ads-optimization/ads-manager',
  [NavPathsTypes.AoWalmartSponsoredProductsCampaigns]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/campaigns',
  [NavPathsTypes.AoWalmartSponsoredProductsAdGroup]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/ad-groups',
  [NavPathsTypes.AoWalmartSponsoredProductsProductAd]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/product-ads',
  [NavPathsTypes.AoWalmartSponsoredProductsTarget]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets',
  [NavPathsTypes.AoAmazonSponsoredProductsCampaigns]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/campaigns',
  [NavPathsTypes.AoAmazonSponsoredProductsAdGroup]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/ad-groups',
  [NavPathsTypes.AoAmazonSponsoredProductsProductAds]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/product-ads',
  [NavPathsTypes.AoAmazonSponsoredProductsTarget]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  [NavPathsTypes.AoAmazonSponsoredBrandsProductsTarget]:
    '/ads-optimization/ads-manager/amazon/sponsored-brands/product-targets',
  [NavPathsTypes.AoAmazonSponsoredProductsProductsTarget]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/product-targets',
  [NavPathsTypes.AoAmazonSponsoredProductsKeywordTargets]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  [NavPathsTypes.AoAmazonSponsoredBrandsCampaigns]:
    '/ads-optimization/ads-manager/amazon/sponsored-brands/campaigns',
  [NavPathsTypes.AoAmazonSponsoredBrandsKeywordTargets]:
    '/ads-optimization/ads-manager/amazon/sponsored-brands/keyword-targets',
  [NavPathsTypes.AoAmazonSponsoredDisplayTargets]:
    '/ads-optimization/ads-manager/amazon/sponsored-display/targets',
  [NavPathsTypes.AoAmazonSponsoredDisplayCampaigns]:
    '/ads-optimization/ads-manager/amazon/sponsored-display/campaigns',
  [NavPathsTypes.AoAmazonSponsoredDisplayProductAds]:
    '/ads-optimization/ads-manager/amazon/sponsored-display/product-ads',
  [NavPathsTypes.IOSku]: '/products/sku',
  [NavPathsTypes.IOSkuDetails]: '/products/sku/*',
  [NavPathsTypes.Upgrade]: '/upgrade',
  [NavPathsTypes.AIPowered]: 'ai-powered/*',
  [NavPathsTypes.AIPoweredProductSettings]: '/ai-powered/product-settings/',
  [NavPathsTypes.AIPoweredProductSettingsV2]:
    '/ai-powered/v2/product-settings/',
  [NavPathsTypes.AIProductGroupSettings]:
    '/ai-powered/v2/product-settings/product-group',
  [NavPathsTypes.ManageAIPoweredProductSettings]:
    '/ai-powered/product-settings/manage',
  [NavPathsTypes.Compass]: '/compass',
};

export const PathsWithBackButton = [
  NavPathsTypes.UserEdit,
  NavPathsTypes.InviteUser,
  NavPathsTypes.ManageAIPoweredProductSettings,
].map((path) => NavPaths[path]);
