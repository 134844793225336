import { SvgIcon } from '@teikametrics/tm-design-system/components/Icon';
import {
  AfghanistanAfIcon,
  AlbaniaAlIcon,
  AlgeriaDzIcon,
  AndorraAdIcon,
  AngolaAoIcon,
  AntiguaBarbudaAgIcon,
  ArgentinaArIcon,
  ArmeniaAmIcon,
  AustraliaAuIcon,
  AustriaAtIcon,
  AzerbaijanAzIcon,
  BahamasBsIcon,
  BahrainBhIcon,
  BangladeshBdIcon,
  BarbadosBbIcon,
  BelarusByIcon,
  BelgiumBeIcon,
  BelizeBzIcon,
  BeninBjIcon,
  BhutanBtIcon,
  BoliviaBoIcon,
  BosniaAndHerzegovinaBaIcon,
  BotswanaBwIcon,
  BrazilBrIcon,
  BruneiBnIcon,
  BulgraiaBgIcon,
  BurkinaFasoBfIcon,
  BurundiBiIcon,
  CambodiaKhIcon,
  CameroonCmIcon,
  CanadaCaIcon,
  CapeVerdeCvIcon,
  CentralAfricanRepublicCfIcon,
  ChadTdIcon,
  ChileClIcon,
  ChinaCnIcon,
  ColumbiaCoIcon,
  ComorosKmIcon,
  CostaRicaCrIcon,
  CroatiaHrIcon,
  CubaCuIcon,
  CyprusCyIcon,
  CzechRepublicCzIcon,
  DemocraticCongoCdIcon,
  DenmarkDkIcon,
  DjiboutiDjIcon,
  DominicaDmIcon,
  DominicanRepublicDoIcon,
  EcuadorEcIcon,
  EgyptEgIcon,
  ElSalvadorSvIcon,
  EquatorialGuineaGqIcon,
  EritreaErIcon,
  EstoniaEeIcon,
  EswatiniSzIcon,
  EthiopiaEtIcon,
  EuropeIcon,
  FijiFjIcon,
  FinlandFiIcon,
  FranceFrIcon,
  GabonGaIcon,
  GambiaGmIcon,
  GeorgiaGeIcon,
  GermanyDeIcon,
  GhanaGhIcon,
  GreeceGrIcon,
  GrenadaGdIcon,
  GuatemalaGtIcon,
  GuineaGnIcon,
  GuneaBissauGwIcon,
  GuyanaGyIcon,
  HaitiHtIcon,
  HolySeeVaIcon,
  HondurasHnIcon,
  HongKongHkIcon,
  HungaryHuIcon,
  IcelandIsIcon,
  IndiaInIcon,
  IndonesiaIdIcon,
  IranIrIcon,
  IraqIqIcon,
  IrelandIeIcon,
  IsraelIlIcon,
  ItalyItIcon,
  IvoryCoastCiIcon,
  JamaicaJmIcon,
  JapanJpIcon,
  JordanJoIcon,
  KazakhstanKzIcon,
  KenyaKeIcon,
  KiribatiKiIcon,
  KuwaitKwIcon,
  KyrgyzstanKgIcon,
  LaosLaIcon,
  LatviaLvIcon,
  LebanonLbIcon,
  LesothoLsIcon,
  LiberiaLrIcon,
  LibyaLyIcon,
  LiechtensteinLiIcon,
  LithuaniaLtIcon,
  LuxembourgLuIcon,
  MacauMoIcon,
  MadagascarMgIcon,
  MalawiMwIcon,
  MalaysiaMyIcon,
  MaldivesMvIcon,
  MaliMlIcon,
  MaltaMtIcon,
  MarshallIslandsMhIcon,
  MauritaniaMrIcon,
  MauritusMuIcon,
  MexicoMxIcon,
  MicronesiaFmIcon,
  MoldovaMdIcon,
  MonacoMcIcon,
  MongoliaMnIcon,
  MontenegroMeIcon,
  MoroccoMaIcon,
  MozambiqueMzIcon,
  MyanmarMmIcon,
  NamibiaNaIcon,
  NauruNrIcon,
  NepalNpIcon,
  NetherlandsNlIcon,
  NewZealandNzIcon,
  NicaraguaNiIcon,
  NigeriaNgIcon,
  NigerNeIcon,
  NorthKoraKpIcon,
  NorthMacedoniaMkIcon,
  NorwayNoIcon,
  OmanOmIcon,
  PakistanPkIcon,
  PalauPwIcon,
  PalestinePsIcon,
  PanamaPaIcon,
  PapuaNewGuineaPgIcon,
  ParaguayPyIcon,
  PeruPeIcon,
  PhilippinesPhIcon,
  PolandPlIcon,
  PortugalPtIcon,
  QatarQaIcon,
  RepublicOfCongoCgIcon,
  RomaniaRoIcon,
  RussiaRuIcon,
  RwandaRwIcon,
  SaintKittsNevisKnIcon,
  SaintLuciaLcIcon,
  SaintVincentTheGrenadinesVcIcon,
  SamoaWsIcon,
  SanMarinoSmIcon,
  SaoTomePrincipeStIcon,
  SaudiArabiaSaIcon,
  SenegalSnIcon,
  SerbiaRsIcon,
  SeychellesScIcon,
  SierraLeoneSlIcon,
  SingaporeSgIcon,
  SlovakiaSkIcon,
  SloveniaSiIcon,
  SolomonIslandsSbIcon,
  SomaliaSoIcon,
  SouthAfricaZaIcon,
  SouthKoreaKrIcon,
  SouthSudanSsIcon,
  SpainEsIcon,
  SriLankaLkIcon,
  SudanSdIcon,
  SurinameSrIcon,
  SwedenSeIcon,
  SwitzerlandChIcon,
  SyriaSyIcon,
  TaiwanTwIcon,
  TajikistanTjIcon,
  TanzaniaTzIcon,
  ThailandThIcon,
  TimorLesteTlIcon,
  TogoTgIcon,
  TongaToIcon,
  TrinidadTobagoTtIcon,
  TunisiaTnIcon,
  TurkeyTrIcon,
  TurkmenistanTmIcon,
  TuvaluTvIcon,
  UaeAeIcon,
  UgandaUgIcon,
  UkraineUaIcon,
  UnitedKingdomUkIcon,
  UnitedStatesUsIcon,
  UruguayUyIcon,
  UzbekistanUzIcon,
  VanuatuVuIcon,
  VenezuelaVeIcon,
  VietnamVnIcon,
  YemenYeIcon,
  ZambiaZmIcon,
  ZimbabweZwIcon,
} from '@teikametrics/tm-design-system/img/flags';

export const EUROPE_CURRENCY_SYMBOL = 'EU';

export const getCountryFlagIcon = (country: string): SvgIcon => {
  const FLAGS: Record<string, SvgIcon> = {
    AF: AfghanistanAfIcon,
    AL: AlbaniaAlIcon,
    DZ: AlgeriaDzIcon,
    AD: AndorraAdIcon,
    AO: AngolaAoIcon,
    AG: AntiguaBarbudaAgIcon,
    AR: ArgentinaArIcon,
    AM: ArmeniaAmIcon,
    AU: AustraliaAuIcon,
    AT: AustriaAtIcon,
    AZ: AzerbaijanAzIcon,
    BS: BahamasBsIcon,
    BH: BahrainBhIcon,
    BD: BangladeshBdIcon,
    BB: BarbadosBbIcon,
    BY: BelarusByIcon,
    BE: BelgiumBeIcon,
    BZ: BelizeBzIcon,
    BJ: BeninBjIcon,
    BT: BhutanBtIcon,
    BO: BoliviaBoIcon,
    BA: BosniaAndHerzegovinaBaIcon,
    BW: BotswanaBwIcon,
    BR: BrazilBrIcon,
    BN: BruneiBnIcon,
    BG: BulgraiaBgIcon,
    BF: BurkinaFasoBfIcon,
    BI: BurundiBiIcon,
    KH: CambodiaKhIcon,
    CM: CameroonCmIcon,
    CA: CanadaCaIcon,
    CV: CapeVerdeCvIcon,
    CF: CentralAfricanRepublicCfIcon,
    TD: ChadTdIcon,
    CL: ChileClIcon,
    CN: ChinaCnIcon,
    CO: ColumbiaCoIcon,
    KM: ComorosKmIcon,
    CR: CostaRicaCrIcon,
    HR: CroatiaHrIcon,
    CU: CubaCuIcon,
    CY: CyprusCyIcon,
    CZ: CzechRepublicCzIcon,
    CD: DemocraticCongoCdIcon,
    DK: DenmarkDkIcon,
    DJ: DjiboutiDjIcon,
    DM: DominicaDmIcon,
    DO: DominicanRepublicDoIcon,
    EC: EcuadorEcIcon,
    EG: EgyptEgIcon,
    SV: ElSalvadorSvIcon,
    GQ: EquatorialGuineaGqIcon,
    ER: EritreaErIcon,
    EE: EstoniaEeIcon,
    SZ: EswatiniSzIcon,
    ET: EthiopiaEtIcon,
    [EUROPE_CURRENCY_SYMBOL]: EuropeIcon,
    FJ: FijiFjIcon,
    FI: FinlandFiIcon,
    FR: FranceFrIcon,
    GA: GabonGaIcon,
    GM: GambiaGmIcon,
    GE: GeorgiaGeIcon,
    DE: GermanyDeIcon,
    GH: GhanaGhIcon,
    GR: GreeceGrIcon,
    GD: GrenadaGdIcon,
    GT: GuatemalaGtIcon,
    GN: GuineaGnIcon,
    GW: GuneaBissauGwIcon,
    GY: GuyanaGyIcon,
    HT: HaitiHtIcon,
    VA: HolySeeVaIcon,
    HN: HondurasHnIcon,
    HK: HongKongHkIcon,
    HU: HungaryHuIcon,
    IS: IcelandIsIcon,
    IN: IndiaInIcon,
    ID: IndonesiaIdIcon,
    IR: IranIrIcon,
    IQ: IraqIqIcon,
    IE: IrelandIeIcon,
    IL: IsraelIlIcon,
    IT: ItalyItIcon,
    CI: IvoryCoastCiIcon,
    JM: JamaicaJmIcon,
    JP: JapanJpIcon,
    JO: JordanJoIcon,
    KZ: KazakhstanKzIcon,
    KE: KenyaKeIcon,
    KI: KiribatiKiIcon,
    KW: KuwaitKwIcon,
    KG: KyrgyzstanKgIcon,
    LA: LaosLaIcon,
    LV: LatviaLvIcon,
    LB: LebanonLbIcon,
    LS: LesothoLsIcon,
    LR: LiberiaLrIcon,
    LY: LibyaLyIcon,
    LI: LiechtensteinLiIcon,
    LT: LithuaniaLtIcon,
    LU: LuxembourgLuIcon,
    MO: MacauMoIcon,
    MG: MadagascarMgIcon,
    MW: MalawiMwIcon,
    MY: MalaysiaMyIcon,
    MV: MaldivesMvIcon,
    ML: MaliMlIcon,
    MT: MaltaMtIcon,
    MH: MarshallIslandsMhIcon,
    MR: MauritaniaMrIcon,
    MU: MauritusMuIcon,
    MX: MexicoMxIcon,
    FM: MicronesiaFmIcon,
    MD: MoldovaMdIcon,
    MC: MonacoMcIcon,
    MN: MongoliaMnIcon,
    ME: MontenegroMeIcon,
    MA: MoroccoMaIcon,
    MZ: MozambiqueMzIcon,
    MM: MyanmarMmIcon,
    NA: NamibiaNaIcon,
    NR: NauruNrIcon,
    NP: NepalNpIcon,
    NL: NetherlandsNlIcon,
    NZ: NewZealandNzIcon,
    NI: NicaraguaNiIcon,
    NE: NigerNeIcon,
    NG: NigeriaNgIcon,
    KP: NorthKoraKpIcon,
    MK: NorthMacedoniaMkIcon,
    NO: NorwayNoIcon,
    OM: OmanOmIcon,
    PK: PakistanPkIcon,
    PW: PalauPwIcon,
    PS: PalestinePsIcon,
    PA: PanamaPaIcon,
    PG: PapuaNewGuineaPgIcon,
    PY: ParaguayPyIcon,
    PE: PeruPeIcon,
    PH: PhilippinesPhIcon,
    PL: PolandPlIcon,
    PT: PortugalPtIcon,
    QA: QatarQaIcon,
    CG: RepublicOfCongoCgIcon,
    RO: RomaniaRoIcon,
    RU: RussiaRuIcon,
    RW: RwandaRwIcon,
    KN: SaintKittsNevisKnIcon,
    LC: SaintLuciaLcIcon,
    VC: SaintVincentTheGrenadinesVcIcon,
    WS: SamoaWsIcon,
    SM: SanMarinoSmIcon,
    ST: SaoTomePrincipeStIcon,
    SA: SaudiArabiaSaIcon,
    SN: SenegalSnIcon,
    RS: SerbiaRsIcon,
    SC: SeychellesScIcon,
    SL: SierraLeoneSlIcon,
    SG: SingaporeSgIcon,
    SK: SlovakiaSkIcon,
    SI: SloveniaSiIcon,
    SB: SolomonIslandsSbIcon,
    SO: SomaliaSoIcon,
    ZA: SouthAfricaZaIcon,
    KR: SouthKoreaKrIcon,
    SS: SouthSudanSsIcon,
    ES: SpainEsIcon,
    LK: SriLankaLkIcon,
    SD: SudanSdIcon,
    SR: SurinameSrIcon,
    SE: SwedenSeIcon,
    CH: SwitzerlandChIcon,
    SY: SyriaSyIcon,
    TW: TaiwanTwIcon,
    TJ: TajikistanTjIcon,
    TZ: TanzaniaTzIcon,
    TH: ThailandThIcon,
    TL: TimorLesteTlIcon,
    TG: TogoTgIcon,
    TO: TongaToIcon,
    TT: TrinidadTobagoTtIcon,
    TN: TunisiaTnIcon,
    TR: TurkeyTrIcon,
    TM: TurkmenistanTmIcon,
    TV: TuvaluTvIcon,
    AE: UaeAeIcon,
    UG: UgandaUgIcon,
    UA: UkraineUaIcon,
    GB: UnitedKingdomUkIcon,
    UK: UnitedKingdomUkIcon,
    US: UnitedStatesUsIcon,
    UY: UruguayUyIcon,
    UZ: UzbekistanUzIcon,
    VU: VanuatuVuIcon,
    VE: VenezuelaVeIcon,
    VN: VietnamVnIcon,
    YE: YemenYeIcon,
    ZM: ZambiaZmIcon,
    ZW: ZimbabweZwIcon,
  };
  return FLAGS[country] ?? null;
};
