import * as React from 'react';
import { navigate } from '@reach/router';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import Button from '../button';
import './styles.scss';
import noop from 'lodash/noop';

export interface VerifyEmailModalProps {
  readonly onClick: () => void;
}

export const VerifyEmailModal: React.FC<VerifyEmailModalProps> = ({
  onClick,
}) => {
  const handleClick = () => {
    onClick();
    navigate('/logout');
  };

  return (
    <Modal
      style={{
        content: {},
      }}
      isOpen={true}
      className="vem__modal"
      overlayClassName="vem__overlay"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      onRequestClose={noop}
    >
      <div className="vem__content">
        <div
          className="vem__title"
          style={{
            display: 'flex',
          }}
        >
          <FormattedMessage
            id={I18nKey.VERIFY_EMAIL_MODAL_TITLE}
            defaultMessage="Email address not verified"
          />
        </div>
        <div
          className="vem__subtitle"
          style={{
            display: 'flex',
          }}
        >
          <FormattedMessage
            id={I18nKey.VERIFY_EMAIL_MODAL_SUBTITLE}
            defaultMessage="We need to verify your email to enable your Flywheel account."
          />
        </div>
        <div
          className="vem__resend_container"
          style={{
            display: 'flex',
          }}
        >
          <Button onClick={handleClick} dataTestId={'resendVerificationEmail'}>
            <FormattedMessage
              id={I18nKey.VERIFY_EMAIL_MODAL_BUTTON}
              defaultMessage="Resend verification email"
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
