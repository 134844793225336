export enum OptimizelyFlags {
  SkuCatalog = 'sku_catalog',
  SkuDetails = 'sku_details',
  AOManageBidding = 'ao_manage_bidding',
  AIUpgradeFlow = 'ai_upgrade',
  Compass = 'compass',
  Amazon1p = 'scs_amz_1p',
  Compass1 = 'compass_1',
  BrandAndCompetitor = 'brand_and_competitor',
  FlowV1ReEnter = 'flow_v1_re_enter',
  FlowV2 = 'flow_v2',
  AddPaymentMethodCompassCard = 'add_payment_method_compass_card',
  FreeTrialEnforcer = 'free_trial_enforcer',
}
