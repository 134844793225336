import React from 'react';
import { useIntl } from 'react-intl';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import { QuestionCircleLinearIcon as QuestionCircleLinear } from '@teikametrics/tm-design-system/img/icons';
import I18nKey from './lib/types/I18nKey';
import ToolTip, {
  TooltipTheme,
  Alignment,
  Placement,
} from '@teikametrics/tm-design-system/components/portaledTooltip';

export const HelpNavItem: React.FC = () => {
  const intl = useIntl();

  const TOOLTIP = intl.formatMessage({
    id: I18nKey.NAV_BAR_LABEL_HELP_TOOLTIP,
  });

  const onClick = () => {
    window.open('https://intercom.help/flywheel-20/en', '_blank');
  };

  return (
    <div className="absolute bottom-14">
      <ToolTip
        position={{
          alignment: Alignment.Center,
          placement: Placement.Right,
          placementOffsetInPixels: 25,
        }}
        theme={TooltipTheme.Dark}
        content={TOOLTIP}
        dataTestId={'flywheelHelpCenter'}
      >
        <div className="cursor-pointer text-purple-300 hover:text-white">
          <Icon
            svg={QuestionCircleLinear}
            size={IconSize.Medium}
            onClick={onClick}
          />
        </div>
      </ToolTip>
    </div>
  );
};
