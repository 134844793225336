import GradientCircle from '../img/GradientCircle.svg';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import { TriangleDownIcon } from '@teikametrics/tm-design-system/img/icons';

export const Separator: React.FC = () => {
  return (
    <div className="flex flex-col items-center mt-10">
      <img src={GradientCircle} alt="Separator" width={12} height={12} />
      <div className="min-h-64 flex-1 border-l-2 border-dashed border-grey-400 mt-8"></div>
      <Icon
        size={IconSize.Small}
        svg={TriangleDownIcon}
        className="text-grey-400"
      />
    </div>
  );
};
