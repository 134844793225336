import axios from 'axios';
import { DEFAULT_AXIOS_CONFIG } from '../clients/index';
import {
  DataFetcherCreator,
  DataUpdater,
  DataUpdaterCreator,
  DatedDataFetcher,
  DateRangeMoment,
  FilteredDataFetcher,
  FilteredRequest,
  PaginatedDataFetcher,
  PaginatedRequest,
  PaginatedResult,
} from '../clients/types';
import { StringMap } from '../types';
import { Sort } from '../types/Sort';
import { getTrailingDays } from '../utilities/datePickerUtilities';
import { filtersToQueryString } from '../utilities/filter';

export function filterEmptyValues<
  T extends StringMap<string> | Record<string, string>
>(stringMap: T): T {
  return Object.keys(stringMap)
    .filter((key) => stringMap[key] !== '')
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: stringMap[key],
      }),
      {} as T
    );
}

const sortToQueryString = (sort: Sort): string =>
  `${sort.column},${sort.direction}`;

export const generateSortQueryString = (sorts?: Sort[]): string =>
  sorts ? sorts.map(sortToQueryString).join(';') : '';

const getBasePaginationParamsFromRequest = (
  request: PaginatedRequest
): StringMap<string> => ({
  filter: filtersToQueryString(request.filters),
  limit: request.itemsPerPage.toString(),
  offset: (request.itemsPerPage * (request.page - 1)).toString(),
  sort: generateSortQueryString(request.sorts),
});

export const getPaginationParamsFromRequest = (
  request: PaginatedRequest
): StringMap<string> => ({
  ...request.extraParams,
  ...getBasePaginationParamsFromRequest(request),
});

export function createPaginatedApiDataFetcher<T>({
  endpoint,
  headers,
  baseURL,
}: DataFetcherCreator): PaginatedDataFetcher<T> {
  return (request: PaginatedRequest): Promise<PaginatedResult<T>> => {
    const allParams = getPaginationParamsFromRequest(request);
    const params = filterEmptyValues(allParams);

    const config = Object.assign(
      {},
      DEFAULT_AXIOS_CONFIG,
      { headers },
      { baseURL }
    );

    return axios.get(endpoint, { ...config, params }).then((response) => ({
      totalItems: response.data.filteredElements as number,
      items: response.data.elements as T[],
    }));
  };
}

/**
 * Like #createPaginatedApiDataFetcher, but with additional smarterLinks
 *
 * @param endpoint
 * @param headers
 */

export function createFilteredApiDataFetcher<T>({
  endpoint,
}: DataFetcherCreator): FilteredDataFetcher<T> {
  return (request: FilteredRequest): Promise<T> => {
    const allParams: StringMap<string> = {
      ...request.extraParams,
      filter: filtersToQueryString(request.filters),
    };
    const params = filterEmptyValues(allParams);

    return axios
      .get(endpoint, { ...DEFAULT_AXIOS_CONFIG, params })
      .then((response) => response.data as T);
  };
}

export function createDatedApiDataFetcher<T>({
  endpoint,
}: DataFetcherCreator): DatedDataFetcher<T> {
  return (dateRange: DateRangeMoment): Promise<T> => {
    const params: StringMap<string> = {
      endDate: dateRange.to.format('YYYY-MM-DD'),
      trailingDays: getTrailingDays(dateRange.from, dateRange.to).toString(),
    };

    return axios
      .get(endpoint, { ...DEFAULT_AXIOS_CONFIG, params })
      .then((response) => response.data as T);
  };
}

export function createApiDataUpdater<T>({
  endpoint,
}: DataUpdaterCreator): DataUpdater<T> {
  return (payload: T): Promise<boolean> => {
    return axios
      .post(endpoint, payload, { ...DEFAULT_AXIOS_CONFIG })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
}
