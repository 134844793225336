import React, { ReactNodeArray, useState } from 'react';
import {
  Icon,
  IconSize,
  SvgIcon,
} from '@teikametrics/tm-design-system/components/Icon';
import {
  AlertTriangleIcon,
  CheckmarkIcon,
  CrossCircleIcon,
  LightningIcon,
} from '@teikametrics/tm-design-system/img/icons';
import { Modal } from '@teikametrics/tm-design-system/components/Modal';
import { ButtonState } from '@teikametrics/tm-design-system/components/Button';
import I18nKey from '../../../lib/types/I18nKey';
import { FormattedMessage, useIntl } from 'react-intl';
import { billingDateLongFormatted } from '../../../lib/utilities/billingDateRange';
import classNames from 'classnames';

export interface DisableAutomationModalProps {
  afterDisable?: () => void;
  disableAction: () => Promise<void>;
  onCancel?: () => void;
  nextBillingDate?: string;
}

export interface EnableAutomationModalProps {
  afterEnable: () => void;
  enableAction: () => Promise<void>;
  onCancel?: () => void;
  nextBillingDate?: string;
  merchantName: string;
}

export const ShadowyAlertBox: React.FC<{
  icon?: SvgIcon;
  shouldCenterIcon?: boolean;
}> = ({ children, icon, shouldCenterIcon }) => (
  <>
    <div
      className={classNames(
        'flex mt-28 bg-purple-50 border border-solid rounded-md border-grey-200 p-8',
        {
          'items-center': shouldCenterIcon,
        }
      )}
    >
      <div className="flex justify-center items-center flex-none w-32 h-32 rounded-full bg-gradient-to-r from-magenta-500 to-orange-500">
        <Icon
          size={IconSize.Medium}
          svg={icon || AlertTriangleIcon}
          className="text-white mb-2"
        />
      </div>
      <p className="text-base ml-12">{children}</p>
    </div>
  </>
);

export const AiFeatureRow: React.FC<{ text: string; isUpgrade?: boolean }> = ({
  text,
  isUpgrade,
}) => (
  <div className="flex flex-row space-x-8">
    <Icon
      size={IconSize.Medium}
      svg={isUpgrade ? CheckmarkIcon : CrossCircleIcon}
      className={classNames('self-center', {
        'text-grey-500': !isUpgrade,
        'text-purple-500': isUpgrade,
      })}
    />
    <span className="text-base font-normal text-grey-900">{text}</span>
  </div>
);

export const DisableAutomationModal: React.FC<DisableAutomationModalProps> = ({
  afterDisable,
  disableAction,
  onCancel,
}) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [HEADER, BODY, LIST_HEADER, FIRST_FEATURE, SECOND_FEATURE, DISABLE] = [
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_DISABLE_AI,
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_LIVE_AUTOMATIONS,
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_DISABLING_WHAT,
    I18nKey.BILLING_PLAN_FEATURES_BIDDER,
    I18nKey.BILLING_PLAN_FEATURES_TRE,
    I18nKey.DISABLE,
  ].map((id) => intl.formatMessage({ id }));

  const onDisable = async () => {
    setLoading(true);
    await disableAction();
    afterDisable && afterDisable();
  };

  return (
    <Modal
      icon={
        <Icon
          svg={AlertTriangleIcon}
          size={IconSize.Large}
          className="text-purple-500"
        />
      }
      showModal={true}
      header={HEADER}
      body={BODY}
      mainAction={{
        state: isLoading ? ButtonState.Loading : ButtonState.Enabled,
        label: DISABLE,
        onClick: onDisable,
      }}
      secondaryAction={{
        state: isLoading ? ButtonState.Disabled : ButtonState.Enabled,
        label: I18nKey.CANCEL,
        onClick: onCancel,
      }}
    >
      <div className="pt-20 flex flex-col space-y-8">
        <p className="text-base font-medium text-grey-900">{LIST_HEADER}</p>
        <AiFeatureRow text={FIRST_FEATURE} />
        <AiFeatureRow text={SECOND_FEATURE} />
      </div>
    </Modal>
  );
};

export const EnableAutomationModal: React.FC<EnableAutomationModalProps> = ({
  afterEnable,
  enableAction,
  onCancel,
  merchantName,
}) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [
    HEADER,
    LIST_HEADER,
    FIRST_FEATURE,
    SECOND_FEATURE,
    SET_MACS,
    DO_THIS_LATER,
    FUEL,
    SET_AD_MACS,
  ] = [
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_ENABLE_AI,
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_ENABLING_WHAT,
    I18nKey.BILLING_PLAN_FEATURES_BIDDER,
    I18nKey.BILLING_PLAN_FEATURES_TRE,
    I18nKey.SET_MACS,
    I18nKey.DO_THIS_LATER,
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_PLAN_FUEL,
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_PLAN_SET_AD_MACS,
  ].map((id) => intl.formatMessage({ id }));

  const BODY = intl.formatMessage(
    { id: I18nKey.ACCOUNT_SALES_CHANNELS_AI_MERCHANT_POWERED },
    { merchantName }
  );

  const onSetMacs = async () => {
    setLoading(true);
    await enableAction();
    afterEnable && afterEnable();
  };

  return (
    <Modal
      icon={
        <Icon
          svg={LightningIcon}
          size={IconSize.Large}
          className="text-purple-500"
        />
      }
      showModal={true}
      header={HEADER}
      body={BODY}
      mainAction={{
        state: isLoading ? ButtonState.Loading : ButtonState.Enabled,
        label: SET_MACS,
        onClick: onSetMacs,
      }}
      secondaryAction={{
        state: isLoading ? ButtonState.Disabled : ButtonState.Enabled,
        label: DO_THIS_LATER,
        onClick: onCancel,
      }}
    >
      <div className="pt-20 flex flex-col space-y-8">
        <p className="text-base font-medium text-grey-900">{LIST_HEADER}</p>
        <AiFeatureRow text={FIRST_FEATURE} isUpgrade={true} />
        <AiFeatureRow text={SECOND_FEATURE} isUpgrade={true} />
      </div>
      <ShadowyAlertBox>
        <div className="font-medium">{FUEL}</div>
        <span className="text-grey-700">{SET_AD_MACS}</span>
      </ShadowyAlertBox>
    </Modal>
  );
};

export const DowngradePlanModal: React.FC<DisableAutomationModalProps> = ({
  afterDisable,
  disableAction,
  onCancel,
  nextBillingDate,
}) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [
    HEADER,
    BODY,
    LIST_HEADER,
    NOTE,
    NON_TRIAL_NOTE_BOLD,
    NON_TRIAL_NOTE,
    FIRST_FEATURE,
    SECOND_FEATURE,
    DOWNGRADE_BUTTON,
  ] = [
    I18nKey.BILLING_DOWNGRADE_PLAN_TITLE,
    I18nKey.BILLING_DOWNGRADE_PLAN_BODY,
    I18nKey.BILLING_DOWNGRADE_PLAN_LIST_HEADER,
    I18nKey.BILLING_DOWNGRADE_PLAN_NOTE,
    I18nKey.BILLING_DOWNGRADE_PLAN_NON_TRIAL_NOTE_BOLD,
    I18nKey.BILLING_DOWNGRADE_PLAN_NON_TRIAL_NOTE,
    I18nKey.BILLING_PLAN_FEATURES_BIDDER,
    I18nKey.BILLING_PLAN_FEATURES_TRE,
    I18nKey.TERMS_DOWNGRADE,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        nextBillingDate: nextBillingDate
          ? billingDateLongFormatted(nextBillingDate)
          : undefined,
      }
    )
  );

  const onDisable = async () => {
    setLoading(true);
    await disableAction();
    afterDisable && afterDisable();
  };

  return (
    <Modal
      showModal={true}
      header={HEADER}
      body={BODY}
      mainAction={{
        state: isLoading ? ButtonState.Loading : ButtonState.Enabled,
        label: DOWNGRADE_BUTTON,
        onClick: onDisable,
      }}
      secondaryAction={{
        state: isLoading ? ButtonState.Disabled : ButtonState.Enabled,
        label: I18nKey.CANCEL,
        onClick: onCancel,
      }}
    >
      <div className="pt-20 flex flex-col space-y-8">
        <p className="text-base font-medium text-grey-900">{LIST_HEADER}</p>
        <AiFeatureRow text={FIRST_FEATURE} />
        <AiFeatureRow text={SECOND_FEATURE} />
      </div>

      <ShadowyAlertBox shouldCenterIcon={!nextBillingDate}>
        {nextBillingDate ? (
          <>
            <span className="font-medium">{NON_TRIAL_NOTE_BOLD}</span>
            {NON_TRIAL_NOTE}
          </>
        ) : (
          NOTE
        )}
      </ShadowyAlertBox>
    </Modal>
  );
};

export const PlanChangedAdvisoryModal: React.FC = () => {
  const intl = useIntl();

  const [PLAN_CHANGED_HEADER, PLAN_CHANGED_BODY, GOT_IT] = [
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED,
    I18nKey.ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED_BODY,

    I18nKey.GOT_IT,
  ].map((id) => intl.formatMessage({ id }));

  const [showModal, setShowModal] = useState(true);

  const dismiss = () => setShowModal(false);

  return (
    <Modal
      showModal={showModal}
      header={PLAN_CHANGED_HEADER}
      body={PLAN_CHANGED_BODY}
      mainAction={{ label: GOT_IT, onClick: dismiss }}
    >
      <ShadowyAlertBox>
        <FormattedMessage
          id={I18nKey.ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED_DETAILS}
          values={{
            em: (...chunks: ReactNodeArray) =>
              chunks.map((e, i) => (
                <span className="font-medium" key={i}>
                  {e}
                </span>
              )),
          }}
        />
      </ShadowyAlertBox>
    </Modal>
  );
};
