import {
  Typography,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system/components/Typography';
import I18nKey from '../../../lib/types/I18nKey';
import { FormattedMessage } from 'react-intl';

interface OpportunityULProps {
  readonly title: I18nKey;
  readonly list: I18nKey[];
}

export const OpportunityUL: React.FC<OpportunityULProps> = ({
  title,
  list,
}) => {
  return (
    <div className="w-full flex flex-col gap-12">
      <Typography size={TypographySize.xl} weight={TypographyWeight.semibold}>
        <FormattedMessage id={title} />
      </Typography>
      {list.map((item, index) => {
        return (
          <div key={index} className="flex flex-row gap-12">
            <div className="w-6 h-6 rounded bg-purple-400 mt-10"></div>
            <Typography
              key={index}
              size={TypographySize.lg}
              className="flex-1 max-w-500"
            >
              <FormattedMessage
                id={item}
                values={{
                  b: (text: string) => <b>{text}</b>,
                }}
              />
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
