import React, { createContext, useState, useEffect } from 'react';
import {
  createInstance,
  setLogLevel,
  OptimizelyDecision,
  OptimizelyProvider as OptimizelySdkProvider,
} from '@optimizely/react-sdk';
import { UserDetails } from '../../lib/types/Fam';
import { OptimizelyFlags } from '../../lib/types/OptimizelyFlags';
import {
  getCurrentAccountPermissions,
  isTeikametricsUser,
} from '../userProvider/selectors';
import noop from 'lodash/noop';

export type FeatureFlagState = {
  [key in OptimizelyFlags]?: boolean;
};

export interface OptimizelyContextState {
  isClientReady: boolean;
  updateFlagState: (flags: FeatureFlagState) => void;
  featureFlags: FeatureFlagState;
}

export interface TeikaUserProviderProps {
  children: JSX.Element;
  userInfo: UserDetails;
}

// Creates feature flag object that will store all existing Optimizely feature flags
export const createFlagState = (
  getFeatureFlags: Record<string, OptimizelyDecision>
) => {
  return Object.keys(getFeatureFlags).reduce((acc, item) => {
    acc = {
      ...acc,
      [item]: getFeatureFlags[item]?.enabled || false,
    };
    return acc;
  }, {} as FeatureFlagState);
};

// Set Optimizely logger to alert only on errors otherwise to much noise in the console log
setLogLevel(process.env.REACT_APP_OPTIMIZELY_LOG_LEVEL!);

// Optimizely setup config that create initial instance
const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY,
  eventBatchSize: 100,
  eventFlushInterval: 3000,
  datafileOptions: {
    updateInterval: 60000 * 5,
    autoUpdate: true,
  },
});

const initialState = {
  isClientReady: false,
  updateFlagState: noop,
  featureFlags: {} as FeatureFlagState,
};

const OptimizelyContext = createContext<OptimizelyContextState>(initialState);
OptimizelyContext.displayName = 'OptimizelyContext';

const { Provider } = OptimizelyContext;

const OptimizelyProvider: React.FC<TeikaUserProviderProps> = ({
  children,
  userInfo,
}) => {
  // Store boolean indicating wether Optimizely Client instance is ready
  const [isClientReady, setClientReady] = useState(false);

  // Will store the enable state of all Optimizely feature flags
  const [featureFlags, updateFlagState] = useState<FeatureFlagState>(
    {} as FeatureFlagState
  );

  const generateOptimizelyDecisions = () => {
    const featureFlagState = createFlagState(optimizely.decideAll());
    updateFlagState(featureFlagState);
  };

  const onClientReady = () => {
    setClientReady(true);
  };

  // Initialize the client once on component load.
  useEffect(() => {
    optimizely.onReady().then(onClientReady).catch(onClientReady);
  }, []);

  // If the user or client status changes set the user and re-generate the decisions
  // this will allow us to target by user and account on the fly.
  useEffect(() => {
    optimizely.setUser({
      id: userInfo.id,
      attributes: {
        userId: userInfo.id,
        accountId: getCurrentAccountPermissions(userInfo)?.account.id,
        isInternal: isTeikametricsUser(userInfo),
      },
    });

    if (isClientReady) {
      generateOptimizelyDecisions();
    }
  }, [isClientReady, userInfo]);

  return (
    <OptimizelySdkProvider optimizely={optimizely}>
      <Provider value={{ isClientReady, featureFlags, updateFlagState }}>
        {children}
      </Provider>
    </OptimizelySdkProvider>
  );
};

export { OptimizelyContext, OptimizelyProvider };
