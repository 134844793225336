import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flywheel2LogoIcon as FlywheelLogo } from '@teikametrics/tm-design-system/img/branding';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from '../../../lib/types/I18nKey';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  ButtonState,
} from '@teikametrics/tm-design-system/components/Button';
import { ArrowLeftIcon } from '@teikametrics/tm-design-system/img/icons';
import { TextLink } from '@teikametrics/tm-design-system/components/TextLink';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import classnames from 'classnames';
import {
  SurveyQuestionStep,
  SurveyQuestionHeaders,
  SurveyAnswers,
} from '../SurveyQuestions';
import {
  SurveyOption,
  SurveyOptionType,
} from '../../../components/survey/option';
const Gradient: React.FC = () => (
  <div className="h-4 rounded-t bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500" />
);

interface QuestionnaireCardProps extends RouteComponentProps {
  step: SurveyQuestionHeaders;
  title: string;
  onHandleStep: (step: SurveyQuestionHeaders) => void;
  questionsStep: SurveyQuestionStep;
  surveyAnswers: SurveyAnswers;
  saveSurveyAnswers: (
    singleSelect: boolean,
    answer: string | string[],
    checked?: boolean
  ) => void;
  dataTestId?: string;
}

export const DEMO_REQUEST = 'personal_demo_requested';

export const SingleSelectQuestionnaireCard: React.FC<QuestionnaireCardProps> = ({
  step,
  title,
  onHandleStep,
  questionsStep,
  surveyAnswers,
  saveSurveyAnswers,
  dataTestId,
}) => {
  const intl = useIntl();
  const [demoRequested, setDemoRequested] = useState(false);
  const [BACK] = [I18nKey.BACK].map((id) => intl.formatMessage({ id }));
  const answerState = surveyAnswers[step];

  const gotoNextStep = () => {
    if (answerState) {
      onHandleStep(questionsStep.nextStep!!);
    }
  };

  const saveDemoAnswer = () => {
    saveSurveyAnswers(
      true,
      !demoRequested
        ? [...answerState, 'personal_demo_requested']
        : answerState.filter((item) => item !== DEMO_REQUEST)
    );
    setDemoRequested(!demoRequested);
  };

  const goBackStep = () => {
    onHandleStep(questionsStep.previousStep!!);
  };

  return (
    <div
      className="w-500 min-w-min bg-white border border-solid border-grey-200 mt-96"
      style={{ minHeight: 370 }}
    >
      <Gradient />
      <div className="p-24 text-center">
        <div>
          <FlywheelLogo />
          <br />
        </div>
        <div className="text-purple-600 text-sm font-medium mt-24 tracking-widest">
          <FormattedMessage id={questionsStep.header} />
        </div>
        <div className="leading-normal text-grey-900 text-lg mt-12 mb-16">
          {title}
        </div>

        {questionsStep.questions.map((item) => {
          if (item.answerTrigger) {
            return null;
          }

          const isSelected = answerState?.includes(item.value);

          return (
            <div key={item.label} className="mb-8">
              <SurveyOption
                type={SurveyOptionType.Radio}
                checked={isSelected}
                onClick={() => saveSurveyAnswers(true, [item.value])}
                text={
                  <div className="flex flex-row items-center">
                    <div className="text-grey-900 text-base ml-8">
                      <FormattedMessage id={item.label} />
                    </div>
                  </div>
                }
              />
            </div>
          );
        })}

        {answerState &&
          questionsStep.questions.map((item) => {
            if (
              !item.answerTrigger ||
              !item.answerTrigger.includes(answerState[0])
            ) {
              return null;
            }

            return (
              <SurveyOption
                key={item.label}
                type={SurveyOptionType.Checkbox}
                checked={demoRequested || answerState.includes(DEMO_REQUEST)}
                padding="px-16 py-12"
                onClick={saveDemoAnswer}
                backgroundColor="bg-grey-50"
                topContent={
                  <div className="text-sm text-grey-600 font-medium text-left mb-8">
                    Demo request (optional)
                  </div>
                }
                text={
                  <div className="flex flex-row items-center">
                    {item.icon && (
                      <Icon
                        svg={item.icon}
                        size={IconSize.Medium}
                        className="mr-8"
                      />
                    )}

                    <div className="text-grey-900 text-base">
                      <FormattedMessage id={item.label} />
                    </div>
                  </div>
                }
              />
            );
          })}

        <hr className="text-grey-200 mt-24" />
        <div
          className={classnames('mt-24 mx-52', {
            'flex flex-row justify-between': questionsStep.previousStep,
          })}
        >
          {questionsStep.previousStep && (
            <div className="cursor-pointer" role="button" onClick={goBackStep}>
              <Icon
                svg={ArrowLeftIcon}
                className="text-purple-500 mr-4"
                size={IconSize.Small}
              />
              <TextLink
                textLabel={BACK}
                dataTestId={`${dataTestId}_singleSelectQuestinnaireCardBack`}
              />
            </div>
          )}
          <Button
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            state={!!answerState ? ButtonState.Active : ButtonState.Disabled}
            onClick={gotoNextStep}
            label={intl.formatMessage({
              id: I18nKey.ONBOARDING_SURVEY_TERM_NEXT,
            })}
          />
        </div>
      </div>
    </div>
  );
};
