import { RouteComponentProps } from '@reach/router';
import { SvgIcon } from '@teikametrics/tm-design-system/components/svgIcon';
import {
  AmazonLogomarkEnabledIcon as AmazonIcon,
  EbayLogomarkEnabledIcon as EbayIcon,
  WalmartLogomarkEnabledIcon as WalmartIcon,
} from '@teikametrics/tm-design-system/img/sales-channel';
import I18nKey from '../../lib/types/I18nKey';
import { DEMO_REQUEST } from './components/SingleSelectQuestionnareCard';

export enum SurveyQuestionHeaders {
  SellProducts = 'which_channels_are_you_currently_selling_on_',
  AnnualSales = 'annual_gmv_range',
  UsingFlywheel = 'are_you_a_retailer_or_brand_',
  ChooseProducts = 'fw2_account_objective',
  AccomplishFlywheel = 'fw2_account_goals',
  CompletedSurvey = 'CompletedSurvey',
}

export interface SurveyQuestion {
  icon?: SvgIcon;
  label: string;
  value: string;
  answerTrigger?: string[];
}

export type SurveyQuestionStep = {
  header?: I18nKey;
  title: I18nKey;
  questions: SurveyQuestion[];
  previousStep?: SurveyQuestionHeaders;
  nextStep?: SurveyQuestionHeaders;
  stepNumber?: number;
};

const nextSteps = {
  [SurveyQuestionHeaders.SellProducts]: SurveyQuestionHeaders.AnnualSales,
  [SurveyQuestionHeaders.AnnualSales]: SurveyQuestionHeaders.UsingFlywheel,
  [SurveyQuestionHeaders.UsingFlywheel]: SurveyQuestionHeaders.ChooseProducts,
  [SurveyQuestionHeaders.ChooseProducts]:
    SurveyQuestionHeaders.AccomplishFlywheel,
  [SurveyQuestionHeaders.AccomplishFlywheel]:
    SurveyQuestionHeaders.CompletedSurvey,
};

export const sellProductQuestions: SurveyQuestion[] = [
  {
    icon: AmazonIcon,
    label: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_AMAZON,
    value: 'Amazon',
  },
  {
    icon: WalmartIcon,
    label: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_WALMART,
    value: 'Walmart',
  },
  {
    icon: EbayIcon,
    label: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_EBAY,
    value: 'eBay',
  },
];

export const annualSalesQuestions: SurveyQuestion[] = [
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_LESS_THAN_100,
    value: '0-99999',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_BETWEEN_100_AND_1M,
    value: '100000-1000000',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_BETWEEN_1M_AND_5M,
    value: '1000001-5000000',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_MORE_THAN_5M,
    value: '5000001-Infinity',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_EMAIL_ME_FOR_DEMO,
    value: DEMO_REQUEST, // This is a special value that we look for to submit additional properties to HubSpot
    answerTrigger: ['100000-1000000', '1000001-5000000', '5000001-Infinity'],
  },
];

export const chooseProductQuestions: SurveyQuestion[] = [
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_AI_POWER_AUTOMATION,
    value: 'AI-powered automation',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_MULTI_CHANNEL_MANAGEMENT,
    value: 'Multi-channel capabilities',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_MARKET_RESEARCH_TOOL,
    value: 'Market Intelligence',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_EXPERT_KNOWLEDGE_INSIGHTS,
    value: 'Expert knowledge and insights',
  },
];

export const accomplishFLywheelQuestions: SurveyQuestion[] = [
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_SAVE_TIME_AUTOMATION,
    value: 'Save time through automation',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_ACHIEVE_AD_PERFORMANCE,
    value: 'Achieve better ad performance',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_INCREASE_AD_SPEND,
    value: 'Increase ad spend efficiency',
  },
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_OPTIMIZE_PRODUCT_CATALOG,
    value: 'Optimize my product catalog',
  },
];

export const useFlywheelQuestions: SurveyQuestion[] = [
  {
    label: I18nKey.ONBOARDING_SURVEY_QUESTION_MY_PERSONAL_BUSINESS,
    value: 'Brand',
  },
  { label: I18nKey.ONBOARDING_SURVEY_QUESTION_AGENCY_PARTNER, value: 'Agency' },
];

export const surveyQuestionsSteps = {
  [SurveyQuestionHeaders.SellProducts]: {
    header: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_YOUR_BRAND,
    title: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_WHICH_CHANNELS_SELL_PRODUCTS,
    questions: sellProductQuestions,
    nextStep: nextSteps[SurveyQuestionHeaders.SellProducts],
    stepNumber: 1,
  },
  [SurveyQuestionHeaders.AnnualSales]: {
    header: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_YOUR_SIZE,
    title: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_TOTAL_ANNUAL_SALES,
    questions: annualSalesQuestions,
    previousStep: SurveyQuestionHeaders.SellProducts,
    nextStep: nextSteps[SurveyQuestionHeaders.AnnualSales],
    stepNumber: 2,
  },
  [SurveyQuestionHeaders.UsingFlywheel]: {
    header: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_YOUR_NEEDS,
    title: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_HOW_ARE_YOU_USING,
    questions: useFlywheelQuestions,
    previousStep: SurveyQuestionHeaders.AnnualSales,
    nextStep: nextSteps[SurveyQuestionHeaders.UsingFlywheel],
    stepNumber: 3,
  },
  [SurveyQuestionHeaders.ChooseProducts]: {
    header: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_YOUR_OBJECTIVE,
    title: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_CHOOSE_PRODUCT_OVER_OTHERS,
    questions: chooseProductQuestions,
    previousStep: SurveyQuestionHeaders.UsingFlywheel,
    nextStep: nextSteps[SurveyQuestionHeaders.ChooseProducts],
    stepNumber: 4,
  },
  [SurveyQuestionHeaders.AccomplishFlywheel]: {
    header: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_YOUR_GOALS,
    title: I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_ACCOMPLISH_WITH_FLYWHEEL,
    questions: accomplishFLywheelQuestions,
    previousStep: SurveyQuestionHeaders.ChooseProducts,
    nextStep: nextSteps[SurveyQuestionHeaders.AccomplishFlywheel],
    stepNumber: 5,
  },
  [SurveyQuestionHeaders.CompletedSurvey]: {
    title: '' as I18nKey,
    questions: [],
    nextStep: nextSteps[SurveyQuestionHeaders.AccomplishFlywheel],
  },
};

export interface QuestionnaireProps extends RouteComponentProps {
  step: SurveyQuestionHeaders;
  nextStep: (step: SurveyQuestionHeaders) => void;
  surveyAnswers: SurveyAnswers;
  saveSurveyAnswers: (
    singleSelect: boolean,
    answer: string | string[],
    checked?: boolean
  ) => void;
}

export type SurveyAnswers = { [key in SurveyQuestionHeaders]: string[] };
