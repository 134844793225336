import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';

export const OauthPopup: React.FC<RouteComponentProps> = () => {
  // Gets the query params from the URL, posts a MessageEvent with the params,
  // and closes the window.
  useEffect(() => {
    const params = window.location.search;
    if (window.opener) {
      window.opener.postMessage(params, '*');
      window.close();
    }
  }, []);
  return <></>;
};
