import {
  AdGroupStatus,
  AdLevel,
  AdType,
  CampaignStatus,
  FlywheelSalesChannel,
  TargetStatus,
} from '../../../../../lib/types/AOSharedTypes';
import { Filter, FilterOps } from '../../../../../lib/types/Filter';
import {
  ADGROUPS_API_COLUMN_NAME,
  CAMPAIGNS_API_COLUMN_NAME,
  ProductAdStatusValue,
  PRODUCT_ADS_API_COLUMN_NAME,
  PROFILES_API_COLUMN_NAME,
  TARGETS_API_COLUMN_NAME,
} from './index';

export const getDefaultFilters = (
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  adLevel: AdLevel
): Filter[] => {
  const defaultFilters: AdsManagerDefaultFilters = {
    [FlywheelSalesChannel.Walmart]: {
      [AdType.SponsoredProducts]: {
        [AdLevel.Campaigns]: [
          {
            op: FilterOps.in,
            field: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
            value: [
              CampaignStatus.Live,
              CampaignStatus.Scheduled,
              CampaignStatus.Rescheduled,
            ],
          },
        ],
        [AdLevel.AdGroups]: [
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Live],
          },
        ],
        [AdLevel.ProductAds]: [
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.Status,
            value: [ProductAdStatusValue.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
            value: [CampaignStatus.Live],
          },
          {
            op: FilterOps.gt,
            field: ADGROUPS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
        [AdLevel.KeywordTargets]: [
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.TargetStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Live],
          },
          {
            op: FilterOps.gt,
            field: TARGETS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
      },
      [AdType.SearchBrandAmplifier]: {
        [AdLevel.Campaigns]: [
          {
            op: FilterOps.in,
            field: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
            value: [
              CampaignStatus.Live,
              CampaignStatus.Scheduled,
              CampaignStatus.Rescheduled,
            ],
          },
        ],
        [AdLevel.AdGroups]: [
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Live],
          },
        ],
        [AdLevel.Profiles]: [
          {
            op: FilterOps.in,
            field: PROFILES_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: PROFILES_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Live],
          },
        ],
        [AdLevel.ProductAds]: [
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.Status,
            value: [ProductAdStatusValue.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
            value: [CampaignStatus.Live],
          },
          {
            op: FilterOps.gt,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
        [AdLevel.KeywordTargets]: [
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.TargetStatus,
            value: [TargetStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Live],
          },
          {
            op: FilterOps.gt,
            field: TARGETS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
      },
    },
    [FlywheelSalesChannel.Amazon]: {
      [AdType.SponsoredProducts]: {
        [AdLevel.Campaigns]: [
          {
            op: FilterOps.in,
            field: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
        ],
        [AdLevel.AdGroups]: [
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
        ],
        [AdLevel.ProductAds]: [
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.Status,
            value: [ProductAdStatusValue.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
            value: [CampaignStatus.Enabled],
          },
          {
            op: FilterOps.gt,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
        [AdLevel.KeywordTargets]: [
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.TargetStatus,
            value: [TargetStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
          {
            op: FilterOps.gt,
            field: TARGETS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
        [AdLevel.ProductTargets]: [
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.TargetStatus,
            value: [TargetStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
          {
            op: FilterOps.gt,
            field: TARGETS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
      },
      [AdType.SponsoredBrands]: {
        [AdLevel.Campaigns]: [
          {
            op: FilterOps.in,
            field: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
        ],
        [AdLevel.KeywordTargets]: [
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.TargetStatus,
            value: [TargetStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
          {
            op: FilterOps.gt,
            field: TARGETS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
        [AdLevel.ProductTargets]: [
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.TargetStatus,
            value: [ProductAdStatusValue.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
          {
            op: FilterOps.gt,
            field: TARGETS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
      },
      [AdType.SponsoredDisplay]: {
        [AdLevel.Campaigns]: [
          {
            op: FilterOps.in,
            field: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
        ],
        [AdLevel.AdGroups]: [
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: ADGROUPS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
        ],
        [AdLevel.ProductAds]: [
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.Status,
            value: [ProductAdStatusValue.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
            value: [CampaignStatus.Enabled],
          },
          {
            op: FilterOps.gt,
            field: PRODUCT_ADS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
        [AdLevel.Targets]: [
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.TargetStatus,
            value: [TargetStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.AdGroupStatus,
            value: [AdGroupStatus.Enabled],
          },
          {
            op: FilterOps.in,
            field: TARGETS_API_COLUMN_NAME.CampaignStatus,
            value: [CampaignStatus.Enabled],
          },
          {
            op: FilterOps.gt,
            field: TARGETS_API_COLUMN_NAME.AdSpend,
            value: 0,
          },
        ],
      },
    },
  };
  return defaultFilters[salesChannel]?.[adType]?.[adLevel] || [];
};

type AdsManagerDefaultFilters = {
  readonly [salesChannel in FlywheelSalesChannel]: Partial<
    {
      readonly [adType in AdType]: Partial<
        {
          readonly [adLevel in AdLevel]: Filter[];
        }
      >;
    }
  >;
};
