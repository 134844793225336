import moment, { Moment } from 'moment';
import { DateTime } from 'luxon';

export const formatDateRange = (
  startDate: DateTime,
  endDate: DateTime,
  format?: string
) => {
  const actualFormat = format || 'yyyy-mm-dd';
  const startString = startDate.toFormat(actualFormat);
  const endString = endDate.toFormat(actualFormat);
  return [startString, endString].join(' - ');
};

export function billing30DayRange(
  nextBillingDate: string | undefined = ''
): { startDate: Moment; endDate: Moment } | undefined {
  if (moment(nextBillingDate).isValid()) {
    const startDate = moment(nextBillingDate)
      .subtract(4, 'days')
      .subtract(1, 'months')
      .add(1, 'days');

    const endDate = moment(nextBillingDate).subtract(4, 'days');

    return {
      startDate,
      endDate,
    };
  }

  return undefined;
}

export function billing30DayRangeFormatted(
  date: string | undefined
): { startDate: string; endDate: string } {
  const dateRange = billing30DayRange(date);

  return dateRange
    ? {
        startDate: dateRange.startDate.format('MMM DD'),
        endDate: dateRange.endDate.format('MMM DD'),
      }
    : {
        startDate: '',
        endDate: '',
      };
}

export const billingDateLongFormatted = (date: string | undefined): string => {
  return moment(date).format('MMMM D, YYYY');
};
