import { DualModes } from '@teikametrics/tm-design-system/molecules/TableActionRow';
import { DateTime } from 'luxon';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { PartialRecord, StrictStringMap } from '../../../../lib/types';
import {
  AdGroupReviewStatusType,
  AdLevel,
  AdLevelApiEndpoint,
  AdType,
  CampaignCostType,
  EntityTypeRequestParameterForTargets,
  ExpressionType,
  FlywheelSalesChannel,
  KeywordReviewStatusType,
  LabelValue,
  MerchantCountryCode,
  Portfolio,
  ProductReviewStatusType,
} from '../../../../lib/types/AOSharedTypes';
import {
  ConnectionStatus,
  MerchantCountry,
  MerchantType,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  ADS_MANAGER_AD_ITEMS_TABLE_ID,
  ADS_MANAGER_ADGROUP_TABLE_ID,
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  ADS_MANAGER_PROFILES_TABLE_ID,
  ADS_MANAGER_TARGETS_TABLE_ID,
} from './ducks/types';
import {
  EditableColumnsAdGroup,
  EditableColumnsAdItem,
  EditableColumnsCampaign,
  EditableColumnsTarget,
} from './utils';

export const DEFAULT_TAB_INDEX_DATA_INSPECTOR = 0;
export const REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES = '60';
export const MILLISECONDS_TO_DAYS = 60 * 60 * 24 * 1000;

export interface DataInspection {
  readonly parent: LabelValue<AdType>;
  readonly child: LabelValue<AdLevel>[];
}

export const AdTypeI8nKeyMapper: Record<AdType, I18nKey> = {
  [AdType.SearchBrandAmplifier]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SEARCH_BRAND_AMPLIFIER,
  [AdType.SponsoredProducts]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_PRODUCTS,
  [AdType.SponsoredBrands]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_BRANDS,
  [AdType.SponsoredDisplay]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_DISPLAY,
  //  This is just a placeholder. We don't really use SponsoredBrandsVideo anywhere
  [AdType.SponsoredBrandsVideo]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_DISPLAY,
};

export const AdLevelI8nKeyMapper: Record<AdLevel, I18nKey> = {
  [AdLevel.Campaigns]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_CAMPAIGNS,
  [AdLevel.AdGroups]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_ADGROUPS,
  [AdLevel.Targets]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_TARGETS,
  [AdLevel.ProductAds]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_ADS,
  [AdLevel.Profiles]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PROFILES,
  [AdLevel.KeywordTargets]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_KEYWORDS_ADS,
  [AdLevel.ProductTargets]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_TARGETS,
};

export const CAMPAIGN_DATE_FORMAT = 'yyyy-MM-dd';
export const CAMPAIGN_DISPLAY_DATE_FORMAT = 'MM/dd/yy';
export const MERCHANT_COUNTRY_IDS = 'merchantCountryIds';
export const SALES_CHANNEL_ID = 'salesChannelId';
export const AD_TYPE = 'adType';
export const TARGETING_TYPE = 'targetingType';
export const MACS_TARGET_IS_EMPTY_FILTER_VALUE = '-99999';

export enum CampaignTargetingType {
  Auto = 'auto',
  Manual = 'manual',
}

export const ADITEM_BID_AUTO_MIN_VALUE = 0.2;
export const ADITEM_BID_MAX_VALUE = 20;

export const ADGROUP_MACS_TARGET_MIN_VALUE = 0;
export const ADGROUP_BID_AUTO_MIN_VALUE = 0.2;
export const ADGROUP_BID_MANUAL_MIN_VALUE = 0.3;
export const ADGROUP_BID_MAX_VALUE = 20;
export const ADGROUP_DEFAULT_MAX_BID_VALUE = 5;
export const ADGROUP_MIN_MACS_VALUE = 0;
export const ADGROUP_DEFAULT_MACS_VALUE = 20;

export const TARGETS_MIN_BID = 0.3;
export const TARGETS_MAX_BID = 20;

export const CAMPAIGN_TABLE_SEARCH_KEY = 'searchCampaignName';
export const ADGROUP_TABLE_SEARCH_KEY = 'searchAdGroupName';
export const PRODUCTAD_TABLE_SEARCH_KEY = 'searchProductName';
export const PROFILE_TABLE_SEARCH_KEY = 'searchBrand';
export const TARGETS_TABLE_SEARCH_KEY = 'searchTargetText';
export const TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION =
  'searchTargetingExpression';

export const CURRENCY_CODE = 'USD';

export const DEFAULT_TABLE_MODE = DualModes.View;

export enum CampaignDataApiColumnGroupIdentifier {
  CampaignDetailsFields = 'campaignDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PlacementSettingsFields = 'placementSettingsFields',
  PerformanceFields = 'performanceFields',
  FlywheelSettingsFields = 'flywheelSettingsFields',
}

export enum AdgroupDataApiColumnGroupIdentifier {
  AdGroupDetailsFields = 'adGroupDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PlacementSettingsFields = 'placementSettingsFields',
  PerformanceFields = 'performanceFields',
}

export enum ProductAdsApiColumnGroupIdentifier {
  AdItemDetailsFields = 'adItemDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export enum ProfileDataApiColumnGroupIdentifier {
  ProfileDetailsFields = 'profileDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export enum TargetsDataApiColumnGroupIdentifier {
  TargetsDetailsFields = 'targetsDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export interface ApiColumnsWithAdLevel {
  readonly adLevel?: AdLevel;
  readonly columns: string[];
}

export const ADLEVEL_TO_ADLEVEL_API_MAPPER: Record<
  AdLevel,
  AdLevelApiEndpoint
> = {
  [AdLevel.Campaigns]: AdLevelApiEndpoint.Campaigns,
  [AdLevel.AdGroups]: AdLevelApiEndpoint.Adgroups,
  [AdLevel.ProductAds]: AdLevelApiEndpoint.ProductAds,
  [AdLevel.Profiles]: AdLevelApiEndpoint.Profiles,
  [AdLevel.Targets]: AdLevelApiEndpoint.Targets,
  [AdLevel.KeywordTargets]: AdLevelApiEndpoint.KeywordTargets,
  [AdLevel.ProductTargets]: AdLevelApiEndpoint.KeywordTargets,
};

export const API_TO_COLUMN_CAMPAIGN_TYPE_MAPPER: Record<
  string,
  string
> = Object.freeze({
  sponsoredProducts: 'Sponsored products',
  sponsoredBrands: 'Sponsored brands',
});

export enum ADS_MANAGER_AD_LEVEL_PAGES {
  WalmartSponsoredProductsAdGroups = '/ads-optimization/ads-manager/walmart/sponsored-products/ad-groups',
  WalmartSponsoredProductsTargets = '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets',
  WalmartSponsoredProductsProductAds = '/ads-optimization/ads-manager/walmart/sponsored-products/product-ads',
  WalmartSponsoredProductsCampaigns = '/ads-optimization/ads-manager/walmart/sponsored-products/campaigns',
  WalmartSearchBrandAmplifierAdGroups = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/ad-groups',
  WalmartSearchBrandAmplifierKeywordTargets = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/keyword-targets',
  WalmartSearchBrandAmplifierProductAds = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/product-ads',
  WalmartSearchBrandAmplifierProfiles = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/profiles',
  WalmartSearchBrandAmplifierCampaigns = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/campaigns',
  AmazonSponsoredProductsAdGroups = '/ads-optimization/ads-manager/amazon/sponsored-products/ad-groups',
  AmazonSponsoredProductsProductAds = '/ads-optimization/ads-manager/amazon/sponsored-products/product-ads',
  AmazonSponsoredProductsTargets = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  AmazonSponsoredProductsCampaigns = '/ads-optimization/ads-manager/amazon/sponsored-products/campaigns',
  AmazonSponsoredBrandsProductsTarget = '/ads-optimization/ads-manager/amazon/sponsored-brands/product-targets',
  AmazonSponsoredBrandsCampaigns = '/ads-optimization/ads-manager/amazon/sponsored-brands/campaigns',
  AmazonSponsoredBrandsKeywordTargets = '/ads-optimization/ads-manager/amazon/sponsored-brands/keyword-targets',
  AmazonSponsoredDisplayCampaigns = '/ads-optimization/ads-manager/amazon/sponsored-display/campaigns',
  AmazonSponsoredDisplayTargets = '/ads-optimization/ads-manager/amazon/sponsored-display/targets',
  AmazonSponsoredProductsProductsTarget = '/ads-optimization/ads-manager/amazon/sponsored-products/product-targets',
  AmazonSponsoredProductsKeywordTargets = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  AmazonSponsoredDisplayAdGroups = '/ads-optimization/ads-manager/amazon/sponsored-display/ad-groups',
  AmazonSponsoredDisplayProductAds = '/ads-optimization/ads-manager/amazon/sponsored-display/product-ads',
  // TODO: Update  strings. Add ad type and channel name in the key.
  KeywordTargets = '',
  ProductTargets = '',
}

export const ADS_MANAGER_PAGE_TO_AD_TYPE_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES,
  AdType
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargets]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.KeywordTargets]: AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.ProductTargets]: AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets]:
    AdType.SearchBrandAmplifier,
};

export const ADS_MANAGER_PAGE_TO_SALESCHANNEL_MAPPER: Partial<
  Record<ADS_MANAGER_AD_LEVEL_PAGES, FlywheelSalesChannel>
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargets]:
    FlywheelSalesChannel.Amazon,
};

export const ADS_MANAGER_DASHBOARD = '/ads-optimization/ads-manager/';

export enum ADS_MANAGER_AD_LEVEL_PAGES_URI {
  Campaigns = 'campaigns',
  Targets = 'targets',
  KeywordTargets = 'keyword-targets',
  ProductTargets = 'product-targets',
  ProductAds = 'product-ads',
  AdGroups = 'ad-groups',
  Profiles = 'profiles',
}

export const ADLEVEL_TABLE_ID_TO_AD_LEVEL_MAPPER: Record<string, AdLevel> = {
  [ADS_MANAGER_ADGROUP_TABLE_ID]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_ITEMS_TABLE_ID]: AdLevel.ProductAds,
  [ADS_MANAGER_TARGETS_TABLE_ID]: AdLevel.KeywordTargets,
  [ADS_MANAGER_CAMPAIGNS_TABLE_ID]: AdLevel.Campaigns,
  [ADS_MANAGER_PROFILES_TABLE_ID]: AdLevel.Profiles,
};

export const ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES,
  AdLevel
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups]:
    AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups]:
    AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets]: AdLevel.Targets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.KeywordTargets]: AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.ProductTargets]: AdLevel.ProductTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget]:
    AdLevel.ProductTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget]:
    AdLevel.ProductTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups]:
    AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles]:
    AdLevel.Profiles,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets]:
    AdLevel.KeywordTargets,
};

export const ADS_MANAGER_DETAILS_PAGE_AD_LEVEL_MAPPER: Record<
  AdLevel,
  Array<AdLevel>
> = {
  [AdLevel.Campaigns]: [
    AdLevel.AdGroups,
    AdLevel.KeywordTargets,
    AdLevel.ProductTargets,
  ],
  [AdLevel.AdGroups]: [
    AdLevel.Targets,
    AdLevel.ProductAds,
    AdLevel.KeywordTargets,
    AdLevel.Profiles,
    AdLevel.ProductTargets,
  ],
  [AdLevel.ProductAds]: [],
  [AdLevel.Profiles]: [],
  [AdLevel.Targets]: [],
  [AdLevel.KeywordTargets]: [],
  [AdLevel.ProductAds]: [],
  [AdLevel.KeywordTargets]: [],
  [AdLevel.ProductTargets]: [],
};

export const AD_LEVEL_TO_ADS_MANAGER_PAGE_MAPPER: Record<
  AdLevel,
  ADS_MANAGER_AD_LEVEL_PAGES[]
> = {
  [AdLevel.AdGroups]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups,
  ],
  [AdLevel.ProductAds]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds,
  ],
  [AdLevel.Campaigns]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns,
  ],
  [AdLevel.KeywordTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.KeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargets,
  ],
  [AdLevel.ProductTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.ProductTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget,
  ],
  [AdLevel.Targets]: [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets],
  [AdLevel.Profiles]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles,
  ],
};

export const ADS_MANAGER_PAGE_URI_TO_AD_LEVEL_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AdLevel
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets]: AdLevel.Targets,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns]: AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets]: AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets]: AdLevel.ProductTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds]: AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles]: AdLevel.Profiles,
};

export const AD_LEVEL_TO_ADS_MANAGER_PAGE_URI_MAPPER: Record<
  AdLevel,
  ADS_MANAGER_AD_LEVEL_PAGES_URI
> = {
  [AdLevel.AdGroups]: ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
  [AdLevel.Targets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets,
  [AdLevel.ProductAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds,
  [AdLevel.KeywordTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets,
  [AdLevel.Campaigns]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
  [AdLevel.ProductTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets,
  [AdLevel.Profiles]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles,
};

export enum ADS_MANAGER_EMBEDDED_ROUTES {
  WalmartSponsoredProductsAdGroups = 'walmart/sponsored-products/ad-groups',
  WalmartSponsoredProductsProductAds = 'walmart/sponsored-products/product-ads',
  WalmartSponsoredProductsTargets = 'walmart/sponsored-products/keyword-targets',
  WalmartSponsoredProductsCampaigns = 'walmart/sponsored-products/campaigns',
  WalmartSponsoredProductsCampaignDetails = 'walmart/sponsored-products/campaigns/:campaignId',
  WalmartSponsoredProductsAdGroupDetails = 'walmart/sponsored-products/ad-groups/:adGroupsId',
  WalmartSponsoredProductsCampaignAdGroupDetails = 'walmart/sponsored-products/campaigns/:campaignId/:adGroupsId',
  WalmartSearchBrandAmplifierCampaigns = 'walmart/search-brand-amplifier/campaigns',
  WalmartSearchBrandAmplifierAdGroups = 'walmart/search-brand-amplifier/ad-groups',
  WalmartSearchBrandAmplifierProductAds = 'walmart/search-brand-amplifier/product-ads',
  WalmartSearchBrandAmplifierKeywordTargets = 'walmart/search-brand-amplifier/keyword-targets',
  WalmartSearchBrandAmplifierProfiles = 'walmart/search-brand-amplifier/profiles',
  WalmartSearchBrandAmplifierCampaignsDetails = 'walmart/search-brand-amplifier/campaigns/:campaignId',
  WalmartSearchBrandAmplifierAdGroupsDetails = 'walmart/search-brand-amplifier/ad-groups/:adGroupsId',
  WalmartBrandAmplifierCampaignAdGroupDetails = 'walmart/search-brand-amplifier/campaigns/:campaignId/:adGroupsId',

  AmazonSponsoredProductsAdGroups = 'amazon/sponsored-products/ad-groups',
  AmazonSponsoredProductsProductAds = 'amazon/sponsored-products/product-ads',
  AmazonSponsoredProductsTargets = 'amazon/sponsored-products/keyword-targets',
  AmazonSponsoredProductsCampaigns = 'amazon/sponsored-products/campaigns',
  AmazonSponsoredProductsCampaignDetails = 'amazon/sponsored-products/campaigns/:campaignId',
  AmazonSponsoredProductsAdGroupDetails = 'amazon/sponsored-products/ad-groups/:adGroupsId',
  AmazonSponsoredProductsProductsTarget = 'amazon/sponsored-products/product-targets',
  AmazonSponsoredProductsKeywordTargets = 'amazon/sponsored-products/keyword-targets',
  AmazonSponsoredProductsCampaignAdGroupDetails = 'amazon/sponsored-products/campaigns/:campaignId/:adGroupsId',
  AmazonSponsoredBrandsProductsTarget = 'amazon/sponsored-brands/product-targets',
  AmazonSponsoredBrandsCampaigns = 'amazon/sponsored-brands/campaigns',
  AmazonSponsoredBrandsKeywordTargets = 'amazon/sponsored-brands/keyword-targets',
  AmazonSponsoredBrandsCampaignDetails = 'amazon/sponsored-brands/campaigns/:campaignId',
  AmazonSponsoredDisplayCampaigns = 'amazon/sponsored-display/campaigns',
  AmazonSponsoredDisplayTargets = 'amazon/sponsored-display/targets',
  AmazonSponsoredDisplayAdGroups = 'amazon/sponsored-display/ad-groups',
  AmazonSponsoredDisplayProductAds = 'amazon/sponsored-display/product-ads',
  AmazonSponsoredDisplayCampaignDetails = 'amazon/sponsored-display/campaigns/:campaignId',
  AmazonSponsoredDisplayAdGroupDetails = 'amazon/sponsored-display/ad-groups/:adGroupsId',
  AmazonSponsoredDisplayCampaignAdGroupDetails = 'amazon/sponsored-display/campaigns/:campaignId/:adGroupsId',
}

export const AD_LEVEL_PAGE_URI_TO_PAGE_URL_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  ADS_MANAGER_AD_LEVEL_PAGES[]
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles,
  ],
};

export interface TableDataAdsManager {
  readonly isEditMode: boolean;
  readonly selectedAdType: AdType;
  readonly adTypes: AdType[];
  readonly merchantCountry: MerchantCountryCode;
  readonly salesChannel: FlywheelSalesChannel;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly aoApiClient: AOApiClient;
  readonly adLevel?: AdLevel;
  readonly portfolioNames?: Portfolio[];
  readonly merchantType?: MerchantType;
  readonly productConnectionStatus?: ConnectionStatus;
  readonly updateEditedRecords?: (editedRowInfo: EditedRowInfo) => void;
  readonly selectedEndDate?: DateTime;
  readonly allMerchants: MerchantCountry[];
  readonly aiEnabled?: boolean;
  readonly merchantsWithAutomationEnabled?: string[];
  readonly aiDisabledForAllSelectedMerchants?: boolean;
}

export enum ADLEVEL_DETAILS_FILTER_FIELD {
  campaignsId = 'campaignId',
  adGroupsId = 'adGroupId',
}

export interface TableCellChangeProductAd {
  [key: string]: PartialRecord<EditableColumnsAdItem, string>;
}

export type TableCellChange = {
  [key: string]: Record<string, string>;
};

export interface TableChangeGlobalAdLevelCurrency {
  readonly currencyCode: StrictStringMap<string>;
}
export interface TableCellChangeAdGroup {
  [key: string]: Record<EditableColumnsAdGroup, string>;
}
export interface TableCellChangeTarget {
  [key: string]: Record<EditableColumnsTarget, string>;
}
export interface TableCellChangeCampaign {
  [key: string]: PartialRecord<EditableColumnsCampaign, string>;
}

export const AD_LEVEL_EXTERNAL_ID_MAPPER: Record<AdLevel, Array<string>> = {
  [AdLevel.Campaigns]: ['extCampaignId'],
  [AdLevel.AdGroups]: ['extAdGroupId'],
  [AdLevel.ProductAds]: ['extItemId'],
  [AdLevel.Targets]: ['extTargetId'],
  [AdLevel.KeywordTargets]: ['extTargetId'],
  [AdLevel.ProductTargets]: ['extTargetId'],
  [AdLevel.Profiles]: ['extProfileId'],
};

export const AD_LEVEL_INTERNAL_ID_MAPPER: Partial<Record<AdLevel, string>> = {
  [AdLevel.Campaigns]: 'campaignId',
  [AdLevel.AdGroups]: 'adGroupId',
};

export const EXT_CAMPAIGN_ID = 'extCampaignId';
export const EXT_ADGROUP_ID = 'extAdGroupId';
export const END_DATE_PLACEHOLDER = 'MM/DD/YYYY';
export const AD_ITEM_SEARCH_KEY_FIELD = 'searchText';
export const AD_ITEMS_EXT_ITEM_ID_FILTER_FIELD = 'extItemId';

enum AdTypeInUrl {
  SponsoredProducts = 'sponsored-products',
  SearchBrandAmplifier = 'search-brand-amplifier',
  SponsoredDisplay = 'sponsored-display',
  SponsoredBrands = 'sponsored-brands',
}

export const AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER: Record<
  AdType,
  AdTypeInUrl
> = {
  [AdType.SponsoredProducts]: AdTypeInUrl.SponsoredProducts,
  [AdType.SearchBrandAmplifier]: AdTypeInUrl.SearchBrandAmplifier,
  [AdType.SponsoredBrands]: AdTypeInUrl.SponsoredBrands,
  [AdType.SponsoredDisplay]: AdTypeInUrl.SponsoredDisplay,
  // This is just a placeholder. We don't really use SponsoredBrandsVideo anywhere
  [AdType.SponsoredBrandsVideo]: AdTypeInUrl.SponsoredDisplay,
};

export const AD_LEVEL_VALUE_TO_AD_LEVEL_IN_URL_MAPPER: Record<
  AdLevel,
  ADS_MANAGER_AD_LEVEL_PAGES_URI
> = {
  [AdLevel.Campaigns]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
  [AdLevel.AdGroups]: ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
  [AdLevel.ProductAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds,
  [AdLevel.Targets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets,
  [AdLevel.KeywordTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets,
  [AdLevel.ProductTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets,
  [AdLevel.Profiles]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles,
};

export interface Checkbox {
  readonly [key: string]: {
    readonly isSelected: boolean;
    readonly isDisabled?: boolean;
  };
}

export interface TabCheckbox {
  readonly [key: string]: Checkbox;
}

export interface DataInspectionAdLevelConfigProps {
  adLevel: AdLevel;
  columns: TabCheckbox;
}

interface DataInspectionAdTypeConfigProps {
  adType: AdType;
  config: DataInspectionAdLevelConfigProps[];
}

export type DataInspectorColumnManagerConfigProps = {
  readonly [salesChannel in FlywheelSalesChannel]: DataInspectionAdTypeConfigProps[];
};

export const EXPRESSION_TYPE_TO_FILTER_I18NKEY_MAPPER: Record<
  ExpressionType,
  I18nKey
> = {
  [ExpressionType.AsinSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_SAME_AS,
  [ExpressionType.AudienceSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDEINCE_SAME_AS,
  [ExpressionType.AsinCategorySameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_CATEGORY_SAME_AS,
  [ExpressionType.AsinBrandSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_BRAND_SAME_AS,
  [ExpressionType.AsinPriceLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_LESS_THAN,
  [ExpressionType.AsinPriceGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_GREATER_THAN,
  [ExpressionType.AsinPriceBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_BETWEEN,
  [ExpressionType.AsinReviewRatingBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_BETWEEN,
  [ExpressionType.AsinReviewRatingGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_GREATER_THAN,
  [ExpressionType.AsinReviewRatingLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_LESS_THAN,
  [ExpressionType.AsinIsPrimeShippingAvailable]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_IS_PRIME_SHIPPING_AVAILABLE,
  [ExpressionType.AsinAgeRangeSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_AGE_RANGE_SAME_AS,
  [ExpressionType.AsinGenreSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_GENRE_SAME_AS,
  [ExpressionType.Lookback]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_LOOKUP,
  [ExpressionType.QueryHighRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES,
  [ExpressionType.QueryBroadRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES,
  [ExpressionType.AsinSubstituteRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED,
  [ExpressionType.AsinAccessoryRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED,
  [ExpressionType.SimilarProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_SIMILAR_PRODUCT,
  [ExpressionType.ExactProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_EXACT_PRODUCT,
  [ExpressionType.RelatedProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_RELATED_PRODUCT,
  [ExpressionType.AsinIsPrimeShippingEligible]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_IS_PRIME_SHIPPING_AVAILABLE,
};

export const EXPRESSION_TYPE_TO_I18NKEY_MAPPER_WITHOUT_EXPRESSION_VALUE: Record<
  ExpressionType,
  I18nKey
> = {
  [ExpressionType.AsinSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AudienceSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDIENCE_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinCategorySameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_CATEGORY_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinBrandSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_BRAND_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinPriceLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_LESS_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinPriceGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_GREATER_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinPriceBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_BETWEEN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinReviewRatingBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_BETWEEN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinReviewRatingGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_GREATER_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinReviewRatingLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_LESS_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinIsPrimeShippingAvailable]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SHIPPING_IS_PRIME_ELIGIBLE_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinAgeRangeSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_AGE_RANGE_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinGenreSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_GENRE_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.Lookback]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_LOOKBACK_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.QueryHighRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES,
  [ExpressionType.QueryBroadRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES,
  [ExpressionType.AsinSubstituteRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED,
  [ExpressionType.AsinAccessoryRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED,
  [ExpressionType.SimilarProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_SIMILAR_PRODUCT,
  [ExpressionType.ExactProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_PRODUCT,
  [ExpressionType.RelatedProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_RELATED_PRODUCT,
  [ExpressionType.AsinIsPrimeShippingEligible]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_ASIN_IS_PRIME_SHIPPING_ELIGIBLE,
};

export const EXPRESSION_TYPE_TO_CONDITIONAL_TEXT_I18NKEY_MAPPER: Partial<
  Record<ExpressionType, I18nKey>
> = {
  [ExpressionType.AsinSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinCategorySameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinBrandSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinPriceLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN,
  [ExpressionType.AsinPriceGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN,
  [ExpressionType.AsinPriceBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN,
  [ExpressionType.AsinReviewRatingBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN,
  [ExpressionType.AsinReviewRatingGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN,
  [ExpressionType.AsinReviewRatingLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN,
  [ExpressionType.AsinIsPrimeShippingAvailable]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinAgeRangeSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinGenreSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.Lookback]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinIsPrimeShippingEligible]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.SimilarProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_SIMILAR_PRODUCT,
  [ExpressionType.AudienceSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.ExactProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_PRODUCT,
};

export const EXPRESSION_TYPE_NON_CONDITIONAL_TEXT_ARRAY: ExpressionType[] = [
  ExpressionType.QueryHighRelMatches,
  ExpressionType.QueryBroadRelMatches,
  ExpressionType.AsinSubstituteRelated,
  ExpressionType.AsinAccessoryRelated,
  ExpressionType.SimilarProduct,
  ExpressionType.ExactProduct,
  ExpressionType.RelatedProduct,
];

export const EXPRESSION_TYPE_BOOLEAN_KEYS: ExpressionType[] = [
  ExpressionType.AsinIsPrimeShippingEligible,
];

export const AD_TYPE_DEFAULT_VALUE: Record<FlywheelSalesChannel, AdType> = {
  [FlywheelSalesChannel.Walmart]: AdType.SponsoredProducts,
  [FlywheelSalesChannel.Amazon]: AdType.SponsoredProducts,
};

export const CAMPAIGN_FILTER_DATE_FORMAT_MOMENT = 'YYYY-MM-DD';

export interface AoAdsManagerUrlParts {
  readonly adLevel: string;
  readonly adType: string;
  readonly salesChannel: string;
}

export interface NumericValueConfig {
  readonly min?: number;
  readonly max?: number;
  readonly default?: number;
}

export interface NumericValueConfigProps extends NumericValueConfig {
  readonly adTypes: AdType[];
  readonly salesChannels: FlywheelSalesChannel[];
  readonly columnNames: string[];
  readonly countryCodes: MerchantCountryCode[];
  readonly campaignTargetingType?: CampaignTargetingType;
  readonly merchantType?: MerchantType;
  readonly campaignCostType?: CampaignCostType;
}

export const TARGET_TYPE_API_RESPONSE_TO_AD_LEVEL_MAPPER: Record<
  AdType,
  Record<string, AdLevel>
> = {
  [AdType.SponsoredBrands]: {
    'Keyword Targeting': AdLevel.KeywordTargets,
    'Product Attribute Targeting': AdLevel.ProductTargets,
  },
  [AdType.SearchBrandAmplifier]: {},
  [AdType.SponsoredBrandsVideo]: {
    'Keyword Targeting': AdLevel.KeywordTargets,
    'Product Attribute Targeting': AdLevel.ProductTargets,
  },
  [AdType.SponsoredProducts]: {
    'Keyword Targeting': AdLevel.KeywordTargets,
    'Product Attribute Targeting': AdLevel.ProductTargets,
  },
  [AdType.SponsoredDisplay]: {
    'Keyword Targeting': AdLevel.KeywordTargets,
    'Product Attribute Targeting': AdLevel.Targets,
  },
};

export const TARGET_TYPE_API_TO_AD_LEVEL_MAPPER: Record<AdLevel, string> = {
  [AdLevel.Campaigns]: 'campaign',
  [AdLevel.AdGroups]: 'adgroup',
  [AdLevel.Targets]: 'target',
  [AdLevel.ProductAds]: 'productAd',
  [AdLevel.KeywordTargets]: 'keywordTarget',
  [AdLevel.ProductTargets]: 'productTarget',
  [AdLevel.Profiles]: 'profile',
};

export const ENTITY_TYPE_FOR_TARGET_MAPPER: Partial<
  Record<AdLevel, EntityTypeRequestParameterForTargets>
> = {
  [AdLevel.KeywordTargets]:
    EntityTypeRequestParameterForTargets.KeywordTargeting,
  [AdLevel.ProductTargets]:
    EntityTypeRequestParameterForTargets.ProductTargetting,
  [AdLevel.Targets]: EntityTypeRequestParameterForTargets.Targets,
};
export interface EditedRowInfo {
  readonly rowId: string;
  readonly isNameUnique: boolean;
}

export const TARGET_ADLEVEL_LIST = [
  AdLevel.Targets,
  AdLevel.KeywordTargets,
  AdLevel.ProductTargets,
];
export const MERCHANT_NAME_COLUMN_ID_FOR_EXPORT = 'merchantName';

export const AD_TYPE_SHORT_FORM: Record<AdType, string> = {
  [AdType.SponsoredProducts]: 'sp',
  [AdType.SponsoredDisplay]: 'sd',
  [AdType.SponsoredBrands]: 'sb',
  [AdType.SponsoredBrandsVideo]: 'sbv',
  [AdType.SearchBrandAmplifier]: 'sba',
};

export const CAMPAIGN_COST_TYPE_MAPPER: Record<CampaignCostType, I18nKey> = {
  [CampaignCostType.CPC]: I18nKey.ADS_MANAGER_CAMPAIGN_COST_TYPE_CPC,
  [CampaignCostType.VCPM]: I18nKey.ADS_MANAGER_CAMPAIGN_COST_TYPE_VCPM,
};

export const KEYWORD_REVIEW_STATUS_MAPPER: Record<
  KeywordReviewStatusType,
  I18nKey
> = {
  [KeywordReviewStatusType.Approved]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED,
  [KeywordReviewStatusType.Pending]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  [KeywordReviewStatusType.Rejected]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED,
};

export const ADGROUP_REVIEW_STATUS_MAPPER: Record<
  AdGroupReviewStatusType,
  I18nKey
> = {
  [AdGroupReviewStatusType.Pending]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  [AdGroupReviewStatusType.InProgress]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_INPROGRESS,
  [AdGroupReviewStatusType.Complete]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_COMPLETED,
  [AdGroupReviewStatusType.Cancelled]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED,
};

export const PRODUCT_REVIEW_STATUS_MAPPER: Record<
  ProductReviewStatusType,
  I18nKey
> = {
  [ProductReviewStatusType.Approved]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED,
  [ProductReviewStatusType.Pending]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  [ProductReviewStatusType.Rejected]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED,
};

export const REVIEW_URLS = {
  SBA_PROFILE_REVIEW_STATUS:
    'https://intercom.help/flywheel-20/en/articles/5831762-walmart-sba-ads',
  DEFAULT: 'https://www.teikametrics.com/help/ao-keyword-review-status-tooltip',
};
