import { IdToken } from '@auth0/auth0-react';
import {
  RouteComponentProps,
  Router as ReachRouter,
  useLocation,
  useNavigate,
} from '@reach/router';
import { Spinner } from '@teikametrics/tm-design-system/components/Spinner';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { TrialEndedModalFlow } from './components/trialEndedModalFlow';
import { VerifyEmailModal } from './components/verifyEmailModal';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from './containers/optimizelyProvider/optimizelyProvider';
import { SubscriptionContext } from './containers/SubscriptionProvider';
import {
  getCurrentAccountFromContext,
  getFreeTrialDaysRemaining,
  isAIPlanEnabled,
  isManagedAccount,
  showTrialEndedPaymentNeeded,
} from './containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from './containers/userProvider/userProvider';
import { createFAMApiClient } from './lib/clients/FAMApiClient';
import { AccountPermission, Role } from './lib/types/Fam';
import { OptimizelyFlags } from './lib/types/OptimizelyFlags';
import { getAutomationStatusByChannel } from './lib/utilities/tracking';
import * as Segment from './lib/utilities/segment';
import { Navbar } from './Navbar';
import { NavPaths } from './NavPaths';
import {
  isIdleTimeExpired,
  onIdleTimeout,
  setIdleTimeout,
} from './lib/utilities/storageUtils';

const AccountModule = lazy(() => import('./modules/account'));
const AdvertisingOptimization = lazy(
  () => import('./modules/advertising_optimization')
);
const Onboarding = lazy(() => import('./modules/onboarding'));
const TrialBanner = lazy(() => import('./components/trialBanner'));
const OutstandingBalanceModal = lazy(
  () => import('./modules/billing/components/OutstandingBalanceModal')
);
const MarketIntelligence = lazy(() => import('./modules/market_intelligence'));
const UserProfile = lazy(() => import('./modules/userProfile'));
const AIPowered = lazy(() => import('./modules/ai-powered/AIPowered'));
const Products = lazy(() => import('./modules/products'));
const Compass = lazy(() => import('./modules/compass'));
const FlowV2 = lazy(() => import('./modules/flowV2/FlowV2'));

interface ParamProps {
  readonly key: string;
  readonly openDelinquentModal: boolean;
}

export const AppModules: React.FC<
  RouteComponentProps &
    Readonly<{
      token: IdToken | null;
      permissions?: AccountPermission;
    }>
> = ({ token, permissions }): JSX.Element => {
  const optimizelyContext = useContext<OptimizelyContextState>(
    OptimizelyContext
  );
  const shouldEnforceFreeTrial =
    optimizelyContext.featureFlags[OptimizelyFlags.FreeTrialEnforcer];

  const navigate = useNavigate();
  const location = useLocation();
  const userRole = permissions?.role || Role.ACCOUNT_OWNER;
  const userContext = useContext<UserContextState>(UserContext);
  const hasVerifiedEmail = userContext.userInfo.userDetails?.emailVerified;
  const isDelinquent = userContext?.billingInfo?.isDelinquent;
  const aiEnabled = isAIPlanEnabled(userContext);

  const [
    showOutstandingBalanceModal,
    setShowOutstandingBalanceModal,
  ] = useState(false);

  const [
    showTrialEndedModalFlow,
    setShowTrialEndedModalFlow,
  ] = useState<boolean>(false);

  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);
  const currentAccount = getCurrentAccountFromContext(userContext);
  const isAccountLockedOut = !!currentAccount?.lockedAt;
  const daysRemainingInTrial =
    (currentAccount && getFreeTrialDaysRemaining(currentAccount)) || 0;
  const isManaged = isManagedAccount(userContext);

  const subscriptionContext = useContext(SubscriptionContext);
  const {
    salesChannelData,
    isSubscriptionInformationLoaded,
  } = subscriptionContext;

  useEffect(() => {
    if (isSubscriptionInformationLoaded) {
      const aiStatus = getAutomationStatusByChannel(salesChannelData);
      Segment.identify(
        userContext.userInfo.userDetails?.id,
        userContext.userInfo.userDetails?.email!,
        aiStatus
      );
    }
  }, [isSubscriptionInformationLoaded]);

  const navigateToHomeOrIO = () => {
    // we need to check if path is root or GetStarted since isManagedAccount
    // may not be set when this first runs
    if (
      location.pathname === '/' ||
      location.pathname === NavPaths.GetStarted
    ) {
      let navigateTo = NavPaths.GetStarted;
      if (
        aiEnabled &&
        (optimizelyContext.featureFlags[OptimizelyFlags.Compass] || isManaged)
      ) {
        navigateTo = NavPaths.Compass;
      }
      navigate(navigateTo);
    }
  };

  useEffect(() => {
    if (showTrialEndedPaymentNeeded(userContext, shouldEnforceFreeTrial)) {
      setShowTrialEndedModalFlow(true);
    }
  }, [shouldEnforceFreeTrial]);

  useEffect(() => {
    if (
      (location.state as ParamProps)?.openDelinquentModal ||
      isAccountLockedOut
    ) {
      setShowOutstandingBalanceModal(true);
    }
  }, [location.state, isDelinquent]);

  useEffect(() => navigateToHomeOrIO(), [
    isManaged,
    optimizelyContext.featureFlags,
  ]);

  useEffect(() => {
    isIdleTimeExpired() && onIdleTimeout();

    window.addEventListener('click', setIdleTimeout);
    return () => {
      window.removeEventListener('click', setIdleTimeout);
    };
  }, []);

  const verifyEmail = () => {
    createFAMApiClient(token!).triggerVerificationEmail();
  };

  if (!optimizelyContext.isClientReady) {
    return <Spinner />;
  }

  const showTrialAndOnboarding = !isManaged;

  const showOnboardingNavPath =
    !isManaged &&
    (optimizelyContext.featureFlags[OptimizelyFlags.Compass]
      ? !aiEnabled
      : true);

  return (
    <div className="flywheel-container">
      {!hasVerifiedEmail && <VerifyEmailModal onClick={verifyEmail} />}
      <Navbar
        userRole={userRole}
        showOnboardingNavPath={showOnboardingNavPath}
        daysRemainingInTrial={daysRemainingInTrial}
        isManaged={isManaged}
      />
      <Suspense fallback={null}>
        <div className="overflow-auto">
          {token && showTrialAndOnboarding && (
            <TrialBanner idToken={token} userContext={userContext} />
          )}

          {showOutstandingBalanceModal && showTrialAndOnboarding && (
            <OutstandingBalanceModal
              showModal={showOutstandingBalanceModal}
              onClose={() => setShowOutstandingBalanceModal(false)}
              isAccountLockedOut={isAccountLockedOut}
            />
          )}

          {showTrialEndedModalFlow && (
            <TrialEndedModalFlow
              closeModal={() => setShowTrialEndedModalFlow(false)}
              famClient={famApiClient}
            />
          )}

          <ReachRouter>
            {showOnboardingNavPath && <Onboarding path={NavPaths.GetStarted} />}
            <MarketIntelligence path="market-intelligence/*" idToken={token} />
            {token && <AccountModule path="account/*" idToken={token} />}
            <UserProfile path="user-profile" />
            <AdvertisingOptimization
              path="ads-optimization/*"
              idToken={token}
            />
            <AIPowered path="ai-powered/*" idToken={token} />
            {optimizelyContext.featureFlags[OptimizelyFlags.SkuCatalog] && (
              <Products path="products/*" idToken={token} />
            )}
            {optimizelyContext.featureFlags[OptimizelyFlags.FlowV2] && (
              <FlowV2 path="ai-powered/v2/*" idToken={token} />
            )}
            {optimizelyContext.featureFlags[OptimizelyFlags.Compass] && (
              <Compass path="compass/*" idToken={token} />
            )}
          </ReachRouter>
        </div>
      </Suspense>
    </div>
  );
};
