import * as React from 'react';
import * as animationData from '../../img/loader.json';
import Lottie from 'react-lottie';
import { LoaderProps } from './types';
import './styles.scss';

const DEFAULT_WIDTH = 50;
const DEFAULT_HEIGHT = 50;

const defaultOptions = {
  loop: true,
  autoplay: true,
  // https://github.com/chenqingspring/vue-lottie/issues/20
  // `.default` is required for storybook to work but not for production
  animationData: (animationData as any).default || animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loader: React.FC<LoaderProps> = (props) => (
  <div
    className={props.className}
    style={{
      position: props.position,
      top: props.top,
      left: props.left,
    }}
  >
    <Lottie
      options={defaultOptions}
      height={props.height || DEFAULT_HEIGHT}
      width={props.width || DEFAULT_WIDTH}
    />
  </div>
);

export default Loader;
