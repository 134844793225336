/* eslint-disable max-lines */
import includes from 'lodash/includes';
import values from 'lodash/values';

enum I18nKey {
  GENERAL_PERIOD = 'react.general.period',
  GENERAL_BOOLEAN_TRUE = 'react.general.boolean.true',
  GENERAL_BOOLEAN_FALSE = 'react.general.boolean.false',
  GENERIC_EM_DASH = 'react.generic.em_dash',
  GENERIC_SEARCH_LABEL = 'react.general.search_label',
  GENERIC_CANCEL_LABEL = 'react.general.cancel_label',
  GENERIC_SAVE_N_CHANGES = 'react.general.save_n_changes_label',
  ACCOUNT_SWITCHER_PAGE_HEADER = 'react.pages.account_switcher.header',
  ACCOUNT_SWITCHER_PAGE_PILL = 'react.pages.account_switcher.pill',
  ACCOUNT_SWITCHER_PAGE_LOG_OUT = 'react.pages.account_switcher.log_out',
  USER_MANAGEMENT_TABLE_HEADER_USER = 'react.pages.user_management.table_header.user',
  USER_MANAGEMENT_TABLE_HEADER_STATUS = 'react.pages.user_management.table_header.status',
  USER_MANAGEMENT_TABLE_HEADER_ROLE = 'react.pages.user_management.table_header.role',
  USER_MANAGEMENT_TABLE_ADD_USER = 'react.pages.user_management.table.add_user',
  USER_MANAGEMENT_TABLE_EDIT = 'react.pages.user_management.table.edit',
  USER_MANAGEMENT_TABLE_RESEND_INVITE = 'react.pages.user_management.table.resend_invite',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_SUCCESS_HEADLINE = 'react.pages.user_management.table.resend_invite.toast.success_headline',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_SUCCESS_DESCRIPTION = 'react.pages.user_management.table.resend_invite.toast.success_description',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_FAILURE_HEADLINE = 'react.pages.user_management.table.resend_invite.toast.failure_headline',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_FAILURE_DESCRIPTION = 'react.pages.user_management.table.resend_invite.toast.failure_description',
  USER_MANAGEMENT_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.pages.user_management.table.footer.total_items_text',
  USER_MANAGEMENT_INVITE_STATUS_EXPIRED = 'react.pages.user_management.invite_status.expired',
  USER_MANAGEMENT_INVITE_STATUS_PENDING = 'react.pages.user_management.invite_status.pending',
  USER_MANAGEMENT_INVITE_STATUS_VERIFIED = 'react.pages.user_management.invite_status.active',
  USER_MANAGEMENT_CREATE_NEW_USER_SUBMIT = 'react.pages.user_management.create_new_user.send_invite',
  USER_MANAGEMENT_CREATE_NEW_USER_CANCEL = 'react.pages.user_management.create_new_user.cancel',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_TITLE = 'react.pages.user_management.create_new_user.modal_title',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_FIRST_NAME = 'react.pages.user_management.create_new_user.form.first_name',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_LAST_NAME = 'react.pages.user_management.create_new_user.form.last_name',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_EMAIL = 'react.pages.user_management.create_new_user.form.email',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_ROLE = 'react.pages.user_management.create_new_user.form.role',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_ADMIN = 'react.pages.user_management.create_new_user.form.role_options.admin',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_SUCCESS_HEADLINE = 'react.pages.user_management.create_new_user.toast.success_headline',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_SUCCESS_DESCRIPTION = 'react.pages.user_management.create_new_user.toast.success_description',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_FAILURE_HEADLINE = 'react.pages.user_management.create_new_user.toast.failure_headline',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_FAILURE_DESCRIPTION = 'react.pages.user_management.create_new_user.toast.failure_description',
  USER_MANAGEMENT_EDIT_USER_FORM_TITLE = 'react.pages.user_management.edit_user.modal_title',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER = 'react.pages.user_management.edit_user.remove_user',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER_WARNING = 'react.pages.user_management.edit_user.remove_user_warning',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER_SUCCESS = 'react.pages.user_management.edit_user.remove_user_success',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER_FAILURE = 'react.pages.user_management.edit_user.remove_user_failure',
  USER_MANAGEMENT_EDIT_USER_SELECT_ROLE = 'react.pages.user_management.edit_user.select_role',
  NAV_BAR_LABEL_HOME = 'react.components.navbar.home',
  NAV_BAR_LABEL_COMPASS = 'react.components.navbar.compass.header',
  NAV_BAR_LABEL_ACCOUNT = 'react.components.navbar.account.header',
  NAV_BAR_LABEL_ADVERTISING_OPTIMIZATION = 'react.components.navbar.advertising_optimization.header',
  NAV_BAR_LABEL_PRODUCTS = 'react.components.navbar.products.header',
  NAV_BAR_LABEL_SKU_CATALOG = 'react.components.navbar.sku_catalog.header',
  NAV_BAR_LABEL_MARKET_INTELLIGENCE = 'react.components.navbar.market_intelligence.header',
  NAV_BAR_LABEL_MARKET_INTELLIGENCE_SETTINGS = 'react.components.navbar.market_intelligence_settings.header',
  NAV_BAR_LABEL_HELP = 'react.components.navbar.help.header',
  NAV_BAR_LABEL_HELP_TOOLTIP = 'react.components.navbar.help.tooltip',
  HOME_PAGE_TITLE = 'react.pages.home.title',

  HOME_PAGE_ONBOARDING_GUIDE_HEADLINE_WELCOME = 'react.pages.home.onboarding_guide.headline.welcome',
  HOME_PAGE_ONBOARDING_GUIDE_HEADLINE_START_TRIAL = 'react.pages.home.onboarding_guide.headline.start_trial',
  HOME_PAGE_ONBOARDING_GUIDE_HEADLINE_START_DESCRIPTION = 'react.pages.home.onboarding_guide.description.start_trial',

  HOME_PAGE_ONBOARDING_GUIDE_TITLE = 'react.pages.home.onboarding_guide.title',
  HOME_PAGE_ONBOARDING_GUIDE_STEP_ONE = 'react.pages.home.onboarding_guide.step_one',
  HOME_PAGE_ONBOARDING_GUIDE_STEP_TWO = 'react.pages.home.onboarding_guide.step_two',
  HOME_PAGE_ONBOARDING_GUIDE_STEP_THREE = 'react.pages.home.onboarding_guide.step_three',
  HOME_PAGE_ONBOARDING_GUIDE_AI_STEP = 'react.pages.home.onboarding_guide.ai_step',

  HOME_PAGE_ONBOARDING_GUIDE_ADDITIONAL_STEPS = 'react.pages.home.onboarding_guide.additional_steps',
  HOME_PAGE_ONBOARDING_GUIDE_USERS_LINK = 'react.pages.home.onboarding_guide.users_link',
  HOME_PAGE_ONBOARDING_GUIDE_BILLING_LINK = 'react.pages.home.onboarding_guide.billing_link',
  HOME_PAGE_PAYG_TITLE = 'react.pages.home.payg.title',
  HOME_PAGE_PAYG_DESCRIPTION = 'react.pages.home.payg.description',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_ADS_HEADER = 'react.pages.home.connect_step_details_card.ads.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_ADS_INITIAL_DESCRIPTION = 'react.pages.home.connect_step_details_card.ads.initial.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_ADS_INITIAL_BUTTON = 'react.pages.home.connect_step_details_card.ads.initial.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_PRODUCTS_HEADER = 'react.pages.home.connect_step_details_card.products.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_PRODUCTS_INITIAL_DESCRIPTION = 'react.pages.home.connect_step_details_card.products.initial.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_PRODUCTS_INITIAL_BUTTON = 'react.pages.home.connect_step_details_card.products.initial.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_INITIAL_NOTE = 'react.pages.home.connect_step_details_card.initial.note',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SYNCING_DESCRIPTION = 'react.pages.home.connect_step_details_card.syncing.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_CHECK_STATUS_BUTTON = 'react.pages.home.connect_step_details_card.button',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SYNCING_NOTE = 'react.pages.home.connect_step_details_card.syncing.note',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SUCCESS_DESCRIPTION = 'react.pages.home.connect_step_details_card.success.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_FAILED_DESCRIPTION = 'react.pages.home.connect_step_details_card.failed.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SUCCESSFUL_CONNECTIONS = 'react.pages.home.connect_step_details_card.successful_connections',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_FAILED_CONNECTIONS = 'react.pages.home.connect_step_details_card.failed_connections',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_MANAGE_HEADER = 'react.pages.home.connect_step_details_card.ai_manage.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_MANAGE_DESCRIPTION = 'react.pages.home.connect_step_details_card.ai_manage.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_MANAGE_BUTTON = 'react.pages.home.connect_step_details_card.ai_manage.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_START_HEADER = 'react.pages.home.connect_step_details_card.ai_start.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_START_DESCRIPTION = 'react.pages.home.connect_step_details_card.ai_start.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_START_BUTTON = 'react.pages.home.connect_step_details_card.ai_start.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_FOOTNOTE = 'react.pages.home.connect_step_details_card.ai.footnote',

  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_HEADER_COUNTRY = 'react.components.merchant_subscription_claim_table.header.country',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_HEADER_ID = 'react.components.merchant_subscription_claim_table.header.id',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_HEADER_TYPE = 'react.components.merchant_subscription_claim_table.header.type',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_CONTINUE = 'react.components.merchant_subscription_claim_table.action_row_continue',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_CANCEL = 'react.components.merchant_subscription_claim_table.action_row_cancel',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_AVAILABLE_HEADER = 'react.components.merchant_subscription_claim_table.merchant_available_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_SELECTED_HEADER = 'react.components.merchant_subscription_claim_table.merchant_selected_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_NO_MERCHANT_HEADER = 'react.components.merchant_subscription_claim_table.no_merchant_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_NO_MERCHANT_SUB_TITLE = 'react.components.merchant_subscription_claim_table.no_merchant_sub_title',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_HEADER = 'react.components.merchant_subscription_claim_table.merchant_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_SUB_TITLE = 'react.components.merchant_subscription_claim_table.merchant_sub_title',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_NO_MERCHANTS_HEADER = 'react.components.merchant_subscription_claim_table.no_merchants_header',
  SUB_NAV_NEW_ICON_TEXT = 'react.components.navbar.new',

  PRODUCT_SUBSCRIPTIONS_MANAGE_ACCOUNT_BUTTON_TEXT = 'react.pages.product_subscriptions.manage_account_button_text',
  PRODUCT_SUBSCRIPTIONS_MANAGE_ACCOUNT_TOOLTIP_TEXT = 'react.pages.product_subscriptions.manage_account_tooltip_text',

  VERIFY_EMAIL_MODAL_TITLE = 'react.pages.verify_email_modal.title',
  VERIFY_EMAIL_MODAL_SUBTITLE = 'react.pages.verify_email_modal.subtitle',
  VERIFY_EMAIL_MODAL_BUTTON = 'react.pages.verify_email_modal.button',
  CONNECTING_ADS = 'react.pages.connect_sales_channel.connecting_ads',
  BACK_TO_CHANNELS = 'react.pages.connect_sales_channel.back_to_channels',
  CONNECT_SALES_CHANNEL_ROUTE_ADS_HEADER = 'react.pages.connect_sales_channel.route.ads.header',
  CONNECT_SALES_CHANNEL_ROUTE_ADS_DESCRIPTION = 'react.pages.connect_sales_channel.route.ads.description',
  CONNECT_SALES_CHANNEL_ROUTE_ADS_WALMART_NOTE = 'react.pages.connect_sales_channel.route.ads.walmart_note',
  CONNECT_SALES_CHANNEL_ROUTE_PRODUCT_HEADER = 'react.pages.connect_sales_channel.route.product.header',
  CONNECT_SALES_CHANNEL_ROUTE_PRODUCT_DESCRIPTION = 'react.pages.connect_sales_channel.route.product.description',
  CONNECT_SALES_CHANNEL_ROUTE_CONNECTIONS = 'react.pages.connect_sales_channel.route.connections',
  CONNECT_SALES_CHANNEL_ROUTE_CONNECT_PRODUCT_HEADER = 'react.pages.connect_sales_channel.route.connect_product.header',
  CONNECT_SALES_CHANNEL_PILL_START_HERE = 'react.pages.connect_sales_channel.pill.start_here',
  CONNECT_SALES_CHANNEL_PILL_HIGHLY_RECOMMENDED = 'react.pages.connect_sales_channel.pill.highly_recommended',
  CONNECT_SALES_CHANNEL_PILL_ADD_MORE_DATA = 'react.pages.connect_sales_channel.pill.add_more_data',
  CONNECT_SALES_CHANNEL_PILL_NEXT_STEP = 'react.pages.connect_sales_channel.pill.next_step',
  CONNECT_ADS_MODAL_AMAZON_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.header',
  CONNECT_ADS_MODAL_WALMART_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.walmart.header',
  CONNECT_ADS_MODAL_AMAZON_CONTENT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.content',
  CONNECT_ADS_MODAL_AMAZON_BUTTON_TEXT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.button_text',
  CONNECT_ADS_MODAL_AMAZON_NOTE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.note',
  CONNECT_ADS_MODAL_AMAZON_ERROR_HEADING = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.error_heading',
  CONNECT_ADS_MODAL_AMAZON_ERROR_MESSAGE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.message',
  CONNECT_ADS_MODAL_AMAZON_CONNECTED_SUCCESS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.connected_success',
  CONNECT_ADS_MODAL_AMAZON_SYNC_DATA_ADS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.syncing_data_ads',
  CONNECT_ADS_MODAL_AMAZON_SYNC_DATA_PRODUCTS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.syncing_data_products',
  CONNECT_ADS_MODAL_AMAZON_SYNC_ADDITIONAL_MSG = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.syncing_additional_mesage',
  CONNECT_ADS_MODAL_AMAZON_EMAIL_UPON_COMPLETE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.email_upon_complete',
  CONNECT_ADS_MODAL_AMAZON_CONNECTED_SYNCING = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.connected_syncing',
  CONNECT_ADS_MODAL_AMAZON_LOADING_NEXT_STEP = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.loading_next_step',
  CONNECT_ADS_MODAL_AMAZON_NO_MERCHANTS_AVAILABLE_CONNECT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.no_merchants_available_connect',
  CONNECT_ADS_MODAL_AMAZON_MERCHANTS_ALREADY_CONNECT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_already_connect',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_TO_CONNECT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_to_connect',
  CONNECT_ADS_MODAL_AMAZON_MERCHANTS_ALREADY_CONNECT_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_already_connect_header',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_header',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_SUB_TITLE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_sub_title',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_NOTICE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_notice',
  CONNECT_ADS_MODAL_LOG_IN_WITH_ANOTHER_ACCOUNT = 'react.pages.connect_sales_channel.connect_ads_modal.log_in_with_another_account',

  CONNECT_PRODUCTS_MODAL_AMAZON_ADS_CONNECTED = 'react.pages.connect_sales_channel.connect_products_modal.amazon.ads_connected',
  CONNECT_PRODUCTS_MODAL_AMAZON_CONNECTED_MERCHANTS = 'react.pages.connect_sales_channel.connect_products_modal.amazon.connected_merchants',
  CONNECT_PRODUCTS_MODAL_AMAZON_CONNECTING_PRODUCTS = 'react.pages.connect_sales_channel.connect_products_modal.amazon.connecting_products',
  CONNECT_PRODUCTS_MODAL_AMAZON_REQUIRE_AUTH = 'react.pages.connect_sales_channel.connect_products_modal.amazon.require_auth',
  CONNECT_PRODUCTS_MODAL_AMAZON_SELLER_VENDOR_AUTH = 'react.pages.connect_sales_channel.connect_products_modal.amazon.seller_vendor_auth',
  CONNECT_PRODUCTS_MODAL_AMAZON_3P = 'react.pages.connect_sales_channel.connect_products_modal.amazon.3p_accounts',
  CONNECT_PRODUCTS_MODAL_AMAZON_1P = 'react.pages.connect_sales_channel.connect_products_modal.amazon.1p_accounts',
  CONNECT_PRODUCTS_MODAL_AMAZON_MULTIPLE_REGIONS_NOTE = 'react.pages.connect_sales_channel.connect_products_modal.amazon.multiple_regions_note',
  CONNECT_PRODUCTS_MODAL_AMAZON_REGION_NORTH_AMERICA = 'react.pages.connect_sales_channel.connect_products_modal.amazon.region.north_america',
  CONNECT_PRODUCTS_MODAL_AMAZON_REGION_EUROPE = 'react.pages.connect_sales_channel.connect_products_modal.amazon.region.europe',
  CONNECT_PRODUCTS_MODAL_AMAZON_REGION_FAR_EAST = 'react.pages.connect_sales_channel.connect_products_modal.amazon.region.far_east',
  CONNECT_PRODUCTS_MODAL_AMAZON_COMING_SOON = 'react.pages.connect_sales_channel.connect_products_modal.amazon.coming_soon',
  CONNECT_PRODUCTS_MODAL_AMAZON_LOGIN_BUTTON_TEXT = 'react.pages.connect_sales_channel.connect_products_modal.amazon.login_button_text',
  CONNECT_PRODUCTS_MODAL_AMAZON_ERROR_HEADING = 'react.pages.connect_sales_channel.connect_products_modal.amazon.error_heading',
  CONNECT_PRODUCTS_MODAL_AMAZON_OPTIONS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.options',
  CONNECT_ADS_MODAL_WALMART_SYNC_ADDITIONAL_MSG = 'react.pages.connect_sales_channel.connect_ads_modal.walmart.syncing_additional_mesage',
  CONNECT_PRODUCTS_MODAL_AMAZON_REQUEST_ACCESS_OPTIONS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.request_access_options',
  CONNECT_SALES_CHANNEL_MODAL_UNAVAILABLE_TITLE = 'react.pages.connect_sales_channel.connect_ads_modal.header.unavailable',
  CONNECT_SALES_CHANNEL_MODAL_TOOLTIP_EXPERIENCING_ISSUES_DESCRIPTION = 'react.pages.connect_sales_channel.connect_ads_modal.tooltip_description.experiencing_issues',

  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUCCESS_CHECK_EMAIL_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.success.check_email.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUCCESS_CHECK_EMAIL_DESCRIPTION = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.success.check_email.description',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUCCESS_NEXT_STEP = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.success.next_step',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_1P_SUB_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.1p.sub_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_3P_SUB_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.3p.sub_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_SUCCESS_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.success.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_SUCCESS_API_TYPES = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.success.api_types',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_HEADER = 'react.pages.product_subscriptions.add_connection_modal.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUB_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.sub_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_AUTHORIZATION_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.authorization_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_AUTHORIZATION_DESCRIPTION = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.authorization_description',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_BEGIN_PROCESS_BTN = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.begin_process_btn',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_HELP_TEXT = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.help_text',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_SELLER_TYPE = 'react.ad_optimization.modal.seller_type',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_SELLER_ID = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.seller_id',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_CLIENT_ID = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.client_id',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_CLIENT_SECRET = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.client_secret',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_CONSUMER_ID = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.consumer_id',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_PRIVATE_KEY = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.private_key',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_1P_HELP_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.1p.help.message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_3P_HELP_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.3p.help.message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_HELP_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.help.message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WMT_PRD_DATA_FRM_BTN_1P_CONNECT = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.button.1p.connect',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WMT_PRD_DATA_FRM_BTN_3P_CONNECT = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.button.3p.connect',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_MESSAGE_3P = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_message_3p',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_MESSAGE_1P = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_message_1p',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_DATA_ERROR_HEADING = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.error_heading',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_DATA_ERROR_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.error_message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_ERROR_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.error_message',

  ONBOARDING_SURVEY_HEADER_TITLE_WELCOME_TO_FLYWHEEL = 'react.onboarding.survey.header.title.welcome_to_flywheel',
  ONBOARDING_SURVEY_BODY_DESCRIPTION_WELCOME_TO_FLYWHEEL = 'react.onboarding.survey.body.description.welcome_to_flywheel',
  ONBOARDING_SURVEY_BODY_BUTTON_WELCOME_TO_FLYWHEEL = 'react.onboarding.survey.body.button.welcome_to_flywheel',
  ONBOARDING_SURVEY_HEADER_TITLE_YOUR_BRAND = 'react.onboarding.survey.header.title.your_brand',
  ONBOARDING_SURVEY_HEADER_TITLE_YOUR_SIZE = 'react.onboarding.survey.header.title.your_size',
  ONBOARDING_SURVEY_HEADER_TITLE_YOUR_NEEDS = 'react.onboarding.survey.header.title.your_needs',
  ONBOARDING_SURVEY_HEADER_TITLE_YOUR_OBJECTIVE = 'react.onboarding.survey.header.title.your_objective',
  ONBOARDING_SURVEY_HEADER_TITLE_YOUR_GOALS = 'react.onboarding.survey.header.title.your_goals',

  ONBOARDING_SURVEY_HEADER_TITLE_WHICH_CHANNELS_SELL_PRODUCTS = 'react.onboarding.survey.header.title.which_channels_sell_products',
  ONBOARDING_SURVEY_HEADER_TITLE_TOTAL_ANNUAL_SALES = 'react.onboarding.survey.header.title.total_annual_sales',
  ONBOARDING_SURVEY_HEADER_TITLE_HOW_ARE_YOU_USING = 'react.onboarding.survey.header.title.how_are_you_using_flywheel',
  ONBOARDING_SURVEY_HEADER_TITLE_CHOOSE_PRODUCT_OVER_OTHERS = 'react.onboarding.survey.header.title.choose_product_over_others',
  ONBOARDING_SURVEY_HEADER_TITLE_ACCOMPLISH_WITH_FLYWHEEL = 'react.onboarding.survey.header.title.accomplish_with_flywheel',
  ONBOARDING_SURVEY_QUESTION_LESS_THAN_100 = 'react.onboarding.survey.question.less_than_100',
  ONBOARDING_SURVEY_QUESTION_BETWEEN_100_AND_1M = 'react.onboarding.survey.question.between_100_and_1m',
  ONBOARDING_SURVEY_QUESTION_BETWEEN_1M_AND_5M = 'react.onboarding.survey.question.between_1m_and_5m',
  ONBOARDING_SURVEY_QUESTION_MORE_THAN_5M = 'react.onboarding.survey.question.more_than_5m',
  ONBOARDING_SURVEY_QUESTION_MY_PERSONAL_BUSINESS = 'react.onboarding.survey.question.my_personal_business',
  ONBOARDING_SURVEY_QUESTION_AGENCY_PARTNER = 'react.onboarding.survey.question.agency_partner',

  ONBOARDING_SURVEY_QUESTION_AI_POWER_AUTOMATION = 'react.onboarding.survey.question.ai_power_automation',
  ONBOARDING_SURVEY_QUESTION_MULTI_CHANNEL_MANAGEMENT = 'react.onboarding.survey.question.multi_channel_management',
  ONBOARDING_SURVEY_QUESTION_MARKET_RESEARCH_TOOL = 'react.onboarding.survey.question.market_research_tools',
  ONBOARDING_SURVEY_QUESTION_EXPERT_KNOWLEDGE_INSIGHTS = 'react.onboarding.survey.question.expert_knowledge_insights',
  ONBOARDING_SURVEY_QUESTION_SAVE_TIME_AUTOMATION = 'react.onboarding.survey.question.save_time_automation',
  ONBOARDING_SURVEY_QUESTION_ACHIEVE_AD_PERFORMANCE = 'react.onboarding.survey.question.achieve_ad_performance',
  ONBOARDING_SURVEY_QUESTION_INCREASE_AD_SPEND = 'react.onboarding.survey.question.increase_ad_spend',
  ONBOARDING_SURVEY_QUESTION_OPTIMIZE_PRODUCT_CATALOG = 'react.onboarding.survey.question.optimize_product_catalog',
  ONBOARDING_SURVEY_QUESTION_EMAIL_ME_FOR_DEMO = 'react.onboarding.survey.question.email_me_for_demo',
  ONBOARDING_SURVEY_QUESTION_DEMO_REQUEST = 'react.onboarding.survey.question.demo_request',
  ONBOARDING_SURVEY_QUESTION_INPUT_PLACEHOLDER_EXAMPLE = 'react.onboarding.survey.question.input_placeholder_example',
  ONBOARDING_SURVEY_QUESTION_ADD_OTHER_CHANNELS = 'react.onboarding.survey.question.add_other_channels',
  ONBOARDING_SURVEY_QUESTION_ADD_OTHER_OBJECTIVE = 'react.onboarding.survey.question.add_other_objective',
  ONBOARDING_SURVEY_QUESTION_ADD_OTHER_GOAL = 'react.onboarding.survey.question.add_other_goal',
  ONBOARDING_SURVEY_TERM_NEXT = 'react.onboarding.survey.term.next',
  ANIMATED_LOADER_BUTTON_TEXT_AWESOME = 'react.animated_loader.button.text.awesome',

  BILLING_TERM_FREE = 'react.billing.term.free',
  BILLING_TERM_TRIAL = 'react.billing.term.trial',
  BILLING_TITLE = 'react.billing.page.title',
  BILLING_HEADER_TITLE = 'react.billing.page.header.title',
  BILLING_SUBTITLE_TITLE = 'react.billing.page.header.subtitle',
  BILLING_SECTION_PLAN_DETAIL_TITLE = 'react.billing.page.billing_section.billing_plan_details.title',
  BILLING_SECTION_BILLING_DETAIL_TITLE = 'react.billing.page.billing_section.billing_payment_details.title',
  BILLING_SECTION_TRIAL_END = 'react.billing.page.billing_section.trial_end',
  BILLING_DETAIL_CARD_TITLE = 'react.billing.billing_plan_details.card.header.title',
  BILLING_DETAIL_CARD_SUBTITLE = 'react.billing.billing_plan_details.card.header.subtitle',
  BILLING_DETAIL_CARD_FOOTER_QUESTION = 'react.billing.billing_plan_details.card.footer.question',
  BILLING_DETAIL_CARD_FOOTER_LEARN_MORE = 'react.billing.billing_plan_details.card.footer.learn_more',
  BILLING_DETAIL_CARD_FOOTER_MESSAGE = 'react.billing.billing_plan_details.card.footer.message',
  BILLING_PAYMENT_DETAIL_CARD_TITLE = 'react.billing.billing_payment_details.card.header.title',
  BILLING_PAYMENT_DETAIL_CARD_EXPIRY_DATE = 'react.billing.billing_payment_details.card.header.exp_date',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_ADD_DETAILS = 'react.billing.billing_payment_details.card.header.subtitle.add_payment_details',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_PAYMENT_DETAILS = 'react.billing.billing_payment_details.card.header.subtitle.payment_details',
  BILLING_PAYMENT_DETAIL_CARD_ADD_CARD_BUTTON = 'react.billing.billing_payment_details.card.add_card_button',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_SETUP_AUTOPAY = 'react.billing.billing_payment_details.card.header.subtitle.setup_autopay',
  BILLING_PAYMENT_DETAIL_CARD_BACK_BUTTON = 'react.billing.billing_payment_details.card.back_button',
  BILLING_PAYMENT_DETAIL_CARD_NEXT_BILLING_DATE_DETAILS = 'react.billing.billing_payment_details.card.next_billing_date_details',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_PAYMENT_ERROR_DETAILS = 'react.billing.billing_payment_details.card.header.subtitle.payment_error_details',
  BILLING_PAYMENT_DETAIL_CARD_OUTSTANDING_BILLING_DATE_DETAILS = 'react.billing.billing_payment_details.card.outstanding_billing_date_details',

  BILLING_PAYMENT_ZUORA_ERROR_CARD_TITLE = 'react.billing.billing_payment_zuora_error.card.header.title',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_SUBTITLE = 'react.billing.billing_payment_zuora_error.card.header.subtitle',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_SOMETHING_WENT_WRONG_TITLE = 'react.billing.billing_payment_zuora_error.card.header.title.something_went_wrong',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_ERROR_PAYMENT_GATEWAY_SUBTITLE = 'react.billing.billing_payment_zuora_error.card.header.subtitle.error_payment_gateway',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_BUTTON_TITLE_RETRY = 'react.billing.billing_payment_zuora_error.card.button.title.retry',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_BUTTON_TITLE_CONTACT_SUPPORT = 'react.billing.billing_payment_zuora_error.card.button.title.contact_support',

  BILLING_ESTIMATE_CARD_TITLE = 'react.billing.billing_estimate.card.header.title',
  BILLING_ESTIMATE_CARD_ADVERTISING_OPTIMIZATION_TITLE = 'react.billing.billing_estimate.card.advertising_optimization.title',
  BILLING_ESTIMATE_CARD_MARKET_INTELLIGENCE_TITLE = 'react.billing.billing_estimate.card.market_intelligence.title',
  BILLING_ESTIMATE_CARD_ESTIMATE_TOTAL_TITLE = 'react.billing.billing_estimate.card.estimated_total.title',
  BILLING_ESTIMATE_CARD_TOTAL_AP_SPEND_DESCRIPTION = 'react.billing.billing_estimate.card.total_ad_spend.description',
  BILLING_ESTIMATE_CARD_AP_SPEND_MERCHANTS_DESCRIPTION = 'react.billing.billing_estimate.card.ad_spend_merchants.description',
  BILLING_ESTIMATE_CARD_TOTAL_TRACK_TERMS_DESCRIPTION = 'react.billing.billing_estimate.card.total_tracked_terms.description',
  BILLING_ESTIMATE_CARD_PER_TRACKED_TERMS_DESCRIPTION = 'react.billing.billing_estimate.card.per_tracked_term.description',
  BILLING_ESTIMATE_CARD_LAST_UPDATED_DESCRIPTION = 'react.billing.billing_estimate.card.last_updated.description',

  BILLING_DOWNGRADE_PLAN_TITLE = 'react.billing.downgrade_plan.title',
  BILLING_DOWNGRADE_PLAN_BODY = 'react.billing.downgrade_plan.body',
  BILLING_DOWNGRADE_PLAN_LIST_HEADER = 'react.billing.downgrade_plan.list_header',
  BILLING_DOWNGRADE_PLAN_NOTE = 'react.billing.downgrade_plan.note',
  BILLING_DOWNGRADE_PLAN_NON_TRIAL_NOTE_BOLD = 'react.billing.downgrade_plan.non_trial.note_bold',
  BILLING_DOWNGRADE_PLAN_NON_TRIAL_NOTE = 'react.billing.downgrade_plan.non_trial.note',
  TERMS_DOWNGRADE = 'terms.downgrade',

  BILLING_UPGRADE_PLAN_TRIAL_TITLE = 'react.billing.upgrade_plan.trial.title',
  BILLING_UPGRADE_PLAN_NON_TRIAL_TITLE = 'react.billing.upgrade_plan.non_trial.title',
  BILLING_UPGRADE_PLAN_BODY = 'react.billing.upgrade_plan.body',
  BILLING_UPGRADE_PLAN_LIST_HEADER = 'react.billing.upgrade_plan.list_header',
  BILLING_UPGRADE_PLAN_DESCRIPTION = 'react.billing.upgrade_plan.plan_description',
  BILLING_UPGRADE_PLAN_NON_TRIAL_NOTE = 'react.billing.upgrade_plan.non_trial.note',
  BILLING_UPGRADE_PLAN_TRIAL_NOTE_BOLD = 'react.billing.upgrade_plan.trial.note_bold',
  BILLING_UPGRADE_PLAN_TRIAL_NOTE = 'react.billing.upgrade_plan.trial.note',
  BILLING_UPGRADE_PLAN_START_TRIAL_BUTTON = 'react.billing.upgrade_plan.start_trial_button',
  BILLING_UPGRADE_PLAN_NON_TRIAL_BUTTON = 'react.billing.upgrade_plan.non_trial.button',

  UPGRADE_HAPPENED_TITLE = 'react.upgrade_happened.title',
  UPGRADE_HAPPENED_BODY = 'react.upgrade_happened.body',
  UPGRADE_HAPPENED_NOTE_TITLE = 'react.upgrade_happened.note.title',
  UPGRADE_HAPPENED_NOTE_BODY = 'react.upgrade_happened.note.body',
  UPGRADE_HAPPENED_BUTTON = 'react.upgrade_happened.button',
  UPGRADE_HAPPENED_BODY_LINK = 'react.upgrade_happened.body.link',

  TRIAL_ENDED_MODAL_TITLE = 'react.trial_ended.title',
  TRIAL_ENDED_MODAL_BODY = 'react.trial_ended.body',
  TRIAL_ENDED_MODAL_LIST_HEADER = 'react.trial_ended.list_header',
  TRIAL_ENDED_MODAL_SECONDARY_BUTTON = 'react.trial_ended.secondary_button',
  TRIAL_ENDED_MODAL_PRIMARY_BUTTON = 'react.trial_ended.primary_button',

  TRIAL_ENDED_ADD_PAYMENT_TITLE = 'react.trial_ended.add_payment.title',
  TRIAL_ENDED_ADD_PAYMENT_REMINDER_TITLE = 'react.trial_ended.add_payment.reminder.title',
  TRIAL_ENDED_ADD_PAYMENT_BODY = 'react.trial_ended.add_payment.body',
  TRIAL_ENDED_ADD_PAYMENT_REMINDER_BODY = 'react.trial_ended.add_payment.reminder.body',
  TRIAL_ENDED_ADD_PAYMENT_PILL = 'react.trial_ended.add_payment.pill',
  TRIAL_ENDED_ADD_PAYMENT_NOTE = 'react.trial_ended.add_payment.note',

  TRIAL_ENDED_SUCCESSFUL_PAYMENT_BODY = 'react.trial_ended.successful_payment.body',
  TRIAL_ENDED_SUCCESSFUL_PAYMENT_LIST_HEADER = 'react.trial_ended.successful_payment.list_header',

  BILLING_PLAN_FEATURES_BIDDER = 'react.billing.plan_features.bidder',
  BILLING_PLAN_FEATURES_TRE = 'react.billing.plan_features.tre',
  BILLING_PLAN_FEATURES_CAMPAIGN_ORGANIZER = 'react.billing.plan_features.campaign_organizer',

  SALES_CHANNELS_TITLE = 'react.pages.sales_channels.title',
  SALES_CHANNELS_TABLE_TAB_WALMART = 'react.pages.sales_channels.table.tab.walmart',
  SALES_CHANNELS_TABLE_TAB_AMAZON = 'react.pages.sales_channels.table.tab.amazon',
  SALES_CHANNELS_ADD_CONNECTION_BUTTON = 'react.pages.sales_channels.add_connection.button',
  SALES_CHANNELS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.pages.sales_channels.table.footer.total_items_text',
  ACCOUNT_SALES_CHANNELS_TABLE_DUPLICATE_NAME_TEXT = 'react.account.sales_channels.table.duplicate_name.text',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_PROCESSING = 'react.account.sales_channels.column_ad.status.processing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_PROCESSING = 'react.account.sales_channels.column_ad.tooltip.processing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_SYNCING = 'react.account.sales_channels.column_ad.status.syncing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_SYNCING = 'react.account.sales_channels.column_ad.tooltip.syncing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_CONNECTED = 'react.account.sales_channels.column_ad.status.connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_CONNECTED = 'react.account.sales_channels.column_ad.tooltip.connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_NOT_CONNECTED = 'react.account.sales_channels.column_ad.status.not_connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_NOT_CONNECTED = 'react.account.sales_channels.column_ad.tooltip.not_connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_FAILED = 'react.account.sales_channels.column_ad.status.failed',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_FAILED = 'react.account.sales_channels.column_ad.tooltip.failed',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_TOOLTIP_TEXT = 'react.account.sales_channels.column_name.tooltip.text',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_DISABLE_TOOLTIP_TEXT = 'react.account.sales_channels.column_name.disabled_tooltip.text',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_BUTTON_TEXT = 'react.account.sales_channels.column_name.button.text',
  ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_AMAZON_TITLE = 'react.account.sales_channels.no_connections_table.amazon.title',
  ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_WALMART_TITLE = 'react.account.sales_channels.no_connections_table.walmart.title',
  ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_DESCRIPTION = 'react.account.sales_channels.no_connections_table.description',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_NAME = 'react.account.sales_channels.header.column_name',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_SELLER_ID = 'react.account.sales_channels.header.column_seller_id',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_AD = 'react.account.sales_channels.header.column_ad',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_PRODUCT = 'react.account.sales_channels.header.column_product',
  ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_MERCHANT = 'react.account.sales_channels.grouping_header_merchant',
  ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_DATA_CONNECTION = 'react.account.sales_channels.grouping_header_data_connection',
  ACCOUNT_SALES_CHANNELS_TOAST_NAME_SUCCESS = 'react.account.sales_channels.toast.name.success',

  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_AI = 'react.account.sales_channels.header.column_ai',
  ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_AUTOMATION = 'react.account.sales_channels.grouping_header_automation',
  ACCOUNT_SALES_CHANNELS_TABLE_AUTOMATION_COUNT = 'react.account.sales_channels.table.automation_count',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_HEADER = 'react.account.sales_channels.toast.ai.header',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_ENABLED = 'react.account.sales_channels.toast.ai.enabled',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_DISABLED = 'react.account.sales_channels.toast.ai.disabled',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_ENABLED_DESCRIPTION = 'react.account.sales_channels.toast.ai.enabled.description',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_DISABLED_DESCRIPTION = 'react.account.sales_channels.toast.ai.disabled.description',

  ACCOUNT_SALES_CHANNELS_AI_DISABLE_AI = 'react.account.sales_channels.ai.disable_ai',
  ACCOUNT_SALES_CHANNELS_AI_LIVE_AUTOMATIONS = 'react.account.sales_channels.ai.live_automations',
  ACCOUNT_SALES_CHANNELS_AI_DISABLING_WHAT = 'react.account.sales_channels.ai.what_disabling_does',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED = 'react.account.sales_channels.ai.plan_changed',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED_BODY = 'react.account.sales_channels.ai.plan_changed_body',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED_DETAILS = 'react.account.sales_channels.ai.plan_changed_details',

  ACCOUNT_SALES_CHANNELS_AI_ENABLE_AI = 'react.account.sales_channels.ai.enable_ai',
  ACCOUNT_SALES_CHANNELS_AI_MERCHANT_POWERED = 'react.account.sales_channels.ai.merchant_powered',
  ACCOUNT_SALES_CHANNELS_AI_ENABLING_WHAT = 'react.account.sales_channels.ai.what_enabling_does',
  ACCOUNT_SALES_CHANNELS_AI_SET_MAC = 'react.account.sales_channels.ai.plan_changed',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_FUEL = 'react.account.sales_channels.ai.plan_fuel',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_SET_AD_MACS = 'react.account.sales_channels.ai.set_ad_macs',

  ERROR_HEADLINE = 'react.general.error_headline',
  ERROR_MESSAGE = 'react.general.error_message',
  ACCOUNT_SALES_CHANNELS_ADD_NAME_PLACEHOLDER = 'react.account.sales_channels.add.name.placeholder',
  MARKET_INTELLIGENCE_PAGE_HEADER_REPORTING = 'react.market_intelligence.page_header.reporting',
  MARKET_INTELLIGENCE_PAGE_HEADER_SETTINGS = 'react.market_intelligence.page_header.settings',
  MARKET_INTELLIGENCE_LEARN_MORE = 'react.market_intelligence.learn_more',
  MARKET_INTELLIGENCE_ADD_TERMS = 'react.market_intelligence.add_terms',
  MARKET_INTELLIGENCE_CLEAR_SEARCH = 'react.market_intelligence.clear_search',
  MARKET_INTELLIGENCE_TRACKING_NO_TERMS = 'react.market_intelligence.tracking_no_terms',
  MARKET_INTELLIGENCE_ERROR_GENERIC = 'react.market_intelligence.error.generic',
  MARKET_INTELLIGENCE_NOTIFICATION_FILE_DOWNLOAD = 'react.market_intelligence.notification.file_download',
  MARKET_INTELLIGENCE_NOTIFICATION_TERM_DELETED_SUCCESS = 'react.market_intelligence.notification.term_deleted_success',
  MARKET_INTELLIGENCE_NOTIFICATION_SEARCH_TERM_SAVED_SUCCESS = 'react.market_intelligence.notification.search_terms_saved_success',
  MARKET_INTELLIGENCE_NOTIFICATION_SEARCH_TERM_SAVED_SUCCESS_MANUAL = 'react.market_intelligence.notification.search_terms_saved_success_manual',
  MARKET_INTELLIGENCE_NOTIFICATION_SEARCH_TERM_SAVED_SUCCESS_MANUAL_WITH_EXISTING_TERMS = 'react.market_intelligence.notification.search_terms_saved_success_manual_with_existing_terms',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_TERMS_FETCHING_MSG = 'react.market_intelligence.reporting.no_data_error.terms_fetching_message',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_TERMS_FETCHING_DESC = 'react.market_intelligence.reporting.no_data_error.terms_fetching_description',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_DATA_FETCH_MESSAGE = 'react.market_intelligence.reporting.no_data_error.data_fetch_message',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_DATA_FETCH_DESC = 'react.market_intelligence.reporting.no_data_error.data_fetch_description',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_HAVING_ISSUES = 'react.market_intelligence.reporting.no_data_error.having_issues',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_GET_IN_TOUCH = 'react.market_intelligence.reporting.no_data_error.get_in_touch',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_WITH_SUPPORT = 'react.market_intelligence.reporting.no_data_error.with_support',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_TIP = 'react.market_intelligence.reporting.no_data_error.tip',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_CANT_GET_DATA = 'react.market_intelligence.reporting.no_data_error.cant_get_data',
  MARKET_INTELLIGENCE_BUTTON_RUN_QUERY = 'react.market_intelligence.button.run_query',
  MARKET_INTELLIGENCE_BUTTON_RESET = 'react.market_intelligence.button.reset',
  MARKET_INTELLIGENCE_BUTTON_EXPORT = 'react.market_intelligence.button.export',
  MARKET_INTELLIGENCE_REPORTING_ERRORS = 'react.market_intelligence.errors',
  MARKET_INTELLIGENCE_REPORTING_SHOW_ME_HOW = 'react.market_intelligence.show_me_how',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM = 'react.market_intelligence.platform',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_AMAZON = 'react.market_intelligence.platform_options.amazon',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_WALMART = 'react.market_intelligence.platform_options.walmart',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_EBAY = 'react.market_intelligence.platform_options.ebay',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_GOOGLE_SHOPPING = 'react.market_intelligence.platform_options.google_shopping',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_TARGET = 'react.market_intelligence.platform_options.target',
  MARKET_INTELLIGENCE_REPORTING_SEARCH_TERM = 'react.market_intelligence.search_term',
  MARKET_INTELLIGENCE_REPORTING_SEARCH_TERM_NO_VALUE_PLACEHOLDER = 'react.market_intelligence.search_term.no_value.placeholder',
  MARKET_INTELLIGENCE_REPORTING_TIME_FRAME = 'react.market_intelligence.time_frame',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_24_HOURS = 'react.market_intelligence.date_picker_options.24_Hours',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_7_DAYS = 'react.market_intelligence.date_picker_options.7_Days',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_14_DAYS = 'react.market_intelligence.date_picker_options.14_Days',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_30_DAYS = 'react.market_intelligence.date_picker_options.30_Days',
  MARKET_INTELLIGENCE_NO_DATA_TRACKED_TERMS_TABLE_TITLE = 'react.market_intelligence.no_data_tracked_terms_table.title',
  MARKET_INTELLIGENCE_NO_DATA_TRACKED_TERMS_TABLE_DESCRIPTION = 'react.market_intelligence.no_data_tracked_terms_table.description',
  MARKET_INTELLIGENCE_CARD_LEARN_MORE_TITLE = 'react.market_intelligence.card.learn_more.title',
  MARKET_INTELLIGENCE_CARD_LEARN_MORE_DESCRIPTION = 'react.market_intelligence.card.learn_more.description',
  MARKET_INTELLIGENCE_CARD_LEARN_MORE_LINK_LEARN_MORE = 'react.market_intelligence.card.learn_more.link_learn_more',
  MARKET_INTELLIGENCE_CARD_SEARCH_TERMS_SEARCH_TERMS_UNLIMITED = 'react.market_intelligence.card.search_terms.search_terms_unlimited',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_TITLE = 'react.market_intelligence.card.track_terms_usage.title',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_SUBTITLE = 'react.market_intelligence.card.track_terms_usage.subtitle',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_DESCRIPTION = 'react.market_intelligence.card.track_terms_usage.description',
  MARKET_INTELLIGENCE_SETTINGS_SUMMARY_LAST_UPDATED = 'react.market_intelligence.settings_summary.last_updated',
  MARKET_INTELLIGENCE_SETTINGS_SUMMARY_TRACKED_TERMS = 'react.market_intelligence.settings_summary.tracked_terms',
  MARKET_INTELLIGENCE_SETTINGS_SEARCH_TERMS = 'react.market_intelligence.search_terms',
  MARKET_INTELLIGENCE_SETTINGS_DOWNLOAD_TEMPLATE = 'react.market_intelligence.download_template',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_ACTIONS = 'react.market_intelligence.columns.search_term.actions',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_ADDED = 'react.market_intelligence.columns.search_term.added',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_LOCALE = 'react.market_intelligence.columns.search_term.locale',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_TYPE = 'react.market_intelligence.columns.search_term.type',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_SEARCH_TERM = 'react.market_intelligence.columns.search_term.search_term',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_STATUS = 'react.market_intelligence.columns.search_term.status',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_PLATFORM = 'react.market_intelligence.columns.search_term.platform',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_ACTIVE_TITLE = 'react.market_intelligence.tooltip.search_term_status.active.title',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_ACTIVE_DESCRIPTION = 'react.market_intelligence.tooltip.search_term_status.active.description',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_FETCHING_TITLE = 'react.market_intelligence.tooltip.search_term_status.fetching.title',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_FETCHING_DESCRIPTION = 'react.market_intelligence.tooltip.search_term_status.fetching.description',
  MARKET_INTELLIGENCE_TOOLTIP_ACTION_BUTTON_VIEW_REPORT = 'react.market_intelligence.tooltip.actions_buttons.view_report',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_PRODUCT_DETAILS = 'react.market_intelligence.columns.average_product_rank.product_details',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_BRAND_NAME = 'react.market_intelligence.columns.average_product_rank.brand_name',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_APPEARANCES_PERCENTAGE = 'react.market_intelligence.columns.average_product_rank.appearances_percentage',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_AVG_RANK_TOTAL = 'react.market_intelligence.columns.average_product_rank.avg_rank_total',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_AVG_RANK_ORGANIC = 'react.market_intelligence.columns.average_product_rank.avg_rank_organic',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_AVG_RANK_PAID = 'react.market_intelligence.columns.average_product_rank.avg_rank_paid',
  MARKET_INTELLIGENCE_SEARCH_BAR_AVERAGE_PRODUCT_RANK = 'react.market_intelligence.search_bar.average_product_rank',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_BRAND_NAME = 'react.market_intelligence.columns.brand_coverage_on_search.brand_name',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_APPEARANCES_PERCENTAGE = 'react.market_intelligence.columns.brand_coverage_on_search.appearances_percentage',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_TOTAL_BCS = 'react.market_intelligence.columns.brand_coverage_on_search.total_bcs',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_ORGANIC_BCS = 'react.market_intelligence.columns.brand_coverage_on_search.organic_bcs',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_PAID_BCS = 'react.market_intelligence.columns.brand_coverage_on_search.paid_bcs',
  MARKET_INTELLIGENCE_SEARCH_BAR_BRAND_COVERAGE_ON_SEARCH = 'react.market_intelligence.search_bar.brand_coverage_on_search',
  MARKET_INTELLIGENCE_REPORTING_TABLE_TABS_AVERAGE_PRODUCT_RANK = 'react.market_intelligence.reporting_table_tabs.average_product_rank',
  MARKET_INTELLIGENCE_REPORTING_TABLE_TABS_BRAND_COVERAGE_ON_SEARCH = 'react.market_intelligence.reporting_table_tabs.brand_coverage_on_search',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_HEADER = 'react.market_intelligence.delete_search_term.header',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_DESCRIPTION = 'react.market_intelligence.delete_search_term.description',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_CONFIRMATION = 'react.market_intelligence.delete_search_term.confirmation',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_BUTTON_CANCEL = 'react.market_intelligence.delete_search_term.button.cancel',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_BUTTON_DELETE = 'react.market_intelligence.delete_search_term.button.delete',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_UPLOAD_FILE = 'react.market_intelligence.upload_terms_popover.common.upload_file',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_START_UPLOAD = 'react.market_intelligence.upload_terms_popover.common.start_upload',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_SELECT_ANOTHER_FILE = 'react.market_intelligence.upload_terms_popover.common.select_another_file',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_CSV_FILE_STATUS = 'react.market_intelligence.upload_terms_popover.common.csv_file_status',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_CSV_FILE_STATUS_WITH_ERROR = 'react.market_intelligence.upload_terms_popover.common.csv_file_status_with_error',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_TITLE = 'react.market_intelligence.upload_terms_popover.choose_file.title',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_DOWNLOAD_CSV_TEMPLATE = 'react.market_intelligence.upload_terms_popover.choose_file.download_csv_template',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_SELECT_FILE = 'react.market_intelligence.upload_terms_popover.choose_file.select_file',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_UPLOAD_VALID_CSV = 'react.market_intelligence.upload_terms_popover.choose_file.upload_valid_csv',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_DESCRIPTION_POINT1 = 'react.market_intelligence.upload_terms_popover.choose_file.description.point1',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_DESCRIPTION_POINT2 = 'react.market_intelligence.upload_terms_popover.choose_file.description.point2',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_START_UPLOAD_SUBTITLE = 'react.market_intelligence.upload_terms_popover.start_upload.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_TITLE = 'react.market_intelligence.upload_terms_popover.process_file.title',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_SUBTITLE_PROCESSED = 'react.market_intelligence.upload_terms_popover.process_file.subtitle.processed',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_SUBTITLE_PROCESSING = 'react.market_intelligence.upload_terms_popover.process_file.subtitle.processing',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_BUTTON_PRIMARY_TEXT = 'react.market_intelligence.upload_terms_popover.process_file.buttonPrimaryText',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMPLETE_UPLOAD_SUBTITLE = 'react.market_intelligence.upload_terms_popover.complete_upload.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_UNKNOWN_SUBTITLE = 'react.market_intelligence.upload_terms_popover.error_unknown.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_UNKNOWN_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.error_unknown.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_SUBTITLE = 'react.market_intelligence.upload_terms_popover.error_csv.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.error_csv.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_BUTTON_PRIMARY_TEXT = 'react.market_intelligence.upload_terms_popover.error_csv.buttonPrimaryText',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_FIX_CSV_ERRORS = 'react.market_intelligence.upload_terms_popover.error_csv.fix_csv_errors',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_MISSING_VALUE = 'react.market_intelligence.upload_terms_popover.error_csv.missing_value',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_WRONG_VALUE = 'react.market_intelligence.upload_terms_popover.error_csv.wrong_value',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_TERMS_EXCEEDED_SUBTITLE = 'react.market_intelligence.upload_terms_popover.error_terms_exceeded.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_TERMS_EXCEEDED_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.error_terms_exceeded.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_NO_NEW_TERMS_IDENTIFIED_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.no_new_terms_identified.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_MISSING_HEADER_SUBTITLE = 'react.market_intelligence.upload_terms_popover.missing_header.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_MISSING_HEADER_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.missing_header.description',
  MARKET_INTELLIGENCE_EXPORT_SEARCH_TERMS = 'react.market_intelligence.export_search_terms',
  MARKET_INTELLIGENCE_BCS_HEADER = 'react.market_intelligence.bcs.header',
  MARKET_INTELLIGENCE_BCS_TABS_TOTAL = 'react.market_intelligence.bcs.tabs.total',
  MARKET_INTELLIGENCE_BCS_TABS_ORGANIC = 'react.market_intelligence.bcs.tabs.organic',
  MARKET_INTELLIGENCE_BCS_TABS_PAID = 'react.market_intelligence.bcs.tabs.paid',
  MARKET_INTELLIGENCE_BCS_HELP_HEADER = 'react.market_intelligence.bcs.help.header',
  MARKET_INTELLIGENCE_BCS_HELP_EXPLANATION = 'react.market_intelligence.bcs.help.explanation',
  MARKET_INTELLIGENCE_BCS_HELP_EXAMPLE_HEADER = 'react.market_intelligence.bcs.help.example_header',
  MARKET_INTELLIGENCE_BCS_HELP_EXAMPLE_EXPLANATION = 'react.market_intelligence.bcs.help.example_explanation',
  MARKET_INTELLIGENCE_BCS_WARNING = 'react.market_intelligence.bcs.warning',
  MARKET_INTELLIGENCE_CHARTS_PIE_CHART_HEADING = 'react.market_intelligence.charts.pie_chart.heading',
  MARKET_INTELLIGENCE_CHARTS_PIE_CHART_CENTER_TEXT = 'react.market_intelligence.charts.pie_chart.center_text',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_HEADER_COLUMN_BRAND_NAME = 'react.market_intelligence.charts.data_table.header.column_brand_name',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_HEADER_COLUMN_BCS_PERCENTAGE = 'react.market_intelligence.charts.data_table.header.column_bcs_percentage',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_HEADER_COLUMN_BCS_PERCENTAGE_NO_DATA = 'react.market_intelligence.charts.data_table.column_bcs_percentage_no_data',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_FOOTER_COLUMN_BRAND_NAME_TITLE = 'react.market_intelligence.charts.data_table.footer.column_brand_name_title',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_FOOTER_COLUMN_BRAND_NAME_DESCRIPTION = 'react.market_intelligence.charts.data_table.footer.column_brand_name_description',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_HEADER_LEFT = 'react.market_intelligence.charts.line_chart.header.left',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_HEADER_RIGHT = 'react.market_intelligence.charts.line_chart.header.right',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_NO_DATA_HEADER = 'react.market_intelligence.charts.line_chart.no_data.header',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_NO_DATA_DESCRIPTION = 'react.market_intelligence.charts.line_chart.no_data.description',
  MARKET_INTELLIGENCE_REPORTING_SELECT_PLATFORM = 'react.market_intelligence.reporting_select_platform',
  MARKET_INTELLIGENCE_REPORTING_SEARCH_TERMS = 'react.market_intelligence.reporting_search_terms',

  MARKET_INTELLIGENCE_LANDING_LOCKED = 'react.market_intelligence.landing.locked',
  MARKET_INTELLIGENCE_LANDING_TITLE = 'react.market_intelligence.landing.title',
  MARKET_INTELLIGENCE_LANDING_DESCRIPTION = 'react.market_intelligence.landing.description',
  MARKET_INTELLIGENCE_LANDING_CONNECT_CHANNEL = 'react.market_intelligence.landing.connect_channel',
  MARKET_INTELLIGENCE_LANDING_ADD_TERMS = 'react.market_intelligence.landing.add_terms',
  MARKET_INTELLIGENCE_LANDING_NOTICE = 'react.market_intelligence.landing.notice',
  MARKET_INTELLIGENCE_LANDING_TRACK_TERMS_HEADLINE = 'react.market_intelligence.landing.track_terms.headline',
  MARKET_INTELLIGENCE_LANDING_TRACK_TERMS_BODY = 'react.market_intelligence.landing.track_terms.body',
  MARKET_INTELLIGENCE_LANDING_TRAFFIC_SHARE_HEADLINE = 'react.market_intelligence.landing.traffic_share.headline',
  MARKET_INTELLIGENCE_LANDING_TRAFFIC_SHARE_BODY = 'react.market_intelligence.landing.traffic_share.body',
  MARKET_INTELLIGENCE_LANDING_SEE_IMPACT_HEADLINE = 'react.market_intelligence.landing.see_impact.headline',
  MARKET_INTELLIGENCE_LANDING_SEE_IMPACT_BODY = 'react.market_intelligence.landing.see_impact.body',

  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_TITLE = 'react.market_intelligence.no_active_terms.title',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_DESCRIPTION = 'react.market_intelligence.no_active_terms.description',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_GET_STARTED = 'react.market_intelligence.no_active_terms.get_started',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_START_TRACKING_TERMS = 'react.market_intelligence.no_active_terms.start_tracking_terms',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_LEARN_ABOUT = 'react.market_intelligence.no_active_terms.learn_about',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_ILLUSTRATION_FIRST = 'react.market_intelligence.no_active_terms.illustration.first',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_ILLUSTRATION_SECOND = 'react.market_intelligence.no_active_terms.illustration.second',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_ILLUSTRATION_THIRD = 'react.market_intelligence.no_active_terms.illustration.third',
  MARKET_INTELLIGENCE_FULL_STOP = 'react.market_intelligence.full_stop',
  MARKET_INTELLIGENCE_NUMERIC_ONE = 'react.market_intelligence.numeric.one',
  MARKET_INTELLIGENCE_NUMERIC_TWO = 'react.market_intelligence.numeric.two',
  MARKET_INTELLIGENCE_NUMERIC_THREE = 'react.market_intelligence.numeric.three',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_QUESTIONS_PRICING = 'react.market_intelligence.no_active_terms.questions_pricing',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_QUESTIONS_PRICING_TEIKA_USER = 'react.market_intelligence.no_active_terms.questions_pricing_teika_user',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_QUESTIONS_PRICING_NOT_TEIKA_USER = 'react.market_intelligence.no_active_terms.questions_pricing_not_teika_user',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE__PREPOSITION_OF = 'react.market_intelligence.card.track_terms_usage.preposition.of',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_LEGEND_TRACKED_TERMS = 'react.market_intelligence.card.track_terms_usage.legend_tracked_terms',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TOOLTIP_DUPLICATE = 'react.market_intelligence.add_new_terms.tooltip.duplicate',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TOOLTIP_DELETE = 'react.market_intelligence.add_new_terms.tooltip.delete',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TITLE = 'react.market_intelligence.add_new_terms.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_DESCRIPTION = 'react.market_intelligence.add_new_terms.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TAB_MANUALLY = 'react.market_intelligence.add_new_terms.tab.manually',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TAB_BULK_UPLOAD = 'react.market_intelligence.add_new_terms.tab.bulk_upload',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NO_TERMS_TITLE = 'react.market_intelligence.add_new_terms.no_terms.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NO_TERMS_DESCRIPTION = 'react.market_intelligence.add_new_terms.no_terms.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_ACTION = 'react.market_intelligence.add_new_terms.column.action',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_COUNTRY = 'react.market_intelligence.add_new_terms.column.country',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_SALES_CHANNEL = 'react.market_intelligence.add_new_terms.column.sales_channel',
  MARKET_INTELLIGENCE_TRACKED_TERMS_TITLE = 'react.market_intelligence.tracked_terms.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_ACTION_TOOLTIP_TITLE = 'react.market_intelligence.add_new_terms.column.action.tooltip.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_ACTION_TOOLTIP_DESCRIPTION = 'react.market_intelligence.add_new_terms.column.action.tooltip.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_PLACEHOLDER = 'react.market_intelligence.add_new_terms.new_term_placeholder',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_TITLE = 'react.market_intelligence.add_new_terms.new_term_tooltip.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_ERROR_TITLE = 'react.market_intelligence.add_new_terms.new_term_tooltip.error.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_DESCRIPTION = 'react.market_intelligence.add_new_terms.new_term_tooltip.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_COUNTRY_LABEL = 'react.market_intelligence.add_new_terms.country_label',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_TOOLTIP = 'react.market_intelligence.add_new_terms.new_term_footer_tooltip',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_TOOLTIP_TM_ACCOUNT = 'react.market_intelligence.add_new_terms.new_term_footer_tooltip_tm_account',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_AVAILABLE_TERMS_TEXT = 'react.market_intelligence.add_new_terms.new_term_footer_available_term_text',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_AVAILABLE_TERMS_TEXT_TM_ACCOUNT = 'react.market_intelligence.add_new_terms.new_term_footer_available_term_text_tm_account',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_CANCEL_BUTTON = 'react.market_intelligence.add_new_terms.new_term_footer_cancel_button',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_SUBMIT_BUTTON = 'react.market_intelligence.add_new_terms.new_term_footer_submit_button',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_LIMIT_GRADIENT = 'react.market_intelligence.card.track_terms_usage.limit_gradient',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_REDIRECT_DASHBOARD_GRADIENT = 'react.market_intelligence.card.track_terms_usage.redirect_dashboard_gradient',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_REDIRECT_LEARN_MORE_GRADIENT = 'react.market_intelligence.card.track_terms_usage.redirect_learn-more_gradient',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_DASHBOARD_LINK = 'react.market_intelligence.tracked_terms_usage.dashboard_link',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_LEARN_MORE_LINK = 'react.market_intelligence.tracked_terms_usage.learn_more_link',
  MARKET_INTELLIGENCE_SELECT_SALES_CHANNEL_PLACEHOLDER = 'react.market_intelligence.select_sales_channel_placeholder',
  MARKET_INTELLIGENCE_SELECT_COUNTRY_PLACEHOLDER = 'react.market_intelligence.select_country_placeholder',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_SEARCH_DROPDOWN_PLACEHOLDER = 'react.market_intelligence.add_new_terms.search_dropdown_placeholder',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_PREVIEWER = 'react.market_intelligence.add_new_terms.terms_previewer',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_APPEARANCE_PERCENTAGE = 'react.market_intelligence.tooltips.table.apr.appearance_percentage',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_AVG_RANK_ORGANIC = 'react.market_intelligence.tooltips.table.apr.avg_rank_organic',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_AVG_RANK_PAID = 'react.market_intelligence.tooltips.table.apr.avg_rank_paid',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_AVG_RANK_TOTAL = 'react.market_intelligence.tooltips.table.apr.avg_rank_total',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_APPEARANCES_PERCENTAGE = 'react.market_intelligence.tooltips.table.bcs.appearances_percentage',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_PAID = 'react.market_intelligence.tooltips.table.bcs.paid',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_ORGANIC = 'react.market_intelligence.tooltips.table.bcs.organic',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_TOTAL = 'react.market_intelligence.tooltips.table.bcs.total',
  ADVERTISING_OPTIMIZATION_CAMPAIGN_COMPLETED_TOOLTIP = 'react.ad_optimization.ads_manager.edit.campaign_completed_tooltip_for_end_date',

  SKU_CATALOG_TITLE = 'react.pages.sku_catalog.title',

  ADVERTISING_OPTIMIZATION_SERVER_ERROR_TEXT = 'react.ad_optimization.server.error.text',
  ADVERTISING_OPTIMIZATION_REFRESH_TEXT = 'react.ad_optimization.refresh.text',
  ADVERTISING_OPTIMIZATION_WELCOME_TEXT = 'react.ad_optimization.welcome.text',
  ADVERTISING_OPTIMIZATION_CONNECT_SALES_CHANNELS_TEXT = 'react.ad_optimization.connect.sales_channels_text',
  ADVERTISING_OPTIMIZATION_HEADER_DATA_STATUS_SPONSORED_API = 'react.pages.ad_optimization.header.data_status_sponsored_api',
  ADVERTISING_OPTIMIZATION_HEADER_DATA_STATUS = 'react.pages.ad_optimization.header.data_status',
  ADVERTISING_OPTIMIZATION_HEADER_DATA_STATUS_TIME_OFFSET = 'react.pages.ad_optimization.header.data_status_time_offset',
  ADVERTISING_OPTIMIZATION_TITLE = 'react.pages.ad_optimization.title',
  ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_EXPORT = 'react.pages.ad_optimization.table.action_row.export',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_FILE_DOWNLOAD = 'react.pages.ad_optimization.toast_message.file_download',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_SUCCESS = 'react.pages.ad_optimization.toast_message.save_changes_success',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE = 'react.pages.ad_optimization.toast_message.save_changes_failure',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE_HEADLINE = 'react.pages.ad_optimization.toast_message.save_changes_failure_headline',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_EXPORT_FAILED_HEADER = 'react.pages.ad_optimization.toast_message.export_failed_header',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_EXPORT_FAILED = 'react.pages.ad_optimization.toast_message.export_failed',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SPEND = 'react.ad_optimization.campaigns.hero_metrics.ad_spend',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.ad_sales',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_ROAS = 'react.ad_optimization.campaigns.hero_metrics.roas',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_UNITS_SOLD = 'react.ad_optimization.campaigns.hero_metrics.units_sold',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_CONVERSION_RATE = 'react.ad_optimization.campaigns.hero_metrics.conversion_rate',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_CONVERSIONS = 'react.ad_optimization.campaigns.hero_metrics.ad_conversions',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_CAPTION_MESSAGE = 'react.ad_optimization.campaigns.hero_metrics.caption_message',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_ANALYZE = 'react.ad_optimization.data_inspector.analyze',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL = 'react.ad_optimization.data_inspector.ad_level',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_MANAGE_COLUMNS = 'react.ad_optimization.data_inspector.manage_columns',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_APPLY_CHANGES = 'react.ad_optimization.data_inspector.apply_changes',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_PRODUCTS = 'react.ad_optimization.data_inspector.ad_type.sponsored_products',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SEARCH_BRAND_AMPLIFIER = 'react.ad_optimization.data_inspector.ad_type.search_brand_amplifier',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_BRANDS = 'react.ad_optimization.data_inspector.ad_type.sponsored_brands',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_DISPLAY = 'react.ad_optimization.data_inspector.ad_type.sponsored_display',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_CAMPAIGNS = 'react.ad_optimization.data_inspector.ad_level.campaigns',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_ADGROUPS = 'react.ad_optimization.data_inspector.ad_level.adGroups',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_ADS = 'react.ad_optimization.data_inspector.ad_level.productAds',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PROFILES = 'react.ad_optimization.data_inspector.ad_level.profiles',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_TARGETS = 'react.ad_optimization.data_inspector.ad_level.targets',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_KEYWORDS_ADS = 'react.ad_optimization.data_inspector.ad_level.keywordAds',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_TARGETS = 'react.ad_optimization.data_inspector.ad_level.productTargets',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PAGETYPE = 'react.ad_optimization.data_inspector.ad_level.pageType',
  ADVERTISING_OPTIMIZATION_WALMART_BUDGET_ROLLOVER_FALSE_VALUE_TOOLTIP = 'react.ad_optimization.walmar.budget_rollover_false_value_tooltip',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PLATFORM = 'react.ad_optimization.data_inspector.ad_level.platform',
  ADVERTISING_OPTIMIZATION_LEARN_MORE_TEXT = 'react.ad_optimization.data_status.link_learn_more',
  ADVERTISING_OPTIMIZATION_DATA_STATUS_TEXT = 'react.ad_optimization.data_status.body_text',
  ADVERTISING_OPTIMIZATION_AMAZON = 'react.ad_optimization.amazon',
  ADVERTISING_OPTIMIZATION_WALMART = 'react.ad_optimization.walmart',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_PAGE_HEADER_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.header_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_ANALYZE_AD_PERFORMANCE_HEADER = 'react.ad_optimization.connection_in_sync_page.analyze_ad_performance_header',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_ANALYZE_AD_PERFORMANCE_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.analyze_ad_performance_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_BID_AUTOMATION_HEADER = 'react.ad_optimization.connection_in_sync_page.bid_automation_header',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_BID_AUTOMATION_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.bid_automation_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_MANAGE_AD_HEADER = 'react.ad_optimization.connection_in_sync_page.manage_ad_header',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_MANAGE_AD_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.manage_ad_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_CHECK_SYNC_STATUS_BTN = 'react.ad_optimization.connection_in_sync_page.check_status_btn',
  ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_TITLE = 'react.ad_optimization.ads_manager.bulk_edit_modal.error_toast_title',
  ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_MESSAGE = 'react.ad_optimization.ads_manager.bulk_edit_modal.error_toast_message',

  ADS_MANAGER_CAMPAIGNS_TABLE_DETAILS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.details_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_CHANNEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.channel_settings_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_FLYWHEEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.flywheel_settings_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_PERFORMANCE_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.performance_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.campaigns_table.column.merchant_name',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PORTFOLIO_NAME = 'react.ad_optimization.ads_manager.campaigns_table.column.portfolio_name',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_cost_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_tactic',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PRODUCT_PAGE_BID_MULTIPILER = 'react.ad_optimization.ads_manager.campaigns_table.column.product_page_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOP_OF_SEARCH_BID_MULTIPLER = 'react.ad_optimization.ads_manager.campaigns_table.column.top_of_search_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_conversions',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_targeting_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_START_DATE = 'react.ad_optimization.ads_manager.campaigns_table.column.start_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.column.end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.column.daily_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.column.lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_ROLLOVER = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_rollover',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_inclusion_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_BUY_BOX_PLACEMENT = 'react.ad_optimization.ads_manager.campaigns_table.column.item_buy_box_placement',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_CAROUSEL_PLACEMENT = 'react.ad_optimization.ads_manager.campaigns_table.column.item_carousel_placement',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_BUY_BOX = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_buy_box',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_SEARCH_INGRID = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_search_ingrid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_HOME_PAGE = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_home_page',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_STOCK_UP = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_stock_up',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_DESKTOP = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_desktop',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_APP = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_app',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MOBILE = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_mobile',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_orders',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_ORDERS_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.campaigns_table.column.percentage_of_orders_new_to_brand',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_orders_rate',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_SALES_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.campaigns_table.column.percentage_of_sales_new_to_brand',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_AD_UNITS_SOLD_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.campaigns_table.column.percentage_of_ad_units_sold_new_to_brand',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.column.automation_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET = 'react.ad_optimization.ads_manager.campaigns_table.column.macs_target',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MIN_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.min_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MINIMUM_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.minimum_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAX_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.max_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAXIMUM_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.maximum_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.campaigns_table.column.impressions',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.campaigns_table.column.clicks',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SPEND = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_spend',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_TOTAL = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_sales_total',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.campaigns_table.column.units_sold',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_CONVERSION_RATE = 'react.ad_optimization.ads_manager.campaigns_table.column.total_conversion_rate',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ACOS = 'react.ad_optimization.ads_manager.campaigns_table.column.total_acos',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ROAS = 'react.ad_optimization.ads_manager.campaigns_table.column.total_roas',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_CTR = 'react.ad_optimization.ads_manager.campaigns_table.column.click_through_rate',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_AVG_CPC = 'react.ad_optimization.ads_manager.campaigns_table.column.avg_cost_per_click',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_DIRECT = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_sales_direct',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ACOS_DIRECT = 'react.ad_optimization.ads_manager.campaigns_table.column.direct_acos',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ROAS_DIRECT = 'react.ad_optimization.ads_manager.campaigns_table.column.direct_roas',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.campaigns_table.column.related_click_revenue',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BRAND_CLICK_REVENUE = 'react.ad_optimization.ads_manager.campaigns_table.column.brand_click_revenue',
  ADS_MANAGER_CAMPAIGNS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.campaigns_table.footer.total_items_text',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NO_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.no_end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_SET_NO_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.set_no_end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_FORMAT = 'react.ad_optimization.ads_manager.campaigns_ad_format',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION = 'react.ad_optimization.ads_manager.amazon_bid_optimization',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS = 'react.ad_optimization.ads_manager.campaign_serving_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BELOW_SEARCH_BID_MULTIPLIER = 'react.ad_optimization.ads_manager.below_search_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_TABLE_NO_MATCHED_RESULTS_TITLE = 'react.ad_optimization.ads_manager.campaigns_table.no_matched_results.title',
  ADS_MANAGER_CAMPAIGNS_TABLE_NO_MATCHED_RESULTS_DESCRIPTION = 'react.ad_optimization.ads_manager.campaigns_table.no_matched_results.description',
  ADS_MANAGER_CAMPAIGNS_TABLE_DATA_CONNECTION_ERROR_TITLE = 'react.ad_optimization.ads_manager.campaigns_table.data_connection_error.title',
  ADS_MANAGER_CAMPAIGNS_TABLE_DATA_CONNECTION_ERROR_DESCRIPTION = 'react.ad_optimization.ads_manager.campaigns_table.data_connection_error.description',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_EXCLUDED = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_inclusion_status.filter.excluded',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_INCLUDED = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_inclusion_status.filter.included',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE_FILTER_AUTO = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_targeting_type.filter.auto',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE_FILTER_MANUAL = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_targeting_type.filter.manual',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_LIVE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.live',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_EXTEND = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.extend',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ENABLED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.enabled',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PAUSED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.paused',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ARCHIVED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.archived',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_DRAFT = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.draft',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PENDING = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.pending',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_COMPLETED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.completed',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_RESCHEDULED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.rescheduled',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PROPOSAL = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.proposal',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_SCHEDULED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.scheduled',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_DAILY = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.daily',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_LIFETIME = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.lifetime',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_TOTAL = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.total',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_BOTH = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.both',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_campaign_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_daily_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MIN_BID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_min_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MAX_BID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_max_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_BUDGET_ROLLOVER = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_budget_rollover',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MACS_TARGET = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_macs_target',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BIDDING_STRATEGY = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_amazon_bidding_strategy',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_amazon_bid_optimization',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_NEW_VALUE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.new_value',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SB_VIDEO_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.sb_video_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SB_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.sb_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.select_an_option',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_INCLUSION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_inclusion',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_INGRID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_search_ingrid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_INGRID_TOOLTIP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_search_ingrid_tooltip',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_CAROUSEL = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_search_carousel',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_ITEM_BUYBOX = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_item_buybox',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_ITEM_CAROUSEL = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_item_carousel',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIERS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLATFORM_BID_MULTIPLEIRS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_platform_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_automation_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PORTFOLIO = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_portfolio',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_INCLUSION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_inclusion',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLEIRS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ENABLED = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.enabled',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_PAUSED = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.paused',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_COMPLETED = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.completed',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ENABLED_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.enabled_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_PAUSED_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.paused_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ARCHIVED_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.archived_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_SEARCH_IN_GRID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.search_in_grid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_BUY_BOX = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.buy_box',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_HOME_PAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.home_page',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_STOCKUP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.stockup',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_APP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_multipliers.app',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_MOBILE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_multipliers.mobile',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_DESKTOP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_multipliers.desktop',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_AUTO_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.auto_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_MANUAL_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.manual_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_TOP_OF_SEARCH = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.top_of_search',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_BELOW_TOP_OF_SEARCH = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.below_top_of_search',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_PRODUCT_PAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.product_page',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_AMAZON_BIDDING_STRATEGY = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.amazon_bidding_strategy',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.campaign_status_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.daily_budget_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.lifetime_budget_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.placement_bid_multiplier_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.automation_status_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.amazon_bid_automation_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.campaign_status_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SEARCH_CAROUSEL_INCLUSION_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.search_carousel_inclusion_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.daily_budget_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.lifetime_budget_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.placement_bid_multiplier_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_END_DATE_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.end_date_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_NO_END_DATE_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.no_end_date_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_WALMART_BULK_EDIT_MODAL_END_DATE_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.walmart.campaigns_table.bulk_edit_modal.edit.end_date_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.automation_status_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.amazon_bid_optimization_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_BUDGET_MIN_VALUE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.budget.minimum_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET_MIN_VALUE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.lifetime_budget.error',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MACS_TARGET_MIN_VALUE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.macs_target.minimum_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MACS_TARGET_ERROR_BETWEEN_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.macs_target.between_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_BUDGET_COMPARE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.budget.compare_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.daily_budget.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_bid_multipliers.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_EDIT_PORTFOLIO_GROUP_HEADER = 'react.ad_optimization.ads_manager.campaigns_table.edit_portfolio.group_header',
  ADS_MANAGER_CAMPAIGNS_TABLE_NO_PORTFOLIO_LABEL = 'react.ad_optimization.ads_manager.campaigns_table.portfolio.no_portfolio_label',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MIN_BID_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.min_bid.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MAX_BID_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.max_bid.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SET_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.set_end_date',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_EXTEND = 'react.ad_optimization.campaigns_table.invalid.end_date.extend',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_BLANK = 'react.ad_optimization.campaigns_table.invalid.end_date.blank',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_FORMAT = 'react.ad_optimization.campaigns_table.invalid.end_date.format',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_SMALLER_THAN_CURRENT_DATE = 'react.ad_optimization.campaigns_table.invalid.end_date.smaller_than_current_date',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_SMALLER_THAN_START_DATE = 'react.ad_optimization.campaigns_table.invalid.end_date.smaller_than_start_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_CHARACTER_LENGTH = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name.invalid_characters_length',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_ALL_SPECIAL_CHARACTERS = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name.invalid_all_special_characters',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_CHARACTERS = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name.invalid_characters',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_DOWN = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy.dynamic_bids_down',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BID_OPTIMIZATION_OFF = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bid_optimization.off',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BID_OPTIMIZATION_ON = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bid_optimization.on',
  ADS_MANAGER_CAMPAIGNS_TABLE_AUTOMATION_STATUS_ACTIVE = 'react.ad_optimization.ads_manager.campaigns_table.column.automation_status.active',
  ADS_MANAGER_CAMPAIGNS_TABLE_AUTOMATION_STATUS_INACTIVE = 'react.ad_optimization.ads_manager.campaigns_table.column.automation_status.inactive',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_UP_AND_DOWN = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy.dynamic_bids_up_and_down',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_FIXED = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy.dynamic_bids_fixed',
  ADS_MANAGER_CAMPAIGNS_NO_PORTFOLIOS_FOUND = 'react.ad_optimization.ads_manager.campaigns.no_portfolios_found',
  ADS_MANAGER_CAMPAIGNS_TABLE_BUTTON_SAVE_CHANGE = 'react.ad_optimization.ads_manager.campaigns_table.buton.save_change',
  ADS_MANAGER_AD_ITEMS_TABLE_BULK_EDIT_MODAL_BODY = 'react.ad_optimization.ads_manager.ad_items.bulk_edit_modal.body',
  ADS_MANAGER_PRODUCT_AD_TABLE_BULK_EDIT_MODAL_BODY = 'react.ad_optimization.ads_manager.product_ads.bulk_edit_modal.body',
  ADS_MANAGER_PRODUCT_AD_TABLE_BULK_EDIT_MODAL_HEADER_TEXT = 'react.ad_optimization.ads_manager.product_ads.bulk_edit_modal.header_text',
  ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT = 'react.ad_optimization.ad_items.product',
  ADS_MANAGER_AD_ITEMS_TABLE_SKU = 'react.ad_optimization.ad_items.product_ad_sku',
  ADS_MANAGER_AD_ITEMS_TABLE_ASIN = 'react.ad_optimization.ad_items.product_ad_asin',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_NAME = 'react.ad_optimization.ad_items.ad_item_name',
  ADS_MANAGER_AD_ITEMS_TABLE_MERCHANT_NAME = 'react.ad_optimization.ad_items.merchant_name',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_ID = 'react.ad_optimization.ad_items.ad_item_id',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_GROUP_NAME = 'react.ad_optimization.ad_items.ad_group_name',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_GROUP_STATUS = 'react.ad_optimization.ad_items.ad_group_status',
  ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT_AD_STATUS = 'react.ad_optimization.ad_items.product_ad_status',
  ADS_MANAGER_AD_ITEMS_TABLE_PORTFOLIO_NAME = 'react.ad_optimization.ad_items.portfolio_name',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ad_items.campaign_cost_type',
  ADS_MANAGER_AD_ITEMS_TABLE_BID = 'react.ad_optimization.ad_items.bid',
  ADS_MANAGER_AD_ITEMS_TABLE_IMPRESSIONS = 'react.ad_optimization.ad_items.impressions',
  ADS_MANAGER_AD_ITEMS_TABLE_CLICKS = 'react.ad_optimization.ad_items.clicks',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_SPEND = 'react.ad_optimization.ad_items.ad_spend',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_UNITS_SOLD = 'react.ad_optimization.ad_items.ad_units_sold',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_CONVERSION_RATE = 'react.ad_optimization.ad_items.ad_conversion_rate',
  ADS_MANAGER_AD_ITEMS_TABLE_TOTAL_ACOS = 'react.ad_optimization.ad_items.acos_total',
  ADS_MANAGER_AD_ITEMS_TABLE_TOTAL_ROAS = 'react.ad_optimization.ad_items.roas_total',
  ADS_MANAGER_AD_ITEMS_TABLE_CLICK_THROUGH_RATE = 'react.ad_optimization.ad_items.click_through_rate',
  ADS_MANAGER_AD_ITEMS_TABLE_COST_PER_CLICK = 'react.ad_optimization.ad_items.cost_per_click',
  ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_AD_SALES = 'react.ad_optimization.ad_items.ad_sales_direct',
  ADS_MANAGER_AD_ITEMS_TABLE_TOTAL_AD_SALES = 'react.ad_optimization.ad_items.ad_sales_total',
  ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_ACOS = 'react.ad_optimization.ad_items.acos_direct',
  ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_ROAS = 'react.ad_optimization.ad_items.roas_direct',
  ADS_MANAGER_AD_ITEMS_TABLE_RELATED_CLICK_REVENUE = 'react.ad_optimization.ad_items.related_click_revenue',
  ADS_MANAGER_AD_ITEMS_TABLE_BRAND_CLICK_REVENUE = 'react.ad_optimization.ad_items.brand_click_revenue',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_NAME = 'react.ad_optimization.ad_items.campaign_name',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_TYPE = 'react.ad_optimization.ad_items.campaign_type',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ad_items.campaign_targeting_type',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_STATUS = 'react.ad_optimization.ad_items.campaign_status',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_DETAILS = 'react.ad_optimization.ad_items.ad_item_details',
  ADS_MANAGER_AD_ITEMS_TABLE_CHANNEL_SETTINGS = 'react.ad_optimization.ad_items.channel_settings',
  ADS_MANAGER_AD_ITEMS_TABLE_PERFORMANCE = 'react.ad_optimization.ad_items.performance',
  ADS_MANAGER_AD_ITEMS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.ad_items_table.footer.total_items_text',
  ADS_MANAGER_AD_ITEMS_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.ad_items_table.column.campaign_tactic',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.ad_items_table.column.daily_budget',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_TOTAL_BUDGET = 'react.ad_optimization.ads_manager.ad_items_table.column.total_budget',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.ad_items_table.column.campaign_ad_conversions',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.ad_items_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_NEW_TO_BRAND_ORDERS = 'react.ad_optimization.ads_manager.ad_items_table.column.new_to_brand_orders',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.ad_items_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_AD_ITEMS_INVALID_BID_LESS_THAN = 'react.ad_optimization.ad_items.invalid.bid.less.than',
  ADS_MANAGER_AD_ITEMS_INVALID_BID_MORE_THAN = 'react.ad_optimization.ad_items.invalid.bid.more.than',
  ADS_MANAGER_AD_ITEMS_INVALID_BID = 'react.ad_optimization.ad_items.invalid.bid.value',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ENABLED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_enabled_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ENABLED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_enabled_option_productads',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_DISABLED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_disabled_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_PAUSED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_paused_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_DISABLED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_disabled_option_productads',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_PAUSED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_paused_option_productads',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ARCHIVED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_archived_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ARCHIVED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_archived_option_productads',
  ADS_MANAGER_AD_ITEMS_TABLE_BULK_EDIT_MODAL_VALIDATION_AT_LEAST = 'react.ad_optimization.ads_manager.ad_items.bulk_edit_modalat_least',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ENABLED = 'react.ad_optimization.targets.bulk_edit.modal_status_enabled_option',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ENABLED_PRODUCTTARGETS = 'react.ad_optimization.targets.bulk_edit.modal_status_enabled_option_product_targets',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED = 'react.ad_optimization.targets.bulk_edit.modal_status_paused_option',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED_PRODUCTTARGETS = 'react.ad_optimization.targets.bulk_edit.modal_status_paused_option_product_targets',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ARCHIVED = 'react.ad_optimization.targets.bulk_edit.modal_status_archived_option',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ARCHIVED_PRODUCTTARGETS = 'react.ad_optimization.targets.bulk_edit.modal_status_archived_option_product_targets',
  ADS_MANAGER_ADGROUP_TABLE_AUTOMATION_COLUMN_UNSET_STATUS_TOOLTIP = 'react.ad_optimization.ads_manager.adsgroup_table.automation_status_unset_tooltip',
  ADS_MANAGER_ADGROUP_TABLE_DETAILS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.details_group',
  ADS_MANAGER_ADGROUP_TABLE_CHANNEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.channel_settings_group',
  ADS_MANAGER_ADGROUP_TABLE_FLYWHEEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.flywheel_settings_group',
  ADS_MANAGER_ADGROUP_TABLE_PERFORMANCE_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.performance_group',
  ADS_MANAGER_ADGROUP_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.adsgroup_table.footer.total_items_text',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_NEW_VALUE = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.new_value',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AUTO_AD_GROUPS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.auto_ad_groups',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_KEYWORD_AD_GROUPS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.keyword_ad_groups',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_MACS_TARGET = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.macs_target',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_DEFAULT_BID = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.default_bid',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_VALUE_FOR_VCPM = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.value_for_vcpm',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_VALUE_FOR_CPC = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.value_for_cpc',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_MINIMUM_BID = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.minimum_bid',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_MAXIMUM_BID = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.maximum_bid',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.automation_status',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_ADGROUP_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.adgroup_status',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS_HELP_TEXT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.automation_status_help_text',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS_HELP_CONTENT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.automation_status_help_content',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.select_an_option',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACTIVE_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.active_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_INACTIVE_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.inactive_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ENABLED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.enabled_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_DISABLED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.disabled_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_ARCHIVED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.amazon.archived_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_ENABLED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.amazon.enabled_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_PAUSED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.amazon.paused_label',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME = 'react.ad_optimization.ads_manager.adsgroup_table.column.adgroup_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_daily_budget',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_TOTAL_BUDGET = 'react.ad_optimization.ads_manager.adsgroup_table.column.total_budget',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_GROUP_DEFAULT_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.ad_group_default_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.adsgroup_table.column.merchant_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TYPE = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_type',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_targeting_type',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_status',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.adgroup_status',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_REVIEW_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.adgroup_review_status',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_PORTFOLIO_NAME = 'react.ad_optimization.ads_manager.adgroup_table.column.portfolio_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ads_manager.adgroup_table.column.campaign_cost_type',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_automation_status',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MACS_TARGET = 'react.ad_optimization.ads_manager.adsgroup_table.column.macs_target',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DEFAULT_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.default_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.min_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.max_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.adsgroup_table.column.impressions',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.adsgroup_table.column.clicks',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSPEND = 'react.ad_optimization.ads_manager.adsgroup_table.column.adspend',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.adsales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.adsgroup_table.column.units_sold',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CONVERSION_RATE = 'react.ad_optimization.ads_manager.adsgroup_table.column.conversion_rate',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ACOS = 'react.ad_optimization.ads_manager.adsgroup_table.column.acos',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ROAS = 'react.ad_optimization.ads_manager.adsgroup_table.column.roas',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.ad_optimization.ads_manager.adsgroup_table.column.click_through_rate',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_AVERAGE_COST_PER_CLICK = 'react.ad_optimization.ads_manager.adsgroup_table.column.average_cost_per_click',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DIRECT_ADSALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.direct_adsales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DIRECT_ACOS = 'react.ad_optimization.ads_manager.adsgroup_table.column.direct_acos',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DIRECT_ROAS = 'react.ad_optimization.ads_manager.adsgroup_table.column.direct_roas',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.adsgroup_table.column.related_click_revenue',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_BRAND_CLICK_REVENUE = 'react.ad_optimization.ads_manager.adsgroup_table.column.brand_click_revenue',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.adsgroup_table.column.ad_conversions',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_ORDERS = 'react.ad_optimization.ads_manager.adsgroup_table.column.new_to_brand_ad_orders',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.adsgroup_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_tactic',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_GREATER_THAN_ERROR_TEXT = 'react.ad_optimization.ads_manager.targets_table.edit_bid.greater_than_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_LESS_THAN_ERROR_TEXT = 'react.ad_optimization.ads_manager.targets_table.edit_bid.less_than_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_ERROR_TEXT = 'react.ad_optimization.ads_manager.targets_table.edit_bid.invalid_value_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_GREATER_THAN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.targets_table.edit_bid.greater_than_daily_budget_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_GREATER_THAN_HALF_OF_DAILY_BUDGET = 'react.ad_optimization.ads_manager.targets_table.edit_bid.greater_than_half_of_daily_budget_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_MACS_VALUE = 'react.ad_optimization.ads_manager.adgroups_table.column.invalid.macs',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_MACS_VALUE = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid.macs',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_GREATER_THAN_MAX_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.invalid_greater_than_maxbid.minbid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_EMPTY_VALUES = 'react.ad_optimization.ads_manager.adgroups_table.column.empty_values',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_LESS_THAN_MIN_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.invalid_less_than_minbid.maxbid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid_less_than_minbid.maxbid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_DEFAULT_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid_defaul_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_DEFAULT_BID_FOR_SP = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid_default_bid_for_sp',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTERS = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.invalid_characters',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_ALL_SPECIAL_CHARACTERS = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.invalid_all_special_characters',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTER_LENGTH = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.invalid_characters_length',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTER_LENGTH_AMAZON = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.amazon.invalid_characters_length',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_DUPLICATE_AMAZON = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.amazon.duplicate_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_CONVERSION_RATE = 'react.ad_optimization.ads_manager.targets_table.column.ad_conversion_rate',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_DETAILS = 'react.ad_optimization.ads_manager.targets_table.column.group.details',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_CHANNEL_SETTINGS = 'react.ad_optimization.ads_manager.targets_table.column.group.channel_settings',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_PERFORMANCE = 'react.ad_optimization.ads_manager.targets_table.column.group.performance',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_BODY = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.body',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_TARGET_STATUS_ENABLED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.enabled',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_TARGET_STATUS_PAUSED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.paused',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.select_an_option',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEW_VALUE = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.new_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_TEXT = 'react.ad_optimization.ads_manager.targets_table.column.target_text',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_MATCH_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.match_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DATE_ADDED = 'react.ad_optimization.ads_manager.targets_table.column.date_added',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.target_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_FLYWHEEL_AI_ENABLED = 'react.ad_optimization.ads_manager.targets_table.column.flywheel_ai_enabled',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.targets_table.column.merchant_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_GROUP_NAME = 'react.ad_optimization.ads_manager.targets_table.column.ad_group_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_GROUP_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.ad_group_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.targets_table.column.campaign_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.campaign_targeting_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.campaign_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.campaign_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PORTFOLIO_NAME = 'react.ad_optimization.ads_manager.targets_table.column.portfolio_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.campaign_cost_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD_REVIEW_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.keyword_review_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PRODUCT_REVIEW_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.product_review_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_ADGROUP_REVIEW_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.adgroup_review_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_BID = 'react.ad_optimization.ads_manager.targets_table.column.bid',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.target_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.targets_table.column.impressions',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.targets_table.column.clicks',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_SPEND = 'react.ad_optimization.ads_manager.targets_table.column.ad_spend',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TOTAL_AD_SALES = 'react.ad_optimization.ads_manager.targets_table.column.total_ad_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.targets_table.column.units_sold',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TOTAL_ACOS = 'react.ad_optimization.ads_manager.targets_table.column.total_acos',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TOTAL_ROAS = 'react.ad_optimization.ads_manager.targets_table.column.total_roas',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.ad_optimization.ads_manager.targets_table.column.click_through_rate',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AVERAGE_COST_PER_CLICK = 'react.ad_optimization.ads_manager.targets_table.column.average_cost_per_click',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_AD_SALES = 'react.ad_optimization.ads_manager.targets_table.column.direct_adsales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ACOS = 'react.ad_optimization.ads_manager.targets_table.column.direct_acos',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ROAS = 'react.ad_optimization.ads_manager.targets_table.column.direct_roas',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.targets_table.column.related_click_revenue',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_BRAND_CLICK_REVENUE = 'react.ad_optimization.ads_manager.targets_table.column.brand_click_revenue',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_EXPRESSION_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.expression_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.targets_table.column.campaign_tactic',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.targets_table.column.campaign_daily_budget',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.targets_table.column.campaign_lifetime_budget',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD = 'react.ad_optimization.ads_manager.targets_table.column.keyword',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_AD_FORMAT = 'react.ad_optimization.ads_manager.targets_table.column.campaign_ad_format',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.targets_table.column.ad_conversions',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_orders',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_ORDERS_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.targets_table.column.percentage_of_orders_new_to_brand',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_orders_rate',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_SALES = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_SALES_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.targets_table.column.percentage_of_sales_new_to_brand',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_AD_UNITS_SOLD_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.targets_table.column.percentage_of_ad_units_sold_new_to_brand',
  ADS_MANAGER_TARGETS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.targets_table.footer.total_items_text',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDEINCE_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.audience_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_CATEGORY_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_category_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_BRAND_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_brand_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_less_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_greater_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_between',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_less_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_greater_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_between',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SHIPPING_IS_PRIME_ELIGIBLE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_shipping_is_prime_eligible',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_AGE_RANGE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_age_rage',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_GENRE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_genre',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_LOOKBACK = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.lookback',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDIENCE_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.audeince_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_CATEGORY_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_category_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_BRAND_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_brand_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_LESS_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_less_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_GREATER_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_greater_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_BETWEEN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_between_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_LESS_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_less_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_GREATER_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_greater_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_BETWEEN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_between_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SHIPPING_IS_PRIME_ELIGIBLE_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_shipping_is_prime_eligible_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_AGE_RANGE_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_age_rage_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_GENRE_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_genre_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_LOOKBACK_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.lookback_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.query_high_rel_matches',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.query_broad_rel_matches',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_substitute_related',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_accessory_related',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_SIMILAR_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.similar_product',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.exact_product',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_ASIN_IS_PRIME_SHIPPING_ELIGIBLE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_is_prime_shipping_eligible_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_RELATED_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.related_product',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is_less_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is_greater_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is_between',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_COLON = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_colon',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_AGE_RANGE_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_age_range_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_CATEGORY_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_category_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_BRAND_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_brand_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_GENRE_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_genre_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_IS_PRIME_SHIPPING_AVAILABLE = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_is_prime_shipping_available',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_price_between',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_price_greater_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_price_less_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_review_rating_between',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_review_rating_greater_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_review_rating_less_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_LOOKUP = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_lookup',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_SIMILAR_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.similar_product',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_EXACT_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.exact_product',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_RELATED_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.related_product',

  ADS_MANAGER_PROFILES_TABLE_GROUP_DETAILS = 'react.ad_optimization.ads_manager.profiles_table.details_group',
  ADS_MANAGER_PROFILES_TABLE_GROUP_CHANNEL_SETTING = 'react.ad_optimization.ads_manager.profiles_table.channel_settings_group',
  ADS_MANAGER_PROFILES_TABLE_GROUP_PERFORMANCE = 'react.ad_optimization.ads_manager.profiles_table.performance_group',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_BRAND = 'react.ad_optimization.ads_manager.profiles_table.column.brand_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.profiles_table.column.merchant_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_REVIEW_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.profileReviewStatus',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_NAME = 'react.ad_optimization.ads_manager.profiles_table.column.adgroup_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.adgroup_status',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_REVIEW_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.adgroup_review_status',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.profiles_table.column.campaign_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.profiles_table.column.campaign_targeting_type',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.profiles_table.column.daily_budget',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.profiles_table.column.lifetime_budget',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.campaign_status',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_HEADLINE = 'react.ad_optimization.ads_manager.profiles_table.column.headline',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_URL = 'react.ad_optimization.ads_manager.profiles_table.column.clickUrl',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_LOGO = 'react.ad_optimization.ads_manager.profiles_table.column.logo',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SALES_TOTAL = 'react.ad_optimization.ads_manager.profiles_table.column.ad_sales_total',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SPEND = 'react.ad_optimization.ads_manager.profiles_table.column.ad_spend',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ACOS = 'react.ad_optimization.ads_manager.profiles_table.column.total_acos',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ROAS = 'react.ad_optimization.ads_manager.profiles_table.column.total_roas',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_CONVERSION_RATE = 'react.ad_optimization.ads_manager.profiles_table.column.total_conversion_rate',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.profiles_table.column.units_sold',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.profiles_table.column.impressions',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.profiles_table.column.clicks',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AVG_COST_PER_CLICK = 'react.ad_optimization.ads_manager.profiles_table.column.avg_cost_per_click',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.ad_optimization.ads_manager.profiles_table.column.click_through_rate',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SALES_DIRECT = 'react.ad_optimization.ads_manager.profiles_table.column.ad_sales_direct',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_DIRECT_ACOS = 'react.ad_optimization.ads_manager.profiles_table.column.direct_acos',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_DIRECT_ROAS = 'react.ad_optimization.ads_manager.profiles_table.column.direct_roas',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.profiles_table.column.related_click_revenue',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_BRAND_CLICK_REVENUE = 'react.ad_optimization.ads_manager.profiles_table.column.brand_click_revenue',
  ADS_MANAGER_PROFILES_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.profiles_table.footer.total_items_text',

  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_LEGACY = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.legacy',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_PRODUCT = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.product',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_AUDIENCES = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.audiences',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_VIEWS = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.views',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ASIN_NOT_BUYABLE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.asin_not_buyable',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_BILLING_ERROR = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.billing_error',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ENDED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.ended',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_LANDING_PAGE_NOT_AVAILABLE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.landing_page_not_available',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OUT_OF_BUDGET = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.out_of_budget',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PAUSED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.paused',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PENDING_REVIEW = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.pending_review',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_READY = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.ready',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_REJECTED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.rejected',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_DELIVERING = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.delivering',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_SCHEDULED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.scheduled',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_TERMINATED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.terminated',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION_ON = 'react.ad_optimization.ads_manager.ad_level_table_column.amazon_bid_optimization.on',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION_OFF = 'react.ad_optimization.ads_manager.ad_level_table_column.amazon_bid_optimization.off',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_PRODUCT_COLLECTION = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_ad_format.product_collection',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_VIDEO = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_ad_format.video',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_STORE_SPOTLIGHT = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_ad_format.store_spotlight',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_SPONSORED_PRODUCTS = 'react.ad_optimization.ads_manager.ad_level_table_filter.sponsored_products',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AUTO = 'react.ad_optimization.ads_manager.ad_level_table_filter.auto',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_MANUAL = 'react.ad_optimization.ads_manager.ad_level_table_filter.manual',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_LIVE = 'react.ad_optimization.ads_manager.ad_level_table_filter.live',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED = 'react.ad_optimization.ads_manager.ad_level_table_filter.enabled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED = 'react.ad_optimization.ads_manager.ad_level_table_filter.paused',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPLETED = 'react.ad_optimization.ads_manager.ad_level_table_filter.completed',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_RESCHEDULED = 'react.ad_optimization.ads_manager.ad_level_table_filter.rescheduled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PROPOSAL = 'react.ad_optimization.ads_manager.ad_level_table_filter.proposal',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_SCHEDULED = 'react.ad_optimization.ads_manager.ad_level_table_filter.scheduled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ACTIVE = 'react.ad_optimization.ads_manager.ad_level_table_filter.active',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_INACTIVE = 'react.ad_optimization.ads_manager.ad_level_table_filter.inactive',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_UNSET = 'react.ad_optimization.ads_manager.ad_level_table_filter.unset',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DISABLED = 'react.ad_optimization.ads_manager.ad_level_table_filter.disabled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT = 'react.ad_optimization.ads_manager.ad_level_table_filter.exact',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PHRASE = 'react.ad_optimization.ads_manager.ad_level_table_filter.phrase',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BROAD = 'react.ad_optimization.ads_manager.ad_level_table_filter.broad',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPETITOR = 'react.ad_optimization.ads_manager.ad_level_table_filter.competitor',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_GENERIC = 'react.ad_optimization.ads_manager.ad_level_table_filter.generic',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BRAND = 'react.ad_optimization.ads_manager.ad_level_table_filter.brand',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_FLYWHEEL_AI_ADDED = 'react.ad_optimization.ads_manager.ad_level_table_filter.flywheel_ai_added',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_MANUALLY_ADDED = 'react.ad_optimization.ads_manager.ad_level_table_filter.manually_added',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED = 'react.ad_optimization.ads_manager.ad_level_table_filter.archived',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_LEGACY = 'react.ad_optimization.ads_manager.ad_level_table_filter.legacy',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PRODUCT = 'react.ad_optimization.ads_manager.ad_level_table_filter.product',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AUDIENCES = 'react.ad_optimization.ads_manager.ad_level_table_filter.audiences',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_VIEWS = 'react.ad_optimization.ads_manager.ad_level_table_filter.views',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_DOWN = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bidding_strategy.dynamic_bids_down',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_UP_AND_DOWN = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bidding_strategy.dynamic_bids_up_and_down',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_FIXED = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bidding_strategy.dynamic_bids_fixed',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BID_OPTIMIZATION_ON = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bid_optimization.on',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BID_OPTIMIZATION_OFF = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bid_optimization.off',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_PRODUCT_COLLECTION = 'react.ad_optimization.ads_manager.ad_level_table_filter.campaign_ad_format.product_collection',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_VIDEO = 'react.ad_optimization.ads_manager.ad_level_table_filter.campaign_ad_format.video',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_STORE_SPOTLIGHT = 'react.ad_optimization.ads_manager.ad_level_table_filter.campaign_ad_format.store_spotlight',
  ADS_MANAGER_AD_LEVEL_TABLE_NO_MATCHED_RESULTS_TITLE = 'react.ad_optimization.ads_manager.ad_level_table.no_filter_matched_results.title',
  ADS_MANAGER_AD_LEVEL_TABLE_NO_MATCHED_RESULTS_DESCRIPTION = 'react.ad_optimization.ads_manager.ad_level_table.no_filter_matched_results.description',
  ADS_MANAGER_INVALID_NUMERIC_ATLEAST = 'react.ad_optimization.ads_manager.campaigns_table.invalid.daily_budget.atleast',
  ADS_MANAGER_INVALID_NUMERIC_GREATER_THAN = 'react.ad_optimization.ads_manager.campaigns_table.invalid.daily_budget.greaterThan',
  ADS_MANAGER_CAMPAIGNS_INVALID_DAILY_BUDGET_GREATER_THAN_LIFETIME_BUDGET = 'react.ad_optimization.campaigns_table.invalid.daily_budget.daily_budget_greater_than_lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_INVALID_LIFETIME_BUDGET_LESSER_THAN_DAILY_BUDGET = 'react.ad_optimization.campaigns_table.invalid.daily_budget.lifetime_budget_greater_than_daily_budget_error',
  ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT = 'react.ad_optimization.ads_manager.campaigns_table.invalid.daily_budget.atleast.percent',
  ADS_MANAGER_INVALID_BELOW_TOP_OF_SEARCH_BID_MULTIPLIER = 'react.ad_optimization.campaigns_table.invalid.below_top_of_search_bid_multiplier_error',
  ADS_MANAGER_DISABLED_BELOW_TOP_OF_SEARCH_BID_MULTIPLIER = 'react.ad_optimization.campaigns_table.disabled_below_top_of_search_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_INVALID_LIFETIME_BUDGET = 'react.ad_optimization.campaigns_table.invalid.lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_INVALID_MINBID = 'react.ad_optimization.campaigns_table.invalid.minbid',
  ADS_MANAGER_CAMPAIGNS_MINBID_SHOULD_BE_LESS_THAN_MAX_BID = 'react.ad_optimization.campaigns_table.minbid_greater_than_maxbid',
  ADS_MANAGER_CAMPAIGNS_INVALID_BID_BETWEEN = 'react.ad_optimization.campaigns_table.invalid.between_bid',
  ADS_MANAGER_CAMPAIGNS_INVALID_MAXBID = 'react.ad_optimization.campaigns_table.invalid.maxbid',
  ADS_MANAGER_CAMPAIGNS_MAXBID_LESS_THAN_MIN_LIMIT = 'react.ad_optimization.campaigns_table.maxbid_less_than_min_limit',
  ADS_MANAGER_CAMPAIGNS_MAXBID_SHOULD_BE_GREATER_THAN_MIN_BID = 'react.ad_optimization.campaigns_table.maxbid_less_than_minbid',
  ADS_MANAGER_CAMPAIGNS_DISABLED_AMAZON_BID_OPTIMIZATION = 'react.ad_optimization.campaigns_table.disabled_amazon_bid_optimization',
  ADS_MANAGER_CAMPAIGNS_DUPLICATE_CAMPAIGN_NAME = 'react.ad_optimization.campaigns_table.duplicate_camaign_name',
  ADS_MANAGER_COLUMNS_ARCHIVED_ENTITY_ERROR_TOOLTIP = 'react.ad_optimization.ads_manager.edit.archived_entity_error_text',
  ADS_MANAGER_MANAGE_BIDDING = 'react.ad_optimization.ads_manager.manage_bidding.heading',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.download_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.download_step.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_INSTRUCTIONS = 'react.ad_optimization.ads_manager.manage_bidding.download_step.instructions',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.download_step.button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.validation_error_download.button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON_TEXT = 'react.ad_optimization.ads_manager.manage_bidding.validation_error_download.button_success_text',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_SUCESS = 'react.ad_optimization.ads_manager.manage_bidding.download_step.success',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_ERROR_HEADLINE = 'react.ad_optimization.ads_manager.manage_bidding.download_step.error_headline',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_ERROR_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.download_step.error_description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.more_info_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.more_info_step.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_LINK = 'react.ad_optimization.ads_manager.manage_bidding.more_info_step.link',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_GENERIC_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.generic_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.column_mismatch_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR_WITH_COLUMNS = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.column_mismatch_error_with_columns',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.column_mismatch_error_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_VALIDATION_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.validation_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_FILE_TOO_LARGE_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.file_too_large_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_RETRY_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.retry_button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_ATTACH_FILE = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.attach_file',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_UPLOAD_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.upload_button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_success.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.upload_success.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.upload_success.button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_NO_ERRORS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.no_errors_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.errors_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.errors_description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_BUTTON_TEXT = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.button_text',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_step.errors_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.upload_step.errors_description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_BUTTON_TEXT = 'react.ad_optimization.ads_manager.manage_bidding.upload_step.errors_button_text',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_TRY_AGAIN = 'react.ad_optimization.ads_manager.manage_bidding.try_again',

  ADS_MANAGER_FLYWHEEL_SETTINGS_AUTOMATION_STATUS_UNSET_TOOLTIP = 'react.ad_optimization.ads_manager.edit.flywheel_settings.automation_status.unset_tooltip',
  ADS_MANAGER_FLYWHEEL_SETTINGS_UNSET_TOOLTIP = 'react.ad_optimization.ads_manager.edit.flywheel_settings.vcpm_tooltip',
  ADS_MANAGER_UPGRADE_TO_AI_PLAN_BANNER_MESSAGE = 'react.ad_optimization.ads_manager.upgrade_plan_banner_message',
  ADS_MANAGER_UPGRADE_TO_AI_PLAN_BANNER_MESSAGE_TITLE = 'react.ad_optimization.ads_manager.upgrade_plan_banner_message_title',
  ADS_MANAGER_CAMPAIGN_DETAILS_BREADCRUMB_HEADER = 'react.ad_optimization.ads_manager.campaign_detials.breadcrumb_header',
  ADS_MANAGER_ADGROUP_DETAILS_BREADCRUMB_HEADER = 'react.ad_optimization.ads_manager.adgroup_detials.breadcrumb_header',
  ADS_MANAGER_CAMPAIGN_COST_TYPE_CPC = 'react.ad_optimization.ads_manager.campaign_cost_type.cpc',
  ADS_MANAGER_CAMPAIGN_COST_TYPE_VCPM = 'react.ad_optimization.ads_manager.campaign_cost_type.vcpm',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED = 'react.ad_optimization.ads_manager.review_status.approved',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING = 'react.ad_optimization.ads_manager.review_status.pending',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED = 'react.ad_optimization.ads_manager.review_status.rejected',

  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_INPROGRESS = 'react.ad_optimization.ads_manager.review_status.inprogress',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_COMPLETED = 'react.ad_optimization.ads_manager.review_status.completed',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED = 'react.ad_optimization.ads_manager.review_status.cancelled',

  ADS_MANAGER_ADGROUP_REVIEW_STATUS_TOOLTIP_COMMENT_TYPE = 'react.ad_optimization.ads_manager.adgroup_review_status.tooltip.comment_type',
  ADS_MANAGER_ADGROUP_REVIEW_STATUS_TOOLTIP_COMMENTS = 'react.ad_optimization.ads_manager.adgroup_review_status.tooltip.comments',

  ADS_MANAGER_TABLE_LOOKING_AROUND = 'react.ad_optimization.ads_manager.table.looking_around',
  ADS_MANAGER_TABLE_TAKING_A_LOOK = 'react.ad_optimization.ads_manager.table.taking_a_look',

  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_TOOLTIP_FOOTER = 'react.ad_optimization.ads_manager.review_status.tooltip.footer',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_NO_COMMENTS_TOOLTIP_MESSAGE = 'react.ad_optimization.ads_manager.review_status.no.comments.tooltip.message',

  ADS_MANAGER_TOOLTIP_MESSAGE_AUTOMATION_DISABLED = 'react.ad_optimization.ads_manager.tooltip_messages.bid_automation_status_disabled',
  ADS_MANAGER_TOOLTIP_MESSAGE_MC_DISABLED = 'react.ad_optimization.ads_manager.tooltip_message.mc_disabled',

  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_FEES = 'react.products.sku_catalog_table_column.header_fees',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_INVENTORY = 'react.products.sku_catalog_table_column.header_inventory',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_COGS = 'react.products.sku_catalog_table_column.cogs',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_ESTIMATED_FEES = 'react.products.sku_catalog_table_column.estimated_fees',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_INVENTORY_VALUE = 'react.products.sku_catalog_table_column.inventory_value',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_CURRENT_INVENTORY_QUANTITY = 'react.products.sku_catalog_table_column.current_inventory_quantity',

  USER_PROFILE_TITLE = 'react.pages.user_profile.title',
  USER_PROFILE_FIRST_NAME_LABEL = 'react.pages.user_profile.first_name_label',
  USER_PROFILE_LAST_NAME_LABEL = 'react.pages.user_profile.last_name_label',
  USER_PROFILE_NAME_UPDATED = 'react.pages.user_profile.name_updated',
  USER_PROFILE_NAME_UPDATED_FAILURE = 'react.pages.user_profile.change_name_failure',
  USER_PROFILE_CHANGE_PASSWORD_CAPTION = 'react.pages.user_profile.change_password_caption',
  USER_PROFILE_CHANGE_PASSWORD_LABEL = 'react.pages.user_profile.change_password_label',
  USER_PROFILE_CHANGE_PASSWORD_SUCCESS = 'react.pages.user_profile.change_password_success',
  USER_PROFILE_CHANGE_PASSWORD_FAILURE = 'react.pages.user_profile.change_password_failure',
  USERS_TITLE = 'react.pages.users.title',
  COMPANY_PAGE_TITLE = 'react.pages.company.title',
  COMPANY_PAGE_CARD_TITLE_COMPANY_NAME = 'react.pages.company.card_title.company_name',
  COMPANY_PAGE_COMPANY_NAME_UPDATE_SUCCESS = 'react.pages.company.company_name.success',
  FILTER_NO_RESULTS = 'react.filter.no_results',
  FILTER_BUTTON_TOOLTIP = 'react.components.filter.button_tooltip',
  FILTER_CONDITIONAL_EQUAL = 'react.filter.conditional.equal',
  FILTER_CONDITIONAL_LESS_THAN = 'react.filter.conditional.less_than',
  FILTER_CONDITIONAL_GREATER_THAN = 'react.filter.conditional.greater_than',
  FILTER_CONDITIONAL_BETWEEN = 'react.filter.conditional.between',
  FILTER_CONDITIONAL_AFTER = 'react.filter.conditional.after',
  FILTER_CONDITIONAL_BEFORE = 'react.filter.conditional.before',
  FILTER_CONDITIONAL_EXACT = 'react.filter.conditional.exact',
  FILTER_CONDITIONAL_IN = 'react.filter.conditional.in',
  FILTER_CONDITIONAL_NOT_IN = 'react.filter.conditional.not_in',
  FILTER_CONDITIONAL_IS = 'react.filter.conditional.is',
  FILTER_CONDITIONAL_IS_NOT = 'react.filter.conditional.is_not',
  FILTER_CONDITIONAL_IS_EMPTY = 'react.filter.conditional.is_empty',
  FILTER_CONDITIONAL_IS_EMPTY_VALUE = 'react.filter.conditional.is_empty_value',
  FILTER_ADD_FILTER = 'react.filter.add_filter',
  FILTER_ADD_FILTERS = 'react.filter.add_filters',
  FILTER_APPLY_FILTER = 'react.filter.apply_filter',
  FILTER_NO_APPLY_FILTER = 'react.filter_row.no_filter_applied',
  FILTER_OPEN_BUTTON = 'react.filter.open_button',
  FILTER_NO_FILTERS = 'react.filter.no_filters',
  FILTER_WHERE = 'react.filter.where',
  FILTER_ROW_CANCEL = 'react.filter_row.cancel_label',
  CUSTOMIZATION_ROW_TITLE = 'react.components.customization_row.title',
  CUSTOMIZATION_ROW_CLEAR_ALL_FILTERS = 'react.components.customization_row.clear_all_filters',
  BULK_ACTION_ROW_SELECTOR_SELECTED = 'react.components.bulk_action_selector.selected',
  BULK_ACTION_ROW_SELECTOR_TOTAL_ROWS = 'react.components.bulk_action_selector.total_rows',
  BULK_ACTION_ROW_SELECTOR_MATCH_RESULTS = 'react.components.bulk_action_selector.match_results',
  BULK_ACTION_ROW_SELECTOR_ROW_SINGULAR = 'react.components.bulk_action_selector.row_singular',
  BULK_ACTION_ROW_SELECTOR_ROWS_PLURAL = 'react.components.bulk_action_selector.rows_plural',
  BULK_ACTION_MODAL_HEADER = 'react.components.bulk_action_selector.modal.header',

  PRODUCTS_SKU_CATALOG_TABLE_PRODUCT = 'react.products.sku_catalog_table.product',
  PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY = 'react.products.sku_catalog_table.profitability',
  PRODUCTS_SKU_CATALOG_TABLE_TACOS = 'react.products.sku_catalog_table.tacos',
  PRODUCTS_SKU_CATALOG_TABLE_AVERAGE_ORDER_PRICE = 'react.products.sku_catalog_table.average_order_price',
  PRODUCTS_SKU_CATALOG_TABLE_TOTAL_SALES = 'react.products.sku_catalog_table.total_sales',
  PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_GROSS_PROFIT = 'react.products.sku_catalog_table.estimated_gross_profit',
  PRODUCTS_SKU_CATALOG_TABLE_UNITS_SOLD = 'react.products.sku_catalog_table.units_sold',
  PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_GROSS_MARGIN = 'react.products.sku_catalog_table.estimated_gross_margin',
  PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_PRE_AD_GROSSMARGIN = 'react.products.sku_catalog_table.estimated_pre_ad_grossMargin',
  PRODUCTS_TOAST_MESSAGE_FILE_DOWNLOAD = 'react.pages.products.toast_message.file_download',
  PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED_HEADER = 'react.pages.products.toast_message.export_failed_header',
  PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED = 'react.pages.products.toast_message.export_failed',

  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PRODUCT = 'react.products.sku_overview_table.header_product',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PROFITABILITY = 'react.products.sku_overview_table.header_profitability',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_FEES = 'react.products.sku_overview_table.header_fees',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS = 'react.products.sku_overview_table.header_ads',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_INVENTORY = 'react.products.sku_overview_table.header_inventory',

  PRODUCTS_SKU_OVERVIEW_TABLE_AVERAGE_ORDER_PRICE = 'react.products.sku_overview_table.average_order_price',
  PRODUCTS_SKU_OVERVIEW_TABLE_TACOS = 'react.products.sku_overview_table.tacos',
  PRODUCTS_SKU_OVERVIEW_TABLE_TOTAL_SALES = 'react.products.sku_overview_table.total_sales',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_GROSS_PROFIT = 'react.products.sku_overview_table.estimated_gross_profit',
  PRODUCTS_SKU_OVERVIEW_TABLE_UNITS_SOLD = 'react.products.sku_overview_table.units_sold',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_GROSS_MARGIN = 'react.products.sku_overview_table.estimated_gross_margin',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_PRE_AD_GROSS_MARGIN = 'react.products.sku_overview_table.estimated_pre_ad_grossMargin',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_COGS = 'react.products.sku_overview_table.cogs',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_FEES = 'react.products.sku_overview_table.fees',
  PRODUCTS_SKU_OVERVIEW_TABLE_AD_SALES = 'react.products.sku_overview_table.ad_sales',
  PRODUCTS_SKU_OVERVIEW_TABLE_AD_SPEND = 'react.products.sku_overview_table.ad_spend',
  PRODUCTS_SKU_OVERVIEW_TABLE_ACOS = 'react.products.sku_overview_table.acos',
  PRODUCTS_SKU_OVERVIEW_TABLE_CONVERSION_RATE = 'react.products.sku_overview_table.conversion_rate',
  PRODUCTS_SKU_OVERVIEW_TABLE_CAMPAIGN_COUNT = 'react.products.sku_overview_table.campaign_count',
  PRODUCTS_SKU_OVERVIEW_TABLE_AD_GROUP_COUNT = 'react.products.sku_overview_table.ad_group_count',
  PRODUCTS_SKU_OVERVIEW_TABLE_INVENTORY_VALUE = 'react.products.sku_overview_table.inventory_value',

  SKU_MANAGE_COGS = 'react.sku.manage_cogs',
  COGS_MODAL_HEADER = 'react.cogs_modal.header',
  COGS_MODAL_STATE_UPLOADED_HEADER = 'react.cogs_modal.state.uploaded.header',
  COGS_MODAL_STATE_UPLOADED_DESCRIPTION = 'react.cogs_modal.state.uploaded.description',
  COGS_MODAL_STATE_UPLOADED_BUTTON_TEXT = 'react.cogs_modal.state.uploaded.button_text',
  COGS_MODAL_STEP_1_HEADER = 'react.cogs_modal.step_1.header',
  COGS_MODAL_STEP_2_HEADER = 'react.cogs_modal.step_2.header',
  COGS_MODAL_STEP_3_HEADER = 'react.cogs_modal.step_3.header',
  COGS_MODAL_STEP_1_DESCRIPTION = 'react.cogs_modal.step_1.description',
  COGS_MODAL_STEP_2_DESCRIPTION = 'react.cogs_modal.step_2.description',
  COGS_MODAL_STEP_3_DESCRIPTION = 'react.cogs_modal.step_3.description',
  COGS_MODAL_STEP_1_CSV_INSTRUCTION = 'react.cogs_modal.step_1.csv_instruction',
  COGS_MODAL_STEP_1_DOWNLOAD_CSV = 'react.cogs_modal.step_1.download_csv',
  COGS_MODAL_STEP_1_TEMPLATE_DOWNLOADED = 'react.cogs_modal.step_1.template_downloaded',
  COGS_MODAL_STEP_2_MORE_INFO = 'react.cogs_modal.step_2.more_info',
  COGS_MODAL_STEP_3_ATTACH_CSV_FILE = 'react.cogs_modal.step_3.attach_csv_file',
  COGS_MODAL_STEP_3_UPLOAD = 'react.cogs_modal.step_3.upload',
  COGS_MODAL_STEP_3_ERROR_IN_UPLOAD = 'react.cogs_modal.step_3.error_in_upload',
  COGS_MODAL_STEP_3_RETRY = 'react.cogs_modal.step_3.retry',
  COGS_MODAL_STEP_1_DOWNLOAD_HEADLINE = 'react.cogs_modal.step_1.download.headline',
  COGS_MODAL_STEP_1_DOWNLOAD_DESCRIPTION = 'react.cogs_modal.step_1.download.description',

  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_DETAILS = 'react.products.ad_performance_table.header.details',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE = 'react.products.ad_performance_table.header.performance',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CHANNEL = 'react.products.ad_performance_table.channel',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_MERCHANT = 'react.products.ad_performance_table.merchant',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CAMPAIGN = 'react.products.ad_performance_table.campaign',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CAMPAIGN_TYPE = 'react.products.ad_performance_table.campaign_type',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ADGROUP = 'react.products.ad_performance_table.adgroup',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_STATUS = 'react.products.ad_performance_table.ad_status',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_IMPRESSIONS = 'react.products.ad_performance_table.impressions',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CLICKS = 'react.products.ad_performance_table.clicks',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_SALES = 'react.products.ad_performance_table.ad_sales',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_SPEND = 'react.products.ad_performance_table.ad_spend',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ACOS = 'react.products.ad_performance_table.acos',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ROAS = 'react.products.ad_performance_table.roas',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CTR = 'react.products.ad_performance_table.ctr',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CPC = 'react.products.ad_performance_table.cpc',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_CONVERSIONS = 'react.products.ad_performance_table.ad_conversions',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CONVERSION_RATE = 'react.products.ad_performance_table.conversion_rate',

  USER_DELETE_USER_ACTIONS_CANCEL = 'react.user.delete.actions.cancel',
  USER_GET_DETAILS_CHANGE_PASSWORD_TITLE = 'react.user.get.details.change_password.title',
  USER_GET_DETAILS_CHANGE_PASSWORD_DESCRIPTION = 'react.user.get.details.change_password.description',
  USER_GET_DETAILS_CHANGE_PASSWORD_CONFIRMATION_BUTTON = 'react.user.get.details.change_password.confirmation_button',
  USER_GET_DETAILS_CHANGE_PASSWORD_CANCEL_BUTTON = 'react.user.get.details.change_password.cancel_button',
  USER_GET_DETAILS_CHANGE_PASSWORD_SUCCESS = 'react.user.get.details.change_password.success_message',
  SEARCH_BAR_PLACEHOLDER = 'react.components.search_bar.placeholder',
  PAGINATED_TABLE_MAX_ROWS = 'react.components.paginated_table.max_rows',
  PAGINATED_TABLE_OF_TOTAL_PAGES = 'react.components.paginated_table.of_total_pages',
  PAGINATED_TABLE_NO_DATA_HEADER = 'react.components.paginated_table.no_data.header',
  PAGINATED_TABLE_NO_DATA_DESCRIPTION = 'react.components.paginated_table.no_data.description',
  DATE_PICKER_APPLY = 'react.components.date_picker.apply',
  DATE_PICKER_CANCEL = 'react.components.date_picker.cancel',
  CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANT = 'react.components.context_setter.sales_channel_picker_merchant',
  CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANTS = 'react.components.context_setter.sales_channel_picker_merchants',
  CONTEXT_SETTER_SALES_CHANNEL_PICKER_CHANNEL = 'react.components.context_setter.sales_channel_picker_channel',
  CONTEXT_SETTER_SALES_CHANNEL_AND_PICKER_MERCHANT = 'react.components.context_setter.sales_channel_and_merchant_picker',
  CONTEXT_SETTER_SALES_CURRENCY_PICKER_CHANNEL = 'react.components.context_setter.currency_picker',
  CONTEXT_SETTER_SALES_REPORTING_CURRENCY_PICKER_CHANNEL = 'react.components.context_setter.reporting_currency_picker',
  CONTEXT_SETTER_TIMEFRAME_LABEL = 'react.components.context_setter.timeframe_label',

  HERO_METRICS_FORMULA_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_sales',
  HERO_METRICS_FORMULA_AD_SALES_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_sales_description',
  HERO_METRICS_FORMULA_AD_SPEND = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_spend',
  HERO_METRICS_FORMULA_AD_SPEND_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_spend_description',
  HERO_METRICS_FORMULA_CONVERSION_RATE = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.conversion_rate',
  HERO_METRICS_FORMULA_CONVERSION_RATE_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.conversion_rate_description',
  HERO_METRICS_FORMULA_ROAS = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.roas',
  HERO_METRICS_FORMULA_ROAS_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.roas_description',
  HERO_METRICS_FORMULA_UNITS_SOLD_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.units_sold_description',
  HERO_METRICS_FORMULA_AD_CONVERSIONS_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_conversions_description',
  HERO_METRICS_FORMULA_DIRECT_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.direct_ad_sales',
  HERO_METRICS_FORMULA_RELATED_CLICK_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.related_click_ad_sales',
  HERO_METRICS_FORMULA_BRAND_CLICK_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.brand_click_ad_sales',
  HERO_METRICS_FORMULA_AD_CONVERSIONS = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_conversions',
  HERO_METRICS_FORMULA_AD_UNITS_SOLD = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_units_sold',
  HERO_METRICS_FORMULA_CLICKS = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.clicks',
  HERO_METRICS_PREVIOUS_METRIC_LOAD_ERROR = 'react.ad_optimization.ads_manager.hero_metrics.previous_metric.load_error',
  HERO_METRICS_LOAD_ERROR_BEFORE_RELOAD = 'react.ad_optimization.ads_manager.hero_metrics.load_error_before_reload',
  HERO_METRICS_LOAD_ERROR_AFTER_RELOAD = 'react.ad_optimization.ads_manager.hero_metrics.load_error_after_reload',
  HERO_METRICS_CURRENT_PERIOD_UNAVAILABLE_ERROR_HEADER = 'react.ad_optimization.ads_manager.hero_metrics.current_period_unavailable.error_header',
  HERO_METRICS_CURRENT_PERIOD_UNAVAILABLE_ERROR_SUBTEXT = 'react.ad_optimization.ads_manager.hero_metrics.current_period_unavailable.error_sub_text',
  HERO_METRICS_CURRENT_FILTER_DATA_UNAVAILABLE_ERROR_SUBTEXT = 'react.ad_optimization.ads_manager.hero_metrics.current_filter_data_unavailable.error_sub_text',
  HERO_METRICS_RETRY_BUTTON_TEXT = 'react.ad_optimization.ads_manager.hero_metrics.retry',
  HERO_METRICS_CONTACT_SUPPORT_BUTTON_TEXT = 'react.ad_optimization.ads_manager.hero_metrics.contact_support',
  LEARN_MORE = 'terms.learn_more',
  NAME = 'terms.name',
  CHANGE = 'terms.change',
  CHANGES = 'terms.changes',
  BACK = 'terms.back',
  ACCOUNT_OWNER = 'terms.role.account_owner',
  ADMIN = 'terms.role.admin',
  ANALYST = 'terms.role.analyst',
  APPLY = 'terms.apply',
  CANCEL = 'terms.cancel',
  DISABLE = 'terms.disable',
  SET_MACS = 'terms.set_macs',
  DO_THIS_LATER = 'terms.do_this_later',
  GOT_IT = 'terms.got_it',
  SUCCESS = 'terms.success',
  ERROR = 'terms.error',
  UPDATE = 'terms.update',
  REMOVE = 'terms.remove',
  SKIP = 'react.button.skip',
  CLOSE = 'react.button.close',
  SEARCH = 'react.button.search',
  READY = 'terms.ready',
  RETRY = 'terms.retry',
  FINISH = 'terms.finish',
  TURN_OFF = 'terms.turn_off',
  CONNECT = 'terms.connect',
  DESIGN_SYSTEM_COMPONENT_FILTER_ROW_REMOVE_ALL_BUTTON_LABEL = 'react.design_system.component.filter_row.remove_all_button_label',
  SLIDER_TABLE_VIEW = 'react.table.dual_mode.slider.view',
  SLIDER_TABLE_EDIT = 'react.table.dual_mode.slider.edit',
  SELECT_NOT_SET = 'react.components.select.not_set',
  SEARCH_INPUT_PLACEHOLDER = 'react.search_input.placeholder',
  SEARCH_INPUT_PLACEHOLDER_CAMPAIGNS = 'react.search_input.placeholder.campaigns',
  SEARCH_INPUT_PLACEHOLDER_ADGROUPS = 'react.search_input.placeholder.adgroups',
  SEARCH_INPUT_PLACEHOLDER_PRODUCT = 'react.search_input.placeholder.product_ads',
  SEARCH_INPUT_PLACEHOLDER_KEYWORD_TARGETS = 'react.search_input.placeholder.keyword_targets',
  SEARCH_INPUT_PLACEHOLDER_TARGETS = 'react.search_input.placeholder.targets',
  ASYNC_REQUEST_SOMETHING_WRONG_MESSAGE = 'react.async_request.errors.something_wrong_message',
  ASYNC_REQUEST_REFRESH_PAGE_MESSAGE = 'react.async_request.errors.refresh_page_message',
  AO_EDIT_TABLE_SAVE_CHANGES_HEADER = 'react.ad_optimization.edit.table.save_changes_header',
  AO_EDIT_TABLE_SAVE_CHANGES_BODY = 'react.ad_optimization.edit.table.save_changes_body',
  AO_EDIT_TABLE_SAVE_CHANGES_PRIMARY = 'react.ad_optimization.edit.table.save_changes_primary',
  AO_EDIT_TABLE_SAVE_CHANGES_SECONDARY = 'react.ad_optimization.edit.table.save_changes_secondary',
  DATA_CONNECTIONS_HEADER = 'react.data_connections.header',
  DATA_CONNECTIONS_ADS_DATA = 'react.data_connections.ads_data',
  DATA_CONNECTIONS_PRODUCTS_DATA = 'react.data_connections.products_data',
  DATA_CONNECTIONS_CONNECT_BUTTON_TEXT = 'react.data_connections.connect_button_text',
  DATA_CONNECTIONS_TOOLTIP_INITIAL_TITLE = 'react.data_connections.tooltip.initial_title',
  DATA_CONNECTIONS_TOOLTIP_INITIAL_DESCRIPTION = 'react.data_connections.tooltip.initial_description',
  DATA_CONNECTIONS_TOOLTIP_SYNCING_TITLE = 'react.data_connections.tooltip.syncing_title',
  DATA_CONNECTIONS_TOOLTIP_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.syncing_description',
  DATA_CONNECTIONS_TOOLTIP_ADS_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.ads_syncing_description',
  DATA_CONNECTIONS_TOOLTIP_PRODUCTS_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.products_syncing_description',
  DATA_CONNECTIONS_TOOLTIP_ADS_CONNECTED_TITLE = 'react.data_connections.tooltip.ads_connected_title',
  DATA_CONNECTIONS_TOOLTIP_ADS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.ads_connected_description',
  DATA_CONNECTIONS_TOOLTIP_DATA_CONNECTIONS_CONNECTED_TITLE = 'react.data_connections.tooltip.data_connections_connected_title',
  DATA_CONNECTIONS_TOOLTIP_DATA_CONNECTIONS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.data_connections_connected_description',
  DATA_CONNECTIONS_TOOLTIP_SALES_CHANNEL_LINK = 'react.data_connections.tooltip.sales_channel_link',
  DATA_CONNECTIONS_TOOLTIP_PRODUCTS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.products_connected_description',
  DATA_CONNECTIONS_TOOLTIP_PRODUCTS_ONLY_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.products_only_syncing_description',
  DATA_CONNECTIONS_TOOLTIP_ADS_SYNCING_PRODUCTS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.ads_syncing_product_connect_description',

  CURRENT_GMS_HEADER = 'react.current_gms.header',
  CURRENT_GMS_PILL_SYNCING_DATA = 'react.current_gms.pill.syncing_data',
  CURRENT_GMS_BUTTON_ADD_PRODUCTS_DATA = 'react.current_gms.button.add_products_data',
  CURRENT_GMS_TEXT_GROWTH = 'react.current_gms.text.growth',
  CURRENT_GMS_TOOLTIP_SYNCING_TITLE = 'react.current_gms.tooltip.syncing_title',
  CURRENT_GMS_TOOLTIP_SYNCING_DESCRIPTION = 'react.current_gms.tooltip.syncing_description',
  CURRENT_GMS_TOOLTIP_INITIAL_TITLE = 'react.current_gms.tooltip.initial_title',
  CURRENT_GMS_TOOLTIP_INITIAL_DESCRIPTION = 'react.current_gms.tooltip.initial_description',
  CURRENT_GMS_TOOLTIP_CONNECTED_TITLE = 'react.current_gms.tooltip.connected_title',
  CURRENT_GMS_TOOLTIP_CONNECTED_DESCRIPTION = 'react.current_gms.tooltip.connected_description',
  CURRENT_GMS_TOOLTIP_CONNECTED_LAST_UPDATED = 'react.current_gms.tooltip.connected_last_updated',

  BANNER_CONNECT_SALES_CHANNELS_LINK = 'react.banner.connect_sales_channels.link',
  BANNER_TRIAL_FIRST_DAY_MESSAGE = 'react.banner.trial.first_day.message',
  BANNER_TRIAL_HAPPENING_PASSIVE_MESSAGE = 'react.banner.trial.happening.passive.message',
  BANNER_TRIAL_HAPPENING_ALERT_MESSAGE = 'react.banner.trial.happening.alert.message',
  BANNER_TRIAL_HAPPENING_ALERT_LINK = 'react.banner.trial.happening.alert.link',
  BANNER_TRIAL_LAST_DAY_MESSAGE = 'react.banner.trial.last_day.message',
  BANNER_TRIAL_LAST_DAY_LINK = 'react.banner.trial.last_day.link',
  BANNER_AUTOMATION_IS_DISABLED = 'react.banner.ai_plan.automation_disabled',
  BANNER_AUTOMATION_IS_DISABLED_FOR_FEW_MERCHANTS = 'react.banner.ai_plan.automation_disabled_perticular_merchants',
  BANNER_AUTOMATION_IS_DISABLED_FOR_ALL_MERCHANTS = 'react.banner.ai_plan.automation_disabled_for_all_merchants',
  BANNER_AUTOMATION_ENABLE_AI_PLAN = 'react.banner.ai_plan.enable_ai_plan',
  BANNER_TRIAL_DELINQUENT_LINK = 'react.banner.trial.delinquent.link',
  BANNER_TRIAL_DELINQUENT_MESSAGE = 'react.banner.trial.happening.delinquent.message',
  BANNER_TRIAL_ACCOUNT_LOCKOUT_MESSAGE = 'react.banner.trial.happening.account_lockout.message',
  BANNER_TRIAL_ACCOUNT_LOCKOUT_LINK = 'react.banner.trial.account_lockout.link',

  ADD_PAYMENT_MODAL_HEADER = 'react.add_payment_modal.header',
  ADD_PAYMENT_MODAL_DESCRIPTION = 'react.add_payment_modal.description',
  ADD_PAYMENT_MODAL_ADD_BUTTON_TEXT = 'react.add_payment_modal.add_button_text',
  ADD_PAYMENT_MODAL_CANCEL_BUTTON_TEXT = 'react.add_payment_modal.cancel_button_text',
  ADD_PAYMENT_TOAST_SUCCESS_HEADLINE = 'react.add_payment.toast.success_headline',
  ADD_PAYMENT_TOAST_SUCCESS_DESCRIPTION = 'react.add_payment.toast.success_description',
  ADD_PAYMENT_MODAL_SUCCESS_HEADER = 'react.add_payment.modal.success_header',
  ADD_PAYMENT_MODAL_SUCCESS_BODY = 'react.add_payment.modal.success_body',
  ADD_PAYMENT_MODAL_SUCCESS_BUTTON_TEXT = 'react.add_payment.modal.success_button_text',

  OUTSTANDING_BALANCE_MODAL_HEADER_PAY_BALANCE = 'react.outstanding_balance_modal.header.pay_balance',
  OUTSTANDING_BALANCE_MODAL_HEADER_SUBTITLE_KEEP_ACCESS = 'react.outstanding_balance_modal.header.subtitle.keep_access',
  OUTSTANDING_BALANCE_MODAL_HEADER_BALANCE_PAID = 'react.outstanding_balance_modal.header.balance_paid',
  OUTSTANDING_BALANCE_MODAL_HEADER_SUBTITLE_THANK_YOU = 'react.outstanding_balance_modal.header.subtitle.thank_you',

  OUTSTANDING_BALANCE_MODAL_TITLE_BALANCE = 'react.outstanding_balance_modal.title.balance',
  OUTSTANDING_BALANCE_MODAL_TITLE_PAYMENT_METHOD = 'react.outstanding_balance_modal.title.payment_method',
  OUTSTANDING_BALANCE_MODAL_PAYMENT_METHOD_DESCRIPTION = 'react.outstanding_balance_modal.payment_method_description',
  OUTSTANDING_BALANCE_MODAL_BUTTON_ADD_NEW_PAYMENT = 'react.outstanding_balance_modal.button.add_new_payment',
  OUTSTANDING_BALANCE_MODAL_DO_THIS_LATER_LINK = 'react.outstanding_balance_modal.do_this_later.link',
  OUTSTANDING_BALANCE_MODAL_CONTACT_SUPPORT_LINK = 'react.outstanding_balance_modal.contact_support.link',
  OUTSTANDING_BALANCE_MODAL_TITLE_ADD_PAYMENT = 'react.outstanding_balance_modal.title.add_payment',
  OUTSTANDING_BALANCE_MODAL_RECEIPT_PAYMENT_DESCRIPTION = 'react.outstanding_balance_modal.receipt_payment_description',
  OUTSTANDING_BALANCE_MODAL_SUBTITLE_ADD_NEW_CREDIT_CARD = 'react.outstanding_balance_modal.subtitle.add_new_credit_card',
  OUTSTANDING_BALANCE_MODAL_BUTTON_PAY_BALANCE = 'react.outstanding_balance_modal.button.pay_balance',

  VALIDATION_GREATER_THAN = 'react.validation.greater_than',
  VALIDATION_LESS_THAN = 'react.validation.less_than',
  VALIDATION_AT_LEAST = 'react.validation.at_least',

  SKU_CATALOG_SEARCH_FOR_CATALOG = 'react.sku_catalog.search.search_for_catalogs',
  SKU_OVERVIEW_DETAILS_SEARCH_PLACEHOLDER = 'react.overview.search.placeholder',
  SKU_AD_PERFORMANCE_DETAILS_SEARCH_PLACEHOLDER = 'react.sku_ad_performance.search.placeholder',
  SKU_CATALOG_TABLE_COLUMN_AD_SALES = 'react.sku_catalog_table_column.ad_sales',
  SKU_CATALOG_TABLE_COLUMN_AD_SPEND = 'react.sku_catalog_table_column.ad_spend',
  SKU_CATALOG_TABLE_COLUMN_ACOS = 'react.sku_catalog_table_column.acos',
  SKU_CATALOG_TABLE_COLUMN_CONVERSION_RATE = 'react.sku_catalog_table_column.conversion_rate',
  SKU_CATALOG_TABLE_COLUMN_CAMPAIGN_COUNT = 'react.sku_catalog_table_column.campaign_count',
  SKU_CATALOG_TABLE_COLUMN_AD_GROUP_COUNT = 'react.sku_catalog_table_column.ad_group_count',
  SKU_CATALOG_TABLE_COLUMN_ADS = 'react.sku_catalog_table_column.ads',
  SKU_CATALOG_TABLE_COLUMN_SKU = 'react.sku_catalog.sku_catalog_table_column.sku',
  SKU_CATALOG_TABLE_COLUMN_SKU_TOTAL_TEXT = 'react.sku_catalog.sku_catalog_table_column.sku_total_text',
  SKU_CATALOG_TABLE_COLUMN_NAME = 'react.sku_catalog.sku_catalog_table_column.name',
  SKU_CATALOG_TABLE_COLUMN_PRODUCT_ID = 'react.sku_catalog.sku_catalog_table_column.product_id',
  REACT_COMPONENTS_PAGINATED_TABLE_BUTTON_EXPORT = 'react.components.paginated_table.button.export',

  SKU_DETAILS_SIDEBAR_FAILED_MESSAGE_DESCRIPTION = 'react.sku_details.sidebar.error.description',

  SKU_OVERVIEW_TABLE_COLUMN_AD_SALES = 'react.sku_overview_table_column.ad_sales',
  SKU_OVERVIEW_TABLE_COLUMN_AD_SPEND = 'react.sku_overview_table_column.ad_spend',
  SKU_OVERVIEW_TABLE_COLUMN_ACOS = 'react.sku_overview_table_column.acos',
  SKU_OVERVIEW_TABLE_COLUMN_CONVERSION_RATE = 'react.sku_overview_table_column.conversion_rate',
  SKU_OVERVIEW_TABLE_COLUMN_CAMPAIGN_COUNT = 'react.sku_overview_table_column.campaign_count',
  SKU_OVERVIEW_TABLE_COLUMN_AD_GROUP_COUNT = 'react.sku_overview_table_column.ad_group_count',
  REACT_SKU_DETAILS_OVERVIEW_TAB_LEVEL_NAME = 'react.sku.sku_details.sku_overview.tab_level_name',
  REACT_SKU_DETAILS_INVENTORY_TAB_LEVEL_NAME = 'react.sku.sku_details.sku_inventory.tab_level_name',
  REACT_SKU_DETAILS_AD_PERFORMANCE_TAB_LEVEL_NAME = 'react.sku.sku_details.sku_ad_performance.tab_level_name',

  TABLE_LOADING_TEXT = 'react.component.table.loading_text',
  TABLE_NO_DATA_TITLE = 'react.component.table.no_data.title',
  TABLE_NO_DATA_DESCIPTION = 'react.component.table.no_data.description',
  TABLE_API_ERROR_TITLE = 'react.component.table.api_error.title',
  TABLE_API_ERROR_DESCRIPTION = 'react.component.table.api_error.description',
  TABLE_API_ERROR_TRY_AGAIN = 'react.component.table.api_error.try_again',
  TABLE_NO_DATA_WITH_FILTER_TITLE = 'react.component.table.no_data_with_filter.title',
  TABLE_NO_DATA_WITH_FILTER_DESCRIPTION = 'react.component.table.no_data_with_filter.description',
  TABLE_NO_DATA_WITH_FILTER_DESCRIPTION_TRY_NEW = 'react.component.table.no_data_with_filter.description.try_new',

  SKU_OVERVIEW_TABLE_COLUMN_TOTAL_SALES = 'react.sku_overview.table_column.total_sales',
  SKU_OVERVIEW_TABLE_COLUMN_ESTIMATED_GROSS_PROFIT = 'react.sku_overview.table_column.est_gross_profit',
  SKU_OVERVIEW_TABLE_COLUMN_UNITS_SOLD = 'react.sku_overview.table_column.units_sold',
  SKU_OVERVIEW_TABLE_COLUMN_ESTIMATED_GROSS_MARGIN = 'react.sku_overview.table_column.est_gross_margin',
  SKU_OVERVIEW_TABLE_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN = 'react.sku_overview.table_column.est_pre_ad_gross_margin',
  SKU_OVERVIEW_TABLE_COLUMN_COGS = 'react.sku_overview.table_column.cogs',
  SKU_OVERVIEW_TABLE_COLUMN_FEES = 'react.sku_overview.table_column.fees',
  SKU_OVERVIEW_TABLE_COLUMN_INVENTORY_VALUE = 'react.sku_overview.table_column.inventory_value',
  SKU_OVERVIEW_TABLE_COLUMN_CURRENT_INVENTORY_QUANTITY = 'react.sku_overview.table_column.current_inventory_quantity',
  SKU_OVERVIEW_TABLE_COLUMN_PRODUCT_GROUP_HEADER = 'react.sku_overview.table_column.product_group_header',
  SKU_OVERVIEW_TABLE_COLUMN_TACOS = 'react.sku_overview.table_column.tacos',
  SKU_OVERVIEW_TABLE_COLUMN_AVERAGE_ORDER_PRICE = 'react.sku_overview.table_column.average_order_price',
  SKU_OVERVIEW_TABLE_COLUMN_MERCHANT = 'react.sku_overview.table_column.merchant',
  SKU_OVERVIEW_TABLE_COLUMN_CHANNEL = 'react.sku_overview.table_column.channel',

  CHANNELS_MERCHANTS = 'react.sku_catalog.channels_merchants',
  CHANNELS_MERCHANT_ID = 'react.sku_catalog.channels_merchants_id',
  CHANNELS_MERCHANT_ASIN = 'react.sku_catalog.channels_merchants_asin',
  CHANNELS_MERCHANT_CATEGORIES = 'react.sku_catalog.channels_merchants_categories',
  SKU_DETAILS_SKU = 'react.sku_catalog.product_sku',
  UNKNOWN_CHANNEL = 'react.sku_catalog.unknown_channel',

  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_ARCHIVED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.archived',
  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_DISABLED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.disabled',
  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_ENABLED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.enabled',
  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_PAUSED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.paused',

  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_HEADER_PILL_LABEL = 'react.product.sku.no_connection_page.connect_sales_channel_header_pill_label',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_HEADER = 'react.product.sku.no_connection_page.connect_sales_channel_header',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_SUBHEADER = 'react.product.sku.no_connection_page.connect_sales_channel_subheader',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_LABEL = 'react.product.sku.no_connection_page.connect_sales_channel_label',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_SYNC_TEXT = 'react.product.sku.no_connection_page.connect_sales_channel_sync_text',
  PRODUCT_SKU_NO_CONNECTION_VIEW_CATALOG_HEADER = 'react.product.sku.no_connection_page.view_catalog_header',
  PRODUCT_SKU_NO_CONNECTION_VIEW_CATALOG_SUBTEXT = 'react.product.sku.no_connection_page.view_catalog_subtext',
  PRODUCT_SKU_NO_CONNECTION_ANALYZE_PERFORMANCE_HEADER = 'react.product.sku.no_connection_page.analyze_performance_header',
  PRODUCT_SKU_NO_CONNECTION_ANALYZE_PERFORMANCE_SUBTEXT = 'react.product.sku.no_connection_page.analyze_performance_subtext',
  PRODUCT_SKU_NO_CONNECTION_TRACK_PROFITABILITY_HEADER = 'react.product.sku.no_connection_page.track_profitability_header',
  PRODUCT_SKU_NO_CONNECTION_TRACK_PROFITABILITY_SUBTEXT = 'react.product.sku.no_connection_page.track_profitability_subtext',

  BILLING_DETAIL_AI_POWERED_PLAN_TITLE = 'react.billing.plan_details.connect_plan.ai_powered_plan_title',
  BILLING_DETAIL_AI_POWERED_PLAN_SUB_TITLE = 'react.billing.plan_details.connect_plan.ai_powered_plan_sub_title',
  BILLING_DETAIL_BASIC_PLAN_TITLE = 'react.billing.plan_details.connect_plan.basic_plan_title',
  BILLING_DETAIL_BASIC_PLAN_SUB_TITLE = 'react.billing.plan_details.connect_plan.basic_plan_sub_title',
  BILLING_DETAIL_PLAN_UPGRADE = 'react.billing.plan_details.connect_plan.plan_upgrade',
  BILLING_DETAIL_PLAN_DOWNGRADE = 'react.billing.plan_details.connect_plan.plan_downgrade',
  BILLING_DETAIL_PLAN_FREE_KEEP_GROWING = 'react.billing.plan_details.connect_plan.free_keep_growing',

  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_HEADER = 'react.upgrade.self_managed.ai_powered_automation.header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_GO_BACK = 'react.upgrade.self_managed.ai_powered_automation.go_back',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_NEXT_PREVIEW = 'react.upgrade.self_managed.ai_powered_automation.next_preview',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_LEARN_MORE = 'react.upgrade.self_managed.ai_powered_automation.learn_more',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ALL = 'react.upgrade.self_managed.ai_powered_automation.upgrade_all',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ALL_CONFIRM = 'react.upgrade.self_managed.ai_powered_automation.upgrade_all_confirm',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ADVERTISED_PRODUCT = 'react.upgrade.self_managed.ai_powered_automation.upgrade_advertised_products',

  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_OPPORTUNITY_CALL_OUT_TITLE = 'react.upgrade.self_managed.ai_powered_automation.opportunity_call_out_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_OPPORTUNITY_CALL_OUT_LINK = 'react.upgrade.self_managed.ai_powered_automation.opportunity_call_out_link',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_call_out_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_call_out_subtitle',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_LINK = 'react.upgrade.self_managed.ai_powered_automation.preview_call_out_link',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_ADVERTISED_PRODUCTS_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_advertised_products_sub_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CAMPAIGNS_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_campaigns_sub_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_TARGETS_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_targets_sub_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_DAILY_AD_BUDGET_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_daily_ad_budget_sub_title',

  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_CONTENT = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_content',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_ACTION_YES = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_action_yes',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_ACTION_NO = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_action_no',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PROCESSING_SUMMARY_DESCRIPTION = 'react.upgrade.self_managed.ai_powered_automation.processing_summary_description',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ADVERTISED_TAG = 'react.upgrade.self_managed.ai_powered_automation.upgrade_advertised_tag',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PRODUCTS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_products',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_PAUSED = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_paused',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_TITLE = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_NEW = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_new',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_AUTOMATE = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_automate',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_TITLE = 'react.upgrade.self_managed.ai_powered_automation.benefits_ul_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_DAILY = 'react.upgrade.self_managed.ai_powered_automation.benefits_ul_daily',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_BIDDING = 'react.upgrade.self_managed.ai_powered_automation.benefits_ul_bidding',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AD_STRUCTURE = 'react.upgrade.self_managed.ai_powered_automation.ad_structure',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_TAG = 'react.upgrade.self_managed.ai_powered_automation.preview_tag',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_HOW_AI_WORKS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_how_ai_works',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AFTER_AI = 'react.upgrade.self_managed.ai_powered_automation.upgrade_after_ai',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_CAMPAIGNS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_campaigns',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_RECS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_recs',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_BID = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_bid',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_SETTINGS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_settings',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_CAMPAIGNS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_campaigns',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_CAMPAIGNS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_campaigns',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_RECS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_recs',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_RECS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_recs',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_BID = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_bid',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_BID = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_bid',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_SETTINGS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_settings',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_SETTINGS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_settings',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_RESULT_SUB_TITLE_ONE = 'react.upgrade.self_managed.ai_powered_automation.success_result_sub_title_one',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_RESULT_HEADER = 'react.upgrade.self_managed.ai_powered_automation.success_result_header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_COMPLETE = 'react.upgrade.self_managed.ai_powered_automation.success_upgrade_complete',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_WAITING_SUBTITLE = 'react.upgrade.self_managed.ai_powered_automation.success_waiting_subtitle',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_LEARN_PRODUCT_SETTINGS_HEADER = 'react.upgrade.self_managed.ai_powered_automation.success_learn_product_settings_header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_LEARN_PRODUCT_SETTINGS_TEXT = 'react.upgrade.self_managed.ai_powered_automation.success_learn_product_settings_text',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_LEARN_PRODUCT_SETTINGS_LINK = 'react.upgrade.self_managed.ai_powered_automation.success_learn_product_settings_link',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_PRODUCT_PERFORMANCE_HEADER = 'react.upgrade.self_managed.ai_powered_automation.success_product_performance_header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_PRODUCT_PERFORMANCE_TEXT = 'react.upgrade.self_managed.ai_powered_automation.success_product_performance_text',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_PRODUCT_PERFORMANCE_LINK = 'react.upgrade.self_managed.ai_powered_automation.success_product_performance_link',

  NAV_BAR_LABEL_AI_POWERED = 'react.components.navbar.ai_powered.header',
  NAV_BAR_LABEL_AI_SETTINGS = 'react.components.navbar.ai_settings.header',
  AI_SETTINGS_MANAGE_CARD_BUTTON = 'react.components.ai_settings.manage_card.button',
  AI_SETTINGS_MANAGE_SUB_TITLE_ONE = 'react.components.ai_settings.manage.header_sub_title_one',
  AI_SETTINGS_MANAGE_SUB_TITLE_TWO = 'react.components.ai_settings.manage.header_sub_title_two',

  AI_PRODUCT_SETTINGS_SEARCH_SEARCH_FOR_CATALOGS = 'react.ai_product_settings.search.search_for_catalogs',
  AI_PRODUCT_SETTINGS_AD_SALES_HEADER = 'react.ai_product_settings.ad_sales.header',
  AI_PRODUCT_SETTINGS_AI_SETTINGS_HEADER = 'react.ai_product_settings.ai_settings.header',
  AI_PRODUCT_SETTINGS_TAGS_HEADER = 'react.ai_product_settings.tags.header',
  AI_PRODUCT_SETTINGS_PRODUCT = 'react.ai_product_settings.product',
  AI_PRODUCT_SETTINGS_PRODUCT_SKU = 'react.ai_product_settings.product_sku',
  AI_PRODUCT_SETTINGS_PRODUCT_ID = 'react.ai_product_settings.product_id',
  AI_PRODUCT_SETTINGS_AD_SALES = 'react.ai_product_settings.ad_sales',
  AI_PRODUCT_SETTINGS_AD_SPEND = 'react.ai_product_settings.ad_spend',
  AI_PRODUCT_SETTINGS_ACOS = 'react.ai_product_settings.acos',
  AI_PRODUCT_SETTINGS_DAILY_BUDGET = 'react.ai_product_settings.daily_budget',
  AI_PRODUCT_SETTINGS_ACOS_TARGET = 'react.ai_product_settings.acos_target',
  AI_PRODUCT_SETTINGS_TAGS_MANAGEMENT = 'react.ai_product_settings.tags_management',
  AI_PRODUCT_SETTINGS_BRAND = 'react.ai_product_settings.brand',
  AI_PRODUCT_SETTINGS_COMPETITOR = 'react.ai_product_settings.competitor',
  AI_PRODUCT_SETTINGS_MANAGE = 'react.ai_product_settings.manage',
  AI_PRODUCT_SETTINGS_DAILY_BUDGET_ERROR_MESSAGE = 'react.ai_product_settings.daily_budget.error_message',
  AI_PRODUCT_SETTINGS_ACOS_TARGET_ERROR_MESSAGE = 'react.ai_product_settings.acos_target.error_message',
  AI_PRODUCT_SETTINGS_DAILY_BUDGET_ERROR_MESSAGE_TOOLTIP = 'react.ai_product_settings.daily_budget.error_message.tooltip',
  AI_PRODUCT_SETTINGS_ACOS_TARGET_ERROR_MESSAGE_TOOLTIP = 'react.ai_product_settings.acos_target.error_message.tooltip',
  AI_PRODUCT_SETTINGS_EDIT_DAILY_BUDGET = 'react.ai_product_settings.edit.daily_budget',
  AI_PRODUCT_SETTINGS_EDIT_ACOS_TARGET = 'react.ai_product_settings.edit.acos_target',
  AI_PRODUCT_SETTINGS_BUTTON_SAVE_CHANGE = 'react.ai_product_settings.button.save_change',
  AI_PRODUCT_SETTINGS_BUTTON_CANCEL = 'react.ai_product_settings.button.cancel',
  AI_PRODUCT_SETTINGS_TOAST_MESSAGE_SAVE_EDITED_CHANGES_SUCCESS = 'react.pages.ai_product_settings.toast_message.save_changes_success',
  AI_PRODUCT_SETTINGS_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE = 'react.pages.ai_product_settings.toast_message.save_changes_failure',
  AI_PRODUCT_SETTINGS_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE_HEADLINE = 'react.pages.ai_product_settings.toast_message.save_changes_failure_headline',
  AI_PRODUCT_SETTINGS_INPUT_PENDING_ACTION_TOOLTIP = 'react.pages.ai_product_settings.input_pending_action.tooltip',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_CLOSE = 'react.ai_product_settings.manage_competitor_tag_modal.button_close',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TITLE = 'react.ai_product_settings.manage_competitor_tag_modal.brand_competitor_tags',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_CANCEL = 'react.ai_product_settings.manage_competitor_tag_modal.button_cancel',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_SUBMIT = 'react.ai_product_settings.manage_competitor_tag_modal.button_submit',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_ADD_MORE = 'react.ai_product_settings.manage_competitor_tag_modal.button_add_more',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_PRODUCT_COUNT = 'react.ai_product_settings.manage_competitor_tag_modal.product_count',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BRAND_TAGS_HEADER_TITLE = 'react.ai_product_settings_manage_competitor_tag_modal.brand_tags_header_title',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BRAND_TAGS_HEADER_SUBTITLE = 'react.ai_product_settings_manage_competitor_tag_modal.brand_tags_header_subtitle',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_COMPETITOR_TAGS_HEADER_TITLE = 'react.ai_product_settings_manage_competitor_tag_modal.competitor_tags_header_title',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_COMPETITOR_TAGS_HEADER_SUBTITLE = 'react.ai_product_settings_manage_competitor_tag_modal.competitor_tags_header_subtitle',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BULK_EDIT_REMOVE = 'react.ai_product_settings_manage_competitor_tag_modal.bulk_edit_remove',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_REMOVE_TAG = 'react.ai_product_settings_manage_competitor_tag_modal.remove_tag',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_MOVE_TO_COMPETITOR = 'react.ai_product_settings_manage_competitor_tag_modal.move_to_competitor',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_MOVE_TO_BRAND = 'react.ai_product_settings_manage_competitor_tag_modal.move_to_brand',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_INFO_HEADER = 'react.ai_product_settings_manage_competitor_tag_modal.info_header',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BEST_PRACTICES_FOOTER = 'react.ai_product_settings_manage_competitor_tag_modal.best_practices_footer',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TOAST_SUCCESS_HEADLINE = 'react.ai_product_settings_manage_competitor_tag_modal_toast_success_headline',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TOAST_SUCCESS_DESCRIPTION = 'react.ai_product_settings_manage_competitor_tag_modal_toast_success_description',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TYPEAHEAD_PLACEHOLDER = 'react.ai_product_settings_manage_competitor_tag_modal_typeahead_placeholder',
  AI_PRODUCT_SETTINGS_ON_BOARDING_ERROR = 'react.ai_product_settings.on_boarding_error',
  AI_PRODUCT_SETTINGS_CONTACT_SUPPORT = 'react.ai_product_settings.contact_support',
  AI_PRODUCT_SETTINGS_BUTTON_UPGRADE = 'react.ai_product_settings.button_upgrade',
  AI_PRODUCT_SETTINGS_COGS_FOOTER = 'react.ai_product_settings_cogs.footer',
  AI_PRODUCT_SETTINGS_COGS_SUGGESTION = 'react.ai_product_settings_cogs.suggestion',
  AI_PRODUCT_SETTINGS_SUGGESTION_LABEL = 'react.ai_product_settings_suggestion.label',
  AI_PRODUCT_SETTINGS_SUGGESTION_BUTTON = 'react.ai_product_settings_suggestion.button',
  AI_PRODUCT_SETTINGS_FLYWHEEL_SOLUTION_LABEL = 'react.ai_product_settings_flywheel_solution.label',

  COMPASS_TABS_ERROR_TEXT = 'react.compass.errorStateMainText',
  COMPASS_TABS_ERROR_ADDITIONAL_TEXT = 'react.compass.errorStateSubText',
  COMPASS_TABS_ERROR_RETRY_TEXT = 'react.compass.errorStateRetryText',
  COMPASS_TABS_ERROR_CONTACT_SUPPORT = 'react.compass.errorStateContactSupport',
  COMPASS_TABS_ERROR_CONTACT_SUPPORT_DESCRIPTION = 'react.compass.errorStateContactSupportDescription',
  COMPASS_DASHBOARD_AD_SALES = 'react.compass.dashboard.adSales',
  COMPASS_DASHBOARD_AD_SPEND = 'react.compass.dashboard.adSpend',
  COMPASS_DASHBOARD_TOTAL_SALES = 'react.compass.dashboard.totalSales',
  COMPASS_DASHBOARD_TACOS = 'react.compass.dashboard.tacos',
  COMPASS_DASHBOARD_TOTAL_SALES_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.total_sales_tooltip_description',
  COMPASS_DASHBOARD_AD_SALES_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.ad_sales_tooltip_description',
  COMPASS_DASHBOARD_AD_SPEND_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.ad_spend_tooltip_description',
  COMPASS_DASHBOARD_TACOS_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.tacos_tooltip_description',
  COMPASS_DASHBOARD_AD_SALES_MESSAGE = 'react.compass.dashboard.adSales.message',
  COMPASS_DASHBOARD_AD_SPEND_MESSAGE = 'react.compass.dashboard.adSpend.message',
  COMPASS_DASHBOARD_TOTAL_SALES_MESSAGE = 'react.compass.dashboard.totalSales.message',
  COMPASS_DASHBOARD_TACOS_MESSAGE = 'react.compass.dashboard.tacos.message',
  COMPASS_DASHBOARD_METRIC_TABS_CAPTION = 'react.compass.dashboard.metric_tabs_caption',
  COMPASS_TABS_LOADING_TEXT = 'react.compass.loadingStateMainText',
  COMPASS_TABS_LOADING_ADDITIONAL_TEXT = 'react.compass.loadingStateSubText',
  COMPASS_DASHBOARD_SALES_PERCENT = 'react.compass.dashboard.insights.table_header.sales.percent',
  COMPASS_DASHBOARD_AD_SPEND_PERCENT = 'react.compass.dashboard.insights.table_header.ad_spend.percent',
  COMPASS_DASHBOARD_TACOS_PERCENT = 'react.compass.dashboard.insights.table_header.tacos.percent',
  COMPASS_DASHBOARD_INSIGHTS_TABLE_HEADER_MERCHANT = 'react.compass.dashboard.insights.table_header.merchant',
  COMPASS_DASHBOARD_INSIGHTS_TITLE = 'react.compass.dashboard.insights.title',
  COMPASS_DASHBOARD_INSIGHTS_PREVIOUS_VALUE = 'react.compass.dashboard.insights.previous_value',
  COMPASS_DASHBOARD_INSIGHTS_TABLE_ROW_OTHER = 'react.compass.dashboard.insights.table_row.other',

  COMPASS_DASHBOARD_EXPORT_DATA_LABEL = 'react.compass.dashboard.export_data.label',
  COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_TITLE = 'react.compass.dashboard.file_download.success.title',
  COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_DESCRIPTION = 'react.compass.dashboard.file_download.success.description',

  COMPASS_DASHBOARD_CHART_DOWNLOAD = 'react.compass.dashboard.chart.download',
  COMPASS_DASHBOARD_CHART_DOWNLOAD_TOOLTIP = 'react.compass.dashboard.chart.downloadTooltip',
  COMPASS_DASHBOARD_CHART_OVERVIEW = 'react.compass.dashboard.chart.overview',
  COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD = 'react.compass.dashboard.chart.legend.current_period',
  COMPASS_DASHBOARD_CHART_LEGEND_PERVIOUS_PERIOD = 'react.compass.dashboard.chart.legend.previous_period',
  COMPASS_DASHBOARD_CHART_LEGEND_DATA_CONNECTED = 'react.compass.dashboard.chart.legend.data_connected',
  COMPASS_DASHBOARD_CHART_LEGEND_AD_SPEND = 'react.compass.dashboard.chart.legend.ad_spend',
  COMPASS_DASHBOARD_CHART_LEGEND_TOTAL_SALES = 'react.compass.dashboard.chart.legend.total_sales',
  COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_TACOS = 'react.compass.dashboard.chart.legend.current_tacos',
  COMPASS_DASHBOARD_CHART_LEGEND_PREVIOUS_TACOS = 'react.compass.dashboard.chart.legend.previous_tacos',
  COMPASS_DASHBOARD_CHART_LEGEND_ACTIVATED_AI = 'react.compass.dashboard.chart.legend.activated_ai',
  COMPASS_DASHBOARD_CHART_LEGEND_DEACTIVATED_AI = 'react.compass.dashboard.chart.legend.deactivated_ai',
  COMPASS_DASHBOARD_CHART_LEGEND_BEFORE_AI = 'react.compass.dashboard.chart.legend.before_ai',
  COMPASS_DASHBOARD_CHART_LEGEND_WITH_AI = 'react.compass.dashboard.chart.legend.with_ai',
  COMPASS_DASHBOARD_CHART_MINI_METRIC_WEEKLY_DATE_FORMAT = 'react.compass.dashboard.chart.mini_metric.weekly.date.format',
  COMPASS_DASHBOARD_SALES_CHANNEL_AMAZON = 'react.compass.dashboard.sales_channel.amazon',
  COMPASS_DASHBOARD_SALES_CHANNEL_WALMART = 'react.compass.dashboard.sales_channel.walmart',
  COMPASS_DASHBOARD_CHART_DETAILS = 'react.compass.dashboard.chart.details',
  COMPASS_DASHBOARD_CHART_DETAILS_PIECHART_TITLE = 'react.compass.dashboard.charts.details.piechart_title',

  COMPASS_PRODUCT_ACTION_CARD_CONTENT = 'react.compass.product_action_card.content',
  COMPASS_PRODUCT_ACTION_CARD_BUTTON_TITLE = 'react.compass.product_action_card.button_title',
  COMPASS_PRODUCT_ACTION_CARD_HEADER = 'react.compass.product_action_card.header',
  COMPASS_PAYMENT_ACTION_CARD_BUTTON_TITLE = 'react.compass.payment_action_card.button_title',
  COMPASS_PAYMENT_ACTION_CARD_CONTENT = 'react.compass.payment_action_card.content',
  COMPASS_PAYMENT_ACTION_CARD_HEADER = 'react.compass.payment_action_card.header',
  COMPASS_PAYMENT_ACTION_CARD_BUTTON_SECONDARY_TITLE = 'react.compass.payment_action_card.button_secondary_title',
  COMPASS_AUTOMATE_ADVERTISING_POPUP_HEADER = 'react.compass.automate_advertising_popup.header',
  COMPASS_AUTOMATE_ADVERTISING_POPUP_SUB_HEADER = 'react.compass.automate_advertising_popup.sub_header',
  COMPASS_AUTOMATE_ADVERTISING_POPUP_BUTTON_CLOSE = 'react.compass.automate_advertising_popup.button.close',
  COMPASS_MISSING_ACOS_TARGETS_AD_GROUP_COUNT = 'react.compass.missing_acos_targets.ad_group_count',
  COMPASS_MISSING_ACOS_TARGETS_CAMPAIGN_COUNT = 'react.compass.missing_acos_targets.campaign_count',
  COMPASS_MISSING_ACOS_ADVERTISING_TYPE_SPONSORED_BRANDS = 'react.compass.missing_acos_targets.advertising_type.sponsored_brands',
  COMPASS_MISSING_ACOS_ADVERTISING_TYPE_SPONSORED_DISPLAY = 'react.compass.missing_acos_targets.advertising_type.sponsored_display',
  COMPASS_MISSING_ACOS_ADVERTISING_TYPE_SPONSORED_PRODUCTS = 'react.compass.missing_acos_targets.advertising_type.sponsored_products',
  COMPASS_TEASER_WELCOME = 'react.pages.compass.teaser.welcome_back',
  COMPASS_TEASER_COMING_SOON = 'react.pages.compass.teaser.coming_soon',
  COMPASS_TEASER_COMING_SOON_TEXT = 'react.pages.compass.teaser.coming_soon_text',
  COMPASS_TEASER_MORE_ANALYSIS_TOOL_COMMING = 'react.pages.compass.teaser.more_analysis_tool',
  COMPASS_TEASER_GO_TO_AO = 'react.pages.compass.teaser.go_to_AO',
  COMPASS_TEASER_GO_TO_SKU = 'react.pages.compass.teaser.go_to_SKU',
  COMPASS_TEASER_SUBSCRIBE_INTERIM_MESSAGE_FOR_COMING_SOON = 'react.pages.compass.teaser.interim_message_for_comming_soon',
  COMPASS_TEASER_BUTTON_SUBSCRIBE_FOR_UPDATES = 'react.pages.compass.teaser.subscribe_for_updates',
  COMPASS_TEASER_DESCRIPTION = 'react.pages.compass.teaser.description',
  COMPASS_TEASER_SUBSCRIBE_INTERIM_MESSAGE = 'react.pages.compass.teaser.interim_message',
  COMPASS_TEASER_SUBSCRIBE_ACTIONS_COMPLETED_MESSAGE = 'react.pages.compass.teaser.actions_completed_message',
  COMPASS_TEASER_BUTTON_SUBSCRIBED = 'react.pages.compass.teaser.subscribed',
  COMPASS_TEASER_POST_SUBSCRIBE_MESSAGE_HEADER = 'react.pages.compass.teaser.post_subscribe_header',
  COMPASS_TEASER_POST_SUBSCRIBE_MESSAGE_DESCRIPTION = 'react.pages.compass.teaser.post_subscribe_description',
  COMPASS_ADD_COGS_CARD_HEADER = 'react.compass.add_cogs_card.header',
  COMPASS_ADD_COGS_CARD_CONTENT = 'react.compass.add_cogs_card.content',
  COMPASS_ADD_COGS_CARD_BUTTON_TITLE = 'react.compass.add_cogs_card.button_title',

  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_HEADER = 'react.compass.automate_bidding.video_popup.header',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_DESCRIPTION = 'react.compass.automate_bidding.video_popup.description',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_SUBHEADER = 'react.compass.automate_bidding.video_popup.subheader',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_SUBHEADER_DESCRIPTION = 'react.compass.automate_bidding.video_popup.subheader_description',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_BUTTON_LETS_DO_IT = 'react.compass.automate_bidding.video_popup.button.lets_do_it',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_BUTTON_NEXT_STEPS = 'react.compass.automate_bidding.video_popup.button.next_steps',
  COMPASS_TEASER_MISSING_ACOS_TARGET_HEADER = 'react.pages.compass.teaser.missing_acos_target.header',
  COMPASS_TEASER_MISSING_ACOS_TARGET_DESCRIPTION = 'react.pages.compass.teaser.missing_acos_target.description',
  COMPASS_TEASER_MISSING_ACOS_TARGET_X_OF_Y_COUNT = 'react.pages.compass.teaser.missing_acos_target.x_of_y_count',
  COMPASS_TEASER_MISSING_ACOS_TARGET_PRIMARY_BUTTON = 'react.pages.compass.teaser.missing_acos_target.primary_button',
  COMPASS_TEASER_SHOW_ME_HOW = 'react.pages.compass.teaser.show_me_how',
  COMPASS_MI_CARD_TITLE = 'react.pages.compass.market_intelligence_card_title',
  COMPASS_MI_CARD_DESCRIPTION = 'react.pages.compass.market_intelligence_card_description',
  COMPASS_MI_CARD_BUTTON_TEXT = 'react.pages.compass.market_intelligence_card_button_text',
  COMPASS_MI_CARD_BUTTON_TEXT_V2 = 'react.pages.compass.market_intelligence_card_button_text_v2',
  COMPASS_DASHBOARD_TABS_BUSINESS = 'react.pages.compass.dashboard.tabs.business',
  COMPASS_DASHBOARD_TABS_ADS = 'react.pages.compass.dashboard.tabs.ads',
  COMPASS_DASHBOARD_TABS_PRODUCTS = 'react.pages.compass.dashboard.tabs.products',
  COMPASS_DASHBOARD_ACTION_ITEMS_HEADER = 'react.pages.compass.dashboard.action_items.header',
  COMPASS_DASHBOARD_ACTION_ITEMS_ALL_CLEAR = 'react.pages.compass.dashboard.action_items.all_clear',
  COMPASS_DASHBOARD_ACTION_ITEMS_ALL_CLEAR_DESCRIPTION = 'react.pages.compass.dashboard.action_items.all_clear.description',
  COMPASS_DASHBOARD_MI_ACTION_CARD_HEADER = 'react.pages.compass.market_intelligence.header',

  NAV_BAR_LABEL_PRODUCT_SETTINGS = 'react.components.navbar.product_settings.header',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_SKU = 'react.components.ai_product_settings_v2_product_settings_row_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_BASE_OR_PARENT_SKU = 'react.components.ai_product_settings_v2_product_settings_row_base_or_parent_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_ADVERTISED = 'react.components.ai_product_settings_v2_product_settings_row_advertised',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_TOOLTIP_SETTINGS = 'react.components.ai_product_settings_v2_product_settings_row_tooltip_settings',
  COMPASS_CHART_CONTEXT_DAILY = 'react.pages.compass.chart.daily',
  COMPASS_CHART_CONTEXT_WEEKLY = 'react.pages.compass.chart.weekly',
  COMPASS_CHART_CONTEXT_MONTHLY = 'react.pages.compass.chart.monthly',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_PREVIOUS = 'react.pages.compass.charts.metric.activated_ai.previous',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_PREVIOUS_TACOS = 'react.pages.compass.charts.metric.activated_ai.previousTacos',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_TACOS = 'react.pages.compass.charts.metric.activated_ai.value.tacos',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_LEARN_MORE = 'react.pages.compass.charts.metric.activated_ai.learnmore',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_LEARN_MORE_URL = 'react.pages.compass.charts.metric.activated_ai.learnmore.url',
  COMPASS_CHART_NO_DATA_HEADER = 'react.pages.compass.charts.no_data.header',
  COMPASS_CHART_NO_DATA_DESCRIPTION = 'react.pages.compass.charts.no_data.discription',
  COMPASS_CHART_NO_DATA_CONNECT_PRODUCT = 'react.pages.compass.charts.no_data.connect_product',
  NAV_BAR_LABEL_PRODUCT_GROUP_SETTINGS = 'react.components.navbar.product_group_settings.header',
  AI_PRODUCT_GROUP_SETTINGS_ADVERTISING_LEVEL = 'react.components.ai_product_group_settings.advertising.level',
  AI_PRODUCT_GROUP_SETTINGS_BRANDS_AND_COMPETITORS_LEVEL = 'react.components.ai_product_group_settings.brands_and_competitors.level',
  AI_PRODUCT_GROUP_SETTINGS_COGS_LEVEL = 'react.components.ai_product_group_settings.cogs.level',
  AI_PRODUCT_SETTINGS_V2_SEARCH_FILTER = 'react.components.ai_product_settings_v2_search_filter',
  AI_PRODUCT_SETTINGS_V2_NO_RESULTS = 'react.components.ai_product_settings_v2_no_results',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_MERCHANT_COUNTRY_PLACEHOLDER = 'react.components.ai_product_settings_v2_merchant_country_placeholder',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_PARENT_PRODUCT = 'react.components.ai_product_settings_v2_product_settings_parent_product',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_PRODUCT_SHELF = 'react.components.ai_product_settings_v2_product_settings_product_shelf',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_VIEW_SHELF_ON_WALMART = 'react.components.ai_product_settings_v2_product_settings_view_shelf_on_walmart',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_TITLE = 'react.components.ai_product_settings_v2_advertising_changes_modal_title',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_DESCRIPTION = 'react.components.ai_product_settings_v2_advertising_changes_modal_description',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_CANCEL_BUTTON = 'react.components.ai_product_settings_v2_advertising_changes_modal_cancel_button',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_OK_BUTTON = 'react.components.ai_product_settings_v2_advertising_changes_modal_ok_button',

  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_PARENT_SKU = 'react.components.ai_product_settings_v2_product_settings_parent_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_PARENT_ASIN = 'react.components.ai_product_settings_v2_product_settings_parent_asin',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_MERCHANT = 'react.components.ai_product_settings_v2_merchant',

  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_HEADER_SKU_CHILDREN = 'react.components.ai_product_settings_v2_product_settings_header_sku_children',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_CHILD_SKU = 'react.components.ai_product_settings_v2_product_settings_child_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_CHILD_ASIN = 'react.components.ai_product_settings_v2_product_settings_child_asin',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_CHILD_ID = 'react.components.ai_product_settings_v2_product_settings_child_id',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_TITLE = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_title',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_COUNT = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_count',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_ADS_SUGGESTION = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_ads_suggestion',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_TAGS_SUGGESTION = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_tags_suggestion',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_COGS_SUGGESTION = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_cogs_suggestion',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_HEADER_CAMPAIGNS = 'react.components.ai_product_settings_v2_product_settings_header_campaigns',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_KEYWORD_CAMPAIGNS = 'react.components.ai_product_settings_v2_product_settings_title_keyword_campaigns',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_ALIGNED_STRUCTURE = 'react.components.ai_product_settings_v2_product_settings_title_aligned_structure',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_ACOS = 'react.components.ai_product_settings_v2_product_settings_title_acos',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_DAILY_BUDGET = 'react.components.ai_product_settings_v2_product_settings_title_daily_budget',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_AUTOMATIC = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_automatic',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_BRAND = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_brand',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_COMPETITOR = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_competitor',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_GENERIC = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_generic',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_MAIN_LABEL = 'react.ai_product_settings_suggestion.main_label',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_MAIN_HEADER = 'react.ai_product_settings_suggestion.main_header',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_QUESTION = 'react.ai_product_settings_suggestion.question',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_HEADER = 'react.ai_product_settings_suggestion.campaign_header',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_1 = 'react.ai_product_settings_suggestion.campaign_explanation_1',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_2 = 'react.ai_product_settings_suggestion.campaign_explanation_2',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_3 = 'react.ai_product_settings_suggestion.campaign_explanation_3',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_4 = 'react.ai_product_settings_suggestion.campaign_explanation_4',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_HEADER = 'react.ai_product_settings_suggestion.structure_explanation_header',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_1 = 'react.ai_product_settings_suggestion.structure_explanation_1',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_2 = 'react.ai_product_settings_suggestion.structure_explanation_2',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_3 = 'react.ai_product_settings_suggestion.structure_explanation_3',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_LEARN_MORE_LINK = 'react.ai_product_settings_suggestion.learn_more_link',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_APPLY_SOLUTION = 'react.ai_product_settings_suggestion.apply_solution',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_PAUSE = 'react.ai_product_settings_suggestion.pause',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_KEEP = 'react.ai_product_settings_suggestion.keep',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CREATE = 'react.ai_product_settings_suggestion.create',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_DAILY_BUDGET = 'react.ai_product_settings_suggestion.daily_budget',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_ACOS_TARGET = 'react.ai_product_settings_suggestion.acos_target',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_UNKNOWN = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_unknown',

  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_TARGET_RECOMMENDATIONS = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_target_recommendations',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_TARGET_RECOMMENDATIONS_ON = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_target_recommendations_on',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_TARGET_RECOMMENDATIONS_OFF = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_target_recommendations_off',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_NAME = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_name',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_DAILY_BUDGET = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_daily_budget',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_GROUP_NAME = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_ad_group_name',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_ACOS_TARGET = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_ad_acos_target',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_AUTOMATION = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_automation',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_AUTOMATION_ENABLED = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_automation_enabled',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_AUTOMATION_DISABLED = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_automation_disabled',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_CHILD_SKUS = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_child_skus',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_COGS_MISSING_COGS_VALUE = 'react.components.ai_product_settings_v2_product_settings_cogs_missing_cogs_value',
  AI_PRODUCT_SETTINGS_BRANDS_AND_COMPETITORS_SUGGESTION = 'react.components.ai_product_brands_and_competitors_suggestion',

  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_HEADLINE = 'react.generic.http_error_response.toast.failure_headline',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION = 'react.generic.http_error_response.toast.failure_description',

  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_400 = 'react.generic.http_error_response.toast.failure_description_400',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_401 = 'react.generic.http_error_response.toast.failure_description_401',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_403 = 'react.generic.http_error_response.toast.failure_description_403',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_404 = 'react.generic.http_error_response.toast.failure_description_404',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_405 = 'react.generic.http_error_response.toast.failure_description_405',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_408 = 'react.generic.http_error_response.toast.failure_description_408',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_414 = 'react.generic.http_error_response.toast.failure_description_414',
}

export const isI18nKey = (value: I18nKey | string): value is I18nKey =>
  includes(values(I18nKey), value);

export default I18nKey;
