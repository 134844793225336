/* import {
  TextLink,
  TextLinkSize,
} from '@teikametrics/tm-design-system/components/TextLink'; */
import React from 'react';
import { useIntl } from 'react-intl';
import { AIOnboardingResponse } from '../../../lib/types/AIPoweredSharedTypes';
import I18nKey from '../../../lib/types/I18nKey';
import { UpgradeStatus } from '../Upgrade';
import { Callout } from './Callout';
import { Header } from './Header';
import { PreviewBreakdown } from './PreviewBreakdown';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system/components/Button';
import { UpgradeModal } from './UpgradeModal';

interface PreviewProps {
  readonly aiOnboardingData: AIOnboardingResponse;
  readonly onNextClick: () => void;
  readonly count?: number;
}

export const Preview: React.FC<PreviewProps> = ({
  aiOnboardingData,
  onNextClick,
  count = 0,
}) => {
  const intl = useIntl();
  const [show, setShow] = React.useState(false);
  const onUpgradeButtonClickHandler = () => {
    setShow(true);
    // onNextClick();
  };
  const closeModal = () => {
    setShow(false);
  };
  const onYesClickHandler = () => {
    closeModal();
    onNextClick();
  };
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="mt-40">
          <Header status={UpgradeStatus.Preview} />
        </div>
        <div className="mt-56 mb-40 w-full flex items-center justify-center">
          <PreviewBreakdown aiOnboardingData={aiOnboardingData} />
        </div>
        <div className="flex flex-row gap-8 items-center mt-56">
          <Button
            label={intl.formatMessage(
              {
                id:
                  I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ALL,
              },
              {
                products: intl.formatNumber(count),
              }
            )}
            variant={ButtonVariant.Primary}
            size={ButtonSize.Large}
            onClick={onUpgradeButtonClickHandler}
            dataTestId="ob_ActionbarUpgradeAll"
          />
        </div>
        <div className="flex flex-row justify-center items-center p-24">
          <Callout
            title={intl.formatMessage({
              id:
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_TITLE,
            })}
            subtitle={intl.formatMessage({
              id:
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_SUB_TITLE,
            })}
          >
            {/* in the future, we will have a link
           <TextLink
            textLabel={intl.formatMessage({
              id:
                I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_LINK,
            })}
            size={TextLinkSize.Small}
            dataTestId="ob_preview"
          /> */}
          </Callout>
        </div>
      </div>
      <UpgradeModal
        onClose={closeModal}
        show={show}
        count={count}
        onYesClick={onYesClickHandler}
      />
    </>
  );
};
