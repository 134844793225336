import { SvgIcon } from '@teikametrics/tm-design-system';
import {
  AmazonIcon as AmazonDarkIcon,
  WalmartIcon as WalmartDarkIcon,
} from '@teikametrics/tm-design-system/img/icons/index';
import { DualModes } from '@teikametrics/tm-design-system/molecules/TableActionRow';
import { DateTime } from 'luxon';
import { ProductAdStatusValue } from 'modules/advertising_optimization/containers/adsManager/utils';
import { MerchantCountry, MerchantType } from './Fam';
import { Filter } from './Filter';
import {
  Money as MoneyWithAmountInNumber,
  MoneyWithAmountInString,
} from './Money';

export const AO_BASE_URL = process.env.REACT_APP_AO_HOST;
export const REQUEST_DATE_FORMAT = 'yyyy-MM-dd';
export const EMPTY_STRING = '';

export type CampaignsChannelSettingsEditInfoKeys =
  | 'campaignName'
  | 'mobileBidMultiplier'
  | 'appBidMultiplier'
  | 'homePageBidMultiplier'
  | 'desktopBidMultiplier'
  | 'endDate'
  | 'buyBoxBidMultiplier'
  | 'belowTopOfSearchBidMultiplier'
  | 'bidOptimization'
  | 'searchIngridBidMultiplier'
  | 'budgetRollover'
  | 'stockUpBidMultiplier'
  | 'topOfSearchBidMultiplier'
  | 'carouselStatus'
  | 'itemBuyBox'
  | 'itemCarousel'
  | 'status'
  | 'biddingStrategy'
  | 'totalBudget'
  | 'dailyBudget'
  | 'productPageBidMultiplier';

export interface Range {
  readonly startDate: string;
  readonly endDate: string;
}

export enum MerchantlSalesChannel {
  Walmart = 'walmart',
  Amazon = 'amazon',
}

export enum CampaignTargetingType {
  Auto = 'auto',
  Manual = 'manual',
}

// FIXME: The more accurate name for this enum is `ProductVerticalName`
export enum PRODUCT_SUBSCRIPTIONS {
  AO = 'AO',
  MI = 'MI',
  IO = 'IO',
  AI = 'AI',
}

export enum MetricType {
  'adSpend' = 'adSpend',
  'adSalesTotal' = 'adSalesTotal',
  'roasTotal' = 'roasTotal',
  'unitsSold' = 'unitsSold',
  'conversionRate' = 'conversionRate',
  'adConversions' = 'adConversions',
}

export enum DecimalNumericColumn {
  'roasTotal' = 'roasTotal',
  'roasDirect' = 'roasDirect',
}

export type Metric = number | MoneyWithAmountInString;
export type MerchantCountryId = Array<string | number> | string;
export type SalesChannelId = string | number;

export enum HeroMetricsAggregationPeriodType {
  Current = 'current',
  Previous = 'previous',
}

// Update the interface to add more performance fields
export interface HeroMetric {
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly roasTotal?: number;
  readonly conversionRate?: number;
  readonly adConversions?: number;
  readonly range: Range;
}

export interface HeroMetricsData {
  readonly aggregationPeriodType: HeroMetricsAggregationPeriodType;
  readonly performanceMetrics: HeroMetric;
}

export enum HeroMetricType {
  Numeric,
  Money,
  Percent,
}

export enum CampaignServingStatus {
  AsinNotBuyable = 'asinNotBuyable',
  BillingError = 'billingError',
  Ended = 'ended',
  LandingPageNotAvailable = 'landingPageNotAvailable',
  OutOfBudget = 'outOfBudget',
  Paused = 'paused',
  PendingReview = 'pendingReview',
  Ready = 'ready',
  Rejected = 'rejected',
  Delivering = 'running',
  Scheduled = 'scheduled',
  Terminated = 'terminated',
}

export enum AmazonBidOptimization {
  True = 'true',
  False = 'false',
}

export interface HeroMetricsItem {
  readonly metricName: MetricType;
  readonly previousValue?: Metric;
  readonly currentValue?: Metric;
  readonly heroMetricType: HeroMetricType;
}

export interface PerformanceMetricsRequest {
  readonly merchantCountryIds: string[];
  readonly performanceFields: MetricType[];
  readonly startDate: string;
  readonly endDate: string;
  readonly aggregationPeriodType: HeroMetricsAggregationPeriodType;
  readonly channelId: string;
  readonly filters: Filter[];
  readonly advertisingType: AdType;
  readonly targetType?: EntityTypeRequestParameterForTargets;
  readonly merchantType?: MerchantType;
}

export enum EntityTypeRequestParameterForTargets {
  KeywordTargeting = 'Keyword Targets',
  ProductTargetting = 'Product Targets',
  Targets = 'Targets',
}

export interface CampaignDataRequest {
  readonly merchantCountryIds: string[];
  readonly campaignDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly placementSettingsFields: string[];
  readonly flywheelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface CampaignDetails {
  readonly campaignId: string;
  readonly campaignDetails: Details;
  readonly channelSettings: ChannelSettings;
  readonly campaignPerformance: CampaignPerformance;
  readonly flywheelSettings: TeikaSettingsCampaigns;
  readonly pendingFields?: string[];
}

export interface TeikaSettingsCampaigns {
  readonly automationStatus?: boolean;
  readonly macsTarget?: number;
  readonly minBid?: MoneyWithAmountInString;
  readonly maxBid?: MoneyWithAmountInString;
}

interface Details {
  readonly campaignName?: string;
  readonly merchantCountryId?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignTactic?: CampaignTactic;
  readonly campaignAdFormat?: CampaignAdFormat;
  readonly targetingType?: CampaignTargetingType;
  readonly campaignCostType?: CampaignCostType;
}

export enum CampaignAdFormat {
  ProductCollection = 'productCollection',
  Video = 'video',
  StoreSpotlight = 'storeSpotlight',
}

export interface ChannelSettings {
  readonly campaignType?: string;
  readonly status?: CampaignStatus;
  readonly budgetType?: string;
  readonly dailyBudget?: MoneyWithAmountInString;
  readonly totalBudget?: MoneyWithAmountInString;
  readonly budgetRollover?: boolean;
  readonly carouselStatus?: string;
  readonly itemBuyBox?: string;
  readonly itemCarousel?: string;
  readonly placementBidMultiplier?: PlacementBidMultiplier;
  readonly platformBidMultiplier?: PlatformBidMultiplier;
  readonly campaignServingStatus?: CampaignServingStatus;
  readonly bidOptimization?: boolean;
  readonly startDate: string;
  readonly endDate?: string;
  readonly biddingStrategy?: AmazonBiddingStrategy;
}

export interface PlacementBidMultiplier {
  readonly buyBoxBidMultiplier: number;
  readonly homePageBidMultiplier: number;
  readonly searchIngridBidMultiplier: number;
  readonly stockUpBidMultiplier: number;
  readonly productPageBidMultiplier?: number;
  readonly topOfSearchBidMultiplier?: number;
  readonly belowTopOfSearchBidMultiplier?: number;
}

export interface PlatformBidMultiplier {
  readonly mobileBidMultiplier: number;
  readonly desktopBidMultiplier: number;
  readonly appBidMultiplier: number;
}

interface CampaignPerformance {
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly acosTotal?: number;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly roasDirect?: number;
  readonly acosDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly adConversions?: number;
  readonly newToBrandOrders?: number;
  readonly newToBrandOrdersPercentage?: number;
  readonly newToBrandOrderRate?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandSalesPercentage?: number;
  readonly newToBrandAdUnitsSold?: number;
  readonly newToBrandAdUnitsSoldPercentage?: number;
}

type DetailsType =
  | string
  | CampaignTargetingType
  | CampaignTactic
  | MoneyWithAmountInString
  | ProductReviewStatus
  | AdGroupReviewStatus
  | undefined;
type DetailTargetsType =
  | string
  | CampaignTactic
  | TargetTextExpressionTypeView[]
  | MoneyWithAmountInString
  | CampaignAdFormat
  | KeywordReviewStatus
  | AdGroupReviewStatus
  | undefined
  | boolean;

type ProfileDetailsType =
  | string
  | CampaignTargetingType
  | CampaignTactic
  | MoneyWithAmountInString
  | AdGroupStatus
  | CampaignStatus
  | AdGroupReviewStatus
  | ProfileReviewStatus
  | undefined;

interface ItemDetails {
  readonly [key: string]: DetailsType;
  readonly productName: string;
  readonly productImage: string;
  readonly adGroupName: string;
  readonly adGroupId: string;
  readonly adGroupStatus: AdGroupStatus;
  readonly campaignName: string;
  readonly campaignId: string;
  readonly campaignType: string;
  readonly campaignTargetingType: CampaignTargetingType;
  readonly campaignStatus: CampaignStatus;
  readonly merchantCountryId: string;
  readonly itemPageUrl?: string;
  readonly extItemId?: string;
  readonly sku?: string;
  readonly campaignTactic?: CampaignTactic;
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly campaignTotalBudget?: MoneyWithAmountInString;
  readonly startDate?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignCostType?: CampaignCostType;
  readonly adItemReviewStatus?: ProductReviewStatus;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
}

export enum FlywheelSalesChannel {
  Walmart = 'walmart',
  Amazon = 'amazon',
}

interface ItemSettings {
  readonly status: ProductAdStatusValue;
  readonly bid?: MoneyWithAmountInString;
}

interface ProductAdPerformance {
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly acosTotal?: number;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly roasDirect?: number;
  readonly acosDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly adConversions?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandAdUnitsSold?: number;
  readonly newToBrandOrders?: number;
}

export interface ProductAdsDataRequest {
  readonly merchantCountryIds: string[];
  readonly adItemDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface ProductAdDetails {
  readonly adItemId: string;
  readonly adItemDetails: ItemDetails;
  readonly channelSettings: ItemSettings;
  readonly adItemPerformance: ProductAdPerformance;
  readonly pendingFields?: string[];
}

export enum MerchantCountryCode {
  AE = 'AE',
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IN = 'IN',
  IT = 'IT',
  JP = 'JP',
  KSA = 'KSA',
  MX = 'MX',
  NL = 'NL',
  SG = 'SG',
  UK = 'UK',
  US = 'US',
  GB = 'GB',
  SE = 'SE',
}

export enum AdLevel {
  Campaigns = 'campaigns',
  AdGroups = 'adGroups',
  Targets = 'targets',
  ProductAds = 'productAds',
  Profiles = 'profiles',
  KeywordTargets = 'keywordTargets',
  ProductTargets = 'productTargets',
}

export enum AdType {
  'SponsoredProducts' = 'sponsoredProducts',
  'SearchBrandAmplifier' = 'searchBrandAmplifier',
  'SponsoredBrands' = 'sponsoredBrands',
  'SponsoredDisplay' = 'sponsoredDisplay',
  'SponsoredBrandsVideo' = 'sponsoredBrandsVideo',
}

export enum AutomationStatus {
  Inactive = 'false',
  Active = 'true',
  Unset = 'Unset',
}

export enum AmazonBiddingStrategy {
  'DynamicBidsDownOnly' = 'legacyForSales',
  'DynamicBidsUpAndDown' = 'autoForSales',
  'FixedBid' = 'manual',
}

export enum AutomationStatusFilterValue {
  Active = 'true',
  Inactive = 'false',
  Unset = 'unset',
}

export enum CampaignType {
  'Sponsored Products' = 'sponsoredProducts',
}

export enum MatchType {
  Exact = 'exact',
  Phrase = 'phrase',
  Broad = 'broad',
}

export enum TargetType {
  Generic = 'generic',
  Competitor = 'competitor',
  Brand = 'brand',
}

export enum FlywheelAIEnabled {
  FlywheelAIAdded = 'true',
  ManuallyAdded = 'false',
}

export enum TargetStatus {
  Enabled = 'enabled',
  Paused = 'paused',
  Archived = 'archived',
}

export enum AdGroupStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Paused = 'paused',
  Archived = 'archived',
}

export enum CampaignStatus {
  Enabled = 'enabled',
  Paused = 'paused',
  Completed = 'completed',
  Live = 'live',
  Extend = 'extend',
  Rescheduled = 'rescheduled',
  Scheduled = 'scheduled',
  Proposal = 'proposal',
  Archived = 'archived',
  Pending = 'pending',
  Draft = 'draft',
}

export enum BudgetType {
  Daily = 'daily',
  Total = 'total',
  Both = 'both',
  Lifetime = 'lifetime',
}

export enum BudgetRollover {
  True = 'true',
  False = 'false',
}

export enum PlacementStatus {
  Excluded = 'excluded',
  Included = 'included',
}

export interface LabelValue<T> {
  readonly label: string;
  readonly value: T;
}

export type MerchantCountryAdTypeConfig = Partial<
  {
    readonly [merchantCountry in MerchantCountryCode]: AdType[];
  }
>;

export type AdLevelConfig = Partial<{ readonly [adType in AdType]: AdLevel[] }>;

export interface DataInspectionRequest {
  readonly salesChannel: FlywheelSalesChannel;
  readonly adsManagerDetailsPageFlag: boolean;
  readonly adLevel: AdLevel;
}

export interface DataInspectionResponse {
  readonly dataInspection: DataInspectionList[];
}

export interface DataInspectionList {
  readonly merchantCountryCode: MerchantCountryCode;
  readonly advertisingTypeInfo: AdvertisingTypeInfo[];
}

export interface DataAvailabilityInfoResponseType {
  readonly syncPerMerchantIds: DataAvailabilityInfo[];
}

export interface DataAvailabilityInfoApiResponse {
  readonly syncPerMerchantIds: DataAvailabilityInfoResponse[];
}

export interface DataAvailabilityInfoResponse {
  readonly merchantCountryId: string;
  readonly earliestAvailableDate: string;
  readonly latestAvailableDate: string;
  readonly lastSyncedAt: string;
}

export interface DataAvailabilityInfo {
  readonly merchantCountryId: string;
  readonly earliestAvailableDate: Date;
  readonly latestAvailableDate: Date;
  readonly lastSyncedAt: Date; //not currently being used an other part of the application.placefolder for value returned in response.
}

export interface PortfolioResponse {
  readonly portfolios: Portfolio[];
}

export interface Portfolio {
  readonly name: string;
  readonly merchantCountryId?: string;
  readonly portFolioId?: string;
}

export interface TargetTypes {
  readonly targetTypes: string[];
}

export interface DataSyncInfoResponse {
  readonly lastSyncedAt: string;
}
export interface SyncDate {
  readonly earliestAvailableDate?: string;
  readonly latestAvailableDate?: string;
  readonly lastSyncedAt?: string;
}

export interface MerchantSyncDate extends SyncDate {
  readonly merchantCountryId: string;
}

export interface DataSyncInfoResponse extends SyncDate {
  readonly syncPerMerchantIds?: Array<MerchantSyncDate>;
}

export interface AdvertisingTypeInfo {
  readonly adType: AdType;
  readonly adLevels: AdLevel[];
}

export enum AdLevelApiEndpoint {
  Campaigns = 'campaigns',
  Adgroups = 'adgroups',
  ProductAds = 'aditems',
  Targets = 'targets',
  PageType = 'pageType',
  Platform = 'platform',
  KeywordTargets = 'targets',
  ProductTargets = 'productTargets',
  Profiles = 'profiles',
}

export interface AdGroupDataRequest {
  readonly merchantCountryIds: string[];
  readonly adGroupDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly flywheelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface UpdateCampaignChannelSettingsRequest {
  readonly updates: CampaignsChannelSettingsUpdateInfo[];
  readonly channelId: string;
}

export interface UpdateAdgroupChannelSettingsRequest {
  readonly updates: EditAdgroupsChannelSettingRow[];
}
export interface UpdateFlywheelSettingsRequest {
  readonly channelId: string;
  readonly creates: Partial<FlywheelSettingsUpdateInfo>[];
  readonly updates: Partial<FlywheelSettingsUpdateInfo>[];
}

export interface CampaignsChannelSettingsEditInfo {
  readonly buyBoxBidMultiplier?: number;
  readonly campaignName?: string;
  readonly carouselStatus?: string;
  readonly itemBuyBox?: string;
  readonly itemCarousel?: string;
  readonly dailyBudget?: MoneyWithAmountInString;
  readonly desktopBidMultiplier?: number;
  readonly endDate?: string | null;
  readonly bidOptimization?: boolean;
  readonly belowTopOfSearchBidMultiplier?: number;
  readonly homePageBidMultiplier?: number;
  readonly appBidMultiplier?: number;
  readonly mobileBidMultiplier?: number;
  readonly searchIngridBidMultiplier?: number;
  readonly status?: string;
  readonly stockUpBidMultiplier?: number;
  readonly totalBudget?: MoneyWithAmountInString;
  readonly budgetRollover?: boolean;
  readonly portfolio?: string | null;
  readonly biddingStrategy?: string;
  readonly topOfSearchBidMultiplier?: number;
  readonly productPageBidMultiplier?: number;
  readonly noPortfolio?: boolean;
}

export interface CampaignsChannelSettingsUpdateInfo {
  readonly campaignId: string;
  readonly edits: CampaignsChannelSettingsEditInfo;
}

export enum EntityType {
  Campaign = 'campaign',
  AdGroup = 'adgroup',
}

export interface FlywheelSettingsUpdateInfo {
  readonly entityId: string;
  readonly bidAutomationEnabled: boolean;
  readonly macsTarget: number;
  readonly minBid: MoneyWithAmountInString;
  readonly maxBid: MoneyWithAmountInString;
  readonly entityType: EntityType;
}

export interface EditAdgroupsChannelSettingRow {
  readonly adGroupId: string;
  readonly edits: EditAdgroupsChannelSettingFields;
}

export interface EditTargetChannelSettingRow {
  readonly targetId: string;
  readonly edits: EditTargetFields;
}

export interface EditAdgroupsChannelSettingFields {
  readonly name?: string;
  readonly status?: string;
  readonly defaultBid?: MoneyWithAmountInString;
}

export interface AdGroupDetails {
  readonly adGroupId: string;
  readonly adGroupDetails: DetailAdGroup;
  readonly channelSettings: ChannelSettingsAdgroup;
  readonly flywheelSettings: TeikaSettingsAdgroup;
  readonly adGroupPerformance: PerformanceAdgroup;
  readonly pendingFields?: string[];
}

export interface DetailAdGroup {
  readonly [key: string]: DetailsType;
  readonly adGroupName?: string;
  readonly adGroupType?: string;
  readonly campaignName?: string;
  readonly campaignId?: string;
  readonly campaignType?: string;
  readonly campaignTargetingType?: CampaignTargetingType;
  readonly campaignStatus?: CampaignStatus;
  readonly campaignTactic?: CampaignTactic;
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly campaignTotalBudget?: MoneyWithAmountInString;
  readonly merchantCountryId?: string;
  readonly startDate?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignCostType?: CampaignCostType;
}

export interface ChannelSettingsAdgroup {
  readonly status?: AdGroupStatus;
  readonly defaultBid?: MoneyWithAmountInString;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
}

interface TeikaSettingsAdgroup {
  readonly automationStatus?: boolean;
  readonly macsTarget?: number;
  readonly minBid?: MoneyWithAmountInString;
  readonly maxBid?: MoneyWithAmountInString;
}
interface PerformanceAdgroup {
  readonly impressions?: number;
  readonly clicks?: number;
  readonly adSpend?: MoneyWithAmountInString;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly acosTotal?: number;
  readonly roasTotal?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly acosDirect?: number;
  readonly roasDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly totalConversions?: number;
  readonly adConversions?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandAdUnitsSold?: number;
  readonly newToBrandOrders?: number;
}

export type FlywheelTableRowCell<T> = {
  isEditMode: boolean;
  data: T;
};

export interface DateRange {
  readonly initialStartDate: DateTime;
  readonly initialEndDate: DateTime;
  readonly minDate: DateTime;
  readonly maxDate: DateTime;
}

export interface TargetsDataRequest {
  readonly merchantCountryIds: string[];
  readonly targetDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export enum ExpressionType {
  AsinSameAs = 'asinSameAs',
  AsinCategorySameAs = 'asinCategorySameAs',
  AsinBrandSameAs = 'asinBrandSameAs',
  AsinPriceLessThan = 'asinPriceLessThan',
  AsinPriceGreaterThan = 'asinPriceGreaterThan',
  AsinPriceBetween = 'asinPriceBetween',
  AsinReviewRatingLessThan = 'asinReviewRatingLessThan',
  AsinReviewRatingGreaterThan = 'asinReviewRatingGreaterThan',
  AsinReviewRatingBetween = 'asinReviewRatingBetween',
  AsinIsPrimeShippingAvailable = 'asinIsPrimeShippingAvailable',
  AsinAgeRangeSameAs = 'asinAgeRangeSameAs',
  AsinGenreSameAs = 'asinGenreSameAs',
  Lookback = 'lookback',
  AsinSubstituteRelated = 'asinSubstituteRelated',
  AsinAccessoryRelated = 'asinAccessoryRelated',
  QueryBroadRelMatches = 'queryBroadRelMatches',
  QueryHighRelMatches = 'queryHighRelMatches',
  SimilarProduct = 'similarProduct',
  ExactProduct = 'exactProduct',
  AsinIsPrimeShippingEligible = 'asinIsPrimeShippingEligible',
  AudienceSameAs = 'audienceSameAs',
  RelatedProduct = 'relatedProduct',
}

export interface TargetTextExpressionTypeView {
  readonly type: ExpressionType;
  readonly value?: string;
}

export interface TargetsDetails {
  readonly targetId: string;
  readonly targetDetails: DetailTargets;
  readonly channelSettings: ChannelSettingsTargets;
  readonly targetPerformance: PerformanceTargets;
  readonly pendingFields?: string[];
}

export enum ProductReviewStatusType {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

interface ProductReviewStatus {
  readonly reviewStatus: ProductReviewStatusType;
  readonly reviewComments?: string;
}

export enum KeywordReviewStatusType {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum AdGroupReviewStatusType {
  Pending = 'pending',
  InProgress = 'inprogress',
  Complete = 'complete',
  Cancelled = 'cancelled',
}

interface KeywordReviewStatus {
  readonly reviewStatus: KeywordReviewStatusType;
  readonly reviewComments?: string;
}

export interface AdGroupReviewReason {
  readonly commentType: string;
  readonly comments: string;
}
interface AdGroupReviewStatus {
  readonly reviewStatus: AdGroupReviewStatusType;
  readonly reviewReason?: AdGroupReviewReason[];
}

export interface DetailTargets {
  readonly [key: string]: DetailTargetsType;
  readonly adGroupName?: string;
  readonly adGroupStatus?: AdGroupStatus;
  readonly campaignName?: string;
  readonly campaignStatus?: CampaignStatus;
  readonly campaignTargetingType?: string;
  readonly campaignType?: string;
  readonly matchType?: string;
  readonly targetType?: string;
  readonly createdAt?: string;
  readonly flywheelAiEnabled?: boolean;
  readonly targetText?: string;
  readonly campaignTactic?: CampaignTactic;
  readonly entityType?: string;
  readonly targetingExpressionType?: string;
  readonly viewsTargetText?: TargetTextExpressionTypeView[];
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly genre?: string;
  readonly ageRange?: string;
  readonly brand?: string;
  readonly targetingClause?: string;
  readonly dailyBudget?: MoneyWithAmountInString;
  readonly campaignTotalBudget?: MoneyWithAmountInString;
  readonly nativeLanguageKeyword?: string;
  readonly campaignAdFormat?: CampaignAdFormat;
  readonly merchantCountryId?: string;
  readonly adGroupId?: string;
  readonly campaignId?: string;
  readonly startDate?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignCostType?: CampaignCostType;
  readonly keywordReviewStatus?: KeywordReviewStatus;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
}

interface ChannelSettingsTargets {
  readonly bid?: MoneyWithAmountInString;
  readonly status?: TargetStatus;
}

interface PerformanceTargets {
  readonly impressions?: number;
  readonly clicks?: number;
  readonly adSpend?: MoneyWithAmountInString;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly acosTotal?: number;
  readonly roasTotal?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly acosDirect?: number;
  readonly roasDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly adConversions?: number;
  readonly newToBrandOrders?: number;
  readonly newToBrandAdUnitsSold?: number;
  readonly adConversionRate?: number;
  readonly newToBrandOrdersPercentage?: number;
  readonly newToBrandOrderRate?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandSalesPercentage?: number;
  readonly newToBrandAdUnitsSoldPercentage?: number;
}

export interface EditProductAdRequest {
  readonly updates: EditProductAdRow[];
}
export interface EditProductAdRow {
  readonly adItemId: string;
  readonly edits: EditProductAdFields;
}

export interface EditProductAdFields {
  readonly status?: string;
  readonly bid?: MoneyWithAmountInString;
}

export interface EditTargetFields {
  readonly status?: string;
  readonly bid?: MoneyWithAmountInString;
}

export interface EditTargetsRow {
  readonly targetId: string;
  readonly edits: EditTargetFields;
}

export interface EditTargetsRequest {
  readonly updates: EditTargetsRow[];
}

export interface FilterFieldMapper {
  readonly field: string;
  readonly alias: string;
}

export enum MerchantPickerType {
  Single = 'Single',
  Multi = 'Multi',
}

export const FLYWHEEL_CHANNEL_TO_MERCHANT_PICKER_TYPE_MAPPER: Record<
  FlywheelSalesChannel,
  MerchantPickerType
> = {
  [FlywheelSalesChannel.Amazon]: MerchantPickerType.Single,
  [FlywheelSalesChannel.Walmart]: MerchantPickerType.Multi,
};

export const SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER: Record<
  FlywheelSalesChannel,
  MetricType[]
> = {
  [FlywheelSalesChannel.Amazon]: [
    MetricType.adSpend,
    MetricType.adSalesTotal,
    MetricType.roasTotal,
    MetricType.adConversions,
    MetricType.conversionRate,
  ],
  [FlywheelSalesChannel.Walmart]: [
    MetricType.adSpend,
    MetricType.adSalesTotal,
    MetricType.roasTotal,
    MetricType.unitsSold,
    MetricType.conversionRate,
  ],
};

export enum CampaignTactic {
  Legacy = 'T00001',
  Product = 'T00020',
  Audiences = 'T00030',
  Views = 'remarketing',
}

type AdLevelDetailsProps = Partial<
  { readonly [adLevel in AdLevel]: AdLevel[] }
>;

type AdTypeDetailsProps = Partial<
  { readonly [adType in AdType]: AdLevelDetailsProps }
>;

type SalesChannelDetailsProps = {
  readonly [salesChannel in FlywheelSalesChannel]: AdTypeDetailsProps;
};

export const SALES_CHANNEL_DETAILS_AD_LEVEL_CONFIG: SalesChannelDetailsProps = {
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SponsoredProducts]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups],
      [AdLevel.AdGroups]: [AdLevel.ProductAds, AdLevel.KeywordTargets],
      [AdLevel.ProductAds]: [AdLevel.ProductAds, AdLevel.KeywordTargets],
      [AdLevel.KeywordTargets]: [AdLevel.KeywordTargets],
    },
    [AdType.SearchBrandAmplifier]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups],
      [AdLevel.AdGroups]: [
        AdLevel.Profiles,
        AdLevel.ProductAds,
        AdLevel.KeywordTargets,
      ],
    },
  },
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SponsoredProducts]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups],
      [AdLevel.AdGroups]: [
        AdLevel.ProductAds,
        AdLevel.KeywordTargets,
        AdLevel.ProductTargets,
      ],
      [AdLevel.ProductAds]: [
        AdLevel.ProductAds,
        AdLevel.KeywordTargets,
        AdLevel.ProductTargets,
      ],
      [AdLevel.KeywordTargets]: [AdLevel.KeywordTargets],
      [AdLevel.ProductTargets]: [AdLevel.ProductTargets],
    },
    [AdType.SponsoredBrands]: {
      [AdLevel.Campaigns]: [AdLevel.KeywordTargets, AdLevel.ProductTargets],
      [AdLevel.KeywordTargets]: [AdLevel.KeywordTargets],
      [AdLevel.ProductTargets]: [AdLevel.ProductTargets],
    },
    [AdType.SponsoredDisplay]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups],
      [AdLevel.AdGroups]: [AdLevel.ProductAds, AdLevel.Targets],
      [AdLevel.ProductAds]: [AdLevel.ProductAds, AdLevel.Targets],
      [AdLevel.Targets]: [AdLevel.Targets],
    },
  },
};

export interface ValidateUniqueValuesRequest {
  readonly merchantCountryId: string;
  readonly entityType: EntityType;
  readonly value: string;
  readonly column: string;
  readonly parentEntityId?: string;
}

export interface ValidateUniqueValuesResponse {
  readonly entityType: EntityType;
  readonly value: string;
  readonly column: string;
  readonly isValid: boolean;
}

export type MerchantInfo = Pick<
  MerchantCountry,
  'merchantCountryId' | 'salesChannelId' | 'merchantType'
> & {
  readonly merchantCountryName: string;
  readonly salesChannelName: string;
};

export interface SkuAdPerformanceRequest {
  readonly merchantCountries: MerchantInfo[];
  readonly advertisingFields: string[];
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: string;
  readonly previousPeriodIncluded: boolean;
}

export type SkuAdPerformanceData = {
  readonly productCatalogId: string;
  readonly advertisingDetails: SkuAdPerformanceAdvertisingDetails;
  readonly currentMetrics: SkuAdPerformanceMetrics;
  readonly previousMetrics?: SkuAdPerformanceMetrics;
};

export type SkuAdPerformanceAdvertisingDetails = {
  readonly salesChannelId?: string;
  readonly merchantCountryId?: string;
  readonly campaignName?: string;
  readonly adGroupName?: string;
  readonly campaignType?: string;
  readonly adStatus?: string;
};

export type SkuAdPerformanceMetrics = {
  readonly clicks?: number;
  readonly impressions?: number;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly acosTotal?: number;
  readonly adConversions?: number;
  readonly conversionRate?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
};

export type SkuAdPerformanceSummary = {
  readonly currentMetrics: SkuAdPerformanceMetrics;
  readonly previousMetrics: SkuAdPerformanceMetrics;
};
export const ADLEVEL_TO_ADLEVEL_IN_MANAGE_BIDDING_UPLOAD_REQUEST_MAPPER: Partial<
  Record<AdLevel, string>
> = {
  [AdLevel.Campaigns]: 'campaign',
  [AdLevel.AdGroups]: 'adgroup',
};

export interface ManageBiddingSecureUrlResponse {
  readonly url: string;
}

export interface ManageBiddingValidationResponse {
  readonly status: string;
  readonly message: string;
  readonly validationErrorFileName?: string;
  readonly successRecordFileName?: string;
  readonly totalRows: number;
  readonly invalidRows: number;
}

export enum CampaignCostType {
  CPC = 'cpc',
  VCPM = 'vcpm',
}

export enum CompassActionCardType {
  MissingAcosTargetData = 'MissingAcosTargetData',
}

export interface MissingAcosTargetData {
  readonly completedAcosTargetCount: number;
  readonly totalAcosTargetCount: number;
  readonly missingAcos: MissingAcos[];
}

export interface MissingAcos {
  readonly salesChannelId: string;
  readonly salesChannelName: FlywheelSalesChannel;
  readonly adsDataPerMerchantCountry: AdsDataPerMerchantCountry[];
}

export enum AdvertisingType {
  'SponsoredProducts' = 'SponsoredProducts',
  'SponsoredBrands' = 'SponsoredBrands',
  'SponsoredDisplay' = 'SponsoredDisplay',
}

export interface AdsDataPerMerchantCountry {
  readonly actionCardType?: string;
  readonly merchantCountryId: string;
  readonly missingCount: number;
  readonly advertisingType: AdvertisingType;
  readonly entityType: EntityType;
  readonly country: string;
  readonly merchantName: string;
}

export const MAP_ENTITY_TYPE_TO_AD_LEVEL: Record<EntityType, AdLevel> = {
  [EntityType.Campaign]: AdLevel.Campaigns,
  [EntityType.AdGroup]: AdLevel.AdGroups,
};

export const MAP_ADVERTISING_TYPE_TO_ADTYPE: Record<AdvertisingType, AdType> = {
  [AdvertisingType.SponsoredProducts]: AdType.SponsoredProducts,
  [AdvertisingType.SponsoredDisplay]: AdType.SponsoredDisplay,
  [AdvertisingType.SponsoredBrands]: AdType.SponsoredBrands,
};

export interface RedirectStateParams {
  readonly tableMode?: DualModes;
  readonly filters?: Filter[];
}

// start of profiles data type for SBA

export interface ProfileApiDataRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly merchantCountryIds: string[];
  readonly channelId: string;
  readonly advertisingType: AdType;
  readonly salesChannelId: string;
  readonly merchantType: MerchantType | undefined;
  readonly profileDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
}

export interface ProfileApiDataDetails {
  readonly profileId: string;
  readonly profileDetails: ProfileDetails;
  readonly channelSettings: ProfileChannelSettings;
  readonly profilePerformance: ProfileAdGroupPerformance;
}

export interface ProfileAdGroupPerformance {
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly acosTotal?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly unitsSold?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInNumber;
  readonly adSalesDirect?: MoneyWithAmountInNumber;
  readonly acosDirect?: number;
  readonly roasDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInNumber;
  readonly brandClickRevenue?: MoneyWithAmountInNumber;
}

export interface ProfileChannelSettings {
  readonly headline?: string;
  readonly clickUrl?: string;
  readonly logo?: string;
}

export interface ProfileDetails {
  readonly [key: string]: ProfileDetailsType;
  readonly brand: string;
  readonly merchantCountryId?: string;
  readonly adGroupName?: string;
  readonly adGroupId?: string;
  readonly adGroupStatus?: AdGroupStatus;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
  readonly campaignName?: string;
  readonly campaignId?: string;
  readonly campaignStatus?: CampaignStatus;
  readonly campaignTargetingType?: CampaignTargetingType;
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly getCampaignLifetimeBudget?: MoneyWithAmountInString;
  readonly profileReviewStatus?: ProfileReviewStatus;
}

export interface ProfileReviewStatus {
  readonly reviewStatus: ReviewStatus;
  readonly reviewReason: string;
}

export enum ReviewStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

// end of profiles data type for SBA
export const SUPPORTED_MERCHANT_COUNTRIES_PER_CHANNEL: Record<
  FlywheelSalesChannel,
  MerchantCountryCode[]
> = {
  [FlywheelSalesChannel.Amazon]: [
    MerchantCountryCode.BR,
    MerchantCountryCode.CA,
    MerchantCountryCode.ES,
    MerchantCountryCode.SG,
    MerchantCountryCode.US,
    MerchantCountryCode.FR,
    MerchantCountryCode.JP,
    MerchantCountryCode.IT,
    MerchantCountryCode.GB,
    MerchantCountryCode.AE,
    MerchantCountryCode.AU,
    MerchantCountryCode.MX,
    MerchantCountryCode.NL,
    MerchantCountryCode.IN,
    MerchantCountryCode.KSA,
    MerchantCountryCode.DE,
    MerchantCountryCode.SE,
  ],
  [FlywheelSalesChannel.Walmart]: [MerchantCountryCode.US],
};

export const ICON_BY_MERCHANT_NAME: Record<string, SvgIcon> = {
  [MerchantlSalesChannel.Amazon]: AmazonDarkIcon,
  [MerchantlSalesChannel.Walmart]: WalmartDarkIcon,
};
