import { PaginatedResult } from '../clients/types';

export enum FlywheelSalesChannel {
  Amazon = 'amazon',
  Walmart = 'walmart',
}

export interface UserDetails {
  id: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  accountPermissions: AccountPermission[];
}

export type UserResponseType = {
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    state: UserStatus;
  };
  role: {
    role: Role;
    id: string;
  };
};
export interface SalesChannelsResponseType {
  readonly salesChannels: Array<SalesChannel>;
}

export interface SalesChannel {
  readonly salesChannelId: string;
  readonly name: string;
  readonly merchantCountries: Array<MerchantCountry>;
}

export interface AllSalesChannel {
  readonly id: string;
  readonly name: string;
  readonly createdAt: string;
}

export interface MerchantsBySalesChannel {
  readonly flywheelSalesChannel: FlywheelSalesChannel;
  readonly merchants: SalesChannelData[];
}

export enum MerchantType {
  Seller = 'seller',
  Vendor = 'vendor',
}
export interface MerchantCountry {
  readonly merchantCountryId: string;
  readonly merchantName: string;
  readonly salesChannelId?: string;
  readonly merchantType: MerchantType;
  readonly country: string;
}

export interface MerchantClaimResponseType {
  readonly merchantCountriesNotClaimed?: string[];
}

export interface MerchantCorrelationResponseType {
  readonly status: FAMConnectionStatus;
  readonly merchantCountries: Array<MerchantCountries>;
}

export interface MerchantCountries {
  countryCode: string;
  extMerchantCountryId: string;
  extMerchantId: string;
  merchantCountryId: string;
  merchantType: string;
  owningAccount: OwningAccount | null;
}

export interface OwningAccount {
  companyName: string;
  id: string;
  onboardedAt: Date | string; // TODO: remove when FD-681 has been incorporated
}

export type UserFetchedResponseType = UserResponseType | null;

export interface AccountPermission {
  account: Account;
  role: Role;
  subscriptionPermissions: SubscriptionPermission[];
}

export interface ChannelAuthenticationTimes {
  firstAuthenticatedAt: string;
  salesChannelId: string;
}

export interface Account {
  id: string;
  companyName: string;
  onboardedAt: string;
  freeTrialStartedAt?: string;
  lockedAt?: string;
  isManaged: boolean;
  channelAuthenticationTimes?: ChannelAuthenticationTimes[];
}

/**
 * @see https://github.com/teikametrics/flywheel-account-model/blob/master/flywheel_account_model/models/role.py
 */
export enum Role {
  ACCOUNT_OWNER = 'account_owner',
  ADMIN = 'admin',
  ANALYST = 'analyst', // NOTE: This role is not implemented as of 2021-03-03
}

/**
 * @see https://github.com/teikametrics/flywheel-account-model/blob/master/flywheel_account_model/models/user_account_state.py
 */
export enum UserStatus {
  Expired = 'expired', // 'Invitation expired',
  InviteSent = 'pending', // 'Invitation sent',
  Verified = 'active', // 'Verified',
}

export interface SubscriptionPermission {
  merchantCountries: string[];
  subscription: Subscription;
}

export interface Subscription {
  accountId: string;
  productVertical: ProductVertical;
}

// TODO: Use PRODUCT_SUBSCRIPTIONS strong type
export interface ProductVertical {
  id: string;
  name: string;
}

// TODO: remove AdConnectionStatus and ProductConnectionStatus so we can
// standardize statuses in https://teikametrics.atlassian.net/browse/FD-714

/**
 * See: https://github.com/teikametrics/flywheel-account-model/blob/master/flywheel_account_model/models/channel_api_connection.py#L15
 */
/* Changed from AdConnectionStatus to FAMConnectionStatus to be in sync with the enum
 * that the backend is using since this enum used for product and ads
 */

/**
 * For more detail information regarding this FAMConnectionStatus enum please read the details below:
 * ApiConnectionStatus(Enum) from backend is located here  flywheel_account_model/models/channel_api_connection.py:16
 * Connection status combinations: Onboarding Page Truth Table -->
 * https://docs.google.com/spreadsheets/d/1pW9xfaaK7d4AVborEFc0nUtMCLQ2PNoTUvsRgY2WymU/edit#gid=0
 * */
export enum FAMConnectionStatus {
  Processing = 'PendingUser', // User sent email
  Syncing = 'PendingVerify', // Data sent to Channel integration to get merchant countries
  PendingWalmart = 'PendingWalmart', // Waiting on walmart to give us access
  Active = 'Active', // Connection succeeded
  Inactive = 'Inactive', // Permissions to the API are revoked
  Failed = 'Failed', // Connection failed
}

export enum ProductConnectionStatus {
  Syncing = 'Syncing',
  Connected = 'Connected',
  Error = 'Error',
}

export enum ConnectionStatus {
  Syncing = 'Syncing',
  Processing = 'Processing',
  Connected = 'Connected',
  NotConnected = 'NotConnected',
  Failed = 'Failed',
}

export type SalesChannelData = {
  readonly id: string;
  readonly name: string;
  readonly country: string;
  readonly extMerchantId: string;
  readonly aoEnabled: boolean;
  readonly aiEnabled: boolean;
  readonly adConnectionStatus: string;
  readonly productConnectionStatus: string;
  readonly extMerchantType?: string;
  readonly updatedAt: Date;
};

export interface SalesChannelUpdateData {
  readonly name?: string;
  readonly aoEnabled?: boolean;
  readonly aiEnabled?: boolean;
}

export type AccountSalesChannelPaginatedResult = PaginatedResult<SalesChannelData>;

export interface SalesChannelResponse {
  readonly filteredElements: number;
  readonly elements: SalesChannelData[];
}

export interface AmazonAdsConnectResult {
  readonly id: string;
}

export interface WalmartProductConnectResult {
  readonly id: string;
}

export interface ProductDataSyncRequestType {
  readonly id: string; //For both Customer ID and Client ID
  readonly sellerId: string;
  readonly secretKey: string;
  readonly api: string; //Indicates whether 1P ro 3P Seller Type
  readonly country: string;
}

export interface ProductSubscriptionEventResponse {
  readonly account_id: string;
  readonly product_vertical_id: string;
  readonly accountEvents: AccountEvent[];
  readonly merchant_country_events: MerchantCountryEvent[];
}

export interface AccountEvent {
  readonly action: SubscriptionActionType;
  readonly date: string;
}

export interface MerchantCountryEvent extends AccountEvent {
  readonly merchant_country_id: string;
}

export enum SubscriptionActionType {
  Created = 'Created',
  Deleted = 'Deleted',
}
