import React from 'react';
import { Modal } from '@teikametrics/tm-design-system/components/Modal';
import I18nKey from '../../../lib/types/I18nKey';
import { LightningIcon } from '@teikametrics/tm-design-system/img/icons';
import { Icon, IconSize } from '@teikametrics/tm-design-system/components/Icon';
import {
  Typography,
  TypographySize,
} from '@teikametrics/tm-design-system/components/Typography';
import { FormattedMessage, useIntl } from 'react-intl';

export interface UpgradeModalProps {
  onClose: () => void;
  onYesClick: () => void;
  show: boolean;
  count: number;
}

const ModalHeader = () => {
  return (
    <Icon
      size={IconSize.Large}
      svg={LightningIcon}
      className="mr-8 text-purple-500"
    />
  );
};

export const UpgradeModal: React.FC<UpgradeModalProps> = ({
  onClose,
  onYesClick,
  show = false,
  count = 0,
}) => {
  const intl = useIntl();

  return (
    <Modal
      showModal={show}
      icon={<ModalHeader />}
      header={intl.formatMessage(
        {
          id:
            I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ALL_CONFIRM,
        },
        {
          products: intl.formatNumber(count),
        }
      )}
      mainAction={{
        label:
          I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_ACTION_YES,
        onClick: onYesClick,
      }}
      secondaryAction={{
        label:
          I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_ACTION_NO,
        onClick: onClose,
      }}
    >
      <Typography size={TypographySize.base}>
        <FormattedMessage
          id={
            I18nKey.UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_CONTENT
          }
        />
      </Typography>
    </Modal>
  );
};
