import { IdToken } from '@auth0/auth0-react';
import React, { createContext, useState } from 'react';
import { UserDetails } from '../../lib/types/Fam';
import { isAOSubscribed } from './selectors';
import { AccountStatusResponse } from '../../lib/types/Billing';
import { createBillingApiClient } from '../../lib/clients/BillingApiClient';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import noop from 'lodash/noop';
import {
  AsyncRequest,
  AsyncRequestNotStarted,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  asyncRequestIsComplete,
  asyncRequestIsFailed,
} from '../../lib/utilities/asyncRequest';

export interface UserInfo {
  idToken?: IdToken;
  userDetails?: UserDetails;
  currentAccountId?: string;
}

export interface UserContextState {
  userInfo: UserInfo;
  billingInfo?: AccountStatusResponse;
  aoSubscribed?: boolean;
  updateUserInfo: (info: UserInfo) => void;
  updateBillingInfo: (info: AsyncRequest<AccountStatusResponse>) => void;
  refreshUserContext: () => void;
  isBillingLoaded: boolean;
}

export interface TeikaUserProviderProps {
  children: JSX.Element;
}

const initialState = {
  userInfo: {},
  aoSubscribed: false,
  updateUserInfo: noop,
  updateBillingInfo: noop,
  refreshUserContext: noop,
  billingInfo: undefined,
  isBillingLoaded: false,
};

const UserContext = createContext<UserContextState>(initialState);
UserContext.displayName = 'UserContext';

const { Provider } = UserContext;

const UserProvider: React.FC<TeikaUserProviderProps> = ({ children }) => {
  const [userInfo, updateUserInfo] = useState<UserInfo>({});
  const [billingInfo, updateBillingInfo] = useState<
    AsyncRequest<AccountStatusResponse>
  >(AsyncRequestNotStarted());
  const aoSubscribed = isAOSubscribed(userInfo.userDetails);

  const isBillingLoaded =
    asyncRequestIsComplete(billingInfo) || asyncRequestIsFailed(billingInfo);

  const billingData = asyncRequestIsComplete(billingInfo)
    ? billingInfo.result
    : undefined;

  const refreshUserContext = () => {
    if (userInfo.idToken && userInfo.currentAccountId) {
      createBillingApiClient(userInfo.idToken)
        .getAccountStatus(userInfo.currentAccountId)
        .then((billingInfo) => {
          updateBillingInfo(AsyncRequestCompleted(billingInfo));
        })
        .catch(() => {
          updateBillingInfo(AsyncRequestFailed(undefined));
        });
      createFAMApiClient(userInfo.idToken)
        .getUserDetails()
        .then((userDetails) =>
          updateUserInfo({
            ...userInfo,
            userDetails,
          })
        );
    }
  };

  return (
    <Provider
      value={{
        userInfo,
        aoSubscribed,
        updateUserInfo,
        updateBillingInfo,
        billingInfo: billingData,
        refreshUserContext,
        isBillingLoaded,
      }}
    >
      {children}
    </Provider>
  );
};

export { UserContext, UserProvider };
