import React from 'react';
import ReactDOM from 'react-dom';
import './sass/index.scss';
import './sass/tailwind.output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Router as ReachRouter, navigate } from '@reach/router';
import enMessages from './translations/en.json';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { datadogRum } from '@datadog/browser-rum';
import { fromNullable } from 'fp-ts/lib/Option';
import { debugContextDevtool } from 'react-context-devtool';
import Flywheel from '@teikametrics/tm-design-system/Flywheel';
import { IntlProvider } from 'react-intl';
import { enUS } from '@teikametrics/tm-design-system/translations';
import { mergeDeep } from '@teikametrics/tm-design-system/utils/mergeDeep';
import { parseQuery } from './lib/utilities/queryString';

if (process.env.REACT_APP_ENV !== 'development') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APP_ID!,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DD_RUM_SERVICE_NAME!,
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    trackInteractions: true,
  });
}

const messages = mergeDeep(enUS, enMessages);
const SIGN_UP = 'signup';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  release: process.env.REACT_APP_SENTRY_RELEASE,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const onRedirectCallback = (appState: AppState) => {
  const navigateTo = fromNullable(appState.returnTo).getOrElse('/');
  navigate(navigateTo, { replace: true });
};

const urlParams = new URLSearchParams(window.location.search);
const SCREEN_HINT =
  urlParams.has('action') && urlParams.get('action') === SIGN_UP ? SIGN_UP : '';

const getUtmQueryParams = () => {
  const parsedQuery = parseQuery(window.location.search);
  if (!parsedQuery) {
    return '';
  }

  const whitelist = [
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
    'utm_term',
    'tap_a',
    'tap_s',
  ];
  const filteredQuery = {} as { [key: string]: any };
  Object.keys(parsedQuery).forEach((key) => {
    const name = decodeURIComponent(key);
    const value = decodeURIComponent(parsedQuery[key]);

    if (whitelist.includes(name)) {
      filteredQuery[name] = value;
    }
  });

  return filteredQuery;
};

const utmParams = getUtmQueryParams();

const redirectUri = window.location.origin + '/login/callback';

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    redirectUri={redirectUri}
    onRedirectCallback={onRedirectCallback}
    screen_hint={SCREEN_HINT}
    {...utmParams}
  >
    <IntlProvider locale="en" messages={messages}>
      <Flywheel>
        <ReachRouter>
          <App default={true} />
        </ReachRouter>
      </Flywheel>
    </IntlProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'development') {
  document.title = `[DEV] ${document.title}`;
}

debugContextDevtool(document.getElementById('root'), {});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.ENVIRONMENT !== 'production') {
  reportWebVitals(console.log);
}
