import { StringMap } from '../types';
import { MerchantCountryCode } from '../types/AOSharedTypes';

export enum CurrencyCode {
  AED = 'AED',
  BRL = 'BRL',
  SGD = 'SGD',
  EUR = 'EUR',
  USD = 'USD',
  CAD = 'CAD',
  AUD = 'AUD',
  MXN = 'MXN',
  CRC = 'CRC',
  GBP = 'GBP',
  ILS = 'ILS',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  NGN = 'NGN',
  PHP = 'PHP',
  PLN = 'PLN',
  PYG = 'PYG',
  THB = 'THB',
  UAH = 'UAH',
  VND = 'VND',
  TRY = 'TRY',
}

// https://stackoverflow.com/questions/19373860/convert-currency-names-to-currency-symbol
export const getCurrencySymbol = (currencyCode: string) => {
  const currencySymbols: StringMap<string> = {
    [CurrencyCode.AED]: 'AED', // United Arab Emirates dirham
    [CurrencyCode.BRL]: 'R$', // Brazilian real
    [CurrencyCode.SGD]: 'SGD', //Singapore dollar
    [CurrencyCode.USD]: '$', // US Dollar
    [CurrencyCode.EUR]: '€', // Euro
    [CurrencyCode.CAD]: 'CA$', // Canadian Dollar
    [CurrencyCode.AUD]: 'A$', // Australian Dollar
    [CurrencyCode.MXN]: 'MX$', // Mexican Peso
    [CurrencyCode.CRC]: '₡', // Costa Rican Colón
    [CurrencyCode.GBP]: '£', // British Pound Sterling
    [CurrencyCode.ILS]: '₪', // Israeli New Sheqel
    [CurrencyCode.INR]: '₹', // Indian Rupee
    [CurrencyCode.JPY]: '¥', // Japanese Yen
    [CurrencyCode.KRW]: '₩', // South Korean Won
    [CurrencyCode.NGN]: '₦', // Nigerian Naira
    [CurrencyCode.PHP]: '₱', // Philippine Peso
    [CurrencyCode.PLN]: 'zł', // Polish Zloty
    [CurrencyCode.PYG]: '₲', // Paraguayan Guarani
    [CurrencyCode.THB]: '฿', // Thai Baht
    [CurrencyCode.UAH]: '₴', // Ukrainian Hryvnia
    [CurrencyCode.VND]: '₫', // Vietnamese Dong
    [CurrencyCode.TRY]: '₺', // Turkish Lira
  };
  return currencySymbols[currencyCode] || currencyCode;
};

export const getCurrencySymbolFromMerchantCountryCode = (
  merchantCountryCode: MerchantCountryCode | null
) => {
  const currencySymbols: StringMap<string> = {
    US: '$', // US Dollar
    CA: 'CA$', // Canadian Dollar
    MX: 'MX$', // Mexican Peso
    BR: 'R$', // Brazilian Real
    UK: '£', // Pound sterling
    GB: '£', // Pound sterling
    DE: '€', // Euro
    FR: '€', // Euro
    ES: '€', // Euro
    IT: '€', // Euro
    NL: '€', // Euro
    JP: '¥', // Japanese Yen
    AU: 'AUD', // Australian Dollar
    AE: 'AED', // United Arab Emirates Dirham
    SG: 'S$', // Singapore dollar
    IN: '₹', // Indian rupee
  };
  return (
    (merchantCountryCode && currencySymbols[merchantCountryCode]) ||
    merchantCountryCode
  );
};

export const getCurrencyCodeFromMerchantCountryCode = (
  merchantCountryCode: MerchantCountryCode | null
) => {
  const currencySymbols: StringMap<string> = {
    US: 'USD', // US Dollar
    CA: 'CAD', // Canadian Dollar
    MX: 'MXN', // Mexican Peso
    BR: 'BRL', // Brazilian Real
    UK: 'GBP', // Pound sterling
    GB: 'GBP', // Pound sterling
    DE: 'EUR', // Euro
    FR: 'EUR', // Euro
    ES: 'EUR', // Euro
    IT: 'EUR', // Euro
    NL: 'EUR', // Euro
    JP: 'JPY', // Japanese Yen
    AU: 'AUD', // Australian Dollar
    AE: 'AED', // United Arab Emirates Dirham
    SG: 'SGD', // Singapore dollar
    IN: 'INR', // Indian Rupee
  };
  return (
    (merchantCountryCode && currencySymbols[merchantCountryCode]) ||
    merchantCountryCode
  );
};
