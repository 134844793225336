import { NavigateFn } from '@reach/router';
import * as Sentry from '@sentry/react';
import { Type } from '@teikametrics/tm-design-system/components/Toast';
import { AxiosError } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import { IntlShape } from 'react-intl';
import { NotificationContextState } from '../../containers/notificationProvider';
import { ClientError } from '../types/HttpErrorCodes';
import I18nKey from '../types/I18nKey';

const sanitizeBeforeLog = (axiosError: AxiosError) => {
  const config = cloneDeep(axiosError.config);
  if (config?.headers?.Authorization) {
    delete config.headers.Authorization;
  }
  const response = cloneDeep(axiosError.response);
  if (response?.config?.headers?.Authorization) {
    delete response.config.headers.Authorization;
  }
  Sentry.captureMessage(
    `Error: ${axiosError?.code}; errorMessage: ${
      axiosError?.message
    }; config: ${JSON.stringify(config)}; responseFrom: ${JSON.stringify(
      response
    )};`,
    Sentry.Severity.Error
  );
};

export const handleErrorResponse = (
  axiosError: AxiosError,
  toasts: NotificationContextState,
  intl: IntlShape,
  navigate: NavigateFn
) => {
  const failureToast = (description: string) => {
    const headline = intl.formatMessage({
      id: I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_HEADLINE,
    });
    toasts.addNotification({
      headline,
      description,
      type: Type.Attention,
      onClose: noop,
      dataTestId: 'httpResponseError',
    });
  };

  switch (axiosError?.response?.status) {
    case ClientError.HTTP_400_BAD_REQUEST:
      if (process.env.REACT_APP_ENV !== 'production') {
        failureToast(
          intl.formatMessage({
            id:
              I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_400,
          })
        );
      }
      break;
    case ClientError.HTTP_401_UNAUTHORIZED:
      failureToast(
        intl.formatMessage({
          id: I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_401,
        })
      );
      navigate('/logout');
      break;
    case ClientError.HTTP_403_FORBIDDEN:
      failureToast(
        intl.formatMessage({
          id: I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_403,
        })
      );
      // Temperarily removing logout navigation till we come up with a way
      // to handle 403 request
      // navigate('/logout');
      break;
    case ClientError.HTTP_404_NOT_FOUND:
      failureToast(
        intl.formatMessage({
          id: I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_404,
        })
      );
      break;
    case ClientError.HTTP_405_REQUEST_METHOD_NOT_ALLOWED:
      if (process.env.REACT_APP_ENV !== 'production') {
        failureToast(
          intl.formatMessage({
            id:
              I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_405,
          })
        );
      }
      break;
    case ClientError.HTTP_408_REQUEST_TIMEOUT:
      failureToast(
        intl.formatMessage({
          id: I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_408,
        })
      );
      break;
    case ClientError.HTTP_414_URL_LENGTH_EXCEEDS:
      if (process.env.REACT_APP_ENV !== 'production') {
        failureToast(
          intl.formatMessage({
            id:
              I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_414,
          })
        );
      }
      break;
    default:
      failureToast(
        intl.formatMessage({
          id: I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION,
        })
      );
  }

  sanitizeBeforeLog(axiosError);
};
