import React, { useContext, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useIntl } from 'react-intl';
import { Flywheel2LogoIcon as FlywheelLogo } from '@teikametrics/tm-design-system/img/branding';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system/components/Button';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { SellProductsQuestionnaire } from './components/SellProductsQuestionare';
import { AnnualSalesQuestionnaire } from './components/AnnualSalesQuestionare';
import { AccomplishFlywheelQuestionnaire } from './components/AccomplishFlywheelQuestionnare';
import { ChooseProductQuestionnaire } from './components/ChooseProductQuestionnare';
import { UsingFlywheelQuestionnaire } from './components/UsingFlyWheelQuestionnare';
import { CompletedSurvey } from './components/CompletedSurvey';
import { SurveyHeader } from '../../components/survey/header';
import I18nKey from '../../lib/types/I18nKey';
import * as Segment from '../../lib/utilities/segment';
import {
  SurveyQuestionHeaders,
  surveyQuestionsSteps,
  SurveyQuestionStep,
  SurveyAnswers,
} from './SurveyQuestions';
import { ReactComponent as ShieldIcon } from './shield.svg';

const Gradient: React.FC = () => (
  <div className="h-4 rounded-t bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500" />
);

interface OnboardingSurveyProps extends RouteComponentProps {
  surveyAnswers: SurveyAnswers;
  setSurveyAnswers: (answers: SurveyAnswers) => void;
  onComplete: () => void;
  step: SurveyQuestionHeaders | undefined;
  setStep: (step: SurveyQuestionHeaders) => void;
}

export const OnboardingSurvey: React.FC<OnboardingSurveyProps> = ({
  setSurveyAnswers,
  surveyAnswers,
  onComplete,
  step,
  setStep,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const userInfo = userContext.userInfo;

  const userDetails = useMemo(() => {
    const initialDetails = { firstName: '', lastName: '', companyName: '' };

    if (!userInfo?.idToken) {
      return initialDetails;
    }

    const metadata =
      userInfo.idToken['https://flywheel.teikametrics.com/metadata'];

    if (!metadata) {
      return initialDetails;
    }

    return {
      firstName: metadata.first_name,
      lastName: metadata.last_name,
      companyName: metadata.business_name,
    };
  }, [userInfo]);

  const intl = useIntl();

  const [WELCOME, DESCRIPTION, BUTTON] = [
    I18nKey.ONBOARDING_SURVEY_HEADER_TITLE_WELCOME_TO_FLYWHEEL,
    I18nKey.ONBOARDING_SURVEY_BODY_DESCRIPTION_WELCOME_TO_FLYWHEEL,
    I18nKey.ONBOARDING_SURVEY_BODY_BUTTON_WELCOME_TO_FLYWHEEL,
  ].map((id) => intl.formatMessage({ id }));

  const gotoNextStep = (step: SurveyQuestionHeaders) => {
    setStep(step);
  };

  const surveyStep: SurveyQuestionStep = step
    ? surveyQuestionsSteps[step]
    : ({} as SurveyQuestionStep);

  const surveyStepCount = {
    total: 5,
    current: surveyStep?.stepNumber ?? 1,
  };

  const saveSurveyAnswers = (
    singleSelect: boolean,
    answer: string | string[],
    checked?: boolean
  ) => {
    const answerState = surveyAnswers[step!!] || [];

    if (singleSelect) {
      setSurveyAnswers({ ...surveyAnswers, [step!!]: answer });
    } else {
      if (checked) {
        const filteredAnswers = answerState.filter(
          (item: string) => item !== answer
        );
        return setSurveyAnswers({
          ...surveyAnswers,
          [step!!]: filteredAnswers,
        });
      }
      if (answerState.includes(answer as string)) {
        return;
      }
      return setSurveyAnswers({
        ...surveyAnswers,
        [step!!]: [...answerState, answer],
      });
    }
  };

  React.useEffect(() => {
    Segment.track('Onboarding survey started');
  }, []);

  const getQuestionnaire = (step: SurveyQuestionHeaders) => {
    switch (step) {
      case SurveyQuestionHeaders.AnnualSales:
        return (
          <AnnualSalesQuestionnaire
            step={step}
            nextStep={gotoNextStep}
            companyName={userDetails.companyName}
            surveyAnswers={surveyAnswers}
            saveSurveyAnswers={saveSurveyAnswers}
          />
        );
      case SurveyQuestionHeaders.SellProducts:
        return (
          <SellProductsQuestionnaire
            surveyAnswers={surveyAnswers}
            step={step}
            nextStep={gotoNextStep}
            saveSurveyAnswers={saveSurveyAnswers}
          />
        );
      case SurveyQuestionHeaders.AccomplishFlywheel:
        return (
          <AccomplishFlywheelQuestionnaire
            step={step}
            nextStep={gotoNextStep}
            surveyAnswers={surveyAnswers}
            saveSurveyAnswers={saveSurveyAnswers}
          />
        );
      case SurveyQuestionHeaders.UsingFlywheel:
        return (
          <UsingFlywheelQuestionnaire
            step={step}
            surveyAnswers={surveyAnswers}
            saveSurveyAnswers={saveSurveyAnswers}
            nextStep={gotoNextStep}
          />
        );
      case SurveyQuestionHeaders.ChooseProducts:
        return (
          <ChooseProductQuestionnaire
            step={step}
            surveyAnswers={surveyAnswers}
            saveSurveyAnswers={saveSurveyAnswers}
            nextStep={gotoNextStep}
          />
        );
      case SurveyQuestionHeaders.CompletedSurvey:
        return (
          <CompletedSurvey
            name={userDetails.firstName}
            companyName={userDetails.companyName}
            onComplete={onComplete}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <SurveyHeader
        surveySteps={step ? surveyStepCount : undefined}
        userDetails={userDetails}
      />
      <div className="h-screen bg-grey-50">
        <div className="relative h-surveyContent text-grey-500 text-center text-base leading-none font-medium">
          {step && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <span className="text-purple-500">{surveyStepCount.current}</span>{' '}
              of {surveyStepCount.total}
            </div>
          )}
        </div>

        <div className="flex justify-center items-start">
          {step ? (
            getQuestionnaire(step)
          ) : (
            <div className="w-500 min-w-min bg-white border border-solid border-grey-200">
              <Gradient />
              <div className="pt-28 px-32 pb-36 flex flex-col items-center text-center">
                <div>
                  <FlywheelLogo />
                  <br />
                </div>
                <div className="leading-normal text-xl text-grey-900 font-medium mt-24">
                  {WELCOME}
                  {userDetails?.firstName && `, ${userDetails?.firstName}`}
                </div>
                <div className="leading-normal text-lg text-grey-700 mx-32 mt-12">
                  {DESCRIPTION}
                </div>

                <div className="flex items-center justify-center w-shield h-shield mt-16 shadow-lg rounded-full">
                  <ShieldIcon />
                </div>

                <hr className="text-grey-200 my-24 w-full" />
                <Button
                  size={ButtonSize.Large}
                  variant={ButtonVariant.Primary}
                  onClick={() => setStep(SurveyQuestionHeaders.SellProducts)}
                  label={BUTTON}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
