import { IdToken } from '@auth0/auth0-react';
import { createSKUApiClient } from '../clients/SKUApiClient';
import { MerchantCountrySalesChannel } from '../types/SKUSharedTypes';
import { CurrencyCode } from './currency';
import { DateTime } from 'luxon';
import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';
import { createBillingApiClient } from '../clients/BillingApiClient';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../types/SalesChannels';
import { createFAMApiClient } from '../clients/FAMApiClient';

const getMerchantCountries = async (token: IdToken, accountId: string) => {
  const famApiClient = createFAMApiClient(token);
  const salesChannelsIds = [AMAZON_SALES_CHANNEL_ID, WALMART_SALES_CHANNEL_ID];
  const merchantCountriesResponse = await famApiClient.getMerchantCountries(
    accountId,
    salesChannelsIds
  );
  return merchantCountriesResponse.merchantCountryInfoItems || [];
};

/**
 * Gets revenue totals from IO api for the account and associated MCs
 * @returns an object with data formatted for Segment tracking
 */
export const getRevenueTotals = async (token: IdToken, accountId: string) => {
  const merchantCountries = await getMerchantCountries(token, accountId);

  if (merchantCountries.length === 0) {
    return {};
  }

  // map the FAM MerchantCountry type to the format expected in the IO request
  const merchantCountySalesChannel: MerchantCountrySalesChannel[] = merchantCountries.map(
    (merchant) => ({
      merchantCountryId: merchant.merchantCountryId,
      salesChannelId: merchant.salesChannelId || '',
    })
  );

  const ioClient = createSKUApiClient(token);

  // we only care about 3 fields (adSales, adSpend, totalSales) over the last
  // 30 days, but the api requires all of the fields below to be included
  const requestBody = {
    startDate: DateTime.now().minus({ days: 31 }).toISODate(),
    endDate: DateTime.now().minus({ days: 1 }).toISODate(),
    currency: CurrencyCode.USD,
    advertisementFields: ['adSales', 'adSpend'],
    inventoryFields: [],
    platformFeesFields: [],
    profitabilityFields: ['totalSales'],
    productFields: [],
    skuDetailsFields: [
      'sku',
      'name',
      'imageUrl',
      'extItemId',
      'extItemType',
      'salesChannelId',
      'salesChannelName',
    ],
    merchantCountries: merchantCountySalesChannel,
    metricsPeriodType: 'CURRENT',
    previousPeriodIncluded: false,
  };

  const response = await ioClient.getSkuCatalogSummary(
    accountId,
    requestBody,
    {}
  )({ filters: [] });

  return {
    initial_actual_trailing_30_day_total_rev: roundToInt(
      response.profitabilityFields.totalSales?.amount
    ),
    initial_actual_trailing_30_day_ad_spend: roundToInt(
      response.advertisementFields.adSpend?.amount
    ),
    initial_actual_trailing_30_day_ad_rev: roundToInt(
      response.advertisementFields.adSales?.amount
    ),
  };
};

const roundToInt = (moneyWithAmountInString?: string) => {
  return moneyWithAmountInString && parseFloat(moneyWithAmountInString);
};

/**
 * Parses salesChannelData merchant countries to get automation status for each
 * sales channel. Any MC with `aiEnabled` will return `true` for the sales channel
 * @param salesChannelData the data returned from the SubscriptionContext
 * @returns segment traits object for tracking
 */
export const getAutomationStatusByChannel = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) => {
  const amazonStatus = salesChannelData
    .find((data) => data.salesChannel === 'amazon')
    ?.items.some((item) => item.aiEnabled);
  const walmartStatus = salesChannelData
    .find((data) => data.salesChannel === 'walmart')
    ?.items.some((item) => item.aiEnabled);
  return {
    amazon_automation_status: amazonStatus ? 'Yes' : 'No',
    walmart_automation_status: walmartStatus ? 'Yes' : 'No',
  };
};

/**
 * Gets Zuora info from Billing API
 * @returns an object with data formatted for Segment tracking
 */
export const getZuoraAccountInfo = async (
  token: IdToken,
  accountId: string
) => {
  const { zuoraAccountId } = await createBillingApiClient(token).getBillingInfo(
    accountId
  );
  return {
    zuora_account_id__url_: zuoraAccountId,
  };
};

/**
 * Gets the date when each sales channel was first authenticated for the
 * current account.
 * @param token
 * @param accountId
 * @returns an object with data formatted for Segment tracking
 */
export const getAuthenticationDate = async (
  token: IdToken,
  accountId: string
) => {
  const account = await createFAMApiClient(token)
    .getAccountDetails(accountId)
    .catch(undefined);
  if (!account) {
    return {};
  }
  const { channelAuthenticationTimes } = account;
  const amazonAuthTime = channelAuthenticationTimes?.find(
    (cat) => cat.salesChannelId === AMAZON_SALES_CHANNEL_ID
  );
  const walmartAuthTime = channelAuthenticationTimes?.find(
    (cat) => cat.salesChannelId === WALMART_SALES_CHANNEL_ID
  );
  return {
    amazon_first_authentication_date: amazonAuthTime?.firstAuthenticatedAt,
    walmart_first_authentication_date: walmartAuthTime?.firstAuthenticatedAt,
  };
};
