import { UserDetails } from '../types/Fam';
import { getCurrentAccountPermissions } from '../../containers/userProvider/selectors';
import { onDocumentReady } from './intercom';

export const FULL_STORY_ORG_ID = process.env.REACT_APP_FS_ORG_ID || '';
export const FULL_STORY_ENABLED = process.env.REACT_APP_ENABLE_FS === 'true';

declare global {
  interface Window {
    FS: any;
  }
}

export const userLoggedIn = (userDetails: UserDetails): void => {
  const logUserIntoFullStory = () => {
    if (FULL_STORY_ENABLED) {
      const accountInfo = getCurrentAccountPermissions(userDetails)!;

      const { id, firstName, lastName, email } = userDetails;

      if (window.FS) {
        window.FS.identify(id, {
          email,
          displayName: `${firstName} ${lastName}`,
          companyName: accountInfo.account.companyName,
        });
      }
    }
  };

  onDocumentReady(logUserIntoFullStory);
};
