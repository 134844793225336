import { useLocation, useNavigate } from '@reach/router';
import { AppNav } from '@teikametrics/tm-design-system/molecules/AppNav';
import { useContext } from 'react';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from './containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from './containers/saveChangesFlagProvider';
import { useUpgradeContext } from './containers/upgradeProvider';
import { HelpNavItem } from './HelpNavItem';
import { Role } from './lib/types/Fam';
import { getNavModulesForAccount } from './NavModules';
import { NavPaths } from './NavPaths';
interface NavbarProps {
  readonly userRole: Role;
  readonly showOnboardingNavPath: boolean;
  readonly daysRemainingInTrial: number;
  readonly isManaged: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({
  userRole,
  showOnboardingNavPath,
  daysRemainingInTrial,
  isManaged,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  const optimizelyContext = useContext<OptimizelyContextState>(
    OptimizelyContext
  );

  const { upgradeResponse, isAIPoweredEligible } = useUpgradeContext();

  const onClickNavLink = (
    path: string,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (saveChangesFlagContext.saveChangesData.editDataFlag) {
      e.preventDefault();
      saveChangesFlagContext.updateSaveChangesData({
        ...saveChangesFlagContext.saveChangesData,
        navPath: path,
        saveChangesModalIsOpen: true,
      });
    }
  };

  const upgradeFlowNavigation = () => {
    navigate(NavPaths.Upgrade);
  };

  return (
    <AppNav
      currentPath={location.pathname}
      modules={getNavModulesForAccount(
        userRole,
        optimizelyContext.featureFlags,
        showOnboardingNavPath,
        isAIPoweredEligible,
        upgradeFlowNavigation,
        upgradeResponse,
        isManaged
      )}
      showTrialRemainingDays={false}
      helpComponent={<HelpNavItem />}
      daysRemainingInTrial={daysRemainingInTrial}
      onClickNavLink={onClickNavLink}
    />
  );
};
