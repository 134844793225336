import { IdToken } from '@auth0/auth0-spa-js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ExportBusinessMetricsRequest } from 'lib/types/CompassSharedTypes';
import { DEFAULT_AXIOS_CONFIG } from '.';
import {
  generateSortQueryString,
  getPaginationParamsFromRequest,
} from '../../lib/factories/apiDataFetcherFactory';
import {
  DataAvailabilityResponse,
  ManageCogsSecureUrlResponse,
  MissingCogsResponse,
  ProductDetailsSummaryResponse,
  ProductGroupIdsResponse,
  SkuCatalogData,
  SkuCatalogDetailsRequest,
  SkuCatalogExportRequest,
  SkuCatalogSummary,
  SkuCatalogSummaryRequest,
  SkuOverviewData,
  SkuOverviewRequest,
  SkuOverviewSummary,
  SkuOverviewSummaryRequest,
} from '../../lib/types/SKUSharedTypes';
import { filterEmptyValues } from '../../lib/utilities/buildUrlUtilities';
import { filtersToQueryString } from '../utilities/filter';
import {
  FilteredRequest,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResult,
} from './types';

const SKU_BASE_URL = process.env.REACT_APP_SKU_HOST;

export interface SKUApiClient {
  readonly getDataAvailabilityInfo: (
    accountId: string,
    merchantCountryIds: string[]
  ) => Promise<DataAvailabilityResponse>;

  readonly getSkuCatalogDetails: (
    accountId: string,
    skuCatalogRequest: SkuCatalogDetailsRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuCatalogData>>;

  readonly getSkuCatalogSummary: (
    accountId: string,
    skuCatalogRequest: SkuCatalogSummaryRequest,
    queryParams: Record<string, string>
  ) => (filteredequest: FilteredRequest) => Promise<SkuCatalogSummary>;

  readonly exportSkuCatalog: (
    accountId: string,
    skuCatalogRequest: SkuCatalogExportRequest,
    queryParams: Record<string, string>
  ) => Promise<AxiosResponse<Blob>>;

  readonly manageCogsUpload: (
    accountId: string,
    fileName: string
  ) => Promise<void>;

  readonly exportCogsTemplate: (
    accountId: string,
    merchantCountryIds: string[]
  ) => Promise<AxiosResponse<Blob>>;

  readonly getSkuOverviewDetails: (
    accountId: string,
    skuOverviewRequest: SkuOverviewRequest,
    productGroupId: string
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuOverviewData>>;

  readonly getSkuOverviewSummary: (
    accountId: string,
    productGroupId: string,
    skuCatalogRequest: SkuOverviewSummaryRequest,
    queryParams: Record<string, string>
  ) => (filteredequest: FilteredRequest) => Promise<SkuOverviewSummary>;

  readonly getProductDetailsSummary: (
    accountId: string,
    productGroupId: string
  ) => Promise<ProductDetailsSummaryResponse>;

  readonly getProductGroupIds: (
    accountId: string,
    skus: string[]
  ) => Promise<ProductGroupIdsResponse>;

  readonly manageCogsGetS3SecureUrl: (
    accountId: string,
    fileName: string
  ) => Promise<AxiosResponse<ManageCogsSecureUrlResponse>>;

  readonly manageCogsS3FileUpload: (
    url: string,
    file: File
  ) => Promise<AxiosResponse<void>>;

  readonly getMissingCogs: (accountId: string) => Promise<MissingCogsResponse>;

  readonly exportCompassBusinessPerformanceCsvData: (
    accountId: string,
    requestBody: ExportBusinessMetricsRequest
  ) => Promise<AxiosResponse<Blob>>;
}

export const PATHS = Object.freeze({
  DATA_AVAILABILITY_INFO: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/data-availability-info`,
  SKU_CATALOG_DETAILS: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${dummyEndPoint ? 'dummy/' : ''}${accountId}/sku-catalog/data`,
  SKU_CATALOG_SUMMARY: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/data/aggregation`,
  SKU_CATALOG_EXPORT: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${dummyEndPoint ? 'dummy/' : ''}${accountId}/sku-catalog/export`,
  MANAGE_COGS_UPLOAD: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${dummyEndPoint ? 'dummy/' : ''}${accountId}/manage-cogs/upload`,
  EXPORT_COGS_TEMPLATE: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/cogs-template/export`,
  SKU_OVERVIEW_DETAILS: (
    accountId: string,
    productGroupId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/${productGroupId}/sku-details`,

  SKU_OVERVIEW_SUMMARY: (
    accountId: string,
    productGroupId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/${productGroupId}/sku-details/aggregation`,

  PRODUCT_DETAILS_SUMMARY: (accountId: string, productGroupId: string) =>
    `/accounts/${accountId}/sku-catalog/${productGroupId}/skuDetailsSummary`,

  PRODUCT_GROUP_IDS: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/productGroupId`,

  MANAGE_COGS_SECURE_URL: (accountId: string) =>
    `/accounts/${accountId}/manage-cogs/generate-signed-url`,

  MANAGE_COGS_S3_FILE_UPLOAD: (url: string) => url,

  MISSING_COGS: (accountId: string) => `/accounts/${accountId}/missing-cogs`,

  EXPORT_BUSINESS_PERFORMANCE_CSV: (accountId: string) =>
    `/accounts/${accountId}/compass/business/dataPoints/export`,
});

export const createSKUApiClient = (token: IdToken | null): SKUApiClient => {
  const config: AxiosRequestConfig = {
    ...DEFAULT_AXIOS_CONFIG,
    baseURL: SKU_BASE_URL,
  };
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token?.__raw}`,
  };

  const getDataAvailabilityInfo = async (
    accountId: string,
    merchantCountryIds: string[]
  ): Promise<DataAvailabilityResponse> => {
    const DEFAULT_DATA_AVAILABILITY_RESPONSE = {
      earliestAvailableDate: '',
      latestAvailableDate: '',
      lastSyncedAt: '',
      syncPerMerchantIds: [],
    };

    if (merchantCountryIds.length > 0) {
      return axios
        .post<DataAvailabilityResponse>(
          PATHS.DATA_AVAILABILITY_INFO(accountId),
          { merchantCountryIds },
          { ...config }
        )
        .then((resp) => resp.data)
        .catch(() => DEFAULT_DATA_AVAILABILITY_RESPONSE);
    } else {
      return DEFAULT_DATA_AVAILABILITY_RESPONSE;
    }
  };

  const getSkuCatalogDetails = (
    accountId: string,
    skuCatalogRequest: SkuCatalogDetailsRequest
  ) => async (
    paginatedRequest: PaginatedRequest
  ): Promise<PaginatedResult<SkuCatalogData>> => {
    const allParams = getPaginationParamsFromRequest(paginatedRequest);
    const params = filterEmptyValues(allParams);

    const response = await axios.post<PaginatedResponse<SkuCatalogData>>(
      PATHS.SKU_CATALOG_DETAILS(accountId),
      skuCatalogRequest,
      { ...config, params }
    );

    return {
      items: response.data.elements,
      totalItems: response.data.filteredElements,
    };
  };

  const getSkuCatalogSummary = (
    accountId: string,
    request: SkuCatalogSummaryRequest,
    queryParams: Record<string, string>
  ) => async (filteredRequest: FilteredRequest): Promise<SkuCatalogSummary> => {
    const allParams = {
      ...filteredRequest.extraParams,
      filter: filtersToQueryString(filteredRequest.filters),
      sort: generateSortQueryString(filteredRequest.sorts),
    };

    const params = filterEmptyValues(allParams);
    const response = await axios.post<SkuCatalogSummary>(
      PATHS.SKU_CATALOG_SUMMARY(accountId),
      {
        ...request,
      },
      { ...config, params: { ...queryParams, ...params } }
    );
    return response.data;
  };

  const exportSkuCatalog = async (
    accountId: string,
    skuCatalogRequest: SkuCatalogExportRequest,
    queryParams: Record<string, string>
  ): Promise<AxiosResponse<Blob>> => {
    return axios.post(
      PATHS.SKU_CATALOG_EXPORT(accountId),
      {
        ...skuCatalogRequest,
      },
      { ...config, params: queryParams }
    );
  };

  const manageCogsUpload = async (
    accountId: string,
    fileName: string
  ): Promise<void> => {
    const params = {
      cogsCsvS3FileName: fileName,
    };
    await axios.post(
      PATHS.MANAGE_COGS_UPLOAD(accountId),
      {},
      { ...config, params }
    );
  };

  const exportCogsTemplate = async (
    accountId: string,
    merchantCountryIds: string[]
  ): Promise<AxiosResponse<Blob>> => {
    return axios.post(
      PATHS.EXPORT_COGS_TEMPLATE(accountId),
      {
        merchantCountryIds,
      },
      { ...config }
    );
  };

  const getSkuOverviewDetails = (
    accountId: string,
    skuOverviewRequest: SkuOverviewRequest,
    productGroupId: string
  ) => async (
    paginatedRequest: PaginatedRequest
  ): Promise<PaginatedResult<SkuOverviewData>> => {
    const allParams = getPaginationParamsFromRequest(paginatedRequest);
    const params = filterEmptyValues(allParams);

    const response = await axios.post<PaginatedResponse<SkuOverviewData>>(
      PATHS.SKU_OVERVIEW_DETAILS(accountId, productGroupId),
      skuOverviewRequest,
      { ...config, params }
    );
    return {
      items: response.data.elements,
      totalItems: response.data.filteredElements,
    };
  };

  const getSkuOverviewSummary = (
    accountId: string,
    productGroupId: string,
    request: SkuOverviewSummaryRequest,
    queryParams: Record<string, string>
  ) => async (
    filteredRequest: FilteredRequest
  ): Promise<SkuOverviewSummary> => {
    const allParams = {
      ...filteredRequest.extraParams,
      filter: filtersToQueryString(filteredRequest.filters),
      sort: generateSortQueryString(filteredRequest.sorts),
    };

    const params = filterEmptyValues(allParams);

    const response = await axios.post<SkuOverviewSummary>(
      PATHS.SKU_OVERVIEW_SUMMARY(accountId, productGroupId),
      {
        ...request,
      },
      { ...config, params: { ...queryParams, ...params } }
    );
    return response.data;
  };

  const getProductDetailsSummary = async (
    accountId: string,
    productGroupId: string
  ) => {
    const response = await axios.get(
      PATHS.PRODUCT_DETAILS_SUMMARY(accountId, productGroupId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const getProductGroupIds = async (accountId: string, skus: string[]) => {
    const response = await axios.post(
      PATHS.PRODUCT_GROUP_IDS(accountId),
      {
        skus,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const manageCogsGetS3SecureUrl = async (
    accountId: string,
    fileName: string
  ): Promise<AxiosResponse<ManageCogsSecureUrlResponse>> => {
    const params = { biddingCsvFileName: fileName };
    return axios.get(PATHS.MANAGE_COGS_SECURE_URL(accountId), {
      ...config,
      params,
    });
  };

  const manageCogsS3FileUpload = async (url: string, file: File) => {
    return axios.put(PATHS.MANAGE_COGS_S3_FILE_UPLOAD(url), file, {});
  };

  const getMissingCogs = async (
    accountId: string
  ): Promise<MissingCogsResponse> => {
    const response = await axios.get<MissingCogsResponse>(
      PATHS.MISSING_COGS(accountId),
      {
        ...config,
      }
    );

    return response.data;
  };

  const exportCompassBusinessPerformanceCsvData = (
    accountId: string,
    requestBody: ExportBusinessMetricsRequest
  ) =>
    axios.post(PATHS.EXPORT_BUSINESS_PERFORMANCE_CSV(accountId), requestBody, {
      ...config,
      responseType: 'blob',
    });

  return {
    getDataAvailabilityInfo,
    getSkuCatalogDetails,
    getSkuCatalogSummary,
    exportSkuCatalog,
    manageCogsUpload,
    exportCogsTemplate,
    getSkuOverviewDetails,
    getSkuOverviewSummary,
    getProductDetailsSummary,
    getProductGroupIds,
    manageCogsGetS3SecureUrl,
    manageCogsS3FileUpload,
    getMissingCogs,
    exportCompassBusinessPerformanceCsvData,
  };
};
